import { faSearch, faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  FormControlLabel,
  IconButton,
  InputAdornment,
  Switch,
  TextField,
} from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import {
  EDGE_STYLE,
  generateUniqueNodeId,
  getNodeTypeFromNode,
  getNodeTypeKeyFromNodeType,
  makeNode,
  renameNode,
} from 'graph';
import { NodeTypeKey, NodeTypes } from 'graph/NodeTypes';
import {
  FlowGraphNode,
  FlowGraphNodeType,
  OriginDataType,
  PipelineDataType,
} from 'graph/types';
import useFlowGraph from 'hooks/useFlowGraph';
import useFlowRenderer from 'hooks/useFlowRenderer';
import useNodeInfoDialog from 'hooks/useNodeInfoDialog';
import { Fragment, KeyboardEvent, useEffect, useState } from 'react';
import {
  addEdge,
  Edge,
  OnConnectStartParams,
  Viewport,
  XYPosition,
} from 'reactflow';
import { NodeCategory } from '../../graph/nodeCategories';
import useGraph from '../../hooks/useGraph';
import FilteredNodeBrowser from './FilteredNodeBrowser';
import GroupedNodeBrowser from './GroupedNodeBrowser';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  compatibleNodes?: boolean;
  originDataType?: OriginDataType;
  originConnectionParams?: OnConnectStartParams;
  targetViewport?: Viewport;
}

const NodeBrowser = ({
  open,
  onClose,
  compatibleNodes,
  originDataType,
  originConnectionParams,
  targetViewport,
}: Props) => {
  const [filterText, setFilterText] = useState<string>('');
  const [autoClose, setAutoClose] = useState(true);
  const { setFlowGraph } = useFlowGraph();
  const { projectToCanvas } = useFlowRenderer();
  const { showNodeInfoDialog } = useNodeInfoDialog();
  const {
    graphState: { id: graphId },
  } = useGraph();

  const [highlightedNode, setHighlightedNode] = useState<
    FlowGraphNodeType | undefined
  >();

  useEffect(() => {
    if (open) {
      setFilterText('');
    }
  }, [open]);

  const addNode = (nodeType: NodeTypeKey) => {
    // Try to add a node in the top left of the screen. Failing that, place it at a reasonable spot.
    const windowPosition = {
      x: window.innerWidth / 4,
      y: window.innerHeight / 4,
    };
    const defaultPosition = { x: 250, y: 250 };

    const position = projectToCanvas(windowPosition) ?? defaultPosition;
    // Randomly offset so subsequent nodes don't overlap perfectly
    position.x += Math.random() * 100;
    position.y += Math.random() * 100;

    setFlowGraph((flowGraph) => ({
      ...flowGraph,
      nodes: [...flowGraph.nodes, makeNode(nodeType, flowGraph, position)],
    }));

    if (autoClose) onClose();
  };

  const addNodeAndEdges = (nodeTypeKey: NodeTypeKey) => {
    const originHandleId = originConnectionParams?.handleId;
    const originNodeId = originConnectionParams?.nodeId;
    const originHandleType = originConnectionParams?.handleType;
    // Try to add a node in the top left of the screen. Failing that, place it at a reasonable spot.
    const defaultPosition: XYPosition = {
      x: window.innerWidth / 4,
      y: window.innerHeight / 4,
    };

    const originKey = `${originNodeId}_${originHandleId}`;

    // Rename the node, being careful not to produce a duplicate name
    const receivingNodeId = `${originKey}_output`;

    const targetPosition = {
      x:
        originHandleType !== 'source'
          ? (targetViewport?.x ?? defaultPosition.x) - 220
          : targetViewport?.x ?? defaultPosition.x,
      y: targetViewport?.y ?? defaultPosition.y,
    };

    const position: XYPosition =
      projectToCanvas(targetPosition) ?? defaultPosition;

    setFlowGraph((previousFlowGraph) => {
      const newNode: FlowGraphNode = makeNode(
        nodeTypeKey,
        previousFlowGraph,
        position
      );

      const newNodeId = generateUniqueNodeId(
        previousFlowGraph,
        newNode,
        receivingNodeId
      );

      let newEdges: Edge[] = [...previousFlowGraph.edges];

      const targetNodeType: FlowGraphNodeType = getNodeTypeFromNode(
        newNode as FlowGraphNode
      );

      const sourceNodeInputType: PipelineDataType | undefined =
        originDataType?.type;

      const getTargetHandle =
        originHandleType === 'source'
          ? targetNodeType.inputTypes?.find((inputType) =>
              inputType?.type.compatibleWith(sourceNodeInputType!)
            )
          : targetNodeType.outputTypes?.find((outputType) =>
              outputType?.type.compatibleWith(sourceNodeInputType!)
            );

      const targetHandleKey = getTargetHandle ? getTargetHandle.key : '';

      if (originConnectionParams?.nodeId !== undefined) {
        newEdges =
          originHandleType === 'source'
            ? addEdge(
                {
                  source: originConnectionParams.nodeId,
                  sourceHandle: originConnectionParams.handleId,
                  target: newNode.id,
                  targetHandle: targetHandleKey,
                  style: EDGE_STYLE,
                },
                newEdges
              )
            : addEdge(
                {
                  source: newNode.id,
                  sourceHandle: targetHandleKey,
                  target: originConnectionParams.nodeId!,
                  targetHandle: originConnectionParams.handleId!,
                  style: EDGE_STYLE,
                },
                newEdges
              );
      }

      const newFlowGraph = {
        ...previousFlowGraph,
        nodes: [...previousFlowGraph.nodes, newNode],
        edges: newEdges,
      };

      if (targetNodeType.category.key === NodeCategory.OUTPUT.key) {
        return renameNode(newFlowGraph, graphId!, newNode.id, newNodeId);
      }
      return newFlowGraph;
    });

    if (autoClose) onClose();
  };

  const onSearchKeyPress = (e: KeyboardEvent) => {
    if (e.key == 'Enter') {
      if (filterText.length > 0 && highlightedNode) {
        // Find the node key for the highlighted node
        const nodeKey = getNodeTypeKeyFromNodeType(highlightedNode);
        if (nodeKey) {
          addNode(nodeKey);
          setFilterText('');
        }
      }
    }
  };

  return (
    <Fragment>
      <DraggableDialog
        title="Add Node"
        open={open}
        onClose={onClose}
        actions={
          <FormControlLabel
            key="auto-close"
            control={
              <Switch
                checked={autoClose}
                onChange={(e) => setAutoClose(e.target.checked)}
                color="primary"
              />
            }
            label="Close after adding"
          />
        }
        className={styles.dialog}
        allowClose
      >
        {compatibleNodes !== true && (
          <TextField
            value={filterText}
            onChange={(e) => setFilterText(e.target.value)}
            className={styles.search}
            label="Filter nodes..."
            autoFocus
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <FontAwesomeIcon icon={faSearch} />
                </InputAdornment>
              ),
              endAdornment:
                filterText.length > 0 ? (
                  <InputAdornment position="end">
                    <IconButton onClick={() => setFilterText('')} size="large">
                      <FontAwesomeIcon icon={faTimes} size="sm" />
                    </IconButton>
                  </InputAdornment>
                ) : (
                  <span />
                ),
            }}
            onKeyPress={onSearchKeyPress}
          />
        )}

        {filterText.length > 0 ? (
          <FilteredNodeBrowser
            nodeTypes={Object.values(NodeTypes)}
            highlightedNode={highlightedNode}
            setHighlightedNode={setHighlightedNode}
            filterText={filterText}
            addNode={addNode}
            showDetail={showNodeInfoDialog}
          />
        ) : (
          <GroupedNodeBrowser
            nodeTypes={Object.values(NodeTypes)}
            addNode={!compatibleNodes ? addNode : undefined}
            showDetail={showNodeInfoDialog}
            addNodeAndEdges={compatibleNodes ? addNodeAndEdges : undefined}
            originDataType={compatibleNodes ? originDataType : undefined}
            originConnectionParams={
              compatibleNodes ? originConnectionParams : undefined
            }
          />
        )}
      </DraggableDialog>
    </Fragment>
  );
};

export default NodeBrowser;
