import {
  Pipeline_UserPreferences,
  useGetUserPreferencesQuery,
  useSaveUserPreferencesMutation,
} from 'graphql/graphql';

import { createContext, ReactElement, useEffect } from 'react';
import useAuth from '../hooks/useAuth';
import useUserState from '../hooks/useUserState';

interface IUserPreferencesContext {
  updateUserPreferences: (arg: Pipeline_UserPreferences) => Promise<void>;
}

export const UserPreferencesContext = createContext<IUserPreferencesContext>(
  {} as IUserPreferencesContext
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { isPipeliner } = useAuth();
  const { data, refetch: refetchUserPreferences } = useGetUserPreferencesQuery({
    fetchPolicy: 'no-cache',
    skip: !isPipeliner,
  });
  const [saveUserPreferences] = useSaveUserPreferencesMutation();
  const getPreferences = data?.Pipeline_userPreferences;
  const { setUserPreferencesInLocalState } = useUserState();

  const updateUserPreferences = async (
    newUserPreferences: Pipeline_UserPreferences | undefined
  ) => {
    try {
      if (newUserPreferences) {
        await saveUserPreferences({
          variables: {
            userPreferences: {
              id: getPreferences?.id,
              soundEnabled: newUserPreferences.soundEnabled,
            },
          },
        });
        await refetchUserPreferences();
      }
    } catch (error) {
      console.error('Failed to update user preferences:', error);
    }
  };

  useEffect(() => {
    if (getPreferences) {
      setUserPreferencesInLocalState(getPreferences);
    }
  }, [getPreferences, setUserPreferencesInLocalState]);

  return (
    <UserPreferencesContext.Provider
      value={{
        updateUserPreferences,
      }}
    >
      {children}
    </UserPreferencesContext.Provider>
  );
};
