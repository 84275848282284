import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCopy,
  faFile,
  faFileAlt,
  faFolderOpen,
} from '@fortawesome/free-regular-svg-icons';
import {
  faBook,
  faBullhorn,
  faLink,
  faSignOutAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2';
import AppLogo from 'components/AppLogo';
import BackgroundImage from 'components/BackgroundImage';
import EmailDevelopersButton from 'components/EmailDevelopersButton';
import GraphPicker from 'components/GraphPicker';
import NewGraphDialog from 'components/NewGraphDialog';
import PublicLinkLoader from 'components/PublicLinkLoader';
import StyledButton from 'components/StyledButton';
import TemplatePicker from 'components/TemplatePicker';
import { APP_NAME } from 'config';
import { PIPELINES_HOME } from 'constants/zendesk';
import useAnnouncementsDialog from 'hooks/useAnnouncementsDialog';
import useAuth from 'hooks/useAuth';
import { useState } from 'react';
import styles from './index.module.scss';

const GridItem = ({ children }: { children: React.ReactNode }) => {
  return (
    <Grid
      xs={2}
      sm={4}
      md={4}
      padding={0}
      className={styles['grid-item']}
      maxWidth={144}
    >
      {children}
    </Grid>
  );
};

const IconWithText = ({
  icon,
  text,
}: {
  icon: IconDefinition;
  text: string;
}) => {
  return (
    <div className={styles['icon-with-text-container']}>
      <div className={styles['icon-container']}>
        <FontAwesomeIcon icon={icon} size="3x" className={styles['icon']} />
      </div>
      <Typography variant="button">{text}</Typography>
    </div>
  );
};

const StartScreen = () => {
  const { openAnnouncementsDialog } = useAnnouncementsDialog();
  const [newGraphDialogOpen, setNewGraphDialogOpen] = useState(false);
  const [graphPickerOpen, setGraphPickerOpen] = useState(false);
  const [templatePickerOpen, setTemplatePickerOpen] = useState(false);
  const [publicLinkLoaderOpen, setPublicLinkLoaderOpen] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLElement | null>(null);
  const { logout } = useAuth();

  const menuItem = (
    title: string,
    icon: IconDefinition,
    onClick: () => void,
    disabled = false
  ) => {
    // Wrap the click handler to also close the menu
    const onClickHandler = () => {
      onClick();
      setMenuAnchorEl(null);
    };

    return (
      <MenuItem onClick={onClickHandler} disabled={disabled}>
        <ListItemIcon>
          <FontAwesomeIcon icon={icon} />
        </ListItemIcon>
        <ListItemText primary={title} />
      </MenuItem>
    );
  };

  return (
    <div className={styles.container}>
      <BackgroundImage />

      <NewGraphDialog
        open={newGraphDialogOpen}
        onClose={() => setNewGraphDialogOpen(false)}
      />
      <GraphPicker
        open={graphPickerOpen}
        onClose={() => setGraphPickerOpen(false)}
      />
      <TemplatePicker
        open={templatePickerOpen}
        onClose={() => setTemplatePickerOpen(false)}
      />
      <PublicLinkLoader
        open={publicLinkLoaderOpen}
        onClose={() => setPublicLinkLoaderOpen(false)}
      />

      <div className={styles['content-container']}>
        <AppLogo />
        <div className={styles.content}>
          <Grid
            container
            spacing={{ xs: 2, md: 3 }}
            columns={{ xs: 4, sm: 8, md: 12 }}
            className={styles['grid']}
          >
            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={() => setNewGraphDialogOpen(true)}
              >
                <IconWithText icon={faFile} text="New" />
              </StyledButton>
            </GridItem>

            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={() => setGraphPickerOpen(true)}
              >
                <IconWithText icon={faFolderOpen} text="Open" />
              </StyledButton>
            </GridItem>

            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={(e) => setMenuAnchorEl(e.currentTarget)}
              >
                <IconWithText icon={faCopy} text="Import from" />
              </StyledButton>
              <Menu
                open={Boolean(menuAnchorEl)}
                onClose={() => setMenuAnchorEl(null)}
                anchorEl={menuAnchorEl}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'center',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'center',
                }}
              >
                {menuItem(
                  'Template...',
                  faFileAlt,
                  () => setTemplatePickerOpen(true),
                  false
                )}
                {menuItem(
                  'Public Link...',
                  faLink,
                  () => setPublicLinkLoaderOpen(true),
                  false
                )}
              </Menu>
            </GridItem>

            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={openAnnouncementsDialog}
              >
                <IconWithText icon={faBullhorn} text="Announcements" />
              </StyledButton>
            </GridItem>

            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={() => window.open(PIPELINES_HOME, '_blank')}
              >
                <IconWithText icon={faBook} text="Knowledge Base" />
              </StyledButton>
            </GridItem>

            <GridItem>
              <StyledButton
                fullWidth
                styleName="light"
                size="medium"
                onClick={logout}
              >
                <IconWithText icon={faSignOutAlt} text="Log Out" />
              </StyledButton>
            </GridItem>
          </Grid>
        </div>
        <Typography variant="body1" color="white" className={styles.disclaimer}>
          This is a pre-release version of {APP_NAME}, so you may encounter
          unexpected behaviour or results.
        </Typography>
        <Typography variant="body1" color="white">
          Please report any issues to us at <EmailDevelopersButton />
        </Typography>
      </div>
    </div>
  );
};

export default StartScreen;
