import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Pipelines Data Output',
  description:
    'Make arbitrary data available for download and Pipelines reconsumption.',
  descriptionDetail:
    'This node uploads arbitrary input data to the cloud and makes it available for download via the browser. The files produced by this node are appropriate for reimporting into other Pipelines using the Pipelines CSV Loader of Pipelines JSON Loader nodes.',
  icon: faFileExport,
  category: NodeCategory.OUTPUT,
  blobInputAffectorKey: 'data', // This is a special field which tells us which of the inputs controls the blob name
  moduleType: 'MultiFormatWriter',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060621543311',
  configTypes: [
    {
      key: 'file_format',
      type: PipelineConfigType.STRING('File Format').REQUIRED.OPTIONS([
        Pipeline_BlobDataType.Csv,
        Pipeline_BlobDataType.Json,
      ]).HIDDEN,
    },
    {
      key: 'blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}.pipe.{{extension}}')
        .REQUIRED.HIDDEN,
    },
    {
      key: 'overwrite_existing',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
  ],
  inputTypes: [
    {
      key: 'data',
      type: PipelineDataType.ANY.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.BOUNDING_BOXES,
        PipelineDataType.CALIBRATION_PATTERNS,
        PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS,
        PipelineDataType.CAMERA_INTRINSIC_MATRIX,
        PipelineDataType.CAMERA_PROJECTION_MATRIX,
        PipelineDataType.CLASS_LABELS,
        PipelineDataType.CLASS_SCORES,
        PipelineDataType.CUMULATIVE_DISTANCES,
        PipelineDataType.IMAGE_COORDINATES,
        PipelineDataType.POSE_2D,
        PipelineDataType.POSE_3D,
        PipelineDataType.SCALAR,
        PipelineDataType.TRACKED_BOUNDING_BOXES,
        PipelineDataType.TRACKED_WORLD_POSITIONS_2D,
        PipelineDataType.VELOCITIES,
        PipelineDataType.WORLD_CALIBRATION_PATTERNS,
        PipelineDataType.WORLD_COORDINATES_3D,
      ]).LABEL('Input data').REQUIRES_INPUT_WITH_FILEFORMAT,
    },
  ],
  outputTypes: [
    {
      key: 'output_data',
      type: PipelineDataType.ANY.BLOB_DATATYPE(Pipeline_BlobDataType.Json) // data type doesn't really matter, so leave as JSON
        .NO_HANDLE.LABEL('Output Data').DOWNLOADABLE,
    },
  ],
};
