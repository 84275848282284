import { faVideoSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import styles from './index.module.scss';

const ErrorPreview = () => {
  return (
    <div className={styles.container}>
      <FontAwesomeIcon
        className={styles.icon}
        icon={faVideoSlash}
        color="grey"
      />
      <Typography variant="body2" color="#6c757d">
        This video cannot be previewed in the browser.
      </Typography>
      <Typography variant="body2" color="#6c757d">
        Please download it to view it.
      </Typography>
    </div>
  );
};

export default ErrorPreview;
