import { useEffect, useState } from 'react';

/**
 * For the given time since the value last updated and a dictionary mapping times (ms) to messages,
 * find the greatest time that we've exceeded and return its corresponding message. If no times have
 * been exceeded, return undefined.
 * @param timeSinceUpdate - Time in milliseconds since the value was last updated.
 * @param stagnantMessages - The dict mapping millisecond times to messages.
 */
const getStagnantMessage = (
  timeSinceUpdate: number,
  stagnantMessages: { [key: number]: string }
): string | undefined => {
  // Get all the message times as numbers
  const timesAsNumbers = Object.keys(stagnantMessages).map((t) => parseInt(t));
  // Filter to retrieve only the times we're already passed
  const possibleTimes = timesAsNumbers.filter((t) => t <= timeSinceUpdate);
  // Sort them numerically, so the largest passed times are at the front.
  const sorted = possibleTimes.sort((a: number, b: number) => a - b).reverse();

  // Take the first message, if any.
  if (sorted.length > 0) {
    return stagnantMessages[sorted[0]];
  }
};

// Return true if a value is null or undefined (not really nullish, but the definition I want)
const isNullish = (val: any) => typeof val === 'undefined' || val == null;

function useStagnantMessage<T>(
  observedValue: T,
  stagnantMessages: { [key: number]: string },
  updateMs: number,
  disregardNullish?: boolean
): string | undefined {
  const [stagnantMessage, setStagnantMessage] = useState<string>();

  const [lastUpdateTime, setLastUpdateTime] = useState(Date.now());

  useEffect(() => {
    // Reset the last update time whenever the observedValue changes.
    setLastUpdateTime(Date.now());
  }, [observedValue]);

  useEffect(() => {
    // Every *updateMs* milliseconds, grab a message from the stagnantMessages.
    // If disregardNullish and observedValue is nullish, don't do anything.
    const doUpdate = () => {
      if (disregardNullish && isNullish(observedValue)) {
        if (stagnantMessage) setStagnantMessage(undefined);
        return;
      }
      const msSinceUpdate = Date.now() - lastUpdateTime;

      setStagnantMessage(getStagnantMessage(msSinceUpdate, stagnantMessages));
    };

    const interval = setInterval(doUpdate, updateMs);
    return () => clearInterval(interval);
  }, [
    disregardNullish,
    lastUpdateTime,
    observedValue,
    stagnantMessage,
    stagnantMessages,
    updateMs,
  ]);

  return stagnantMessage;
}

export default useStagnantMessage;
