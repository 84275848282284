import {
  isValidConnection as isValidConn,
  validateGraph,
} from 'graph/validation';
import useFlowGraph from 'hooks/useFlowGraph';
import useGraph from 'hooks/useGraph';

import { createContext, ReactElement, useEffect, useState } from 'react';
import { Connection } from 'reactflow';
import { useDebouncedCallback } from 'use-debounce';

export interface IValidationState {
  hasRequiredConfig: boolean; // Required config fields are set
  hasRequiredInputs: boolean; // Required inputs are connected
  hasRequiredOutputs: boolean; // Required outputs are connected
  valid: boolean; // All requirements are met
  missingRequiredConfigs?: string[]; // List of the missing config keys
  missingRequiredInputs?: string[]; // List of the missing input keys
  missingRequiredOutputs?: string[]; // List of the missing output keys
}

export interface INodeValidationState extends IValidationState {
  id: string;
}

export interface IGraphValidationState extends IValidationState {
  isFullyConnected: boolean; // The entire graph is connected
  hasSomeNodes: boolean; // Has at least 1 node
  nodeValidation: INodeValidationState[];
}
interface IGraphValidationContext {
  graphValidationState: IGraphValidationState;
  isValidConnection: (connection: Connection) => boolean;
}

const initialValidationState: IGraphValidationState = {
  hasRequiredConfig: false,
  hasRequiredInputs: false,
  hasRequiredOutputs: false,
  hasSomeNodes: false,
  valid: false,
  isFullyConnected: false,
  nodeValidation: [],
};

export const GraphValidationContext = createContext<IGraphValidationContext>(
  undefined!
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { flowGraph } = useFlowGraph();
  const { inputBlobs: inputs } = useGraph();

  const [graphValidationState, setGraphValidationState] =
    useState<IGraphValidationState>(initialValidationState);

  const validateAndSetDebounced = useDebouncedCallback(
    () => {
      setGraphValidationState(validateGraph(flowGraph, inputs));
    },
    1000,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    // Trigger the validation, debouncing
    validateAndSetDebounced();
  }, [flowGraph, inputs, validateAndSetDebounced]);

  const isValidConnection = (connection: Connection) =>
    isValidConn(flowGraph, connection);

  return (
    <GraphValidationContext.Provider
      value={{
        graphValidationState,
        isValidConnection,
      }}
    >
      {children}
    </GraphValidationContext.Provider>
  );
};
