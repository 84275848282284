import { faChartLine } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Time-Series Plot',
  description: 'Plot time-series data to a graph.',
  descriptionDetail:
    'This node plots a number of scalar values to a time-domain graph',
  icon: faChartLine,
  category: NodeCategory.GRAPH_PLOTTING,
  moduleType: 'TimeSeriesGrapher',
  configTypes: [
    {
      key: 'title',
      type: PipelineConfigType.STRING('Plot title').DESCRIPTION(
        'An optional title for the produced plot.'
      ),
    },
    {
      key: 'window_size',
      type: PipelineConfigType.INTEGER('History length').DESCRIPTION(
        'The number of datapoints to retain on the graph. Leave empty for unlimited.'
      ),
    },
    {
      key: 'headless',
      type: PipelineConfigType.BOOLEAN('Headless').CONSTANT_VALUE(true),
    },
    {
      key: 'dpi',
      type: PipelineConfigType.INTEGER('DPI').CONSTANT_VALUE(150),
    },
  ],
  inputTypes: [
    {
      key: 'data',
      type: PipelineDataType.SCALAR.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.SCALAR,
        PipelineDataType.VELOCITIES,
        PipelineDataType.CUMULATIVE_DISTANCES,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'view',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
