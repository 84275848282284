import { GraphValidationContext } from 'contexts/GraphValidation';
import { JobContext } from 'contexts/Job';
import { canStartStopJob } from 'graph/validation';
import { useContext } from 'react';
import useGraph from './useGraph';

const useGraphValidation = () => {
  const { jobStatus, uploadingCount } = useContext(JobContext);
  const { saveStatus } = useGraph();
  const validationCtx = useContext(GraphValidationContext);

  const { graphValidationState } = validationCtx;

  return {
    ...validationCtx,
    ...canStartStopJob(
      jobStatus,
      graphValidationState,
      saveStatus,
      uploadingCount
    ),
  };
};

export default useGraphValidation;
