import { createContext, Fragment, ReactElement } from 'react';
import { toast, ToastContainer, ToastOptions, Zoom } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useSound } from 'use-sound';
import notifySound from '../assets/notify.mp3';
import warnSound from '../assets/warn.mp3';
import useUserState from '../hooks/useUserState';

interface NotifyOptions extends ToastOptions {
  silent?: boolean;
}

export interface INotificationContext {
  info: (message: string, notifyOptions?: NotifyOptions) => void;
  warning: (message: string, notifyOptions?: NotifyOptions) => void;
  success: (message: string, notifyOptions?: NotifyOptions) => void;
  error: (message: string, notifyOptions?: NotifyOptions) => void;
}

export const NotificationContext = createContext<INotificationContext>(
  undefined!
);

const TOAST_SETTINGS: NotifyOptions = {
  position: 'top-center',
  autoClose: 3000,
  pauseOnHover: true,
  pauseOnFocusLoss: false,
  draggable: true,
  theme: 'colored',
  transition: Zoom,
};

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { userPreferences } = useUserState();
  const [playNotify] = useSound(notifySound as string);
  const [playWarn] = useSound(warnSound as string);

  const info = (message: string, notifyOptions?: NotifyOptions) => {
    if (!notifyOptions?.silent && userPreferences.soundEnabled) {
      playNotify();
    }
    toast.info(message, {
      ...TOAST_SETTINGS,
      ...notifyOptions,
    });
  };
  const warning = (message: string, notifyOptions?: NotifyOptions) => {
    if (!notifyOptions?.silent && userPreferences.soundEnabled) {
      playWarn();
    }
    toast.warning(message, {
      ...TOAST_SETTINGS,
      ...notifyOptions,
      className: 'toast-warning',
    });
  };
  const success = (message: string, notifyOptions?: NotifyOptions) => {
    if (!notifyOptions?.silent && userPreferences.soundEnabled) {
      playNotify();
    }
    toast.success(message, {
      ...TOAST_SETTINGS,
      ...notifyOptions,
    });
  };
  const error = (message: string, notifyOptions?: NotifyOptions) => {
    if (!notifyOptions?.silent && userPreferences.soundEnabled) {
      playWarn();
    }
    toast.error(message, {
      ...TOAST_SETTINGS,
      ...notifyOptions,
    });
  };

  return (
    <NotificationContext.Provider
      value={{
        info,
        warning,
        success,
        error,
      }}
    >
      <Fragment>
        <ToastContainer />

        {children}
      </Fragment>
    </NotificationContext.Provider>
  );
};
