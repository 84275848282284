import { Tooltip, TooltipProps } from '@mui/material';

const DelayedTooltip = ({
  enterDelay = 1000,
  enterNextDelay = 1000,
  placement = 'top',
  title,
  children,
  ...rest
}: TooltipProps) => (
  <Tooltip
    {...rest}
    placement={placement}
    enterDelay={enterDelay}
    enterNextDelay={enterNextDelay}
    title={
      // If a string was provided, split on any newlines
      typeof title === 'string' ? (
        <>
          {title.split('\n').map((line, i) => (
            <div key={i}>{line}</div>
          ))}
        </>
      ) : (
        title
      )
    }
  >
    {children}
  </Tooltip>
);

export default DelayedTooltip;
