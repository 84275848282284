import DelayedTooltip from 'components/DelayedTooltip';
import VideoPreview from 'components/VideoPreview';
import { PipelineDataType } from 'graph/types';
import useJobOutputs from 'hooks/useJobOutputs';
import { getBlobByKey } from 'utils/blobs';
import BlobDownloadButton from '../BlobDownloadButton';
import styles from './index.module.scss';

interface Props {
  nodeId: string;
  id: string;
  dataType: PipelineDataType;
}

const OutputBlobDownloadButton = ({ nodeId, id, dataType }: Props) => {
  const outputs = useJobOutputs();

  const blob = getBlobByKey(outputs, nodeId, id);

  const ready = Boolean(blob);

  const downloadHintText = `Click to download the ${dataType.label.toLowerCase()}.`;

  const btn = (
    <DelayedTooltip
      title={
        ready ? downloadHintText : 'No outputs yet - run the pipeline first.'
      }
    >
      <span>
        <BlobDownloadButton
          dataType={dataType}
          signedUrl={blob?.downloadUrl?.url}
        />
      </span>
    </DelayedTooltip>
  );

  if (dataType.key === PipelineDataType.VIDEO_FILE.key) {
    return (
      <div className={styles['video-container']}>
        <VideoPreview videoBlob={blob} />
        {btn}
      </div>
    );
  }

  return btn;
};

export default OutputBlobDownloadButton;
