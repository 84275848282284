import { faUserEdit } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Pose Overlayer',
  description: 'Overlay detected pose onto video frames.',
  descriptionDetail:
    'This node draws lines onto video frames representing the detected pose of the persons found.',
  icon: faUserEdit,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'PoseOverlayer',
  configTypes: [
    {
      key: 'include_keypoints',
      type: PipelineConfigType.BOOLEAN('Include joints')
        .REQUIRED.DEFAULT(true)
        .DESCRIPTION(
          'Whether to overlay the joint keypoints in addition to the skeleton.'
        ).ADVANCED,
    },
    {
      key: 'include_labels',
      type: PipelineConfigType.BOOLEAN('Include joint labels')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'Whether to overlay a text label for each joint in addition to the skeleton.'
        ).ADVANCED,
    },
    {
      key: 'skeleton_colour',
      type: PipelineConfigType.COLOUR('Skeleton colour')
        .REQUIRED.DEFAULT([0, 0, 255])
        .DESCRIPTION('The colour to draw the pose skeleton.'),
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED,
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'overlaid_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
