import { Button } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import copy from 'copy-to-clipboard';
import useNotifications from 'hooks/useNotifications';

const EmailDevelopersButton = () => {
  const notify = useNotifications();
  return (
    <DelayedTooltip title="Copy address to clipboard">
      <Button
        variant="text"
        onClick={() => {
          copy('AISDevelopers@ausport.gov.au');
          notify.success('Email address copied to clipboard');
        }}
      >
        AISDevelopers@ausport.gov.au
      </Button>
    </DelayedTooltip>
  );
};

export default EmailDevelopersButton;
