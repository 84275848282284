import {
  faBullhorn,
  faStar,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ToggleButton, ToggleButtonGroup, Typography } from '@mui/material';
import {
  Pipeline_Announcement,
  Pipeline_AnnouncementType,
} from 'graphql/graphql';
import styles from './index.module.scss';

type Props = Pick<Pipeline_Announcement, 'type' | 'date' | 'seenByUser'> & {
  onChangeType: ((type: Pipeline_AnnouncementType) => void) | undefined;
  onChangeDate: ((date: Pipeline_Announcement['date']) => void) | undefined;
};

const titleIcon = (type: Pipeline_AnnouncementType) => {
  switch (type) {
    case Pipeline_AnnouncementType.Release:
      return faStar;
    case Pipeline_AnnouncementType.Announcement:
      return faBullhorn;
    case Pipeline_AnnouncementType.Maintenance:
      return faWrench;
  }
};

const typeLabel = (type: Pipeline_AnnouncementType) => {
  return type.charAt(0).toUpperCase() + type.slice(1).toLowerCase();
};

const TypeEdit = ({
  type,
  onChangeType,
}: {
  type: Pipeline_Announcement['type'];
  onChangeType: (type: Pipeline_Announcement['type']) => void;
}) => {
  return (
    <ToggleButtonGroup
      size="small"
      value={type}
      exclusive
      onChange={(e, type) =>
        onChangeType(type as Pipeline_Announcement['type'])
      }
      onClick={(e) => e.stopPropagation()}
    >
      {Object.values(Pipeline_AnnouncementType).map((key) => {
        const label = typeLabel(key as Pipeline_AnnouncementType);
        return (
          <ToggleButton key={key} value={key} aria-label={label} title={label}>
            <FontAwesomeIcon
              icon={titleIcon(key as Pipeline_AnnouncementType)}
            />
          </ToggleButton>
        );
      })}
    </ToggleButtonGroup>
  );
};

const DateEdit = ({
  date,
  onChangeDate,
}: {
  date: Pipeline_Announcement['date'];
  onChangeDate: (date: Pipeline_Announcement['date']) => void;
}) => {
  return (
    <input
      type="date"
      value={new Date(date as string).toISOString().split('T')[0]}
      onChange={(date) => {
        const newDate = new Date(date.target.value);
        if (newDate.toString() !== 'Invalid Date') {
          onChangeDate(newDate);
        }
      }}
      onClick={(e) => e.stopPropagation()}
    />
  );
};

const AnnouncementDate = ({
  type,
  date,
  seenByUser,
  onChangeType,
  onChangeDate,
}: Props) => {
  return (
    <Typography variant="overline" color="text.secondary" component="span">
      {onChangeType ? (
        <TypeEdit type={type} onChangeType={onChangeType} />
      ) : (
        <FontAwesomeIcon icon={titleIcon(type)} />
      )}
      <span>&nbsp;</span>
      {onChangeDate ? (
        <DateEdit date={date} onChangeDate={onChangeDate} />
      ) : (
        new Date(date as string).toLocaleDateString()
      )}
      {/* Only show the "new" label if it hasn't been seen, and we're not editing */}
      {seenByUser || onChangeType ? (
        ''
      ) : (
        <span className={styles['new-span']}>&nbsp;New!</span>
      )}
    </Typography>
  );
};

export default AnnouncementDate;
