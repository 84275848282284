import { cloneDeep } from 'lodash-es';
import { uuid } from 'utils/helpers';
import {
  addAdjacency,
  deleteAdjacenciesForNode,
  IAdjacencyMap,
  INodeMap,
} from 'utils/konvaGraphUtils';

const shortestCycleFromNode = (
  root: string,
  adjacencies: IAdjacencyMap,
  maxDepth = 4
) => {
  // Find the shortest cycle for the given node by applying breadth-first search until we return to it
  // Chains holds the list of nodes back to the root node for each node at each depth
  const chains: string[][][] = [[[root]]];

  let depth = 0;
  let currDepthChains = chains[depth];

  while (currDepthChains.length > 0) {
    chains.push([]);
    const childDepth = depth + 1;
    for (let chainIdx = 0; chainIdx < currDepthChains.length; chainIdx++) {
      const currChain = currDepthChains[chainIdx];
      const lastLinkInChain = currChain[currChain.length - 1];
      const chainLinkChildren = Array.from(adjacencies[lastLinkInChain] ?? []);
      for (let i = 0; i < chainLinkChildren.length; i++) {
        const child = chainLinkChildren[i];
        if (child === root && currChain.length > 2) {
          // We found a cycle
          return currChain;
        } else if (currChain.includes(child)) {
          // Don't revisit this node
          continue;
        }
        const chain = [...currChain, child];
        chains[childDepth].push(chain);
      }
    }
    depth++;
    currDepthChains = chains[depth];
    if (depth > maxDepth) {
      return;
    }
  }
};

export const findCycles = (nodes: INodeMap, adjacencies: IAdjacencyMap) => {
  return Object.fromEntries(
    Object.keys(nodes).map((nodeId) => [
      nodeId,
      shortestCycleFromNode(nodeId, adjacencies),
    ])
  );
};

const nodesAreConnected = (
  adjacencies: IAdjacencyMap,
  node1: string,
  node2: string
) => {
  // Returns true if there is a path between the two nodes
  const visited = new Set<string>();
  const queue = [node1];
  while (queue.length > 0) {
    const currNode = queue.shift()!;
    if (currNode === node2) {
      return true;
    }
    visited.add(currNode);
    const neighbours = Array.from(adjacencies[currNode]);
    for (let i = 0; i < neighbours.length; i++) {
      if (!visited.has(neighbours[i])) {
        queue.push(neighbours[i]);
      }
    }
  }
  return false;
};

const getAllConnectedNodes = (
  adjacencies: IAdjacencyMap,
  startNode: string
) => {
  // Returns all nodes connected to the start node via edges, including the start node
  const visited = new Set<string>();
  const queue = [startNode];
  while (queue.length > 0) {
    const currNode = queue.shift()!;
    visited.add(currNode);
    const neighbours = Array.from(adjacencies[currNode]);
    for (let i = 0; i < neighbours.length; i++) {
      if (!visited.has(neighbours[i])) {
        queue.push(neighbours[i]);
      }
    }
  }
  return Array.from(visited);
};

type Axis = 'x' | 'y';
const nodesShareAxis = (
  node1: string,
  node2: string,
  nodes: INodeMap
): Axis | undefined => {
  const node1Pos = nodes[node1].pixelPos;
  const node2Pos = nodes[node2].pixelPos;
  if (node1Pos.x === node2Pos.x) {
    return 'x';
  } else if (node1Pos.y === node2Pos.y) {
    return 'y';
  }
};
const findNeighbourOnAxis = (
  node: string,
  axis: Axis,
  nodes: INodeMap,
  adjacencies: IAdjacencyMap
) =>
  Array.from(adjacencies[node] ?? []).find(
    (neighbour) => nodesShareAxis(node, neighbour, nodes) === axis
  );

const makeRightTriangle = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap,
  node1: string,
  node2: string,
  sharedAxis: Axis
) => {
  // The length of the new adjacent edge
  const ADJ_EDGE_LENGTH = 100;
  const refNodePos = nodes[node1].pixelPos;

  const otherAxis = sharedAxis === 'x' ? 'y' : 'x';
  let rightAngleNode =
    findNeighbourOnAxis(node2, otherAxis, nodes, adjacencies) ??
    findNeighbourOnAxis(node1, otherAxis, nodes, adjacencies);

  if (!rightAngleNode) {
    const newPixelPos = { ...refNodePos };
    rightAngleNode = uuid();
    if (sharedAxis === 'x') {
      // The right angle will be formed to the right of node1
      newPixelPos.x += ADJ_EDGE_LENGTH;
    } else {
      // The right angle will be formed below node1
      newPixelPos.y += ADJ_EDGE_LENGTH;
    }
    nodes[rightAngleNode] = {
      id: rightAngleNode,
      pixelPos: newPixelPos,
    };
  }
  if (!adjacencies[node1].has(rightAngleNode)) {
    addAdjacency(adjacencies, node1, rightAngleNode);
  }
  if (!adjacencies[node2].has(rightAngleNode)) {
    addAdjacency(adjacencies, node2, rightAngleNode);
  }
};

const checkTwoNodesFormTriangleEdge = (
  adjacencies: IAdjacencyMap,
  node1: string,
  node2: string
) => {
  // Two nodes form the edge of a triangle if they have a common neighbour
  const node1Neighbours = Array.from(adjacencies[node1]);
  const node2Neighbours = Array.from(adjacencies[node2]);
  return node1Neighbours.some((neighbour) =>
    node2Neighbours.includes(neighbour)
  );
};

export const insertRightTriangleEdges = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap
) => {
  /*
   If two nodes share an edge and line on the same axis, add another node to construct a right triangle
   and add an edge between the two nodes and the new node, if it doesn't already exist.

   E.g., if nodes A and B are like this:
    A
    |
    |
    B

    Then add a new node C and edges AC and BC, like this:
    A
    |\
    | \
    B--C
   */
  Object.entries(adjacencies).forEach(([node1, neighbours]) => {
    neighbours.forEach((node2) => {
      if (!checkTwoNodesFormTriangleEdge(adjacencies, node1, node2)) {
        const sharedAxis = nodesShareAxis(node1, node2, nodes);
        if (sharedAxis) {
          makeRightTriangle(nodes, adjacencies, node1, node2, sharedAxis);
        }
      }
    });
  });
};

export const checkConnectivity = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap
) => {
  const visited = new Set<string>();
  const queue = [Object.keys(nodes)[0]];
  while (queue.length > 0 && visited.size < Object.keys(nodes).length) {
    const node = queue.shift();
    if (node && !visited.has(node)) {
      visited.add(node);
      queue.push(...Array.from(adjacencies[node] ?? []));
    }
  }
  return visited.size === Object.keys(nodes).length;
};

export const getArticulationPoints = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap
) => {
  const removeNode = (
    nodeId: string,
    localNodes: INodeMap,
    localAdjacencies: IAdjacencyMap
  ) => {
    delete localNodes[nodeId];
    deleteAdjacenciesForNode(localAdjacencies, nodeId);
  };

  const articulationPoints = [];
  for (const nodeId of Object.keys(nodes)) {
    const tempNodes = { ...nodes };
    const tempAdjacencies = cloneDeep(adjacencies);
    removeNode(nodeId, tempNodes, tempAdjacencies);
    if (!checkConnectivity(tempNodes, tempAdjacencies)) {
      articulationPoints.push(nodeId);
    }
  }

  return articulationPoints;
};

/*
Ported version of the C++ implementation of Tarjan's articulation point algorithm found
at https://codeforces.com/blog/entry/71146
*/

const tarjanArticulationPoints = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap
) => {
  const visited = new Set<string>();
  const low: { [key: string]: number } = {};
  const disc: { [key: string]: number } = {};
  const ap = new Set<string>();
  let time = 0;
  const root = Object.keys(nodes)[0];

  const dfsApRecursive = (node: string, parent: string) => {
    visited.add(node);
    low[node] = disc[node] = ++time;
    let children = 0;
    for (const neighbour of Array.from(adjacencies[node])) {
      if (neighbour === parent) {
        continue;
      }
      if (!disc[neighbour]) {
        children++;
        dfsApRecursive(neighbour, node);
        if (
          (node !== root && disc[node] <= low[neighbour]) ||
          (node === root && low[neighbour] > 1)
        ) {
          ap.add(node);
        }
        low[node] = Math.min(low[node], low[neighbour]);
      } else {
        low[node] = Math.min(low[node], disc[neighbour]);
      }
    }
    return children;
  };
  dfsApRecursive(root, Object.keys(nodes)[0]);
  return Array.from(ap);
};

const filterArticulationPoints = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap,
  articulationPoints: string[]
) => {
  const removeNode = (
    nodeId: string,
    localAdjacencies: IAdjacencyMap,
    localNodes?: INodeMap
  ) => {
    if (localNodes) {
      delete localNodes[nodeId];
    }
    deleteAdjacenciesForNode(localAdjacencies, nodeId);
  };

  // For each articulation point, consider the separate sub-graphs which would result by removing it,
  // and check if each of the sub-graphs are valid. If any of them are not, then the node is an articulation point.
  const filteredArticulationPoints = articulationPoints.filter((nodeId) => {
    const tempAdjacencies = cloneDeep(adjacencies);
    const neighbours = tempAdjacencies[nodeId];
    removeNode(nodeId, tempAdjacencies);

    // Group the neighbours into sub-graphs
    const subGraphNeighbours: string[][] = [];
    neighbours.forEach((neighbour) => {
      let found = false;
      for (let j = 0; j < subGraphNeighbours.length; j++) {
        // Add to subgraph[j] if it is connected to any of its nodes
        if (
          subGraphNeighbours[j].some((subGraphNode) =>
            nodesAreConnected(tempAdjacencies, subGraphNode, neighbour)
          )
        ) {
          subGraphNeighbours[j].push(neighbour);
          found = true;
          break;
        }
      }
      if (!found) {
        // It didn't below to any subgraph, so create a new one
        subGraphNeighbours.push([neighbour]);
      }
    });

    const adequateSubGraphCount = subGraphNeighbours.filter(
      (subGraphNeighbour) => {
        // If it's connected via an axis-aligned edge, then it's fine
        const isConnectedViaAxisAlignedEdge = subGraphNeighbour.some(
          (neighbour) => nodesShareAxis(nodeId, neighbour, nodes)
        );
        if (isConnectedViaAxisAlignedEdge) {
          return true;
        }

        // Otherwise, it's only okay if there's more than a single edge connecting it to the rest of the graph,
        // and there's at least one axis-aligned edge in the subgraph itself
        if (subGraphNeighbour.length > 1) {
          const nodesInSubGraph = [
            nodeId,
            ...getAllConnectedNodes(tempAdjacencies, subGraphNeighbour[0]),
          ];

          const subGraphNodes = { ...nodes };
          const subGraphAdjacencies = cloneDeep(adjacencies);
          for (const node of Object.keys(subGraphNodes)) {
            if (!nodesInSubGraph.includes(node)) {
              removeNode(node, subGraphAdjacencies, subGraphNodes);
            }
          }
          // At this point, we now have a subgraph which is disconnected from the rest of the graph
          // in subGraphNodes and subGraphAdjacencies
          // Check that it has at least 1 axis-aligned edge
          const hasAxisAlignedEdge = Object.keys(subGraphAdjacencies).some(
            (node) =>
              Array.from(subGraphAdjacencies[node]).some((neighbour) => {
                return nodesShareAxis(node, neighbour, subGraphNodes);
              })
          );

          if (hasAxisAlignedEdge) {
            return true;
          }
        }
        return false;
      }
    );
    return adequateSubGraphCount.length !== subGraphNeighbours.length;
  });
  return filteredArticulationPoints;
};

export default function validate(
  nodes: INodeMap,
  adjacencies: IAdjacencyMap,
  minNodes: number
) {
  if (
    Object.keys(nodes).length === 0 ||
    Object.keys(adjacencies).length === 0
  ) {
    return {
      isValid: false,
      problemNodes: [],
    };
  }
  nodes = cloneDeep(nodes);
  adjacencies = cloneDeep(adjacencies);
  insertRightTriangleEdges(nodes, adjacencies);
  const cycles = findCycles(nodes, adjacencies);
  const isConnected = checkConnectivity(nodes, adjacencies);
  let articulationPoints = tarjanArticulationPoints(nodes, adjacencies);
  articulationPoints = filterArticulationPoints(
    nodes,
    adjacencies,
    articulationPoints
  );
  // Find all nodes that have a cycle length above 3
  const nonMinimalCycles = Object.entries(cycles).flatMap(([nodeId, cycle]) =>
    cycle && cycle.length > 3 ? [nodeId] : []
  );
  const noCycleNodes = Object.keys(cycles).filter(
    (nodeId) => cycles[nodeId] === undefined
  );
  const problemNodes = [
    ...articulationPoints,
    ...nonMinimalCycles,
    ...noCycleNodes,
  ];

  return {
    isValid:
      Object.keys(nodes).length >= minNodes &&
      problemNodes.length === 0 &&
      isConnected,
    problemNodes,
  };
}
