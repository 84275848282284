import { Slider } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { useCallback } from 'react';
import { useThrottledCallback } from 'use-debounce';
import styles from './index.module.scss';

interface Props {
  startTime: number | undefined;
  endTime: number | undefined;
  duration: number;
  onJumpToStart: () => void;
  onJumpToEnd: () => void;
  setStartTime: (newTime: number) => void;
  setEndTime: (newTime: number) => void;
}
const StartEndSlider = ({
  startTime,
  endTime,
  duration,
  onJumpToStart,
  onJumpToEnd,
  setStartTime,
  setEndTime,
}: Props) => {
  const jumpToStartDebounced = useThrottledCallback(onJumpToStart, 50, {
    leading: false,
    trailing: true,
  });
  const jumpToEndDebounced = useThrottledCallback(onJumpToEnd, 50, {
    leading: false,
    trailing: true,
  });

  const onChangeHandler = useCallback(
    (event: Event, value: number | number[], activeThumb: number) => {
      if (typeof value === 'number') {
        return;
      }

      if (Math.abs(value[0] - value[1]) < 1) {
        return;
      }

      if (activeThumb === 0) {
        // Space of at least 1 second between start/end
        value[0] = Math.min(value[0], value[1] - 1);
        // >= 0
        value[0] = Math.max(value[0], 0);
        setStartTime(value[0]);
        jumpToStartDebounced();
      }
      if (activeThumb === 1) {
        // Space of at least 1 second between start/end
        value[1] = Math.max(value[1], value[0] + 1);
        // <= duration
        value[1] = Math.min(value[1], duration);
        setEndTime(value[1]);
        jumpToEndDebounced();
      }
    },
    [
      duration,
      jumpToEndDebounced,
      jumpToStartDebounced,
      setEndTime,
      setStartTime,
    ]
  );

  startTime = startTime ?? 0;
  endTime = endTime ?? duration;

  return (
    <div className={`nodrag ${styles.container}`}>
      <DelayedTooltip
        title="Video start/end time"
        placement="top"
        enterDelay={500}
      >
        <Slider
          className="nodrag"
          size="small"
          value={[startTime, endTime]}
          onChange={onChangeHandler}
          step={0.02}
          min={0}
          max={duration}
          valueLabelDisplay="auto"
          disableSwap
        />
      </DelayedTooltip>
    </div>
  );
};

export default StartEndSlider;
