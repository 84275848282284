import { GraphContext } from 'contexts/Graph';
import { useContext } from 'react';

const useFlowGraph = () => {
  const {
    graphState: { flowGraph },
    setFlowGraph,
  } = useContext(GraphContext);

  return {
    flowGraph: flowGraph ?? { nodes: [], edges: [] },
    setFlowGraph,
  };
};

export default useFlowGraph;
