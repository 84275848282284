import { canStartStopJob, validateGraph } from 'graph/validation';
import useFlowGraph from 'hooks/useFlowGraph';
import useGraph from 'hooks/useGraph';
import useNotifications from 'hooks/useNotifications';
import {
  JobStatus as gqlJobStatus,
  useGetJobFullQuery,
  useGetJobStatusQuery,
  useSaveJobMutation,
} from '../graphql/graphql';

import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';

const JOB_POLL_INTERVAL_MS = 2000;

export interface IJobCanonical {
  id: string | undefined;
  completionPercent: number | undefined;
  status: JobStatus;
  startedDate: Date | undefined;
  finishedDate: Date | undefined;
}

export enum JobStatus {
  NOT_FETCHED = 'NOT_FETCHED', // Haven't yet checked if there's a job on the server
  FETCHING = 'FETCHING', // We're in the process of fetching the job from the server
  PENDING = 'PENDING',
  QUEUEING = 'QUEUEING',
  QUEUED = 'QUEUED',
  STARTING = 'STARTING',
  FAILED_TO_START = 'FAILED_TO_START',
  RUNNING = 'RUNNING',
  COMPLETE = 'COMPLETE',
  FAILED = 'FAILED',
  CANCELLING = 'CANCELLING',
  CANCELLED = 'CANCELLED',
}

type Log = {
  time: Date | undefined;
  title: string;
  message: string;
  status: JobStatus;
  unsuccessful: boolean;
};

export interface IJobContext {
  jobStatus: JobStatus;
  id: string | undefined;
  completionPercent: number | undefined;
  jobDuration: number | undefined;
  logs: Log[];
  refetchJob: () => void;
  startJob: () => void;
  cancelJob: () => void;
  uploadingCount: number;
  incrementUploadingCount: () => void;
  decrementUploadingCount: () => void;
}

export const JobContext = createContext<IJobContext>(undefined!);

const MAX_LOG_LENGTH = 10;

const emptyJob: IJobCanonical = {
  id: undefined,
  completionPercent: undefined,
  finishedDate: undefined,
  startedDate: undefined,
  status: JobStatus.NOT_FETCHED,
};

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [currentJob, setCurrentJob] = useState<IJobCanonical>(emptyJob);
  const [logs, setLogs] = useState<Log[]>([]);
  const { graphState, saveStatus, inputBlobs, refetchGraph } = useGraph();
  const { id: graphId } = graphState;
  const { flowGraph } = useFlowGraph();
  // The graph ID corresponding to the current job
  const [jobGraphId, setJobGraphId] = useState<string>();
  const [uploadingCount, setUploadingCount] = useState(0);
  const [jobDuration, setJobDuration] = useState<number | undefined>();

  // To trigger notifications
  const notify = useNotifications();

  // Fetch a job in its entirety
  const {
    data: fullJobData,
    loading: fullJobLoading,
    refetch: refetchFullJob,
  } = useGetJobFullQuery({
    fetchPolicy: 'no-cache',
    variables: { graphId },
    skip: !graphId,
  });

  // Fetch only the parts of the job pertaining to its progress
  const {
    data: jobStatusData,
    loading: jobStatusLoading,
    refetch: refetchJobStatus,
  } = useGetJobStatusQuery({
    fetchPolicy: 'no-cache',
    variables: { graphId },
    skip: !graphId,
  });
  // Save the job
  const [saveJob] = useSaveJobMutation();

  // Append to the log
  const addToLogs = useCallback(
    (
      title: string,
      message = '',
      date: Date | string | undefined,
      status: JobStatus
    ) => {
      const time = typeof date === 'string' ? new Date(date) : date;
      const entry: Log = {
        time,
        title,
        message,
        status,
        unsuccessful: [
          JobStatus.FAILED,
          JobStatus.FAILED_TO_START,
          JobStatus.CANCELLED,
        ].includes(status),
      };
      const newLogs = [entry, ...logs];
      const trimmedLogs = newLogs.slice(0, MAX_LOG_LENGTH);
      setLogs(trimmedLogs);
    },
    [logs]
  );

  // Merge the given job properties with the job local state
  const setJobProperties = useCallback(
    (jobProps: any) => {
      // We shouldn't show notifications if we're just fetching the latest status from the server on load
      // or if the status hasn't changed.
      const requiresNotification =
        ![JobStatus.NOT_FETCHED, JobStatus.FETCHING].includes(
          currentJob.status
        ) && currentJob.status !== jobProps.status;

      if (
        currentJob.status !== JobStatus.STARTING &&
        jobProps.status === JobStatus.STARTING
      ) {
        if (requiresNotification) notify.info('Starting job...');
      } else if (
        currentJob.status !== JobStatus.RUNNING &&
        jobProps.status === JobStatus.RUNNING
      ) {
        if (requiresNotification) notify.success('Job started!');
      } else if (
        currentJob.status !== JobStatus.FAILED_TO_START &&
        jobProps.status === JobStatus.FAILED_TO_START
      ) {
        if (requiresNotification)
          notify.error('Job failed to start - see recent runs for more info');
        addToLogs(
          'Job failed to start',
          jobProps.error as string,
          jobProps.finishedDate as string,
          jobProps.status as JobStatus
        );
      } else if (
        currentJob.status !== JobStatus.FAILED &&
        jobProps.status === JobStatus.FAILED
      ) {
        if (requiresNotification)
          notify.error('Job crashed - see recent runs for more info');
        addToLogs(
          'Job crashed',
          jobProps.error as string,
          jobProps.finishedDate as string,
          jobProps.status as JobStatus
        );
      } else if (
        currentJob.status !== JobStatus.COMPLETE &&
        jobProps.status === JobStatus.COMPLETE
      ) {
        if (requiresNotification) notify.success('Job finished!');
        addToLogs(
          'Job finished successfully',
          '',
          jobProps.finishedDate as string,
          jobProps.status as JobStatus
        );
        void refetchGraph();
        void refetchFullJob();
      } else if (
        currentJob.status !== JobStatus.CANCELLED &&
        jobProps.status === JobStatus.CANCELLED
      ) {
        if (requiresNotification) notify.error('Job cancelled');
        addToLogs(
          'Job was cancelled',
          '',
          jobProps.finishedDate as string,
          jobProps.status as JobStatus
        );
        void refetchFullJob();
      }

      const newJob = {
        ...currentJob,
        ...JSON.parse(JSON.stringify(jobProps)),
      };
      newJob.finishedDate = new Date(newJob.finishedDate as string);

      setCurrentJob(newJob as IJobCanonical);
    },
    [addToLogs, currentJob, notify, refetchFullJob, refetchGraph]
  );

  // Reset the completion percent
  useEffect(() => {
    if (currentJob.completionPercent && currentJob.status !== JobStatus.RUNNING) {
      setJobProperties({'completionPercent': 0});
    }
  }, [currentJob.status]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the local job state when the full job is retrieved from the api
  useEffect(() => {
    if (!fullJobLoading && fullJobData && fullJobData?.Pipeline_job) {
      setJobGraphId(graphId);
      setJobProperties(fullJobData?.Pipeline_job);
    }
  }, [fullJobData, fullJobLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Update the local job state when the partial job retrieved from the api
  useEffect(() => {
    if (!jobStatusLoading && jobStatusData && jobStatusData.Pipeline_job) {
      setJobProperties(jobStatusData?.Pipeline_job);
    }
  }, [jobStatusData, jobStatusLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  // Polls if there's something worth polling
  const pollIfRequired = useCallback(() => {
    if (
      currentJob.id &&
      [JobStatus.QUEUED, JobStatus.STARTING, JobStatus.RUNNING].includes(
        currentJob.status
      )
    ) {
      const doPoll = async () => {
        const { data } = await refetchJobStatus();
        if (data?.Pipeline_job.status === gqlJobStatus.Complete) {
          await refetchFullJob();
        }
      };
      void doPoll();
    }
  }, [currentJob, refetchFullJob, refetchJobStatus]);

  useEffect(() => {
    const interval = window.setInterval(
      () => pollIfRequired(),
      JOB_POLL_INTERVAL_MS
    );
    return () => window.clearInterval(interval);
  }, [pollIfRequired]);

  // Fetch the most-recent job for the current graph if we don't yet have one, or if the job we have is for another graph
  useEffect(() => {
    const jobIsForOtherGraph = graphId !== jobGraphId;
    const jobNotFetched = currentJob.status === JobStatus.NOT_FETCHED;
    const doFetch = () => {
      // Fetch the job for this graph
      if (graphId) {
        setJobProperties({ status: JobStatus.FETCHING });
        void refetchFullJob({ graphId });
      }
    };
    // Clear out the job logs and outputs, as they're not for this graph
    if (jobIsForOtherGraph || jobNotFetched) {
      if (logs.length > 0) {
        setJobProperties(emptyJob);
        setLogs([]);
      }
      doFetch();
    }
  }, [graphId]); // eslint-disable-line react-hooks/exhaustive-deps

  // Queue the job for processing
  const startJob = useCallback(() => {
    if (currentJob?.id && graphId) {
      const { canStartJob } = canStartStopJob(
        currentJob.status,
        validateGraph(flowGraph, inputBlobs),
        saveStatus,
        uploadingCount
      );
      if (canStartJob) {
        notify.info('Queueing job...');
        // Set the job status to queueing to prevent the user submitting the job multiple times
        setJobProperties({
          status: JobStatus.QUEUEING,
          completionPercent: undefined,
        });

        const doStart = async () => {
          try {
            if (currentJob.status !== JobStatus.PENDING) {
              await saveJob({
                variables: {
                  job: {
                    graph: graphId,
                    status: gqlJobStatus.Queued,
                  },
                },
              });
            } else {
              await saveJob({
                variables: {
                  job: {
                    id: currentJob.id,
                    graph: graphId,
                    status: gqlJobStatus.Queued,
                  },
                },
              });
            }
          } catch (e) {
            console.error(e);
            // If the job failed to queue, set the status back to pending
            setJobProperties({ status: JobStatus.PENDING });
            // Toggle a notification
            notify.error('Failed to queue job');
          }
          await refetchFullJob({ graphId });
        };
        void doStart();
      }
    }
  }, [
    currentJob.id,
    currentJob.status,
    flowGraph,
    graphId,
    inputBlobs,
    notify,
    refetchFullJob,
    saveJob,
    saveStatus,
    setJobProperties,
    uploadingCount,
  ]);

  // Cancel the currently-running job
  const cancelJob = useCallback(() => {
    if (currentJob?.id && graphId) {
      const { canCancelJob } = canStartStopJob(
        currentJob.status,
        validateGraph(flowGraph, inputBlobs),
        saveStatus,
        uploadingCount
      );
      if (canCancelJob) {
        // Set the job status to cancelling to prevent the user cancelling the job multiple times
        setJobProperties({ status: JobStatus.CANCELLING });

        const doCancel = async () => {
          await saveJob({
            variables: {
              job: {
                id: currentJob.id,
                graph: graphId,
                status: gqlJobStatus.Cancelled,
              },
            },
          });
          await refetchFullJob({ graphId });
        };
        void doCancel();
      }
    }
  }, [
    currentJob.id,
    currentJob.status,
    flowGraph,
    graphId,
    inputBlobs,
    refetchFullJob,
    saveJob,
    saveStatus,
    setJobProperties,
    uploadingCount,
  ]);

  // A basic counter to know whether there are files uploading
  const incrementUploadingCount = useCallback(() => {
    setUploadingCount((prevCount) => prevCount + 1);
  }, []);
  const decrementUploadingCount = useCallback(() => {
    setUploadingCount((prevCount) => prevCount - 1);
  }, []);

  // Calculate the time since currentJob.startedDate and now in milliseconds and set it in jobDuration
  useEffect(() => {
    const interval = window.setInterval(() => {
      if (
        currentJob.startedDate &&
        [JobStatus.RUNNING, JobStatus.CANCELLING].includes(currentJob.status)
      ) {
        const timeSinceStarted =
          new Date().getTime() - new Date(currentJob.startedDate).getTime();

        setJobDuration(timeSinceStarted);
      } else if (jobDuration) {
        setJobDuration(undefined);
      }
    }, 900);

    return () => window.clearInterval(interval);
  }, [currentJob.startedDate, currentJob.status, jobDuration]);

  return (
    <JobContext.Provider
      value={{
        jobStatus: currentJob.status,
        id: currentJob.id,
        completionPercent: currentJob.completionPercent,
        jobDuration: jobDuration,
        logs,
        refetchJob: () => void refetchFullJob(),
        startJob,
        cancelJob,
        uploadingCount,
        incrementUploadingCount,
        decrementUploadingCount,
      }}
    >
      {children}
    </JobContext.Provider>
  );
};
