import { faInfo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  Typography,
} from '@mui/material';
import fuzzysort from 'fuzzysort';
import { getNodeTypeKeyFromNodeType } from 'graph';
import { NodeTypeKey } from 'graph/NodeTypes';
import { FlowGraphNodeType } from 'graph/types';
import { useMemo } from 'react';
import styles from '../index.module.scss';

interface Props {
  nodeTypes: FlowGraphNodeType[];
  showDetail: (nodeTypeObj: FlowGraphNodeType) => void;
  addNode: (nodeType: NodeTypeKey) => void;
  filterText: string;
  highlightedNode: FlowGraphNodeType | undefined;
  setHighlightedNode: (node: FlowGraphNodeType | undefined) => void;
}

const filterNodes = (
  nodeTypes: FlowGraphNodeType[],
  searchString: string
): FlowGraphNodeType[] => {
  const results = fuzzysort.go(searchString, nodeTypes, {
    keys: ['name', 'description', 'descriptionDetail'],
    threshold: -100000,
  });

  return results.map((res) => res.obj);
};

const FilteredNodeBrowser = ({
  nodeTypes,
  showDetail,
  addNode,
  filterText,
  highlightedNode,
  setHighlightedNode,
}: Props) => {
  const filtered = useMemo(() => {
    const filtered = filterNodes(nodeTypes, filterText);

    if (filtered.length > 0 && filtered[0].name !== highlightedNode?.name) {
      setHighlightedNode(filtered[0]);
    }

    return filtered;
  }, [filterText, highlightedNode, nodeTypes, setHighlightedNode]);

  const makeComponents = (nodeTypes: FlowGraphNodeType[]) =>
    nodeTypes.map((nodeType) => (
      <ListItem
        key={nodeType.name}
        disablePadding
        secondaryAction={
          <IconButton
            edge="end"
            size="small"
            onClick={() => showDetail(nodeType)}
          >
            <FontAwesomeIcon icon={faInfo} size="sm" />
          </IconButton>
        }
      >
        <ListItemButton
          onClick={() => {
            const key = getNodeTypeKeyFromNodeType(nodeType);
            if (key) {
              addNode(key);
            }
          }}
          className="MuiListItem-button" // Add btn class to get the hover css
        >
          <ListItemAvatar>
            <Avatar>
              {nodeType.icon ? (
                <FontAwesomeIcon icon={nodeType.icon} />
              ) : (
                nodeType.name[0].toUpperCase()
              )}
            </Avatar>
          </ListItemAvatar>
          <ListItemText
            primary={nodeType.name}
            secondary={nodeType.description}
          />
        </ListItemButton>
      </ListItem>
    ));

  return (
    <List dense>
      {filtered.length === 0 ? (
        <ListItem>
          <Typography
            variant="h4"
            gutterBottom
            color="textSecondary"
            className={styles['no-results']}
          >
            <div>No results!</div>
          </Typography>
        </ListItem>
      ) : (
        makeComponents(filtered)
      )}
    </List>
  );
};

export default FilteredNodeBrowser;
