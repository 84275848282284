import { faCropAlt } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Tracked Bounding Box Crop Extractor',
  description: 'Extract a crop from a video for a chosen tracked bounding box.',
  descriptionDetail:
    'This node produces a cropped video using the chosen track.',
  icon: faCropAlt,
  category: NodeCategory.VIDEO_UTILS,
  moduleType: 'TrackedBoundingBoxExtractor',
  configTypes: [
    {
      key: 'track_index',
      type: PipelineConfigType.INTEGER('Selected track number')
        .REQUIRED.MIN(1)
        .DESCRIPTION('Which track number to extract .'),
    },
    {
      key: 'skip_untracked_periods',
      type: PipelineConfigType.BOOLEAN('Skip untracked periods')
        .DEFAULT(true)
        .DESCRIPTION('Whether to skip periods where the track is not visible.'),
    },
    {
      key: 'box_scale_factor',
      type: PipelineConfigType.DECIMAL('Box scale factor')
        .MIN(0.25)
        .MAX(2)
        .DEFAULT(1).ADVANCED,
    },
  ],
  inputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'extracted_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
