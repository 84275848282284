import Konva from 'konva';
import { useCallback, useRef } from 'react';
import { IAdjacencyMap, INodeMap } from 'utils/konvaGraphUtils';
import useGraph from '../../useGraph';

export const SNAP_DIST_THRESH = 25;

const makeDefaultState = (): [INodeMap, IAdjacencyMap] => [{}, {}];
const useCorrespondence = (
  initialState?: [INodeMap, IAdjacencyMap] | undefined
) => {
  const stageRef = useRef<Konva.Stage>(null);
  const {
    nodes,
    edges,
    addNode,
    moveNodesPixel,
    deleteNodes,
    removeEdge,
    selectedNodeIds,
    setNodeSelected,
    addNodeToSelection,
    removeNodeFromSelection,
  } = useGraph(initialState ?? makeDefaultState());

  const deleteAndDeselectNodes = useCallback(
    (ids: string[]) => {
      const newSelectedNodeIds = selectedNodeIds.filter(
        (id) => !ids.includes(id)
      );
      setNodeSelected(
        newSelectedNodeIds.length > 0 ? newSelectedNodeIds[0] : null
      );
      deleteNodes(ids, true, true, true);
    },
    [deleteNodes, selectedNodeIds, setNodeSelected]
  );

  return {
    stageRef,
    edges,
    nodes,
    setNodeSelected,
    deleteNodes: deleteAndDeselectNodes,
    removeEdge,
    addNodeToSelection,
    removeNodeFromSelection,
    selectedNodeIds,
    addNode,
    moveNodesPixel,
  };
};

export default useCorrespondence;
