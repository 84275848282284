import { useGraphFromTemplateMutation } from 'graphql/graphql';
import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import useConfirmDialog from './useConfirmDialog';
import useNotifications from './useNotifications';

export default () => {
  const notify = useNotifications();
  const navigate = useNavigate();

  const [graphFromTemplate] = useGraphFromTemplateMutation();
  const { triggerDialog } = useConfirmDialog();

  const graphFromTemplateAndRedirect = useCallback(
    async (templateId: string, nameOverride = 'a template') => {
      try {
        const res = await graphFromTemplate({
          variables: {
            templateId,
          },
        });
        const newGraphId = res.data?.Pipeline_graphFromTemplate?.id;
        notify.success(`Successfully created from ${nameOverride}`);
        navigate(`/graph/${newGraphId}`);
        return Promise.resolve(res.data?.Pipeline_graphFromTemplate);
      } catch (e) {
        triggerDialog({
          title: 'Error creating pipeline',
          message: `The pipeline could not be created from the provided ${nameOverride} pipeline. Confirm that the pipeline exists and is ${nameOverride} before trying again.`,
          confirmButtonText: 'OK',
          hideCancelButton: true,
          onConfirm: () => navigate('/'),
        });
        return Promise.reject();
      }
    },
    [graphFromTemplate, navigate, notify, triggerDialog]
  );

  return graphFromTemplateAndRedirect;
};
