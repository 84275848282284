import { FormControlLabel, Stack, Switch } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import { useEffect, useState } from 'react';
import { Pipeline_UserPreferences } from '../../graphql/graphql';
import useUserPreferences from '../../hooks/useUserPreferences';
import useUserState from '../../hooks/useUserState';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

const UserPreferencesDialog = ({ open, onClose }: Props) => {
  const { updateUserPreferences } = useUserPreferences();
  const { userPreferences } = useUserState();
  const [soundEnabled, setSoundEnabled] = useState(
    userPreferences.soundEnabled
  );

  useEffect(() => {
    if (userPreferences) {
      setSoundEnabled(userPreferences?.soundEnabled);
    }
  }, [userPreferences]);

  const onsubmit = () => {
    const newPreferences: Pipeline_UserPreferences = {
      ...userPreferences,
      soundEnabled: soundEnabled,
    };
    void updateUserPreferences(newPreferences);
    onClose();
  };

  return (
    <DraggableDialog
      title="Preferences"
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg' }}
      actions={[
        <StyledButton
          key="save"
          styleName="primary"
          onClick={() => void onsubmit()}
          solid
        >
          Save
        </StyledButton>,
      ]}
      className={styles.dialog}
    >
      <Stack component="form" spacing={2}>
        <FormControlLabel
          key="auto-close"
          control={
            <Switch
              checked={soundEnabled}
              onChange={(e) => setSoundEnabled(e.target.checked)}
              color="primary"
            />
          }
          label={soundEnabled ? 'Sound enabled' : 'Sound disabled'}
        />
      </Stack>
    </DraggableDialog>
  );
};

export default UserPreferencesDialog;
