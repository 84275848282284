import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: '3D Pose Plotter',
  description: 'Plot 3D pose to a graph.',
  descriptionDetail:
    'This node plots any number of 3D poses onto a graph with configurable xyz axis domains.',
  icon: faWalking,
  category: NodeCategory.GRAPH_PLOTTING,
  moduleType: 'WorldPoseGrapher',
  configTypes: [
    {
      key: 'headless',
      type: PipelineConfigType.BOOLEAN('Headless').CONSTANT_VALUE(true),
    },
    {
      key: 'dpi',
      type: PipelineConfigType.INTEGER('DPI').CONSTANT_VALUE(150),
    },
    {
      key: 'graph_domain_minimum',
      type: PipelineConfigType.INTEGER('Plot domain minimum')
        .TUPLE(['X min', 'Y min', 'Z min'])
        .DESCRIPTION(
          'The (x, y, z) minimum coordinates of the graphed 3d volume domain. By default the domain is inferred from the provided coordinates.'
        ),
    },
    {
      key: 'graph_domain_maximum',
      type: PipelineConfigType.INTEGER('Plot domain maximum')
        .TUPLE(['X max', 'Y max', 'Z max'])
        .DESCRIPTION(
          'The (x, y, z) maximum coordinates of the graphed 3d volume domain. By default the domain is inferred from the provided coordinates.'
        ),
    },
    {
      key: 'invert_x_axis',
      type: PipelineConfigType.BOOLEAN('Invert x axis')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'Whether to invert the x axis - may be required in some situations.'
        ),
    },
    {
      key: 'invert_y_axis',
      type: PipelineConfigType.BOOLEAN('Invert y axis')
        .REQUIRED.DEFAULT(true)
        .DESCRIPTION(
          'Whether to invert the y axis - may be required in some situations.'
        ),
    },
    {
      key: 'invert_z_axis',
      type: PipelineConfigType.BOOLEAN('Invert z axis')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'Whether to invert the z axis - may be required in some situations.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'world_pose_data',
      type: PipelineDataType.WORLD_COORDINATES_3D.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.POSE_3D,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'plot_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
