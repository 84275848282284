export const IS_DEV_MODE = import.meta.env.DEV;

export const APP_NAME = 'Pipelines';

export const GQL_HOST = import.meta.env.VITE_GQL_HOST;

export const SPORT_AUS_CONNECT_GROUP = 'PIPELINE';

export const ROLES = {
  INTERNAL_MANAGER: 'InternalManager',
  API_USER: 'ApiUser',
};
