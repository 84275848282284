import { faPlay, faStop } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DelayedTooltip from 'components/DelayedTooltip';
import { ReactElement, useCallback, useEffect, useState } from 'react';

import Spinner from 'components/Spinner';
import StyledButton from 'components/StyledButton';
import { JobStatus } from 'contexts/Job';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useGraphLockStatus from 'hooks/useGraphLockStatus';
import useGraphValidation from 'hooks/useGraphValidation';
import useJobInfo from 'hooks/useJobInfo';
import useJobOutputs from 'hooks/useJobOutputs';
import useStartStopJob from 'hooks/useStartStopJob';
import styles from './index.module.scss';

const shouldPresentStartButton = (status: JobStatus): boolean =>
  [
    JobStatus.NOT_FETCHED,
    JobStatus.PENDING,
    JobStatus.FAILED_TO_START,
    JobStatus.CANCELLED,
    JobStatus.FAILED,
    JobStatus.COMPLETE,
  ].includes(status);

const statusString = (status: JobStatus): string => {
  switch (status) {
    case JobStatus.QUEUED: {
      return 'Job queued';
    }
    case JobStatus.STARTING: {
      return 'Job starting';
    }
    case JobStatus.RUNNING: {
      return 'Job running';
    }
    default:
      return '';
  }
};

interface Props {
  hideWhenLocked?: boolean;
}
const JobRunControls = ({ hideWhenLocked = false }: Props) => {
  const { jobStatus, completionPercent, uploadingCount } = useJobInfo();
  const outputs = useJobOutputs();
  const { startJob, cancelJob } = useStartStopJob();
  const { graphValidationState, canStartJob, canCancelJob } =
    useGraphValidation();
  const { triggerDialog } = useConfirmDialog();
  const { graphIsLocked } = useGraphLockStatus();

  const [validationMessage, setValidationMessage] = useState<string>('');

  useEffect(() => {
    if (graphValidationState) {
      const {
        hasRequiredConfig,
        hasRequiredInputs,
        hasRequiredOutputs,
        isFullyConnected,
        hasSomeNodes,
      } = graphValidationState;

      if (!hasSomeNodes) {
        setValidationMessage('The pipeline must comprise at least one node.');
      } else if (uploadingCount > 1) {
        setValidationMessage('Files are still uploading.');
      } else if (uploadingCount > 0) {
        setValidationMessage('A file is still uploading.');
      } else if (!hasRequiredConfig) {
        setValidationMessage('One or more required config values are missing.');
      } else if (!hasRequiredInputs) {
        setValidationMessage('One or more required inputs are not connected.');
      } else if (!hasRequiredOutputs) {
        setValidationMessage('One or more required outputs are not connected.');
      } else if (!isFullyConnected) {
        setValidationMessage("The pipeline isn't fully connected.");
      }
    }
  }, [graphValidationState, canStartJob, uploadingCount]);

  // If we have some outputs from a previous run, open the confirmation dialog. Otherwise, just start running
  const startJobWithConfirm = useCallback(() => {
    // Check if any outputs are non-null
    if (outputs && Object.values(outputs).some((v) => v !== null)) {
      triggerDialog({
        title: 'Run the pipeline again?',
        message:
          "You still have outputs from a previous run, so running the pipeline again will replace them.\nEnsure you've downloaded anything you'd like to keep before proceeding.",
        confirmButtonText: 'Start',
        onConfirm: startJob,
      });
    } else {
      startJob();
    }
  }, [outputs, startJob, triggerDialog]);

  let button: ReactElement;
  let spinner: ReactElement = <span />;
  let tooltip: string;

  if (shouldPresentStartButton(jobStatus)) {
    if (!canStartJob) {
      tooltip = `Cannot run pipeline - ${validationMessage}`;
    } else {
      tooltip = 'Run Pipeline';
    }
    button = (
      <StyledButton
        styleName="primary"
        solid
        className={styles.button}
        onClick={startJobWithConfirm}
        disabled={!canStartJob}
      >
        <FontAwesomeIcon icon={faPlay} size="2x" />
      </StyledButton>
    );
  } else {
    tooltip = `${statusString(jobStatus)} - click to cancel`;
    button = (
      <>
        <StyledButton
          styleName={jobStatus === JobStatus.RUNNING ? 'success' : 'primary'}
          solid
          className={styles.button}
          onClick={cancelJob}
          disabled={!canCancelJob}
        >
          <FontAwesomeIcon icon={faStop} size="2x" />
        </StyledButton>
      </>
    );
    spinner = (
      <div className={styles['spinner-container']}>
        <Spinner percent={completionPercent} />
      </div>
    );
  }
  if (hideWhenLocked && graphIsLocked) return <span />;

  return (
    <DelayedTooltip title={tooltip}>
      <div className={styles['button-container']}>
        {button}
        {spinner}
      </div>
    </DelayedTooltip>
  );
};

export default JobRunControls;
