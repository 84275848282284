import { faFilm, faPhotoFilm } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StyledButton from 'components/StyledButton';
import { Blob } from 'contexts/Graph';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';
import ErrorPreview from './PreviewError';
import VideoFramePreview from './VideoFramePreview';
import VideoPlayer from './VideoPlayer';

interface Props {
  videoBlob: Blob | undefined;
  maxWidth?: number | string;
  startWithVideoPlayer?: boolean;
}

const VideoPreview = ({
  videoBlob,
  maxWidth = '100%',
  startWithVideoPlayer = false,
}: Props) => {
  const [localBlob, setLocalBlob] = useState<Blob | undefined>(undefined);
  const [useStillFrame, setUseStillFrame] = useState<boolean>(
    !startWithVideoPlayer
  );
  const [framePreviewErroredOut, setFramePreviewErroredOut] =
    useState<boolean>(false);
  const [playerErroredOut, setPlayerErroredOut] = useState<boolean>(false);

  useEffect(() => {
    if (!localBlob || videoBlob?.id !== localBlob?.id) {
      // Set the received blob if we don't have one
      setLocalBlob(videoBlob);
    } else {
      // Otherwise, only set the received blob if our current one has expired, or
      // it doesn't have an expiry date to begin with
      let remaining = -1;
      if (localBlob?.downloadUrl?.expiryDate) {
        const currMs = new Date().getTime();
        remaining =
          new Date(localBlob.downloadUrl.expiryDate).getTime() - currMs;
      }
      if (remaining < 0) {
        setLocalBlob(videoBlob);
      }
    }
  }, [localBlob, videoBlob]);

  if (!videoBlob) {
    return null;
  }

  const showStillFrame = useStillFrame || playerErroredOut;
  const showPlayer = !useStillFrame || framePreviewErroredOut;
  const showError = framePreviewErroredOut && playerErroredOut;

  let comp = null;
  if (showError) {
    comp = <ErrorPreview />;
  } else if (showStillFrame) {
    comp = (
      <VideoFramePreview
        videoBlob={localBlob}
        seekSeconds={0.1}
        onError={() => {
          setFramePreviewErroredOut(true);
          if (!playerErroredOut) setUseStillFrame(false);
        }}
        maxWidth={maxWidth}
      />
    );
  } else if (showPlayer) {
    comp = (
      <VideoPlayer
        videoBlob={localBlob}
        onError={() => {
          setPlayerErroredOut(true);
          if (!framePreviewErroredOut) setUseStillFrame(true);
        }}
        maxWidth={maxWidth}
      />
    );
  }

  return (
    <div className={styles.container}>
      {showError || (
        <StyledButton
          styleName="secondary"
          classes={{
            root: styles['toggle-button'],
          }}
          onClick={() => setUseStillFrame(!useStillFrame)}
          title="Toggle between video player and still frame (video player may not work for some videos/browsers)"
        >
          <FontAwesomeIcon
            icon={useStillFrame ? faPhotoFilm : faFilm}
            size="lg"
          />
        </StyledButton>
      )}
      {comp}
    </div>
  );
};

export default VideoPreview;
