import { faPaintRoller } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Kinogram Builder',
  description: 'Builds a dynamic kinogram video of the detected athlete(s).',
  descriptionDetail:
    'A kinogram is a video that overlays freeze frames of an athlete on top of the original video, to visually capture the dynamics of their movement. This node operates in two modes.\nIf frame labels are provided, it will extract the video frames on which those labels are present.\nIf no frame labels are provided, frames are extracted based on the IoU threshold, indicating that the athlete has moved sufficiently.',
  icon: faPaintRoller,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'KinogramBuilder',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7992116111247',
  configTypes: [
    {
      key: 'frame_label_filter',
      type: PipelineConfigType.STRING_ARRAY('Frame labels')
        .COLOUR_CODED.DESCRIPTION(
          'The frame labels on which to draw a new frame for the athlete. These should correspond to labels used in the relevant Video Loader node. If empty, frames are automatically chosen based on the IoU threshold below.'
        )
        .UI_GROUP('frame_labels', 'Labelled frame extraction'),
    },
    {
      key: 'frame_label_opacities',
      type: PipelineConfigType.COMMA_SEPARATED_NORM_DECIMALS(
        'Frame label opacities',
        'Enter an opacity between 0 and 1'
      )
        .MIN(0)
        .DESCRIPTION(
          'The opacity of each of the frame labels, between 0 and 1. Each entry corresponds to the frame label at the same position in the Frame Labels field above. Any labels not accounted for will use the Alpha value from below.'
        )
        .UI_GROUP('frame_labels', 'Labelled frame extraction'),
    },
    {
      key: 'iou_threshold',
      type: PipelineConfigType.NORM_DECIMAL('IoU threshold')
        .DESCRIPTION(
          'The Intersection-over-Union threshold below which a new frame is drawn for the athlete. Higher values means more frames are drawn, as it is essentially an indicator of how much the athlete has moved.'
        )
        .DEFAULT(0.3)
        .UI_GROUP('iou', 'Automatic frame extraction'),
    },
    {
      key: 'box_scale_factor',
      type: PipelineConfigType.DECIMAL('Box scale factor')
        .DESCRIPTION(
          'The scale factor to apply to the bounding boxes. Often useful when the bounding boxes cut off parts of the athlete.'
        )
        .MIN(0.25)
        .MAX(2.5)
        .DEFAULT(1.5)
        .UI_GROUP('global', 'Global settings'),
    },
    {
      key: 'alpha',
      type: PipelineConfigType.NORM_DECIMAL('Alpha')
        .MIN(0.05)
        .MAX(1)
        .DEFAULT(1)
        .SLIDER(0.05, [0.5, 0.75])
        .DESCRIPTION(
          'The opacity of the overlaid images, where values less than 1 are partial transparent.'
        )
        .UI_GROUP('global', 'Global settings'),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
    {
      key: 'segmentation_masks',
      type: PipelineDataType.SEGMENTATION_MASKS.REQUIRED,
    },
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'kinogram_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
