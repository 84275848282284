import Spinner from 'components/Spinner';
import styles from './Fallback.module.scss';

const PreviewFallback = () => (
  <div className={styles.container}>
    <Spinner />
  </div>
);

export default PreviewFallback;
