import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { faArrowsRotate } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, InputAdornment, TextField, Typography } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { useGenerateApiKeyMutation } from 'graphql/graphql';
import useNotifications from 'hooks/useNotifications';

const ApiKeyGenerator = () => {
  const { success } = useNotifications();
  const [generateApiKeyMutation, { data }] = useGenerateApiKeyMutation();

  let apiKey: string | null = null;
  let apiExpiry: Date | null = null;
  if (data) {
    apiKey = data.Pipeline_generateApiKey.apiKey;
    apiExpiry = new Date(
      data.Pipeline_generateApiKey.expiresAtReadable as string
    );
  }

  const generateApiKey = async () => {
    await generateApiKeyMutation();
    success('API key generated');
  };

  const copyToClipboard = async () => {
    if (!apiKey) return;
    await navigator.clipboard.writeText(apiKey);
    success('API key copied to clipboard');
  };

  let buttonHint = 'Generate API key';
  let buttonIcon = faArrowsRotate;
  let buttonAction = generateApiKey;
  if (apiKey) {
    buttonHint = 'Copy API key to clipboard';
    buttonIcon = faClipboard;
    buttonAction = copyToClipboard;
  }

  return (
    <div>
      <TextField
        fullWidth
        placeholder="API key"
        value={apiKey}
        InputProps={{
          readOnly: true,
          endAdornment: (
            <InputAdornment position="end">
              <DelayedTooltip title={buttonHint} enterDelay={250}>
                <Button onClick={() => void buttonAction()}>
                  <FontAwesomeIcon icon={buttonIcon} />
                </Button>
              </DelayedTooltip>
            </InputAdornment>
          ),
        }}
      />
      {apiExpiry && (
        <Typography variant="caption">
          Key expiry: {apiExpiry.toLocaleString()}.
        </Typography>
      )}
    </div>
  );
};

export default ApiKeyGenerator;
