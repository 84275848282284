import { ROLES } from 'config';
import useAuth from './useAuth';

const useRolePermissions = () => {
  let { userRoles } = useAuth();
  userRoles = userRoles || [];

  const canSetGraphAsTemplate = userRoles.includes(ROLES.INTERNAL_MANAGER);
  const canAddEditAnnouncements = userRoles.includes(ROLES.INTERNAL_MANAGER);
  const canAccessApi =
    userRoles.includes(ROLES.INTERNAL_MANAGER) ||
    userRoles.includes(ROLES.API_USER);

  return {
    canSetGraphAsTemplate,
    canAddEditAnnouncements,
    canAccessApi,
  };
};

export default useRolePermissions;
