import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Tracked Pose Writer',
  description:
    'Writes tracked pose to a CSV and makes it available for download.',
  descriptionDetail:
    'Given a collection of tracked bounding boxes and the associated pose, this node writes it to a CSV file in the cloud and makes it available for download via the browser.',
  icon: faFileExport,
  category: NodeCategory.OUTPUT,
  moduleType: 'TrackedPoseWriter',
  configTypes: [
    {
      key: 'blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}.csv').REQUIRED
        .HIDDEN,
    },
    {
      key: 'overwrite_existing',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_3D.LABEL('Pose').REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.POSE_2D,
        PipelineDataType.POSE_3D,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'output_data',
      type: PipelineDataType.ANY.BLOB_DATATYPE(
        Pipeline_BlobDataType.Csv
      ).NO_HANDLE.LABEL('Output Data').DOWNLOADABLE,
    },
  ],
};
