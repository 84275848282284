import StyledButton from 'components/StyledButton';
import { PipelineDataType } from 'graph/types';

interface Props {
  signedUrl?: string;
  dataType: PipelineDataType;
}

const BlobDownloadButton = ({ signedUrl, dataType }: Props) => {
  const ready = Boolean(signedUrl);

  return (
    <StyledButton
      styleName={ready ? 'primary' : 'secondary'}
      onClick={() => {
        if (signedUrl)
          window.open(
            `${signedUrl}&response-content-disposition=attachment`,
            '_blank'
          );
      }}
      fullWidth
      disabled={!ready}
    >
      Download {dataType.label}
    </StyledButton>
  );
};

export default BlobDownloadButton;
