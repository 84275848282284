import { faCancel, faSave } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import MaxLengthTextField from 'components/MaxLengthTextField';
import ActionRow from 'components/PointCorrespondenceWizard/ActionRow';
import StyledButton from 'components/StyledButton';
import {
  Pipeline_PointCorrespondenceTemplate,
  Pipeline_PointCorrespondenceTemplateDto,
} from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { lazy, Suspense, useState } from 'react';
import { isArrayEqual } from 'utils/helpers';
import { assembleTemplateDto } from '..';
import { nodesAndAdjacenciesFromGQLFormat } from '../../useGraph';
import BuilderFallback from './Builder/Fallback';
import useTemplate from './useTemplate';

const TemplateBuilder = lazy(() => import('./Builder'));

interface Props {
  templateToEdit: Pipeline_PointCorrespondenceTemplate | undefined;
  onSubmit: (template: Pipeline_PointCorrespondenceTemplateDto) => void;
  onCancel: () => void;
}

const MAX_NAME_LEN = 128;
const MAX_DESCRIP_LEN = 1024;

const PointCorrespondenceTemplateBuilder = ({
  templateToEdit,
  onSubmit,
  onCancel,
}: Props) => {
  const [stageScale, setStageScale] = useState(1);
  const [name, setName] = useState<string | undefined>(templateToEdit?.name);
  const [description, setDescription] = useState<string | undefined>(
    templateToEdit?.description ?? undefined
  );
  const { triggerDialog } = useConfirmDialog();

  const initialState = templateToEdit
    ? nodesAndAdjacenciesFromGQLFormat(templateToEdit)
    : undefined;

  const template = useTemplate(stageScale, initialState);
  const [localWorldOrigin, setLocalWorldOrigin] = useState<number[]>(
    templateToEdit?.worldOrigin ?? [0, 0]
  );

  let { isValid } = template;
  let helpText: string | undefined;
  if (!isValid) {
    if (Object.keys(template.nodes).length < 4) {
      helpText = 'The template must have at least 4 nodes.';
    } else {
      helpText = 'The red nodes must be fixed before saving the template.';
    }
  }

  const nameStr = name ?? '';
  const nameModified =
    name !== templateToEdit?.name && typeof name !== 'undefined';
  const nameMissing = !(name && name.length > 0);
  const descriptionStr = description ?? '';
  const descriptionModified =
    description !== templateToEdit?.description &&
    typeof description !== 'undefined';
  const descriptionMissing = !(description && description.length > 0);
  if (isValid) {
    if (nameMissing) {
      isValid = false;
      helpText = 'The template must have a name.';
    } else if (descriptionMissing) {
      isValid = false;
      helpText = 'The template must have a description.';
    }
  }
  const originModified = !isArrayEqual(
    localWorldOrigin,
    templateToEdit?.worldOrigin
  );
  const isModified =
    template.canUndo || nameModified || descriptionModified || originModified;

  const onExit = () => {
    if (isModified) {
      triggerDialog({
        title: 'Unsaved Changes',
        message: 'Are you sure you want to discard your changes?',
        onConfirm: onCancel,
        confirmButtonText: 'Discard changes',
      });
    } else {
      onCancel();
    }
  };

  return (
    <DraggableDialog
      open={true}
      title="Edit Point Correspondence Template"
      maxWidth="lg"
      fullWidth
      allowClose
      onClose={onExit}
      actions={
        <ActionRow
          buttons={[
            <StyledButton
              key="discard"
              styleName="danger"
              onClick={onExit}
              startIcon={<FontAwesomeIcon icon={faCancel} />}
            >
              Discard changes
            </StyledButton>,
            <StyledButton
              key="save"
              styleName="primary"
              onClick={() => {
                if (isValid) {
                  onSubmit(
                    assembleTemplateDto(
                      templateToEdit?.id,
                      nameStr,
                      descriptionStr,
                      template.nodes,
                      template.edges,
                      localWorldOrigin as [number, number]
                    )
                  );
                }
              }}
              disabled={!isValid}
              startIcon={<FontAwesomeIcon icon={faSave} />}
            >
              Save Changes
            </StyledButton>,
          ]}
          helpText={helpText}
        />
      }
    >
      <Typography variant="subtitle1">
        Describe the physical layout of the scene by placing nodes at key
        locations, ensuring that you only add edges that you have actually
        measured. You must place a minimum of 4 nodes to describe your scene,
        but it is recommended to place more nodes to improve the accuracy and
        usefulness of the template. Nodes are red if they are insufficiently
        located by measurements.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Once you have placed all of the nodes, drag the origin tag to designate
        a node as the world origin. This is the location in the scene from which
        all world coordinates will be measured. For help, click the question
        mark icon in the top right corner.
      </Typography>

      <Suspense fallback={<BuilderFallback />}>
        <TemplateBuilder
          {...template}
          worldOrigin={localWorldOrigin}
          setWorldOrigin={setLocalWorldOrigin}
          stageScale={stageScale}
          setStageScale={(s) => setStageScale(s)}
        />
      </Suspense>
      <div>
        <MaxLengthTextField
          label="Template Name"
          placeholder="E.g. Long jump runway and pit"
          value={nameStr}
          onChange={(e) => setName(e.target.value as string)}
          maxLength={MAX_NAME_LEN}
          error={nameMissing && nameModified}
          helperText={nameMissing && nameModified && 'Must provide a name'}
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
        />
        <MaxLengthTextField
          label="Template Description"
          placeholder="E.g. A 1.22m wide runway with a 30cm take-off board and 2.75m wide pit."
          value={descriptionStr}
          onChange={(e) => setDescription(e.target.value as string)}
          maxLength={MAX_DESCRIP_LEN}
          error={descriptionMissing && descriptionModified}
          helperText={
            descriptionMissing &&
            descriptionModified &&
            'Must provide a description'
          }
          fullWidth
          margin="normal"
          InputLabelProps={{
            shrink: true,
          }}
          multiline
        />
      </div>
    </DraggableDialog>
  );
};

export default PointCorrespondenceTemplateBuilder;
