import { Divider, List, ListSubheader } from '@mui/material';
import { CustomDataType } from 'graph/types';
import { memo, ReactElement } from 'react';

interface Props {
  nodeId: string;
  title?: string;
  items: { key: string; type: CustomDataType }[];
  renderListGroup: (
    nodeId: string,
    groupKey: string | undefined,
    groupLabel: string | undefined,
    groupedElements: {
      key: string;
      type: CustomDataType;
    }[]
  ) => ReactElement;
}

const insertDividers = (elements: ReactElement[]): ReactElement[] => {
  const elementCount = elements.length;
  return elements.flatMap((el, i) => {
    if (i < elementCount - 1) {
      return [el, <Divider key={`DIVIDER_${i}`} id={`DIVIDER_${i}`} />];
    }
    return el;
  });
};

const groupDataTypes = (elements: { key: string; type: CustomDataType }[]) => {
  const groupKeys: (string | undefined)[] = [];
  const groupLabels: (string | undefined)[] = [];
  const groupedElements: {
    key: string;
    type: CustomDataType;
  }[][] = [];

  elements.forEach((dataType) => {
    if (
      groupKeys.length === 0 ||
      groupKeys[groupKeys.length - 1] !== dataType.type.groupKey
    ) {
      groupedElements.push([]);
      groupKeys.push(dataType.type.groupKey);
      groupLabels.push(dataType.type.groupLabel);
    }
    groupedElements[groupedElements.length - 1].push(dataType);
  });

  return {
    groupKeys,
    groupLabels,
    groupedElements,
  };
};

const NodeItemList = ({ nodeId, title, items, renderListGroup }: Props) => {
  const visibleItems = items.filter((item) => !item.type.hidden);
  const { groupKeys, groupLabels, groupedElements } =
    groupDataTypes(visibleItems);

  // Not all items are grouped, so we just use their index instead of a group key
  const cleanedGroupKeys = groupKeys.map((v, i) =>
    v === undefined ? `${i}` : v
  );

  const subheader = title ? <ListSubheader>{title}</ListSubheader> : undefined;
  return (
    <List subheader={subheader}>
      {insertDividers(
        groupedElements.map((group, i) =>
          renderListGroup(nodeId, cleanedGroupKeys[i], groupLabels[i], group)
        )
      )}
    </List>
  );
};

export default memo(NodeItemList);
