import {
  faCodeFork,
  faCompressAlt,
  faExpandAlt,
  faInfo,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup, Typography } from '@mui/material';
import EditableText from 'components/EditableText';
import StyledButton from 'components/StyledButton';
import { FlowGraphNodeType, IFlowGraphNodeData } from 'graph/types';
import useFlowGraph from 'hooks/useFlowGraph';
import useGraph from 'hooks/useGraph';
import useNodeInfoDialog from 'hooks/useNodeInfoDialog';
import { memo } from 'react';
import { Edge, Node } from 'reactflow';
import styles from './index.module.scss';

interface Props {
  id: string;
  data: IFlowGraphNodeData;
  nodeTypeObj: FlowGraphNodeType;
  showCollapseButton?: boolean;
}

const NodeHeader = ({ id, data, nodeTypeObj, showCollapseButton }: Props) => {
  const { name, icon } = nodeTypeObj;
  const {
    updateNodeId,
    collapseNode,
    expandNode,
    deleteNode,
    reassociateBlobs,
  } = useGraph();
  const { flowGraph: flowGraph } = useFlowGraph();
  const { showNodeInfoDialog } = useNodeInfoDialog();
  const { collapsed } = data;
  const existingElementIds = (flowGraph.nodes as (Node | Edge)[])
    .concat(flowGraph.edges)
    .map((el) => el.id)
    .filter((elId) => elId !== id);

  const getErrorMsg = (value: string): string | undefined => {
    if (existingElementIds.includes(value)) {
      return 'Must be unique';
    }
    if (value.length === 0) {
      return 'Cannot be empty';
    }
  };

  return (
    <div className={styles.container}>
      <div className={styles['top-row']}>
        <FontAwesomeIcon
          className={styles['node-icon']}
          icon={icon ?? faCodeFork}
          size="1x"
        />
        <Typography variant="overline" display="block" className={styles.title}>
          {name}
        </Typography>

        <ButtonGroup>
          <StyledButton
            styleName="secondary"
            className={`${styles['corner-btn']} nodrag`}
            title="Node Info"
            onClick={() => showNodeInfoDialog(nodeTypeObj)}
          >
            <FontAwesomeIcon icon={faInfo} size="lg" />
          </StyledButton>
          {showCollapseButton && (
            <StyledButton
              styleName="secondary"
              className={`${styles['corner-btn']} nodrag`}
              title={
                collapsed ? 'Show advanced options' : 'Hide advanced options'
              }
              onClick={() => {
                if (collapsed) expandNode(id);
                else collapseNode(id);
              }}
            >
              <FontAwesomeIcon
                icon={collapsed ? faExpandAlt : faCompressAlt}
                size="lg"
              />
            </StyledButton>
          )}
          <StyledButton
            styleName="danger"
            className={`${styles['corner-btn']} nodrag`}
            title="Delete"
            onClick={() => deleteNode(id)}
          >
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </StyledButton>
        </ButtonGroup>
      </div>
      <span className={styles['subtitle-container']}>
        <EditableText
          label="Node name"
          tooltip="Click to edit node name"
          value={id}
          setValue={(newId) => {
            updateNodeId(id, newId);
            reassociateBlobs(id, newId);
          }}
          getErrorMsg={getErrorMsg}
          typographyProps={{
            id: 'title',
            className: styles.subtitle,
            variant: 'h6',
          }}
          textFieldProps={{
            id: 'title',
            className: `${styles.subtitle} nodrag`,
          }}
        />
      </span>
    </div>
  );
};

export default memo(NodeHeader);
