import { GraphContext } from 'contexts/Graph';
import { useContext } from 'react';

const useAllJobInputs = () => {
  const { inputBlobs: inputs, removeBlob: removeInputValue } =
    useContext(GraphContext);
  return {
    inputs,
    removeInputValue,
  };
};

export default useAllJobInputs;
