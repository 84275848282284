import { faInfo, faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Divider,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { getNodeTypeKeyFromNodeType } from 'graph';
import { NodeCategory } from 'graph/nodeCategories';
import { NodeTypeKey } from 'graph/NodeTypes';
import { FlowGraphNodeType, OriginDataType } from 'graph/types';
import { Fragment, ReactElement } from 'react';
import { OnConnectStartParams } from 'reactflow';
import styles from '../index.module.scss';

interface Props {
  nodeTypes: FlowGraphNodeType[];
  showDetail: (nodeTypeObj: FlowGraphNodeType) => void;
  addNode?: (nodeType: NodeTypeKey) => void;
  addNodeAndEdges?: (nodeType: NodeTypeKey) => void;
  originDataType?: OriginDataType;
  originConnectionParams?: OnConnectStartParams;
}

type GroupedNodeTypes = {
  [key: string]: { [key: string]: FlowGraphNodeType };
};

const GroupedNodeBrowser = ({
  nodeTypes,
  showDetail,
  addNode,
  addNodeAndEdges,
  originDataType,
  originConnectionParams,
}: Props) => {
  const categoryFromKey: { [key: string]: NodeCategory } = {};
  const groupedByCategory: GroupedNodeTypes = {};
  const originHandleType = originConnectionParams?.handleType;

  nodeTypes.forEach((nodeType) => {
    if (originDataType) {
      const isCompatible =
        originHandleType === 'source'
          ? nodeType.inputTypes?.some((inputType: any) => {
              return inputType.type.compatibleWith(
                originDataType.type
              ) as boolean;
            })
          : nodeType.outputTypes?.some((outputType: any) => {
              return outputType.type.compatibleWith(
                originDataType.type
              ) as boolean;
            });

      if (isCompatible) {
        const key = nodeType.name;
        categoryFromKey[nodeType.category.key] = nodeType.category;
        groupedByCategory[nodeType.category.key] =
          groupedByCategory[nodeType.category.key] ?? {};
        groupedByCategory[nodeType.category.key][key] = nodeType;
      }
    } else {
      const key = nodeType.name;
      categoryFromKey[nodeType.category.key] = nodeType.category;
      groupedByCategory[nodeType.category.key] =
        groupedByCategory[nodeType.category.key] ?? {};
      groupedByCategory[nodeType.category.key][key] = nodeType;
    }
  });

  const makeCategoryComponents = (
    categoryKey: string,
    nodeGroup: { [key: string]: FlowGraphNodeType }
  ): ReactElement => {
    const category = categoryFromKey[categoryKey];
    return (
      <Fragment key={categoryKey}>
        <ListSubheader className={styles['category-subheader']} disableSticky>
          <span>{category.label}</span>

          <Divider className={styles['inline-divider']} />
          <DelayedTooltip title={category.description}>
            <span className={styles['info-btn']}>
              <FontAwesomeIcon icon={faInfoCircle} />
            </span>
          </DelayedTooltip>
        </ListSubheader>

        {Object.keys(nodeGroup)
          .sort()
          .map((k) => {
            const node = nodeGroup[k];
            const key = getNodeTypeKeyFromNodeType(node);
            const { name, description } = node;
            return (
              <ListItem
                key={key}
                disablePadding
                secondaryAction={
                  <IconButton
                    edge="end"
                    size="small"
                    onClick={() => showDetail(node)}
                  >
                    <FontAwesomeIcon icon={faInfo} size="sm" />
                  </IconButton>
                }
              >
                <ListItemButton
                  onClick={() => {
                    if (key && !originDataType && addNode) {
                      addNode(key);
                    }
                    if (key && originDataType && addNodeAndEdges) {
                      addNodeAndEdges(key);
                    }
                  }}
                  className="MuiListItem-button" // Add btn class to get the hover css
                >
                  <ListItemAvatar>
                    <Avatar>
                      {node.icon ? (
                        <FontAwesomeIcon icon={node.icon} />
                      ) : (
                        name[0].toUpperCase()
                      )}
                    </Avatar>
                  </ListItemAvatar>
                  <ListItemText primary={name} secondary={description} />
                </ListItemButton>
              </ListItem>
            );
          })}
      </Fragment>
    );
  };

  return (
    <List dense>
      {Object.keys(groupedByCategory).length === 0 ? (
        <ListItem>
          <Typography
            variant="h4"
            gutterBottom
            color="textSecondary"
            className={styles['no-results']}
          >
            <div>No results!</div>
          </Typography>
        </ListItem>
      ) : (
        Object.keys(groupedByCategory)
          .sort()
          .map((categoryKey) =>
            makeCategoryComponents(categoryKey, groupedByCategory[categoryKey])
          )
      )}
    </List>
  );
};

export default GroupedNodeBrowser;
