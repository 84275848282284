import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Calibration Intrinsics Computer',
  description:
    'Calculate the camera intrinsic parameters using calibration pattern detections.',
  descriptionDetail:
    'When provided calibration pattern detections of known size, this node uses OpenCV to compute the camera intrinsics.',
  icon: faCalculator,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'CalibrationIntrinsicsComputer',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060495086095',
  configTypes: [
    {
      key: 'no_distortion',
      type: PipelineConfigType.BOOLEAN('No camera distortion')
        .REQUIRED.DEFAULT(true)
        .DESCRIPTION(
          'Whether to assume no camera distortion. This sets the distortion coefficients to zero.'
        ),
    },
    {
      key: 'max_calibration_frames',
      type: PipelineConfigType.INTEGER('Max number of calibration frames')
        .REQUIRED.DEFAULT(20)
        .MIN(1)
        .MAX(150)
        .SLIDER(1)
        .DESCRIPTION(
          'The maximum number of frames used for calibration. More frames means better calibration but longer processing.'
        ),
    },
    {
      key: 'exception_on_failure',
      type: PipelineConfigType.BOOLEAN('Exception on failure')
        .CONSTANT_VALUE(true)
        .DESCRIPTION('Throw an exception if calibration fails.'),
    },
  ],
  inputTypes: [
    {
      key: 'pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'intrinsic_matrix',
      type: PipelineDataType.CAMERA_INTRINSIC_MATRIX,
    },
    {
      key: 'distortion_coefficients',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS,
    },
  ],
};
