/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import { FormControl, Input, Slider, Typography } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { nullOrUndef } from 'utils/helpers';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<number, number> {
  labelOverride?: string;
}

const SliderInput = ({ value, setValue, configType, labelOverride }: Props) => {
  const [inputIsChanging, setInputIsChanging] = useState(false);
  const [localValue, setLocalValue] = useState(value);
  const label = labelOverride ?? configType.label;
  // Set the actual node config value with a debounce
  const updateValueDebounced = useDebouncedCallback(
    (value: number) => {
      configType.setValue(value, setValue);
    },
    500,
    { leading: false, trailing: true }
  );
  useEffect(() => {
    if (!nullOrUndef(localValue)) {
      if (inputIsChanging) {
        updateValueDebounced(localValue);
        setInputIsChanging(false);
      } else {
        setLocalValue(value);
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [localValue, value, updateValueDebounced]);

  const marks = configType.sliderMarks?.map((mark) => ({
    value: mark,
    label: mark,
  }));

  return (
    <FormControl className={styles.formcontrol}>
      <Typography variant="caption" color="rgba(0, 0, 0, 0.6)">
        {label}
      </Typography>
      <div className={`${styles.container} nodrag`}>
        <DelayedTooltip title="Drag to set, double click to reset to default">
          <div
            onClick={(e) => {
              // Reset to default on double click
              if (e.detail === 2 && !nullOrUndef(configType.defaultValue)) {
                setInputIsChanging(true);
                setLocalValue(configType.defaultValue as number);
              }
            }}
          >
            <Slider
              value={localValue}
              min={configType.minValue}
              max={configType.maxValue}
              step={configType.sliderStep}
              onChange={(e, v) => {
                if (Array.isArray(v)) v = v[0];
                setInputIsChanging(true);
                configType.setValue(v, setLocalValue);
              }}
              marks={marks}
              size={nullOrUndef(localValue) ? 'small' : 'medium'}
            />
          </div>
        </DelayedTooltip>
        <Input
          className={styles.input}
          value={localValue}
          size="small"
          onChange={(e) => {
            setInputIsChanging(true);
            configType.setValue(e.target.value, setLocalValue);
          }}
          inputProps={{
            min: configType.minValue,
            max: configType.maxValue,
            step: configType.sliderStep,
            type: 'number',
          }}
        />
      </div>
    </FormControl>
  );
};

export default SliderInput;
