import BlobUploadButton from 'components/Blobs/BlobUploadButton';
import { PipelineDataType } from 'graph/types';
import { Pipeline_BlobType } from '../../../graphql/graphql';
import useInputBlobs from '../../../hooks/useInputBlobs';

interface Props {
  nodeId: string;
  id: string;
  dataType: PipelineDataType;
}

const InputBlobUploadButton = ({ nodeId, id, dataType }: Props) => {
  const { inputBlob, setBlob, removeThisBlob } = useInputBlobs(nodeId, id);
  return (
    <BlobUploadButton
      blob={inputBlob}
      setBlob={setBlob}
      removeBlob={removeThisBlob}
      nodeId={nodeId}
      propertyId={id}
      dataType={dataType}
      blobType={Pipeline_BlobType.Input}
    />
  );
};

export default InputBlobUploadButton;
