import { faObjectGroup } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineDataType } from 'graph/types';

export default {
  name: 'Bounding Box Overlayer',
  description: 'Overlay bounding boxes onto video frames.',
  descriptionDetail:
    'This node draws bounding boxes onto video frames, optionally labelling each box with its category and score.',
  icon: faObjectGroup,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'BoundingBoxOverlayer',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060338060047',
  inputTypes: [
    {
      key: 'bounding_boxes',
      type: PipelineDataType.BOUNDING_BOXES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.TRACKED_BOUNDING_BOXES,
      ]),
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'overlaid_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
