import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'World Space Plotter',
  description:
    'Plot a top-down view of tracking data, using the point correspondence template.',
  descriptionDetail:
    'If a point correspondence has been set for the pipeline, this node allows for the plotting of a top-down view of the tracking data.',
  icon: faFileExport,
  category: NodeCategory.GRAPH_PLOTTING,
  moduleType: 'TopDownWorldPlotter',
  configTypes: [
    {
      key: 'title',
      type: PipelineConfigType.STRING('Title').DESCRIPTION(
        'The title of the plot.'
      ),
    },
    {
      key: 'subtitle',
      type: PipelineConfigType.STRING('Subtitle').DESCRIPTION(
        'The subtitle of the plot.'
      ),
    },
    {
      key: 'max_img_edge_len',
      type: PipelineConfigType.INTEGER('Max image edge length')
        .DESCRIPTION(
          'Sets the dimensions of the output video by limiting the pixel resolution of its longest edge.'
        )
        .MIN(400)
        .MAX(1920)
        .DEFAULT(1280).REQUIRED,
    },
    {
      key: 'padding_pct',
      type: PipelineConfigType.DECIMAL('Padding percentage')
        .DESCRIPTION(
          'The amount of padding to be applied around the plot. This allows room for the title/subtitle.'
        )
        .MIN(0.05)
        .MAX(0.45)
        .DEFAULT(0.05)
        .NUMERIC_STEP(0.05).REQUIRED.ADVANCED,
    },
    {
      key: 'history_seconds',
      type: PipelineConfigType.DECIMAL('Past (seconds)')
        .DESCRIPTION('The seconds of tracking history to be plotted as a tail.')
        .MIN(0)
        .MAX(10)
        .DEFAULT(3).REQUIRED.ADVANCED,
    },
    {
      key: 'future_arrow_seconds',
      type: PipelineConfigType.DECIMAL('Future (seconds)')
        .DESCRIPTION('The seconds into the future to be plotted as an arrow.')
        .MIN(0)
        .MAX(2)
        .DEFAULT(0.5).REQUIRED.ADVANCED,
    },
    {
      key: 'draw_track_ids',
      type: PipelineConfigType.BOOLEAN('Draw track IDs')
        .DESCRIPTION('Whether or not to render the track IDs on the markers.')
        .DEFAULT(true).REQUIRED.ADVANCED,
    },
    {
      key: 'draw_velocities',
      type: PipelineConfigType.BOOLEAN('Draw velocities')
        .DESCRIPTION(
          'Whether or not to render the current velocity beside each marker.'
        )
        .DEFAULT(true).REQUIRED,
    },
  ],
  inputTypes: [
    {
      key: 'world_positions',
      type: PipelineDataType.TRACKED_WORLD_POSITIONS_2D.REQUIRED,
    },
    {
      key: 'velocities',
      type: PipelineDataType.VELOCITIES,
    },
    {
      key: 'point_correspondence',
      type: PipelineDataType.POINT_CORRESPONDENCE.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'plot_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
