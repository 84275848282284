import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Pose Detector',
  description: 'Detect the pose of people found in bounding boxes.',
  descriptionDetail:
    'Given a collection of bounding box detections and the associated video frames, this node detects the pose of the person found in each box.',
  icon: faWalking,
  category: NodeCategory.DETECTION,
  moduleType: 'ViTPose',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060343691279',
  configTypes: [
    {
      key: 'flip',
      type: PipelineConfigType.BOOLEAN('Flip & average')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'Whether to flip images and average predictions. Doing so may yield more accurate results but will take twice as long to process.'
        ),
    },
    {
      key: 'half',
      type: PipelineConfigType.BOOLEAN('Half-precision')
        .CONSTANT_VALUE(true)
        .ADVANCED.DESCRIPTION(
          'If true, 16 bit numbers are used instead of 32 bit. This will run faster at a small cost in accuracy.'
        ),
    },
    {
      key: 'scale_box',
      type: PipelineConfigType.DECIMAL('Box scale factor')
        .DEFAULT(1.5)
        .ADVANCED.MIN(0.5)
        .MAX(2)
        .SLIDER(0.1)
        .DESCRIPTION(
          'The factor to scale up the area of the boxes before detecting pose. Scale factor of 1.5 is default.'
        ),
    },
    {
      key: 'pose_type',
      type: PipelineConfigType.STRING('Pose type')
        .CONSTANT_VALUE('ASPSET')
        .OPTIONS(['MPII', 'COCO', 'ASPSET'])
        .DESCRIPTION('The format of the pose keypoints.'),
    },
    {
      key: 'model_weights',
      type: PipelineConfigType.STRING('Model weights')
        .CONSTANT_VALUE('aspset21j')
        .DEV_OVERRIDE((instance) => {
          instance.constantValue = undefined;
          instance.hidden = false;
          instance.defaultValue = 'aspset21j';
          instance.required = true;
        })
        .DESCRIPTION('The model weights file to use.'),
    },
  ],
  inputTypes: [
    {
      key: 'bounding_boxes',
      type: PipelineDataType.BOUNDING_BOXES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.TRACKED_BOUNDING_BOXES,
      ]),
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED,
    },
  ],
};
