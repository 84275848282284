import { LinearProgress } from '@mui/material';
import axios from 'axios';
import DelayedTooltip from 'components/DelayedTooltip';
import StyledButton from 'components/StyledButton';
import { useCallback, useEffect, useState } from 'react';
import style from './index.module.scss';

interface Props {
  uploadUrl: string;
  file: File;
  onUploadComplete: () => void;
  onUploadCancelled: () => void;
  onUploadFailed: (msg: string) => void;
}

export const fetchUploadUri = async (
  signedUrl: string,
  contentType = 'application/octet-stream'
) => {
  const headers: Record<string, string> = {
    ['X-Goog-Resumable']: 'start',
  };
  if (contentType) {
    headers['Content-Type'] = contentType;
  }
  const resp = await fetch(signedUrl, {
    method: 'POST',
    headers,
  });
  const location = resp.headers.get('Location');
  if (!location) {
    throw new Error('Failed to get upload location');
  }
  return location;
};

const UploadInProgressButton = ({
  uploadUrl,
  file,
  onUploadComplete,
  onUploadCancelled,
  onUploadFailed,
}: Props) => {
  const [percent, setPercent] = useState<number | undefined>();
  const [cancelTokenSrc] = useState(axios.CancelToken.source());

  const beforeCloseEventHandler = useCallback((event: BeforeUnloadEvent) => {
    event.preventDefault();
    return (event.returnValue = 'You have upload(s) in progress.');
  }, []);

  useEffect(() => {
    if (typeof percent === 'undefined' && file) {
      const upload = async () => {
        try {
          const uri = await fetchUploadUri(uploadUrl);

          const req = await axios.put(uri, file, {
            onUploadProgress: (progressEvent) => {
              setPercent((100 * progressEvent.loaded) / progressEvent.total!);
            },
            cancelToken: cancelTokenSrc.token,
          });
          if (req.status !== 200) {
            throw new Error(`Upload failed with status ${req.status}`);
          }
        } catch (err) {
          if (axios.isCancel(err)) {
            onUploadCancelled();
          } else {
            onUploadFailed(String(err));
          }
        }
      };
      window.addEventListener('beforeunload', beforeCloseEventHandler);
      setPercent(0);
      void upload();
    }
  }, [
    beforeCloseEventHandler,
    cancelTokenSrc.token,
    file,
    percent,
    uploadUrl,
    onUploadFailed,
    onUploadCancelled,
  ]);

  useEffect(() => {
    if (percent && percent >= 100) {
      onUploadComplete();
      window.removeEventListener('beforeunload', beforeCloseEventHandler);

      // Reset the percentage to not re-trigger this hook
      setPercent(undefined);
    }
  }, [beforeCloseEventHandler, onUploadComplete, percent]);

  return (
    <DelayedTooltip title={'Uploading - click to cancel'}>
      <span>
        <StyledButton
          className={`nodrag ${style.btn}`}
          styleName="primary"
          fullWidth
          onClick={() => cancelTokenSrc.cancel()}
        >
          <span>Uploading</span>

          <LinearProgress
            variant="determinate"
            value={percent ?? 0}
            className={style.progress}
          />

          <span>{`${Math.round(percent ?? 0).toFixed(0)}%`}</span>
        </StyledButton>
      </span>
    </DelayedTooltip>
  );
};

export default UploadInProgressButton;
