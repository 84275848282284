type FileFormat = 'plain' | 'json' | 'csv';

const acceptString = (fileFormat: FileFormat) => {
  switch (fileFormat) {
    case 'plain':
      return 'text/plain';
    case 'json':
      return 'application/json';
    case 'csv':
      return 'text/csv';
    default:
      return 'text/plain';
  }
};

const formatData = (data: string, fileFormat: FileFormat) =>
  `data:text/${fileFormat};charset=utf-8,${encodeURIComponent(data)}`;

const useTextFileIO = (fileFormat: FileFormat) => {
  const download = (text: string, filename: string) => {
    const element = document.createElement('a');
    element.setAttribute('href', formatData(text, fileFormat));
    element.setAttribute('download', filename);
    element.style.display = 'none';
    document.body.appendChild(element);
    element.click();
    document.body.removeChild(element);
  };

  const upload = () => {
    const input = document.createElement('input');
    input.type = 'file';
    input.accept = acceptString(fileFormat);
    input.style.display = 'none';
    input.multiple = false;
    document.body.appendChild(input);

    return new Promise<string>((resolve, reject) => {
      input.onchange = (e) => {
        const files = (e.target as HTMLInputElement).files;
        if (files && files.length > 0) {
          const file = files[0];
          const reader = new FileReader();
          reader.onload = (e) => {
            if (e.target?.result) {
              resolve(e.target.result as string);
            } else {
              reject();
            }
          };
          reader.readAsText(file);
        } else {
          reject();
        }
      };

      input.click();
      document.body.removeChild(input);
    });
  };

  return {
    download,
    upload,
  };
};

export default useTextFileIO;
