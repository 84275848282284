import { Autocomplete, TextField } from '@mui/material';
import { valueViolatesMinMaxConstraints } from 'graph/typeUtils';
import { useCallback } from 'react';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<any, any> {
  labelOverride?: string;
  integerOnly?: boolean;
  uniqueOnly?: boolean;
  [key: string]: any;
}

interface AutocompleteOption {
  id: string;
  value: string;
}

const CommaSeparatedNumbersInput = ({
  value,
  setValue,
  configType,
  labelOverride,
  integerOnly,
  uniqueOnly,
  nodeId: _1,
  configKey: _2,
  ...rest
}: Props) => {
  value = value ? String(value) : '';

  const parse = useCallback(
    (v: string) => {
      if (integerOnly) {
        return parseInt(v, 10);
      }
      return parseFloat(v);
    },
    [integerOnly]
  );

  const valuesArr: AutocompleteOption[] = value
    .split(',')
    .filter(Boolean)
    .map(parse)
    .map((v: number, i: number) => ({
      value: String(v),
      id: String(i),
    }));

  const label = labelOverride ?? configType.label;
  return (
    <Autocomplete
      className="nodrag"
      multiple
      size="small"
      options={[]}
      getOptionLabel={(option) => option.value}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        const inputInt = parse(inputValue);
        if (isNaN(inputInt)) return [];

        // If we enforce unique values, ensure that the value doesn't yet exist
        if (
          uniqueOnly &&
          valuesArr.some(
            (value: AutocompleteOption) => value.value === inputValue
          )
        ) {
          return [];
        }
        if (
          valueViolatesMinMaxConstraints(
            inputInt,
            configType.minValue,
            configType.maxValue
          )
        ) {
          return [];
        }
        return [
          {
            value: inputValue,
            id: String(valuesArr.length),
          },
        ];
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
      value={valuesArr}
      onChange={(e, v) => {
        configType.setValue(
          (v as AutocompleteOption[]).map((v) => v.value).join(','),
          setValue
        );
      }}
      noOptionsText={configType.inputHint}
      classes={{
        tag: styles.unsetwidth,
        endAdornment: styles.unsetwidth,
      }}
      {...rest}
    />
  );
};

export default CommaSeparatedNumbersInput;
