import { Typography } from '@mui/material';
import ApiKeyGenerator from 'components/ApiKeyGenerator';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';

interface Props {
  open: boolean;
  onClose: () => void;
}

const ApiKeyDialog = ({ open, onClose }: Props) => {
  return (
    <DraggableDialog
      title="API Access"
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md' }}
      actions={[
        <StyledButton key="ok" styleName="primary" onClick={onClose} solid>
          OK
        </StyledButton>,
      ]}
    >
      <Typography gutterBottom marginBottom={1}>
        Generate a key for use with the Pipelines API. Any use of the API via
        this key will be associated with your account, so ensure that it is kept
        secure. This key and associated expiry date will only be shown once
        after generation, so you will need to generate a new key if you lose it
        or when it expires.
      </Typography>
      <ApiKeyGenerator />
      <StyledButton
        href="https://mlpt.zendesk.com/hc/en-us/articles/9422580299535"
        target="_blank"
        styleName="secondary"
        fullWidth
      >
        API documentation
      </StyledButton>
    </DraggableDialog>
  );
};

export default ApiKeyDialog;
