import { faChessBoard } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Calibration Pattern Detector',
  description: 'Detect camera calibration patterns in video frames.',
  descriptionDetail:
    'Camera calibration patterns are used to find camera intrinsic properties as well as building a world-space mapping using multiple cameras. This node uses OpenCV to detect the location of calibration patterns in a video stream.',
  icon: faChessBoard,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'CalibrationPatternDetector',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060472013711',
  configTypes: [
    {
      key: 'pattern_shape',
      type: PipelineConfigType.INTEGER('Pattern shape')
        .REQUIRED.TUPLE(['Width', 'Height'])
        .DEFAULT([7, 5])
        .DESCRIPTION(
          'The number of rows and columns of the calibration pattern. For chessboard this is the number of inner corners.'
        ),
    },
    {
      key: 'pattern_type',
      type: PipelineConfigType.STRING('Pattern type')
        .REQUIRED.OPTIONS(['circles', 'chessboard'])
        .DEFAULT('circles')
        .DESCRIPTION(
          'The type of calibration pattern - assymetric circles or chessboard/checkerboard. Visit calib.io to generate.'
        ),
    },
    {
      key: 'pattern_size',
      type: PipelineConfigType.DECIMAL('Pattern size')
        .REQUIRED.MIN(0.0001)
        .DESCRIPTION(
          'The size of the pattern. For a chessboard pattern this is the size of a cell; for a circle pattern this is the diagonal spacing between circles. The units should correspond to world space units (e.g, mm).'
        ),
    },
    {
      key: 'blob_detector_min_area',
      type: PipelineConfigType.DECIMAL('Min circle area')
        .REQUIRED.DEFAULT(25)
        .MIN(20)
        .DESCRIPTION(
          "The minimum allowable area (pixels²) of a circle to count as 'detected'. Only applies to 'circle' pattern type."
        ).ADVANCED,
    },
    {
      key: 'blob_detector_max_area',
      type: PipelineConfigType.DECIMAL('Max circle area')
        .REQUIRED.DEFAULT(2000)
        .MIN(26)
        .DESCRIPTION(
          "The maximum allowable area (pixels²) of a circle to count as 'detected'. Only applies to 'circle' pattern type."
        ).ADVANCED,
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS,
    },
  ],
};
