import Spinner from 'components/Spinner';
import styles from './Fallback.module.scss';

interface Props {
  height: number | undefined;
  width: number | undefined;
}
const TemplateSelectorFallback = ({ height, width }: Props) => {
  return (
    <div className={styles.container} style={{ height, width }}>
      <div>
        <Spinner />
      </div>
    </div>
  );
};

export default TemplateSelectorFallback;
