import { IFlowGraph } from 'contexts/Graph';
import { FlowGraphNodeType } from 'graph/types';
import AlternateBoundingBoxTracker from './AlternateBoundingBoxTracker';
import BoundingBoxOverlayer from './BoundingBoxOverlayer';
import BoundingBoxTracker from './BoundingBoxTracker';
import BoundingBoxTrackInterpolator from './BoundingBoxTrackInterpolator';
import CalibrationIntrinsicsComputer from './CalibrationIntrinsicsComputer';
import CalibrationPatternDetector from './CalibrationPatternDetector';
import FrameCombiner from './FrameCombiner';
import FrameLabelTimelineOverlayer from './FrameLabelTimelineOverlayer';
import FrameResizer from './FrameResizer';
import ImageColourAdjuster from './ImageColourAdjuster';
import ImageGeometryTransformer from './ImageGeometryTransformer';
import ImagePointOverlayer from './ImagePointOverlayer';
import JointAngleMeasurer from './JointAngleMeasurer';
import KinematicsCalculator from './KinematicsCalculator';
import KinogramBuilder from './KinogramBuilder';
import KinoveaWriter from './KinoveaWriter';
import MarkdownEditor from './MarkdownEditor';
import ObjectDetector from './ObjectDetector';
import PatternLinearityFilter from './PatternLinearityFilter';
import PatternMovementFilter from './PatternMovementFilter';
import PipelinesCSVLoader from './PipelinesCSVLoader';
import PipelinesDataOutput from './PipelinesDataOutput';
import PipelinesJSONLoader from './PipelinesJSONLoader';
import PoseDetector from './PoseDetector';
import PoseLowPassFilter from './PoseLowPassFilter';
import PoseOverlayer from './PoseOverlayer';
import PosePlotter3D from './PosePlotter3D';
import RectangleOverlayer from './RectangleOverlayer';
import Reprojector from './Reprojector';
import ScatterPlot2D from './ScatterPlot2D';
import ScatterPlot3D from './ScatterPlot3D';
import Segmentation from './Segmentation';
import StereoExtrinsicsCalculator from './StereoExtrinsicsCalculator';
import StereoTriangulator from './StereoTriangulator';
import StrideRate from './StrideRate';
import TimeSeriesPlot from './TimeSeriesPlot';
import TrackedBoundingBoxCropExtractor from './TrackedBoundingBoxCropExtractor';
import TrackedBoundingBoxWriter from './TrackedBoundingBoxWriter';
import TrackedPoseWriter from './TrackedPoseWriter';
import TrackSelector from './TrackSelector';
import VideoLoader from './VideoLoader';
import VideoWriter from './VideoWriter';
import WorldSpacePlotter from './WorldSpacePlotter';

export type NodeTypeKey =
  | 'AlternateBoundingBoxTracker'
  | 'BoundingBoxOverlayer'
  | 'BoundingBoxTracker'
  | 'BoundingBoxTrackInterpolator'
  | 'CalibrationIntrinsicsComputer'
  | 'CalibrationPatternDetector'
  | 'FrameCombiner'
  | 'FrameLabelTimelineOverlayer'
  | 'FrameResizer'
  | 'ImageColourAdjuster'
  | 'ImageGeometryTransformer'
  | 'ImagePointOverlayer'
  | 'JointAngleMeasurer'
  | 'KinematicsCalculator'
  | 'KinoveaWriter'
  | 'KinogramBuilder'
  | 'MarkdownEditor'
  | 'RectangleOverlayer'
  | 'ObjectDetector'
  | 'PatternLinearityFilter'
  | 'PatternMovementFilter'
  | 'PipelinesCSVLoader'
  | 'PipelinesDataOutput'
  | 'PipelinesJSONLoader'
  | 'PoseDetector'
  | 'PoseLowPassFilter'
  | 'PoseOverlayer'
  | 'PosePlotter3D'
  | 'Reprojector'
  | 'ScatterPlot2D'
  | 'ScatterPlot3D'
  | 'Segmentation'
  | 'StereoExtrinsicsCalculator'
  | 'StereoTriangulator'
  | 'StrideRate'
  | 'TimeSeriesPlot'
  | 'TrackedBoundingBoxCropExtractor'
  | 'TrackedBoundingBoxWriter'
  | 'TrackedPoseWriter'
  | 'TrackSelector'
  | 'VideoLoader'
  | 'VideoWriter'
  | 'WorldSpacePlotter';

export type NodeTypeDict = { [key in NodeTypeKey]: FlowGraphNodeType };

export const NodeTypes: NodeTypeDict = {
  AlternateBoundingBoxTracker: Object.freeze(AlternateBoundingBoxTracker),
  BoundingBoxOverlayer: Object.freeze(BoundingBoxOverlayer),
  BoundingBoxTracker: Object.freeze(BoundingBoxTracker),
  BoundingBoxTrackInterpolator: Object.freeze(BoundingBoxTrackInterpolator),
  CalibrationIntrinsicsComputer: Object.freeze(CalibrationIntrinsicsComputer),
  CalibrationPatternDetector: Object.freeze(CalibrationPatternDetector),
  FrameCombiner: Object.freeze(FrameCombiner),
  FrameLabelTimelineOverlayer: Object.freeze(FrameLabelTimelineOverlayer),
  FrameResizer: Object.freeze(FrameResizer),
  ImageColourAdjuster: Object.freeze(ImageColourAdjuster),
  ImageGeometryTransformer: Object.freeze(ImageGeometryTransformer),
  ImagePointOverlayer: Object.freeze(ImagePointOverlayer),
  JointAngleMeasurer: Object.freeze(JointAngleMeasurer),
  KinematicsCalculator: Object.freeze(KinematicsCalculator),
  KinoveaWriter: Object.freeze(KinoveaWriter),
  KinogramBuilder: Object.freeze(KinogramBuilder),
  MarkdownEditor: Object.freeze(MarkdownEditor),
  RectangleOverlayer: Object.freeze(RectangleOverlayer),
  ObjectDetector: Object.freeze(ObjectDetector),
  PatternLinearityFilter: Object.freeze(PatternLinearityFilter),
  PatternMovementFilter: Object.freeze(PatternMovementFilter),
  PipelinesCSVLoader: Object.freeze(PipelinesCSVLoader),
  PipelinesDataOutput: Object.freeze(PipelinesDataOutput),
  PipelinesJSONLoader: Object.freeze(PipelinesJSONLoader),
  PoseDetector: Object.freeze(PoseDetector),
  PoseLowPassFilter: Object.freeze(PoseLowPassFilter),
  PoseOverlayer: Object.freeze(PoseOverlayer),
  PosePlotter3D: Object.freeze(PosePlotter3D),
  Reprojector: Object.freeze(Reprojector),
  ScatterPlot2D: Object.freeze(ScatterPlot2D),
  ScatterPlot3D: Object.freeze(ScatterPlot3D),
  Segmentation: Object.freeze(Segmentation),
  StereoExtrinsicsCalculator: Object.freeze(StereoExtrinsicsCalculator),
  StereoTriangulator: Object.freeze(StereoTriangulator),
  StrideRate: Object.freeze(StrideRate),
  TimeSeriesPlot: Object.freeze(TimeSeriesPlot),
  TrackedBoundingBoxCropExtractor: Object.freeze(
    TrackedBoundingBoxCropExtractor
  ),
  TrackedBoundingBoxWriter: Object.freeze(TrackedBoundingBoxWriter),
  TrackedPoseWriter: Object.freeze(TrackedPoseWriter),
  TrackSelector: Object.freeze(TrackSelector),
  VideoLoader: Object.freeze(VideoLoader),
  VideoWriter: Object.freeze(VideoWriter),
  WorldSpacePlotter: Object.freeze(WorldSpacePlotter),
};

// Any nodes that are renamed in the above object should be added to this map so as to not break existing nodes
export const NODE_RENAMES: { [key: string]: NodeTypeKey } = {
  JSONLoader: 'PipelinesJSONLoader',
  CSVLoader: 'PipelinesCSVLoader',
  DataOutput: 'PipelinesDataOutput',
  TrackedBoundingBoxOverlayer: 'BoundingBoxOverlayer',
  MovementStatisticsExtractor: 'KinematicsCalculator',
};

export const NODE_REMOVALS: string[] = ['ConstantNumber'];

export const updateRenamedNodes = (flowGraph: IFlowGraph): IFlowGraph => {
  flowGraph.nodes.forEach((node) => {
    const newNodeType = NODE_RENAMES[node.data.nodeType];
    if (newNodeType) {
      node.data.nodeType = newNodeType;
    }
  });
  return flowGraph;
};
