import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Pattern Movement Filter',
  description:
    "Filter out calibration pattern detections that don't meet movement thresholds.",
  descriptionDetail:
    'Camera parameter estimation can be negatively affected if a large number of calibration pattern detections are clustered in the same image region. This node filters out detections that are close together, allowing for a more diverse collection of detections.',
  icon: faFilter,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'CalibrationPatternMovementRefiner',
  configTypes: [
    {
      key: 'threshold',
      type: PipelineConfigType.DECIMAL('Movement threshold')
        .REQUIRED.DEFAULT(20.0)
        .MIN(0.5)
        .MAX(100)
        .SLIDER(0.5)
        .DESCRIPTION(
          'The minimum required distance between calibration patterns (pixels).'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'patterns',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_patterns',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'patterns',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_patterns',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED,
    },
  ],
};
