export const secondsToFrameNumber = (seconds: number, fps: number) => {
  return Math.floor(seconds * fps);
};
export const frameNumberToSeconds = (frameNumber: number, fps: number) => {
  return frameNumber / fps;
};
export const secondsToTimecode = (seconds: number) => {
  return new Date(1000 * seconds).toISOString().substr(14, 9);
};
export const frameNumberToTimecode = (frameNumber: number, fps: number) => {
  return secondsToTimecode(frameNumberToSeconds(frameNumber, fps));
};
