import Logo from 'assets/AIS_Gold_Stacked_Nograd_Rev.png';
import GlobalPositionContainer from 'components/GlobalPositionContainer';
import styles from './index.module.scss';

interface Props {
  hideVersionNumber?: boolean;
}
const BackgroundImage = ({ hideVersionNumber }: Props) => (
  <>
    <div className={styles.img} />

    <img src={Logo} alt="AIS logo" className={styles.logo} />

    {hideVersionNumber || (
      <GlobalPositionContainer vertical="top" horizontal="right" zIndex={100}>
        <span>{`v${PACKAGE_INFO.VERSION}`}</span>
      </GlobalPositionContainer>
    )}
  </>
);

export default BackgroundImage;
