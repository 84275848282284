import { Stack, Typography } from '@mui/material';
import Logo from 'assets/AIS_Gold_Stacked_Nograd_Rev.png';
import AppLogo from 'components/AppLogo';
import DelayedTooltip from 'components/DelayedTooltip';
import DraggableDialog from 'components/DraggableDialog';
import EmailDevelopersButton from 'components/EmailDevelopersButton';
import RawTextDialog from 'components/RawTextDialog';
import { APP_NAME } from 'config';
import { useState } from 'react';
import eula from '../../../eula.txt?raw';
import license from '../../../license.txt?raw';
import privacy from '../../../privacy.txt?raw';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

interface TextContent {
  title: string;
  text: string;
}

type EntryKey = 'license' | 'privacy' | 'eula';

const textEntries: { [key in EntryKey]: TextContent } = {
  license: {
    title: 'License',
    text: license,
  },
  privacy: {
    title: 'Privacy Policy',
    text: privacy,
  },
  eula: {
    title: 'End-User Agreement',
    text: eula,
  },
};

const AboutDialog = ({ open, onClose }: Props) => {
  const [selectedEntryKey, setSelectedEntryKey] = useState<
    EntryKey | undefined
  >();

  return (
    <>
      <RawTextDialog
        title={selectedEntryKey ? textEntries?.[selectedEntryKey]?.title : ''}
        content={selectedEntryKey ? textEntries?.[selectedEntryKey]?.text : ''}
        open={Boolean(selectedEntryKey)}
        onClose={() => setSelectedEntryKey(undefined)}
      />

      <DraggableDialog
        title={`About ${APP_NAME}`}
        open={open}
        onClose={onClose}
        dialogProps={{ maxWidth: 'sm', fullWidth: true }}
        allowClose
        className={styles.dialog}
      >
        <Stack component="form">
          <img src={Logo} alt="AIS logo" className={styles['ais-logo']} />
          <div>
            <AppLogo className={styles['app-logo']} />
            <DelayedTooltip
              title={
                <>
                  <div>Commit: {PACKAGE_INFO.COMMIT_HASH}</div>
                  <div>Date: {PACKAGE_INFO.COMMIT_DATE}</div>
                </>
              }
            >
              <Typography
                variant="subtitle1"
                color="white"
                component="span"
              >{`v${PACKAGE_INFO.VERSION}`}</Typography>
            </DelayedTooltip>
          </div>
          {/* <ButtonGroup
            fullWidth
            className={styles.buttons}
            variant="text"
            aria-label="text button group"
          >
            {Object.keys(textEntries).map((key) => (
              <Button
                key={key}
                onClick={() => setSelectedEntryKey(key as EntryKey)}
              >
                {textEntries[key as EntryKey].title}
              </Button>
            ))}
          </ButtonGroup> */}
          <EmailDevelopersButton />
        </Stack>
      </DraggableDialog>
    </>
  );
};

export default AboutDialog;
