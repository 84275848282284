import { TextField, Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import useGraphFromTemplate from 'hooks/useGraphFromTemplate';
import { useEffect, useState } from 'react';
import { isValidUuid } from 'utils/helpers';

interface Props {
  open: boolean;
  onClose: () => void;
}

const PublicLinkLoader = ({ open, onClose }: Props) => {
  const [value, setValue] = useState('');
  const [graphId, setGraphId] = useState<string | undefined>();

  const graphFromTemplateAndRedirect = useGraphFromTemplate();

  // Extract the graph ID from the entered URL, if valid.
  const extractId = (value: string): string | undefined => {
    try {
      // Invalid URLs will throw an error
      const url = new URL(value);
      // Extract the graph ID assuming a URL like https://domain/graph/:gid
      // This will work for any URL with the id as the last part of the path
      const graphId = url.pathname.split('/').pop();

      if (isValidUuid(graphId)) return graphId;
    } catch (e) {
      // Do nothing
    }
    return;
  };

  // Update the graph ID whenever the URL changes
  useEffect(() => setGraphId(extractId(value)), [value]);

  // Submission handler - create the graph and redirect or display an error
  const onSubmit = async () => {
    if (graphId) {
      try {
        await graphFromTemplateAndRedirect(graphId, 'public');
      } finally {
        onClose();
      }
    }
  };

  const userHasEnteredUrl = value.length > 0;
  const graphIdValid = graphId && graphId.length > 0;

  // The submit button is disabled with no graph ID
  const buttonDisabled = !graphIdValid;

  // The form displays and error message if the entered graph ID is invalid
  const formError = !graphIdValid && userHasEnteredUrl;
  const error = formError ? 'Please enter a valid URL' : '';

  return (
    <DraggableDialog
      title="Create from public link..."
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg' }}
      allowClose
      actions={[
        <StyledButton
          key="create"
          styleName="primary"
          onClick={() => void onSubmit()}
          solid
          disabled={buttonDisabled}
        >
          Create Pipeline
        </StyledButton>,
      ]}
    >
      <div>
        <Typography variant="subtitle1">
          {`Paste the link below and click "Create Pipeline" to create a new pipeline using it as a template.`}
        </Typography>
        <TextField
          id="publicLink"
          label="Public Link"
          variant="standard"
          fullWidth
          value={value}
          onChange={(e) => setValue(e.target.value)}
          error={formError}
          helperText={error}
        />
      </div>
    </DraggableDialog>
  );
};

export default PublicLinkLoader;
