import React, { createContext, ReactElement, useState } from 'react';
import { Pipeline_UserPreferences } from '../graphql/graphql';

interface IUserStateContext {
  userPreferences: Pipeline_UserPreferences;
  setUserPreferencesInLocalState: React.Dispatch<
    React.SetStateAction<Pipeline_UserPreferences>
  >;
}

export const UserStateContext = createContext<IUserStateContext>(undefined!);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [userPreferences, setUserPreferencesInLocalState] =
    useState<Pipeline_UserPreferences>({ soundEnabled: true });

  return (
    <UserStateContext.Provider
      value={{ userPreferences, setUserPreferencesInLocalState }}
    >
      {children}
    </UserStateContext.Provider>
  );
};
