import Konva from 'konva';
import { useCallback, useRef, useState } from 'react';
import { uuid } from 'utils/helpers';
import {
  addAdjacency,
  IAdjacencyMap,
  INodeMap,
  roundPixelsToCm,
  roundWorldToCm,
  snapToOthers,
  worldToPixels,
} from 'utils/konvaGraphUtils';
import {
  add,
  dist,
  eq,
  mul,
  nearestPointOnLineSegment,
  normed,
  sub,
  Vec2,
} from 'utils/Vec2';
import useGraph from '../../useGraph';
import { MoveRecipient } from './Builder/EdgeEditContextMenu';
import validate from './validation';

export const SNAP_DIST_THRESH = 25;

const makeDefaultState = (): [INodeMap, IAdjacencyMap] => {
  // The default state is a 4m x 4m square
  const nodeMap: INodeMap = {};
  const adjacencyMap: IAdjacencyMap = {};
  const tempList: string[] = [];
  for (let i = 0; i < 2; i++) {
    for (let j = 0; j < 2; j++) {
      const id = uuid();
      const pixelPos: Vec2 = worldToPixels({
        x: i * 4,
        y: j * 4,
      });
      nodeMap[id] = {
        id,
        pixelPos,
      };
      adjacencyMap[id] = new Set();
      tempList.push(id);
    }
  }
  addAdjacency(adjacencyMap, tempList[0], tempList[1]);
  addAdjacency(adjacencyMap, tempList[0], tempList[2]);
  addAdjacency(adjacencyMap, tempList[1], tempList[3]);
  addAdjacency(adjacencyMap, tempList[2], tempList[3]);

  return [nodeMap, adjacencyMap];
};

const useTemplate = (
  stageScale: number,
  initialState?: [INodeMap, IAdjacencyMap] | undefined
) => {
  const stageRef = useRef<Konva.Stage>(null);
  const {
    nodes,
    edges,
    adjacencies,
    addNode,
    moveNodesPixel,
    deleteNodes,
    removeEdge,
    commitChanges,
    pastLength,
    futureLength,
    canUndo,
    canRedo,
    undo,
    redo,
    replaceNodeWithOther,
    splitEdgesWithNode,
    splitEdge,
    correctEdges,
    selectedNodeIds,
    setNodeSelected,
    addNodeToSelection,
    removeNodeFromSelection,
  } = useGraph(initialState ?? makeDefaultState());

  const [snapHintLines, setSnapHintLines] = useState<[Vec2, Vec2][]>([]);

  const addNodeFromOther = useCallback(
    (otherNodeId: string, skipCommit = false) => {
      if (stageRef.current !== null) {
        const stage = stageRef.current;
        const mousePos = stage.getPointerPosition();
        const scale = stage.scaleX();
        const offset = stage.position();
        if (mousePos) {
          const pixelPos = {
            x: (mousePos.x - offset.x) / scale,
            y: (mousePos.y - offset.y) / scale,
          };
          return addNode([otherNodeId], pixelPos, skipCommit);
        }
      }
    },
    [addNode]
  );

  const moveNode = useCallback(
    (
      id: string,
      target: Konva.Node | null,
      x: number,
      y: number,
      allowSnapping: boolean
    ) => {
      const originalPos = { ...nodes[id].pixelPos };

      let targetPixelPos = { x, y };
      let snapHints: [Vec2, Vec2][] = [];

      if (allowSnapping) {
        [targetPixelPos, snapHints] = snapToOthers(
          id,
          { x, y },
          nodes,
          adjacencies,
          selectedNodeIds,
          stageScale,
          SNAP_DIST_THRESH,
          {
            snapToNodes: true,
            snapToNodeAxes: true,
            snapToEdges: true,
          }
        );
      }
      setSnapHintLines(snapHints);

      const roundedTargetPixelPos = roundPixelsToCm(targetPixelPos);
      // Apply the move to the actual konva target node, if provided
      if (target) {
        target.position(roundedTargetPixelPos);
      }

      const deltaPos = sub(roundedTargetPixelPos, originalPos);
      let idsToMove = [...selectedNodeIds];
      if (!selectedNodeIds.includes(id)) {
        // If the node is not selected, select it
        setNodeSelected(id);
        idsToMove = [id];
      }
      const newNodePositions = idsToMove.map((nodeId) => {
        const origNodePos = nodes[nodeId].pixelPos;
        return add(origNodePos, deltaPos);
      });
      moveNodesPixel(idsToMove, newNodePositions);
    },
    [
      nodes,
      stageScale,
      adjacencies,
      selectedNodeIds,
      moveNodesPixel,
      setNodeSelected,
    ]
  );

  const deleteAndDeselectNodes = useCallback(
    (ids: string[], skipCommit = false) => {
      const newSelectedNodeIds = selectedNodeIds.filter(
        (id) => !ids.includes(id)
      );
      setNodeSelected(
        newSelectedNodeIds.length > 0 ? newSelectedNodeIds[0] : null
      );
      deleteNodes(ids, skipCommit);
    },
    [deleteNodes, selectedNodeIds, setNodeSelected]
  );

  const stopDragging = useCallback(
    (objId: string, objClass: string, dropPosition: Vec2) => {
      setSnapHintLines([]);
      if (objClass === 'Circle') {
        let actionTaken = false;

        if (!actionTaken) {
          // Check if we dropped it onto another node, by their positions being identical
          const receivingNode = Object.values(nodes).find(
            (node) => node.id !== objId && eq(node.pixelPos, dropPosition)
          );
          if (receivingNode) {
            // Delete this node and make the other note the corresponding edge's target
            replaceNodeWithOther(objId, receivingNode.id, true);
            actionTaken = true;
          }
        }

        if (!actionTaken) {
          // Check if we dropped it onto an edge, by its nearest point being very near
          const receivingEdges: [string, string][] = [];
          Object.keys(adjacencies).forEach((sourceId) => {
            if (sourceId === objId) {
              return;
            }
            Array.from(adjacencies[sourceId]).find((targetId) => {
              if (targetId === objId) {
                return;
              }
              const nearestPoint = nearestPointOnLineSegment(
                dropPosition,
                nodes[sourceId].pixelPos,
                nodes[targetId].pixelPos
              );
              const nearestPointRounded = roundWorldToCm(nearestPoint);
              if (dist(nearestPointRounded, dropPosition) < 1) {
                receivingEdges.push([sourceId, targetId]);
              }
            });
          });
          if (receivingEdges.length > 0) {
            splitEdgesWithNode(objId, receivingEdges, true);
            actionTaken = true;
          }
        }

        commitChanges();
      }
    },
    [
      adjacencies,
      commitChanges,
      nodes,
      replaceNodeWithOther,
      splitEdgesWithNode,
    ]
  );

  const resizeEdge = useCallback(
    (
      sourceId: string,
      targetId: string,
      newDistance: number,
      moveRecipient: MoveRecipient
    ) => {
      const sourceNode = nodes[sourceId];
      const targetNode = nodes[targetId];

      const sourcePos = sourceNode.pixelPos;
      const targetPos = targetNode.pixelPos;

      // Figure out which node to move
      // See whether the x or y displacement is greater
      const leftNode =
        sourceNode.pixelPos.x < targetNode.pixelPos.x ? sourceNode : targetNode;
      const rightNode =
        sourceNode.pixelPos.x > targetNode.pixelPos.x ? sourceNode : targetNode;
      const topNode =
        sourceNode.pixelPos.y < targetNode.pixelPos.y ? sourceNode : targetNode;
      const bottomNode =
        sourceNode.pixelPos.y > targetNode.pixelPos.y ? sourceNode : targetNode;
      const xDisplacement = Math.abs(sourcePos.x - targetPos.x);
      const yDisplacement = Math.abs(sourcePos.y - targetPos.y);
      const horizontal = xDisplacement > yDisplacement;

      let movingNode = targetNode;
      let staticNode = sourceNode;
      if (horizontal) {
        movingNode = moveRecipient === 'SouthWest' ? rightNode : leftNode;
        staticNode = moveRecipient === 'SouthWest' ? leftNode : rightNode;
      } else {
        movingNode = moveRecipient === 'SouthWest' ? bottomNode : topNode;
        staticNode = moveRecipient === 'SouthWest' ? topNode : bottomNode;
      }

      // Move the node to the new position
      const normDirection = normed(
        sub(movingNode.pixelPos, staticNode.pixelPos)
      );
      const newDistancePixels = worldToPixels(newDistance);
      const newTargetPos = add(
        staticNode.pixelPos,
        mul(normDirection, newDistancePixels)
      );

      moveNode(movingNode.id, null, newTargetPos.x, newTargetPos.y, false);
      commitChanges();
    },
    [commitChanges, moveNode, nodes]
  );

  const { isValid, problemNodes } = validate(nodes, adjacencies, 4);
  return {
    stageRef,
    edges,
    nodes,
    addNodeFromOther,
    setNodeSelected,
    deleteNodes: deleteAndDeselectNodes,
    resizeEdge,
    removeEdge,
    moveNode,
    addNodeToSelection,
    removeNodeFromSelection,
    splitEdge,
    replaceNodeWithOther,
    stopDragging,
    stageScale,
    selectedNodeIds,
    snapHintLines,
    pastLength,
    futureLength,
    canUndo,
    canRedo,
    undo,
    redo,
    correctEdges,
    isValid,
    problemNodes,
  };
};

export default useTemplate;
