import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Pipelines CSV Loader',
  description: 'Load a Pipelines CSV file into the graph.',
  descriptionDetail:
    'This node allows for the upload of a Pipelines CSV file to the cloud for use in a graph. Note that only files produced by the Data Output node (ending with .pipe.csv) can be uploaded.',
  icon: faFileExport,
  category: NodeCategory.DATA_LOADERS,
  moduleType: 'CSVLoader',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060705818255',
  configTypes: [],
  inputTypes: [
    {
      key: 'input_data',
      type: PipelineDataType.ANY.REQUIRED.LABEL(
        'Input data'
      ).UPLOADABLE.BLOB_DATATYPE(Pipeline_BlobDataType.Csv).NO_HANDLE,
    },
  ],
  outputTypes: [
    {
      key: 'data',
      type: PipelineDataType.ANY.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.BOUNDING_BOXES,
        PipelineDataType.CALIBRATION_PATTERNS,
        PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS,
        PipelineDataType.CAMERA_INTRINSIC_MATRIX,
        PipelineDataType.CAMERA_PROJECTION_MATRIX,
        PipelineDataType.CLASS_LABELS,
        PipelineDataType.CLASS_SCORES,
        PipelineDataType.IMAGE_COORDINATES,
        PipelineDataType.POSE_2D,
        PipelineDataType.POSE_3D,
        PipelineDataType.SCALAR,
        PipelineDataType.TRACKED_BOUNDING_BOXES,
        PipelineDataType.TRACKED_WORLD_POSITIONS_2D,
        PipelineDataType.WORLD_CALIBRATION_PATTERNS,
        PipelineDataType.WORLD_COORDINATES_3D,
      ])
        .BLOB_DATATYPE(Pipeline_BlobDataType.Csv)
        .LABEL('Output Data'),
    },
  ],
};
