import { Pipeline_User, useGetUserQuery } from 'graphql/graphql';
import useAuth from 'hooks/useAuth';

import { createContext, ReactElement } from 'react';

interface IUserContext {
  user: Pipeline_User | undefined;
  refetchUser: () => void;
}

export const UserContext = createContext<IUserContext>(undefined!);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { isPipeliner } = useAuth();
  const { data, refetch } = useGetUserQuery({
    fetchPolicy: 'no-cache',
    skip: !isPipeliner,
  });

  return (
    <UserContext.Provider
      value={{
        user: data?.Pipeline_user,
        refetchUser: () => void refetch(),
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
