import { Alert, Button, LinearProgress, Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import LongJobInfoDialog from 'components/LongJobInfoDialog';
import StyledButton from 'components/StyledButton';
import { JobStatus } from 'contexts/Job';
import useGraph from 'hooks/useGraph';
import useGraphValidation from 'hooks/useGraphValidation';
import useJobInfo from 'hooks/useJobInfo';
import useStagnantMessage from 'hooks/useStagnantMessage';
import useStartStopJob from 'hooks/useStartStopJob';
import humanizeDuration from 'humanize-duration';
import { useState } from 'react';
import FaviconProgress from '../FaviconProgress';
import styles from './index.module.scss';

const statusString = (status: JobStatus): string => {
  switch (status) {
    case JobStatus.QUEUED: {
      return 'Job queued';
    }
    case JobStatus.STARTING: {
      return 'Job starting';
    }
    case JobStatus.RUNNING: {
      return 'Job running';
    }
    case JobStatus.CANCELLING: {
      return 'Cancelling job';
    }
    default:
      return '';
  }
};

// These messages are displayed after the job progress fails to update for longer than the millisecond duration key.
const stagnantMessages = {
  [60 * 1000]: 'Still working!',
  [3 * 60 * 1000]: "The job's progress hasn't changed for a few minutes.",
  [30 * 60 * 1000]:
    "The job's progress hasn't changed for more than 30 minutes.",
  [60 * 60 * 1000]:
    "The job's progress hasn't changed for more than an hour. Either it has failed, or you are doing some mammoth computation.",
};

const JobProgressOverlay = () => {
  const [infoDialogOpen, setInfoDialogOpen] = useState(false);
  const {
    graphState: { name },
  } = useGraph();
  const { jobStatus, completionPercent, jobDuration } = useJobInfo();
  const { cancelJob } = useStartStopJob();
  const { canCancelJob } = useGraphValidation();

  const stagnantMessage = useStagnantMessage(
    completionPercent,
    stagnantMessages,
    1000,
    true
  );

  const percent = completionPercent ?? undefined;
  const percentKnown = typeof percent !== 'undefined';

  const variant =
    percentKnown && !stagnantMessage ? 'determinate' : 'indeterminate';

  let status = statusString(jobStatus);
  if (jobStatus === JobStatus.RUNNING && percent === 1) {
    status = 'Gathering results';
  }
  const displayPercentAndDuration =
    percentKnown &&
    jobDuration &&
    ![JobStatus.QUEUEING, JobStatus.QUEUED].includes(jobStatus);
  return (
    <>
      <LongJobInfoDialog open={infoDialogOpen} setIsOpen={setInfoDialogOpen} />
      <FaviconProgress percent={percent} />
      <DraggableDialog
        title={name ?? 'Pipeline Job In Progress'}
        open
        actions={[
          <StyledButton
            key="cancel"
            styleName="danger"
            disabled={!canCancelJob}
            onClick={cancelJob}
          >
            Cancel
          </StyledButton>,
        ]}
        className={styles.container}
      >
        <div>
          {jobStatus === JobStatus.RUNNING && stagnantMessage && (
            <Alert severity="warning" className={styles.alert}>
              <div className={styles['alert-content']}>
                <span className={styles['stagnant-message']}>
                  {stagnantMessage}
                </span>

                {stagnantMessage !== stagnantMessages[0] && (
                  <Button
                    color="warning"
                    onClick={() => setInfoDialogOpen(true)}
                    size="small"
                  >
                    More Info
                  </Button>
                )}
              </div>
            </Alert>
          )}

          <div className={styles['status-container']}>
            <Typography variant="subtitle1" component="span">
              {displayPercentAndDuration &&
                `${(100 * completionPercent!).toFixed(0)}%`}
            </Typography>

            <Typography variant="subtitle1" component="span">
              {`${status}...`}
            </Typography>

            <Typography variant="subtitle1" component="span">
              {displayPercentAndDuration &&
                humanizeDuration(jobDuration, { round: true })}
            </Typography>
          </div>

          <LinearProgress
            variant={variant}
            value={completionPercent && completionPercent * 100}
            className={styles.progress}
          />
        </div>
      </DraggableDialog>
    </>
  );
};

export default JobProgressOverlay;
