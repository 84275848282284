import {
  faCheck,
  faChevronDown,
  faList,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, Collapse, Tab, Tabs } from '@mui/material';
import useGraphValidation from 'hooks/useGraphValidation';
import useJobLogs from 'hooks/useJobLogs';
import { Resizable } from 're-resizable';
import { useCallback, useState } from 'react';
import GraphErrorList from './GraphErrorList';
import styles from './index.module.scss';
import RunList from './RunList';

type TabKey = 'runList' | 'graphErrors';

const BottomPanel = () => {
  const { graphValidationState } = useGraphValidation();
  const logs = useJobLogs();
  const [activeTab, setActiveTab] = useState<TabKey>('runList');
  const [isOpen, setIsOpen] = useState(false);
  const [drawerHeight, setDrawerHeight] = useState(150);

  const negativeLogs = logs.length > 0 && logs[0].unsuccessful;
  const negativeGraphValidation = !graphValidationState.valid;

  const tabs = [
    {
      key: 'runList' as TabKey,
      title: 'Run List',
      icon: faList,
      hasError: negativeLogs,
      component: <RunList />,
    },
    {
      key: 'graphErrors' as TabKey,
      title: 'Graph Validation',
      icon: negativeGraphValidation ? faXmark : faCheck,
      hasError: negativeGraphValidation,
      component: <GraphErrorList />,
    },
  ];

  const activeTabObj = tabs.find((tab) => tab.key === activeTab);
  const activeCompHasError = activeTabObj?.hasError;
  const tabsClassName = `${styles.tabs} ${
    activeCompHasError ? styles['tabs-error'] : ''
  }`;

  const onChange = useCallback(
    (_: any, newValue: TabKey) => {
      setActiveTab(newValue);
      if (!isOpen) {
        setIsOpen(true);
      }
    },
    [setIsOpen, isOpen, setActiveTab]
  );

  return (
    <div className={styles.container}>
      <Tabs
        value={activeTab}
        onChange={onChange}
        classes={{ root: tabsClassName }}
      >
        {tabs.map(({ key, title, icon, hasError }) => {
          let className = '';
          if (hasError) {
            className =
              activeTab === key
                ? styles['btn-error-active']
                : styles['btn-error-inactive'];
          }
          return (
            <Tab
              key={key}
              value={key}
              icon={<FontAwesomeIcon icon={icon} />}
              label={title}
              iconPosition="start"
              classes={{ root: className }}
              onClick={() => {
                if (key === activeTab) {
                  setIsOpen(!isOpen);
                }
              }}
            />
          );
        })}

        {isOpen && (
          <Button
            classes={{ root: styles['collapse-btn'] }}
            onClick={() => setIsOpen(false)}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </Button>
        )}
      </Tabs>

      <Collapse in={isOpen} mountOnEnter unmountOnExit>
        <Resizable
          size={{ width: 800, height: drawerHeight }}
          onResizeStop={(e, direction, ref, d) => {
            const newHeight = drawerHeight + d.height;
            // Close the drawer if it's been made tiny
            if (newHeight < 50) {
              setIsOpen(false);
            } else {
              setDrawerHeight(drawerHeight + d.height);
            }
          }}
          maxWidth="99vw"
          maxHeight="99vh"
          enable={{
            top: true,
            right: false,
            bottom: false,
            left: false,
            topRight: false,
            bottomRight: false,
            bottomLeft: false,
            topLeft: false,
          }}
          className={styles['log-drawer']}
        >
          {activeTabObj?.component}
        </Resizable>
      </Collapse>
    </div>
  );
};

export default BottomPanel;
