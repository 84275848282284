// eslint-disable-next-line import/no-named-as-default
import MediaInfoFactory, { ReadChunkFunc } from 'mediainfo.js';
import { ResultObject } from 'mediainfo.js/dist/types';

export interface MediaInfo {
  duration?: number;
  fileSize?: number;
  containerFormat?: string;
  audioCodec?: string;
  audioChannels?: number;
  audioSampleRate?: number;
  videoCodec?: string;
  videoWidth?: number;
  videoHeight?: number;
  videoFrameRate?: number;
  videoFrameCount?: number;
}

const readChunk =
  (file: File): ReadChunkFunc =>
  (chunkSize: number, offset: number) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onload = (event) => {
        if (event.target?.error) {
          reject(event.target.error);
        } else if (!event.target?.result) {
          reject(new Error('FileReader returned no data'));
        } else {
          resolve(new Uint8Array(event.target.result as ArrayBuffer));
        }
      };
      reader.readAsArrayBuffer(file.slice(offset, offset + chunkSize));
    });

const extractMediaInfo = (result: ResultObject): MediaInfo => {
  const general = result.media.track.find(
    (track) => track['@type'] === 'General'
  );
  const audioTrack = result.media.track.find(
    (track: any) => track['@type'] === 'Audio'
  );
  const videoTrack = result.media.track.find(
    (track: any) => track['@type'] === 'Video'
  );

  // Don't allow prettier to change our lovely one-property-per-line formatting
  // prettier-ignore
  return {
    duration: general?.Duration ? Number(general.Duration) : undefined,
    fileSize: general?.FileSize ? Number(general.FileSize) : undefined,
    containerFormat: general?.Format ? String(general.Format) : undefined,
    audioCodec: audioTrack?.CodecID ? String(audioTrack.CodecID) : undefined,
    audioChannels: audioTrack?.Channel_s_ ? Number(audioTrack.Channel_s_) : undefined,
    audioSampleRate: audioTrack?.SamplingRate ? Number(audioTrack.SamplingRate) : undefined,
    videoCodec: videoTrack?.CodecID ? String(videoTrack.CodecID) : undefined,
    videoWidth: videoTrack?.Width ? Number(videoTrack.Width) : undefined,
    videoHeight: videoTrack?.Height ? Number(videoTrack.Height) : undefined,
    videoFrameRate: videoTrack?.FrameRate ? Number(videoTrack.FrameRate) : undefined,
    videoFrameCount: videoTrack?.FrameCount ? Number(videoTrack.FrameCount) : undefined,
  };
};

export const getMediaInfo = async (file: File) => {
  const mediaInfo = await MediaInfoFactory({
    locateFile: () => MEDIAINFO_WASM_PATH,
  });

  const res = await mediaInfo.analyzeData(() => file.size, readChunk(file));
  return extractMediaInfo(res as ResultObject);
};
