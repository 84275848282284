import { getBlobByKey } from 'utils/blobs';
import { Pipeline_BlobType } from '../graphql/graphql';
import useGraph from './useGraph';

const useInputBlobs = (nodeId: string, inputId: string) => {
  const { inputBlobs, setBlob, removeBlob } = useGraph();

  const removeThisBlob = () => {
    removeBlob(nodeId, inputId);
  };

  const inputBlob = getBlobByKey(
    inputBlobs,
    nodeId,
    inputId,
    Pipeline_BlobType.Input
  );

  return {
    inputBlob,
    setBlob,
    removeThisBlob,
  };
};

export default useInputBlobs;
