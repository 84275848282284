export const PIPELINES_HOME =
  'https://mlpt.zendesk.com/hc/en-us/categories/5363963378447-PIPELINES';
// The bug report form url has the placeholder {{url}} for the current url
const BUG_REPORT_FORM_URL_TEMPLATE =
  'https://mlpt.zendesk.com/hc/en-us/requests/new?ticket_form_id=6692617598095&tf_subject=[PIPELINES]%20Bug%20Report&tf_6692579992975={{url}}';
export const constructBugReportUrl = () => {
  const { href } = window.location;
  return BUG_REPORT_FORM_URL_TEMPLATE.replaceAll('{{url}}', encodeURIComponent(href));
};

export const NODE_REQUEST_URL =
  'https://mlpt.zendesk.com/hc/en-us/requests/new?ticket_form_id=6692663473679&tf_subject=[PIPELINES]%20Node%20Request';