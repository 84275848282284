import { Button as MaterialButton, ButtonProps } from '@mui/material';
import { ReactNode } from 'react';
import styles from './index.module.scss';

export type ButtonStyleName =
  | 'primary'
  | 'secondary'
  | 'success'
  | 'warning'
  | 'danger'
  | 'light'
  | 'dark';

export interface Props extends ButtonProps {
  styleName: ButtonStyleName;
  solid?: boolean;
  children: ReactNode;
  [key: string]: any;
}

const StyledButton = ({
  styleName,
  solid,
  children,
  innerRef,
  ...props
}: Props) => {
  let cssClass: string = styleName;
  if (solid) cssClass = `${styleName}-solid`;

  const className = `${props.className} ${styles.button} ${styles[cssClass]}`;

  return (
    <MaterialButton
      {...props}
      ref={innerRef}
      className={className}
      variant="outlined"
    >
      {children}
    </MaterialButton>
  );
};

export default StyledButton;
