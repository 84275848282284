import { faBezierCurve } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Athlete Tracker',
  description: 'Perform tracking on a collection of bounding boxes.',
  descriptionDetail:
    'When provided a number of predicted athlete bounding boxes, this node performs tracking using visual features and box location/movement. It produces bounding boxes and their likely associations.',
  icon: faBezierCurve,
  category: NodeCategory.TRACKING,
  moduleType: 'DeepSORT',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060398426383',
  configTypes: [
    {
      key: 'extract_track',
      type: PipelineConfigType.COMMA_SEPARATED_INTEGERS(
        'Selected track number(s)',
        'Enter a track ID (>= 1)'
      )
        .MIN(0)
        .DESCRIPTION(
          'Which track number(s) to extract - leave empty to retain all tracks.'
        ),
    },
    {
      key: 'half',
      type: PipelineConfigType.BOOLEAN('Half-precision')
        .CONSTANT_VALUE(true)
        .ADVANCED.DESCRIPTION(
          'If true, 16 bit numbers are used instead of 32 bit. This will run faster at a small cost in accuracy.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'bounding_boxes',
      type: PipelineDataType.BOUNDING_BOXES.REQUIRED,
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
};
