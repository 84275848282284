import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Breakpoint, Button } from '@mui/material';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Paper, { PaperProps } from '@mui/material/Paper';
import { ReactElement, ReactNode } from 'react';
import Draggable from 'react-draggable';
import styles from './index.module.scss';

const AnyDraggable: any = Draggable;

function PaperComponent(props: PaperProps) {
  return (
    <AnyDraggable handle=".MuiDialogTitle-root" cancel=".MuiButton-root">
      <Paper {...props} />
    </AnyDraggable>
  );
}

interface Props {
  title: string | ReactElement;
  children: ReactNode;
  actions?: ReactElement[] | ReactElement;
  open: boolean;
  onClose?: () => void;
  className?: string;
  titleClassName?: string;
  dialogProps?: Partial<DialogProps>;
  allowClose?: boolean;
  maxWidth?: Breakpoint;
  fullWidth?: boolean;
}

const DraggableDialog = ({
  title,
  children,
  actions = [],
  open,
  onClose,
  className = '',
  titleClassName = '',
  dialogProps,
  allowClose,
  maxWidth = 'md',
  fullWidth,
}: Props) => {
  const actionsArr = Array.isArray(actions) ? actions : [actions];
  return (
    <Dialog
      open={open}
      onClose={allowClose ? onClose : undefined}
      PaperComponent={PaperComponent}
      scroll="paper"
      maxWidth={maxWidth}
      fullWidth={fullWidth}
      {...dialogProps}
    >
      <DialogTitle
        className={`${styles['dialog-title']} ${titleClassName}`}
        id="draggable-dialog-title"
      >
        {title}
        {allowClose && (
          <Button onClick={onClose} className={styles['close-btn']}>
            <FontAwesomeIcon icon={faTimes} size="lg" />
          </Button>
        )}
      </DialogTitle>
      <DialogContent dividers className={className}>
        {children}
      </DialogContent>
      {actionsArr && actionsArr.length > 0 && (
        <DialogActions>{actionsArr}</DialogActions>
      )}
    </Dialog>
  );
};

export default DraggableDialog;
