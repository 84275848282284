import GraphBrowserDialog from 'components/GraphBrowserDialog';
import { useDeleteGraphMutation } from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useGraphSummaries from 'hooks/useGraphSummaries';
import { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

interface Props {
  open: boolean;
  onClose: () => void;
}

const GraphPicker = ({ open, onClose }: Props) => {
  const { triggerDialog } = useConfirmDialog();
  const { pathname } = useLocation();
  const navigate = useNavigate();

  const { userGraphs, refetchGraphSummaries } = useGraphSummaries();

  const [deleteGraph] = useDeleteGraphMutation();

  useEffect(() => {
    if (open) {
      void refetchGraphSummaries();
    }
  }, [open, refetchGraphSummaries]);

  const selectGraph = (graphId: string | undefined) => {
    if (graphId) {
      navigate(`/graph/${graphId}`);
      onClose();
    }
  };

  const confirmDeleteGraph = (graphId: string | undefined) => {
    if (graphId) {
      const onConfirm = async () => {
        await deleteGraph({ variables: { id: graphId } });

        // Reroute if we're deleting the currently-selected graph
        if (pathname.includes(graphId)) {
          navigate('/');
        } else {
          void (await refetchGraphSummaries());
        }
      };

      triggerDialog({
        title: 'Confirm Deletion',
        message:
          'Are you sure you want to delete this pipeline? This cannot be undone.\nIf this pipeline is shared with others, they will no longer be able to utilise it as a template. Existing instances will still exist.',
        confirmButtonText: 'Delete',
        onConfirm: () => void onConfirm(),
      });
    }
  };

  return (
    <GraphBrowserDialog
      title="Load Pipeline"
      subtitle="Load an existing pipeline."
      openHint="Load pipeline"
      open={open}
      onClose={onClose}
      onSelectGraph={selectGraph}
      graphs={userGraphs}
      onDeleteGraph={confirmDeleteGraph}
    />
  );
};

export default GraphPicker;
