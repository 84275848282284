import { areArraysEqual } from '@mui/base';
import { Pipeline_PointCorrespondenceTemplate } from 'graphql/graphql';
import { omit } from 'lodash-es';
import { IAdjacencyMap, INodeMap } from 'utils/konvaGraphUtils';
import {
  flatCoordsToVec2,
  flatEdgesToPairList,
  Vec2,
  vec2ToFlatCoords,
} from 'utils/Vec2';

export type PointCorrespondence = {
  templateId: string;
  templateName: string;
  templateDescription: string;
  imagePixelCoords: (Vec2 | undefined)[];
  templatePixelCoords: Vec2[];
  templateWorldCoords: Vec2[];
  templateWorldOrigin: Vec2;
  templateEdges?: number[][];
};

const META_TYPE_KEY = '__contentType';
const validateCorrespondenceHasAllKeys = (
  obj: any
): obj is PointCorrespondence =>
  obj.templateId !== undefined &&
  obj.templateName !== undefined &&
  obj.templateDescription !== undefined &&
  obj.imagePixelCoords !== undefined &&
  obj.templatePixelCoords !== undefined &&
  obj.templateWorldCoords !== undefined &&
  obj.templateWorldOrigin !== undefined;

const validateTemplateHasAllKeys = (
  obj: any
): obj is Pipeline_PointCorrespondenceTemplate =>
  obj.name !== undefined &&
  obj.description !== undefined &&
  obj.pixelCoordinates !== undefined &&
  obj.worldCoordinates !== undefined &&
  obj.edges !== undefined &&
  obj.worldOrigin !== undefined;

export const buildPointCorrespondence = (
  nodes: INodeMap,
  templateWorldOrigin: Vec2,
  template: Pipeline_PointCorrespondenceTemplate
): PointCorrespondence => {
  const imagePixelCoords: Vec2[] = [];
  const templatePixelCoords = flatCoordsToVec2(template.pixelCoordinates);
  templatePixelCoords.forEach((coord, i) => {
    const node = nodes[i.toString()];
    imagePixelCoords.push(node?.pixelPos);
  });
  const templateWorldCoords = flatCoordsToVec2(template.worldCoordinates);
  const edges = template.edges ? flatEdgesToPairList(template.edges) : [];
  return {
    templateId: template.id!,
    templateName: template.name,
    templateDescription: template.description ?? '',
    imagePixelCoords,
    templatePixelCoords,
    templateWorldCoords,
    templateWorldOrigin,
    templateEdges: edges,
  };
};

export const correspondenceToJsonString = (
  correspondence: PointCorrespondence
) => {
  return JSON.stringify({
    [META_TYPE_KEY]: 'PointCorrespondence',
    ...correspondence,
  });
};

export const jsonStringToCorrespondence = (
  str: string
): PointCorrespondence | undefined => {
  const parsed = JSON.parse(str);
  if (
    parsed[META_TYPE_KEY] === 'PointCorrespondence' &&
    validateCorrespondenceHasAllKeys(parsed)
  ) {
    return omit(parsed, META_TYPE_KEY);
  }
};

export const templateToJsonString = (
  template: Pipeline_PointCorrespondenceTemplate
) => {
  return JSON.stringify({
    __contentType: 'PointCorrespondenceTemplate',
    name: template.name,
    description: template.description,
    pixelCoordinates: template.pixelCoordinates,
    worldCoordinates: template.worldCoordinates,
    edges: template.edges,
    worldOrigin: template.worldOrigin,
  });
};

export const jsonStringToTemplate = (
  str: string
): Pipeline_PointCorrespondenceTemplate | undefined => {
  const parsed = JSON.parse(str);
  if (
    parsed[META_TYPE_KEY] === 'PointCorrespondenceTemplate' &&
    validateTemplateHasAllKeys(parsed)
  ) {
    return omit(parsed, META_TYPE_KEY);
  }
};

export const correspondenceToGraphState = (
  correspondence: PointCorrespondence | undefined
): [INodeMap, IAdjacencyMap] => {
  const nodes: INodeMap = {};
  const adjacencies: IAdjacencyMap = {};

  if (correspondence) {
    correspondence.imagePixelCoords.forEach((pixelPos, id) => {
      const idStr = id.toString();
      if (pixelPos) {
        nodes[idStr] = {
          id: idStr,
          pixelPos,
        };
      }
    });
  }

  return [nodes, adjacencies];
};

export const getCorrespondenceTemplateMisalignmentError = (
  correspondence: PointCorrespondence,
  template: Pipeline_PointCorrespondenceTemplate
): string | undefined => {
  if (correspondence.templateId !== template.id) {
    return 'The ID of the selected template does not match the ID in the imported correspondence.';
  }

  const corrTemplatePixelCoords = vec2ToFlatCoords(
    correspondence.templatePixelCoords
  );
  const corrTemplateWorldCoords = vec2ToFlatCoords(
    correspondence.templateWorldCoords
  );
  if (
    !areArraysEqual(corrTemplatePixelCoords, template.pixelCoordinates) ||
    !areArraysEqual(corrTemplateWorldCoords, template.worldCoordinates)
  ) {
    return 'The world coordinates of the selected template do not match those used to build the imported correspondence.';
  }
};
