import { faBezierCurve } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Alternate Athlete Tracker',
  description: 'Perform tracking on a collection of bounding boxes.',
  descriptionDetail:
    'When provided a number of predicted object bounding boxes, this node performs tracking using visual features and box location/movement. It produces bounding boxes in their likely associations. This differs from the standard Object Tracker node in that it uses a different tracking algorithm which is often better suited to moving-camera scenes. Note that this also takes longer to execute.',
  icon: faBezierCurve,
  category: NodeCategory.TRACKING,
  moduleType: 'BoTSORT',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060388390799',
  configTypes: [
    {
      key: 'extract_track',
      type: PipelineConfigType.INTEGER('Selected track number')
        .MIN(1)
        .DESCRIPTION(
          'Which track number to extract - leave empty to retain all tracks.'
        ).ADVANCED,
    },
    {
      key: 'cmc_method',
      type: PipelineConfigType.STRING('CMC method')
        .OPTIONS(['orb', 'sift', 'ecc'])
        .DEFAULT('orb')
        .DESCRIPTION(
          'The feature extraction method to use for camera motion compensation.'
        ).ADVANCED,
    },
    {
      key: 'cmc_im_threshold',
      type: PipelineConfigType.BOOLEAN('Threshold CMC image')
        .DEFAULT(false)
        .DESCRIPTION('Whether to threshold the CMC image.').ADVANCED,
    },
    {
      key: 'with_reid',
      type: PipelineConfigType.BOOLEAN('Re-Identification')
        .DEFAULT(false)
        .DESCRIPTION('Whether to perform re-identification.').ADVANCED,
    },
    {
      key: 'half',
      type: PipelineConfigType.BOOLEAN('Half-precision')
        .CONSTANT_VALUE(true)
        .ADVANCED.DESCRIPTION(
          'If true, 16 bit numbers are used instead of 32 bit. This will run faster at a small cost in accuracy.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'bounding_boxes',
      type: PipelineDataType.BOUNDING_BOXES.REQUIRED,
    },
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
};
