import { Card, Typography } from '@mui/material';
import AppLogo from 'components/AppLogo';
import BackgroundImage from 'components/BackgroundImage';
import StyledButton from 'components/StyledButton';
import { APP_NAME } from 'config';
import useAuth from 'hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

const Login = () => {
  const { loginWithRedirect, isAuthenticated } = useAuth();
  const previousLocation = useLocation().state?.previousLocation;

  const pathString = () => {
    let pathname = '/';
    let search = '';
    if (previousLocation) {
      pathname = previousLocation.pathname;
      search = previousLocation.search;
    }
    return `${pathname}${search}`;
  };

  const loginRedirectString = () => {
    const path = pathString();
    return `${window.location.origin}${path}`;
  };

  if (isAuthenticated) {
    return <Navigate to={pathString()} />;
  }
  return (
    <div className={styles.container}>
      <BackgroundImage />

      <Card className={styles.card}>
        <AppLogo dark />
        <div className={styles.content}>
          <Typography variant="h6" className={styles.subtitle}>
            You must log in to use {APP_NAME}.
          </Typography>
          <StyledButton
            size="large"
            styleName="dark"
            onClick={() => loginWithRedirect(loginRedirectString())}
            fullWidth
          >
            Log In
          </StyledButton>
        </div>
      </Card>
    </div>
  );
};

export default Login;
