import { IFlowGraph } from 'contexts/Graph';
import { addEdge, Edge } from 'reactflow';
import { distSq, Vec2 } from 'utils/Vec2';
import { EDGE_STYLE } from './index';
import { NodeTypeKey, NodeTypes } from './NodeTypes';
import { PipelineDataType } from './types';

type AutoConnectHandle = {
  nodeId: string;
  handleId: string;
  type: PipelineDataType;
  position: Vec2;
};
/**
 * Assign the input/output handles using greedy assignment instead of Hungarian.
 */
const americanAssign = (
  inputHandles: AutoConnectHandle[],
  outputHandles: AutoConnectHandle[]
) => {
  const weightMatrix: number[][] = [];
  for (const inputHandle of inputHandles) {
    const row: number[] = [];
    for (const outputHandle of outputHandles) {
      if (
        outputHandle.nodeId === inputHandle.nodeId ||
        !outputHandle.type.compatibleWith(inputHandle.type)
      ) {
        row.push(Infinity);
      } else if (outputHandle.position.x > inputHandle.position.x) {
        row.push(Infinity);
      } else {
        row.push(distSq(inputHandle.position, outputHandle.position));
      }
    }
    weightMatrix.push(row);
  }

  // Greedily take the min of each row
  const assignments = weightMatrix.map((row) => {
    const min = Math.min(...row);
    if (min === Infinity) {
      return null;
    }
    return row.indexOf(min);
  });

  const pairs: [AutoConnectHandle, AutoConnectHandle][] = assignments.flatMap(
    (assignment, i) => {
      if (assignment === null) {
        return [];
      }
      return [[inputHandles[i], outputHandles[assignment]]];
    }
  );

  return pairs;
};
/**
 * Auto-connect node handles by matching up compatible input and output handles. Handles
 * are assigned such that the total distance between the input and output handles is minimised,
 * and that the input handle is to the right of the output handle.
 * by the additional "modified" flag returned.
 * @param flowGraph - The current flowgraph
 *
 * @returns - The modified flowgraph, and a flag indicating whether any changes were made.
 */
const autoConnectNodesSingle = (flowGraph: IFlowGraph) => {
  const { nodes, edges } = flowGraph;

  // Get all available output connections
  const outputHandles: AutoConnectHandle[] = [];
  const inputHandles: AutoConnectHandle[] = [];
  nodes.forEach((node) => {
    const nodeType = NodeTypes[node.data.nodeType as NodeTypeKey];

    nodeType.outputTypes?.forEach((outputType, idx) => {
      // Only consider handles that are visible to the user
      if (!outputType.type.hidden && !outputType.type.handleHidden) {
        outputHandles.push({
          nodeId: node.id,
          handleId: outputType.key,
          type: outputType.type,
          // Here we add the index to the position to break ties, ensuring that multiple
          // output handles are not assigned to the same position
          position: { x: node.position.x, y: node.position.y + idx },
        });
      }
    });
    // Add the input handle if it's not already connected, and visible to the user
    nodeType.inputTypes?.forEach((inputType, idx) => {
      if (
        !edges.find(
          (edge) =>
            edge.target === node.id && edge.targetHandle === inputType.key
        )
      ) {
        if (!inputType.type.hidden && !inputType.type.handleHidden) {
          inputHandles.push({
            nodeId: node.id,
            handleId: inputType.key,
            type: inputType.type,
            // Here we add the index to the position to break ties, ensuring that multiple
            // input handles are not assigned to the same position
            position: { x: node.position.x, y: node.position.y + idx },
          });
        }
      }
    });
  });

  const pairs = americanAssign(inputHandles, outputHandles);
  const modified = pairs.length > 0;
  let newEdges: Edge[] = [...edges];
  pairs.forEach(([inputHandle, outputHandle]) => {
    newEdges = addEdge(
      {
        source: outputHandle.nodeId,
        sourceHandle: outputHandle.handleId,
        target: inputHandle.nodeId,
        targetHandle: inputHandle.handleId,
        style: EDGE_STYLE,
      },
      newEdges
    );
  });

  return {
    flowGraph: {
      ...flowGraph,
      edges: newEdges,
    },
    modified,
  };
};
/**
 * Auto-connect node handles by matching up compatible input and output handles. Handles
 * are assigned such that the total distance between the input and output handles is minimised,
 * and that the input handle is to the right of the output handle. This function will run
 * multiple times until no more connections can be made, or a limit is reached.
 * by the additional "modified" flag returned.
 * @param flowGraph - The current flowgraph
 *
 * @returns - The modified flowgraph, and a flag indicating whether any changes were made.
 */

const autoConnectNodes = (flowGraph: IFlowGraph) => {
  const LIMIT = 5;
  let modified = false;
  let count = 0;
  do {
    const result = autoConnectNodesSingle(flowGraph);
    flowGraph = result.flowGraph;
    modified = result.modified;
    count++;
  } while (modified && count < LIMIT);

  return { flowGraph, modified };
};

export default autoConnectNodes;
