import { faRulerCombined } from '@fortawesome/free-solid-svg-icons';
import { KEYPOINT_LIST } from 'constants/aspset';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from '../../graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Joint Angle Measurer',
  description: 'Measures the angle between a set of joints.',
  descriptionDetail:
    'This node measures the angle in degrees formed between three 2D joints. The angle is ∠ABC where the joints "from", "vertex" and "to" correspond A, B and C, respectively.',
  icon: faRulerCombined,
  category: NodeCategory.TRACKING,
  moduleType: 'JointAngleMeasurer',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060628035471',
  configTypes: [
    {
      key: 'from_keypoint',
      type: PipelineConfigType.STRING('From keypoint')
        .REQUIRED.OPTIONS(KEYPOINT_LIST)
        .DEFAULT('right_ankle')
        .DESCRIPTION('The keypoint A in the angle measurement ∠ABC.'),
    },
    {
      key: 'vertex_keypoint',
      type: PipelineConfigType.STRING('Vertex keypoint')
        .REQUIRED.OPTIONS(KEYPOINT_LIST)
        .DEFAULT('right_knee')
        .DESCRIPTION('The keypoint B in the angle measurement ∠ABC.'),
    },
    {
      key: 'to_keypoint',
      type: PipelineConfigType.STRING('To keypoint')
        .REQUIRED.OPTIONS(KEYPOINT_LIST)
        .DEFAULT('right_hip')
        .DESCRIPTION('The keypoint C in the angle measurement ∠ABC.'),
    },
    {
      key: 'ccw',
      type: PipelineConfigType.BOOLEAN('CCW')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'If the angle should be measured counterclockwise (CCW) instead of clockwise.'
        ),
    },
    {
      key: 'radians_flag',
      type: PipelineConfigType.BOOLEAN('Radians')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'If the angle should be measured in radians instead of degrees.'
        ),
    },
    {
      key: 'radians_per_second_flag',
      type: PipelineConfigType.BOOLEAN('Radians/sec')
        .REQUIRED.DEFAULT(true)
        .DESCRIPTION(
          'If the angular velocity should be measured in radians/sec instead of degrees/sec.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'joint_angles',
      type: PipelineDataType.SCALAR.LABEL('Joint Angles')
        .DESCRIPTION('The angle formed between the three keypoints.')
        .BLOB_DATATYPE(Pipeline_BlobDataType.Csv),
    },
    {
      key: 'angular_velocities',
      type: PipelineDataType.SCALAR.LABEL('Angular Velocities').BLOB_DATATYPE(
        Pipeline_BlobDataType.Csv
      ),
    },
  ],
};
