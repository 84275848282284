import DarkLogoSmall from 'assets/full-bt-256.png';
import DarkLogo from 'assets/full-bt-512.png';
import LightLogoSmall from 'assets/full-wt-256.png';
import LightLogo from 'assets/full-wt-512.png';
import { APP_NAME } from 'config';
import styles from './index.module.scss';

interface Props {
  dark?: boolean;
  small?: boolean;
  [key: string]: any;
}

const images = {
  light: {
    small: LightLogoSmall,
    large: LightLogo,
  },
  dark: {
    small: DarkLogoSmall,
    large: DarkLogo,
  },
};

const AppLogo = ({ dark = false, small = false, ...rest }: Props) => {
  const logo = images[dark ? 'dark' : 'light'][small ? 'small' : 'large'];
  const { className } = rest;
  const cls = className ? `${className} ${styles.logo}` : styles.logo;
  return <img src={logo} alt={`${APP_NAME} logo`} {...rest} className={cls} />;
};

export default AppLogo;
