import { Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import GraphMetaForm from 'components/GraphMetaForm';
import StyledButton from 'components/StyledButton';
import { PrivacySetting, useDeleteGraphMutation } from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useGraph from 'hooks/useGraph';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
  flashPrivacy?: boolean;
}

const EditGraphMetaDialog = ({ open, onClose, flashPrivacy }: Props) => {
  const { graphState, setGraphState } = useGraph();
  const {
    name,
    description,
    id,
    templateGraph: templateId,
    privacy,
  } = graphState;
  const { triggerDialog } = useConfirmDialog();
  const [tempName, setTempName] = useState<string>('');
  const [tempDescription, setTempDescription] = useState<string>('');
  const [tempPrivacy, setTempPrivacy] = useState<PrivacySetting>(
    PrivacySetting.Private
  );
  const navigate = useNavigate();
  const [deleteGraph] = useDeleteGraphMutation();
  const modified =
    name !== tempName ||
    (description ?? '') !== tempDescription ||
    privacy !== tempPrivacy;

  const onSave = () => {
    setGraphState({
      name: tempName,
      description: tempDescription,
      privacy: tempPrivacy,
    });
    onClose();
  };

  const onCloseWrapper = () => {
    if (modified) {
      triggerDialog({
        title: 'Unsaved Changes',
        message: 'Are you sure you want to exit without saving your changes?',
        confirmButtonText: 'Exit without saving',
        onConfirm: onClose,
      });
    } else {
      onClose();
    }
  };

  // Copy fields into local state on open
  useEffect(() => {
    if (open) {
      setTempName(name ?? '');
      setTempDescription(description ?? '');
      setTempPrivacy(privacy ?? PrivacySetting.Private);
    }
  }, [open, name, description, privacy]);

  const confirmDeleteGraph = () => {
    if (id) {
      const onConfirm = () => {
        // Delete from the DB
        void deleteGraph({ variables: { id } });
        navigate('/');
      };

      triggerDialog({
        title: 'Confirm Deletion',
        message:
          'Are you sure you want to delete this pipeline? This cannot be undone.',
        confirmButtonText: 'Delete',
        onConfirm,
      });
    }
  };

  return (
    <DraggableDialog
      title="Pipeline Metadata"
      open={open}
      onClose={onCloseWrapper}
      dialogProps={{ maxWidth: 'lg' }}
      className={styles['content-container']}
      actions={[
        <Typography
          key="id"
          variant="caption"
          className={styles['template-id']}
        >
          {templateId ? `Template Pipeline: ${templateId}` : ''}
        </Typography>,
        <StyledButton
          key="delete"
          styleName="danger"
          onClick={confirmDeleteGraph}
        >
          Delete
        </StyledButton>,
        <StyledButton
          key="cancel"
          styleName="secondary"
          onClick={onCloseWrapper}
        >
          Cancel
        </StyledButton>,
        <StyledButton
          key="save"
          styleName="primary"
          onClick={onSave}
          solid
          disabled={!tempName || !modified}
        >
          Save
        </StyledButton>,
      ]}
    >
      <GraphMetaForm
        id={id}
        name={tempName}
        setName={setTempName}
        description={tempDescription}
        setDescription={setTempDescription}
        privacy={tempPrivacy}
        setPrivacy={setTempPrivacy}
        flashPrivacy={flashPrivacy}
      />
    </DraggableDialog>
  );
};

export default EditGraphMetaDialog;
