import { DialogContentText, Typography } from '@mui/material';

import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';

interface Props {
  open: boolean;
  setIsOpen: (val: boolean) => void;
}

const LongJobInfoDialog = ({ open, setIsOpen }: Props) => {
  return (
    <DraggableDialog
      title="About Long-Running Jobs"
      open={open}
      onClose={() => {
        setIsOpen(false);
      }}
      actions={[
        <StyledButton
          key="okay"
          styleName="primary"
          onClick={() => {
            setIsOpen(false);
          }}
          solid
        >
          OK
        </StyledButton>,
      ]}
      allowClose
    >
      <DialogContentText>
        <Typography paragraph>
          Most nodes perform computation on a single video frame at a time. This
          means that - assuming we know the number of frames in the video file -
          the node can report its progress as a proportion of the video frames
          that it has processed. This is the ideal case.
        </Typography>

        <Typography paragraph>
          However, some nodes perform processing on all video frames at once.
          For example, camera calibration procedures process all calibration
          patterns simultaneously.
        </Typography>

        <Typography paragraph>
          For this reason, some nodes are unable to report their progress as a
          percentage. Thereby making the progress bar appear frozen, as it
          doesn&apos;t update at all during this computation.
        </Typography>

        <Typography paragraph>
          Thus if the progress appears to be frozen for a while, consider that
          it may be due to including a node which cannot report its progress.
        </Typography>
      </DialogContentText>
    </DraggableDialog>
  );
};

export default LongJobInfoDialog;
