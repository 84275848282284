import { faChartBar } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: '2D Scatter Plot',
  description: 'Plot a collection of 2D points to a graph.',
  descriptionDetail:
    'This node plots arbitrary 2D points onto a graph with configurable xy axis domains. Set the minimums to 0 and the maximums to your video resolution if plotting image coordinates.',
  icon: faChartBar,
  category: NodeCategory.GRAPH_PLOTTING,
  moduleType: 'ScatterGrapher2d',
  configTypes: [
    {
      key: 'headless',
      type: PipelineConfigType.BOOLEAN('Headless').CONSTANT_VALUE(true),
    },
    {
      key: 'dpi',
      type: PipelineConfigType.INTEGER('DPI').CONSTANT_VALUE(150),
    },
    {
      key: 'graph_domain_minimum',
      type: PipelineConfigType.INTEGER('Plot domain minimum')
        .TUPLE(['X min', 'Y min'])
        .DESCRIPTION(
          'The (x, y) minimum coordinates of the graphed domain. By default the domain is inferred from the provided coordinates.'
        ),
    },
    {
      key: 'graph_domain_maximum',
      type: PipelineConfigType.INTEGER('Plot domain maximum')
        .TUPLE(['X max', 'Y max'])
        .DESCRIPTION(
          'The (x, y) maximum coordinates of the graphed domain. By default the domain is inferred from the provided coordinates.'
        ),
    },
    {
      key: 'invert_y_axis',
      type: PipelineConfigType.BOOLEAN('Invert y axis')
        .REQUIRED.DEFAULT(true)
        .DESCRIPTION(
          'Whether to invert the y axis - useful when plotting pixel coordinates.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'points',
      type: PipelineDataType.IMAGE_COORDINATES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.CALIBRATION_PATTERNS,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'plot_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
