import { parse as parseUuid, v4 as uuidv4 } from 'uuid';

export const nullOrUndef = (value: any): value is undefined | null =>
  value === null || typeof value === 'undefined';

export const replaceNullsWithUndefs = (obj: any): any => {
  Object.entries(obj as Map<string, any>).forEach(([k, v]) => {
    if (v === null) {
      obj[k] = undefined;
    } else if (typeof v === 'object') {
      replaceNullsWithUndefs(v);
    }
  });
  return obj;
};

export const uuid = () => uuidv4();
export const isValidUuid = (uuid: string | undefined): boolean => {
  if (!uuid) return false;

  try {
    parseUuid(uuid);
    return true;
  } catch (e) {
    return false;
  }
};

export const currDateTimeString = () => {
  /* Return the current datetime formatted as YY-MM-DD_HH-mm */
  const date = new Date();

  const pad = (val: number) => (val < 10 ? `0${val}` : `${val}`);

  return (
    String(date.getFullYear()).slice(2) +
    '-' +
    pad(date.getMonth() + 1) +
    '-' +
    pad(date.getDate()) +
    '_' +
    pad(date.getHours()) +
    '-' +
    pad(date.getMinutes())
  );
};

/*
 * Split a filename into [base, ext]. If we expect a certain extension, we try to split
 * using this.
 * E.g. splitFileExtension('foo.bar.baz') -> ['foo.bar', 'baz']
 *      splitFileExtension('foo.bar.baz', 'baz') -> ['foo.bar', 'baz']
 *      splitFileExtension('foo.bar.baz', 'bar') -> ['foo', 'bar.baz']
 *      splitFileExtension('foo.bar.baz', 'bar.baz') -> ['foo', 'bar.baz']
 *      splitFileExtension('foo.bar.baz.boo', 'bar.baz.boo') -> ['foo', 'bar.baz.boo']
 *      splitFileExtension('foo.bar', 'baz') -> ['foo', 'bar']
 */
export const splitFileExtension = (
  filename: string,
  expectedExt?: string,
  extSep = '.'
) => {
  if (expectedExt && filename.endsWith(expectedExt)) {
    return [filename.slice(0, -expectedExt.length - 1), expectedExt];
  }
  const parts = filename.split(extSep);
  return [parts.slice(0, -1).join(extSep), parts[parts.length - 1]];
};

export const isJsonEqual = (a: any, b: any) =>
  JSON.stringify(a) === JSON.stringify(b);

export const isArrayEqual = (a: any[] | undefined, b: any[] | undefined) => {
  if (a === undefined) {
    if (b === undefined) return true;
    return false;
  }
  if (b === undefined) return false;

  if (a.length !== b.length) return false;
  return a.every((v, i) => v === b[i]);
};

export const maybeParseFloat = (value: string | number) => {
  if (typeof value === 'number') return value;
  return parseFloat(value);
};

export const maybeParseInt = (value: string | number) => {
  if (typeof value === 'number') return value;
  return parseInt(value);
};

/**
 * Get a nested value from an object using a path string.
 */
export const deepIndex = (obj: any, path: string): any => {
  const parts = path.split('.');
  let curr = obj;
  for (const part of parts) {
    if (curr === undefined) return undefined;
    curr = curr[part];
  }
  return curr;
};

/**
 * Construct a detailed log from the given exceptio and trigger a download.
 *
 * @param error The error object to extract the data from.
 */
export const downloadErrorReport = (error: Error) => {
  const element = document.createElement('a');
  const data = {
    message: error.message,
    stack: error.stack ?? 'No stack trace available',
    name: error.name,
    time: new Date().toISOString(),
  };
  const file = new Blob([JSON.stringify(data, null, 2)], {
    type: 'text/plain',
  });
  element.href = URL.createObjectURL(file);
  element.download = `pipelines-error-report-${currDateTimeString()}.json`;
  document.body.appendChild(element);
  element.click();
  document.body.removeChild(element);
};

/**
 * Convert a string  to capitalized words.
 * @param str - The string to convert.
 * @returns The capitalized string.
 */ export const capitalize = (str: string): string => {
  return str.toLowerCase().replace(/\b\w/g, (char) => char.toUpperCase());
};
