import { faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Autocomplete, IconButton, TextField } from '@mui/material';
import ColouredChip from 'components/ColouredChip';
import { hashStringAndGetHue, hslToHex } from 'utils/colour';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<any, any> {
  labelOverride?: string;
  [key: string]: any;
}

const StringArrayInput = ({
  value,
  setValue,
  configType,
  labelOverride,
  nodeId: _1,
  configKey: _2,
  ...rest
}: Props) => {
  value = value ?? [];

  const label = labelOverride ?? configType.label;
  return (
    <Autocomplete
      className="nodrag"
      multiple
      size="small"
      options={[]}
      getOptionLabel={String}
      filterOptions={(options, params) => {
        const { inputValue } = params;
        if (!inputValue) return [];
        if (!value.some((value: string) => value === inputValue)) {
          return [inputValue];
        }
        return [];
      }}
      renderInput={(params) => (
        <TextField {...params} variant="standard" label={label} />
      )}
      renderTags={(value, getTagProps) => {
        return value.map((option, index) => {
          let fore = '';
          let back = '';
          let border = '';
          let buttonSx = {};
          if (configType?.colourCoded) {
            const hue = hashStringAndGetHue(option as string);
            fore = hslToHex(hue, 100, 22);
            back = hslToHex(hue, 100, 90);
            border = hslToHex(hue, 80, 78);

            buttonSx = {
              color: `${fore} !important`,
              '&:hover': {
                backgroundColor: `${back} !important`,
              },
            };
          }
          return (
            <ColouredChip
              size="small"
              label={option}
              {...getTagProps({ index })}
              key={option}
              foregroundColour={fore}
              backgroundColour={back}
              borderColour={border}
              deleteIcon={
                <IconButton
                  size="small"
                  sx={{ ...buttonSx, padding: '2px 4px' }}
                >
                  <FontAwesomeIcon icon={faXmark} size="sm" />
                </IconButton>
              }
            />
          );
        });
      }}
      value={value}
      onChange={(e, v) => {
        configType.setValue(v as string[], setValue);
      }}
      noOptionsText={configType.inputHint}
      classes={{
        tag: styles.unsetwidth,
        endAdornment: styles.unsetwidth,
      }}
      {...rest}
    />
  );
};

export default StringArrayInput;
