import { IFlowGraph } from 'contexts/Graph';
import { Node, XYPosition } from 'reactflow';
import { NodeTypeKey, NodeTypes } from './NodeTypes';
import { FlowGraphNode, FlowGraphNodeType, IFlowGraphNodeData } from './types';

export const CONNECTION_LINE_STYLE = { strokeWidth: 3 };
export const EDGE_STYLE = { strokeWidth: 5 };

/**
 * Returns the FlowGraphNodeType object for a given node
 */
export const getNodeTypeFromNode = (node: FlowGraphNode): FlowGraphNodeType => {
  const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
  return nodeTypeObj;
};

/**
 * Returns the FlowGraphNodeTypeKey object for a given node type
 */
export const getNodeTypeKeyFromNodeType = (
  nodeType: FlowGraphNodeType
): NodeTypeKey | undefined => {
  const key = Object.keys(NodeTypes).find(
    (k) => NodeTypes[k as NodeTypeKey].name === nodeType.name
  );
  if (key) {
    return key as NodeTypeKey;
  }
};

/**
 * Creates and returns a new instance matching the provided NodeTypeKey. The current set of FlowElements
 * must be provided in order to create a unique ID for the new node.
 * @param nodeType - NodeTypeKey
 * @param flowGraph - The set of current FlowElements
 * @param position - The position of the new node.
 */
export const makeNode = (
  nodeType: NodeTypeKey,
  flowGraph: IFlowGraph,
  position: XYPosition
): FlowGraphNode => {
  const nodeTypeSpec = NodeTypes[nodeType];
  const newNode: FlowGraphNode = {
    type: 'CustomNode', // This should never be changed
    id: 'TEMP_ID',
    position,
    data: {
      nodeType,
      moduleType: nodeTypeSpec.moduleType,
      collapsed: true,
      config: {},
    },
  };
  newNode.id = generateUniqueNodeId(
    flowGraph,
    newNode,
    nodeTypeSpec.name.replaceAll(' ', '')
  );

  (NodeTypes[nodeType].configTypes ?? []).forEach(({ key, type }) => {
    if (type.generate) {
      newNode.data.config[key] = type.generate({
        nodeId: newNode.id,
        configId: key,
        node: newNode,
      });
    } else {
      newNode.data.config[key] = type.defaultValue;
    }
  });

  return newNode;
};

/**
 * Returns a duplicate of the given node. The current set of FlowElements must be provided in order to
 * create a unique ID for the new node. The new element is placed to the bottom-right of the existing one.
 * @param node - The FlowGraphNode to duplicate.
 * @param flowGraph - The current flowgraph
 */
export const duplicateNode = (node: FlowGraphNode, flowGraph: IFlowGraph) => {
  const { nodeType } = node.data as IFlowGraphNodeData;
  const position = {
    x: node.position.x + 100,
    y: node.position.y + 100,
  };

  const newNode = makeNode(nodeType, flowGraph, position);
  Object.keys((node.data.config as Map<string, any>) ?? {}).forEach((key) => {
    newNode.data.config[key] = node.data.config[key];
  });
  return newNode;
};

/**
 * When trying to rename a node, we need to make sure that the new name is unique.
 */
export const generateUniqueNodeId = (
  flowGraph: IFlowGraph,
  node: Node,
  newId: string
) => {
  let uniqueNewId = newId;
  if (node.id !== newId) {
    // Renaming needs to result in a unique node id, so in the case that the user (stupidly) connects the same
    // output to two distinct DataOutput nodes, find the lowest number suffix which makes it unique.
    // E.g. SomeNode_outputparam_output, SomeNode_outputparam_output2, SomeNode_outputparam_output3, ...
    let idCollisionNode: Node | undefined;
    let renameIndex = 2;
    do {
      idCollisionNode = flowGraph.nodes.find((node) => node.id === uniqueNewId);
      if (idCollisionNode) {
        uniqueNewId = `${newId}${renameIndex++}`;
      }
    } while (idCollisionNode);
  }
  return uniqueNewId;
};

/**
 * Renames a node and the corresponding edges to match the new id.
 */
export const renameNode = (
  flowGraph: IFlowGraph,
  graphId: string,
  oldId: string,
  newId: string
) => ({
  nodes: flowGraph.nodes.map((node) => {
    if (node.id === oldId) {
      return {
        ...node,
        id: newId,
      };
    }
    return node;
  }),
  edges: flowGraph.edges.map((edge) => {
    if (edge.source === oldId) {
      // Update the source ID and reconstruct the edge eID
      return {
        ...edge,
        source: newId,
        id: edge.id.replace(oldId, newId),
      };
    } else if (edge.target === oldId) {
      // Update the target ID and reconstruct the edge eID
      return {
        ...edge,
        target: newId,
        id: edge.id.replace(oldId, newId),
      };
    }
    return edge;
  }),
});

/**
 * Update a node. Takes the node's string id, and a callback like this:
 * (prevNode: Node): Node | null => {
 *   return {
 *     ...prevNode,
 *     blah: 'abc',
 *   };
 * }
 */
export const updateNode = (
  flowGraph: IFlowGraph,
  id: string,
  updateCallback: (prevNode: Node) => Node | null
) => {
  // This flag will be true if it was a node and we deleted it
  let nodeDeleted = false;

  // Map, setting the new value
  const newGraph = {
    ...flowGraph,
    nodes: flowGraph.nodes.flatMap((node) => {
      if (node.id === id) {
        const newEl = updateCallback(node);
        // If the callback produced a nullish value, it's been deleted
        nodeDeleted = !newEl;
        // Returning an empty array will delete the node from the array
        return newEl ?? [];
      }
      return node;
    }),
  };
  // If we deleted a node, delete any related edges
  newGraph.edges = flowGraph.edges.filter((edge) => {
    if (nodeDeleted) {
      return edge.source !== id && edge.target !== id;
    }
    return true;
  });

  return newGraph;
};
