import {
  ApolloClient,
  ApolloLink,
  ApolloProvider,
  HttpLink,
  InMemoryCache,
} from '@apollo/client';
import { onError } from '@apollo/client/link/error';
import {
  ErrorBoundary as RollbarErrorBoundary,
  Provider as RollbarProvider,
} from '@rollbar/react';
import '@szhsin/react-menu/dist/index.css';
import Login from 'components/Auth/Login';
import RequireAuth from 'components/Auth/RequireAuth';
import BottomPanel from 'components/BottomPanel';
import NodeList from 'components/NodeList';
import AnnouncementsDialogContextProvider from 'contexts/AnnouncementsDialog';
import GraphSummariesContextProvider from 'contexts/GraphSummaries';
import UserPreferencesContextProvider from 'contexts/UserPreferences';
import { parse, stringify } from 'flatted';
import useNotifications from 'hooks/useNotifications';
import { useMemo } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import { Navigate, Route, Routes } from 'react-router-dom';
import { ReactFlowProvider } from 'reactflow';
import 'reactflow/dist/base.css';
import Rollbar, { Configuration as RollbarConfig } from 'rollbar';
import './App.scss';
import AccountMenuButton from './components/AccountMenuButton';
import ActiveJobRouter from './components/ActiveJobRouter';
import AddNodeButton from './components/AddNodeButton';
import DynamicTitle from './components/DynamicTitle';
import ErrorBoundaryFallback from './components/ErrorBoundaryFallback';
import GlobalPositionContainer from './components/GlobalPositionContainer';
import Graph from './components/Graph';
import GraphLockOverlay from './components/GraphLockOverlay';
import JobRunControls from './components/JobRunControls';
import MenuBar from './components/MenuBar';
import NetworkErrorOverlay from './components/NetworkErrorOverlay';
import SaveStatusChip from './components/SaveStatusChip';
import StartScreen from './components/StartScreen';
import { GQL_HOST } from './config';
import { GraphContextsProvider, IdentityContextsProvider } from './contexts';
import useAuth from './hooks/useAuth';
import useConnectivity from './hooks/useConnectivity';

const ReactFlowProviderWrapper: any = ReactFlowProvider;

export const httpLink = new HttpLink({
  uri: `${GQL_HOST}/graphql`,
  credentials: 'include',
});

const cleanTypename = new ApolloLink((operation, forward) => {
  const omitTypename = (key: any, value: any): any =>
    key === '__typename' ? undefined : value;

  if (operation.variables && !operation.getContext().hasUpload) {
    operation.variables = parse(stringify(operation.variables), omitTypename);
  }

  return forward(operation);
});

const rollbarConfig: RollbarConfig = {
  accessToken: 'd00f78a9711d44b48385a4793bf20a1e',
  environment: import.meta.env.MODE,
  enabled: !import.meta.env.DEV,
};

function App() {
  const { isAuthenticated, resetAuth } = useAuth();
  const { isConnected, isCheckingConnection, setNotConnected } =
    useConnectivity();
  const notify = useNotifications();

  // This handles errors coming from both the network and graphql
  const errorLink = useMemo(() => {
    return onError(({ graphQLErrors, networkError, response }) => {
      if (graphQLErrors) {
        graphQLErrors.forEach(({ message, name }) => {
          // In the event we get an authentication error, logout in the frontend;
          if (
            isAuthenticated &&
            (name === 'AuthenticationError' ||
              message.toLowerCase().includes('not authenticated'))
          ) {
            notify.error('You have been logged out due to inactivity.');
            resetAuth();
          }
        });
      }

      if (networkError) console.error(`[Network error]: ${networkError}`);

      // In the case of a server miss, flag that we're disconnected to present the user the network error screen
      if (
        networkError ||
        graphQLErrors?.some(({ message }) => message.includes('ECONNREFUSED'))
      ) {
        if (response) response.errors = undefined;

        if (!isCheckingConnection && isConnected) {
          setNotConnected();
        }
      }
    });
  }, [resetAuth]); // eslint-disable-line react-hooks/exhaustive-deps

  const client = useMemo(() => {
    return new ApolloClient({
      // httpLink is terminating so it goes last
      link: ApolloLink.from([cleanTypename, errorLink, httpLink]),
      cache: new InMemoryCache({}),
    });
  }, [errorLink]);

  const rollbar = new Rollbar(rollbarConfig);

  return (
    <div className="App">
      <RollbarProvider config={rollbarConfig}>
        <RollbarErrorBoundary>
          <ErrorBoundary
            FallbackComponent={ErrorBoundaryFallback}
            onError={(error, info) => {
              rollbar.error(error, info);
            }}
          >
            <ApolloProvider client={client}>
              <UserPreferencesContextProvider>
                <IdentityContextsProvider>
                  {isConnected ? (
                    <AnnouncementsDialogContextProvider>
                      <GraphSummariesContextProvider>
                        <ReactFlowProviderWrapper>
                          <Routes>
                            <Route
                              path="/login"
                              element={
                                <RequireAuth>
                                  <Login />
                                </RequireAuth>
                              }
                            />
                            <Route
                              path="/graph/:gid"
                              element={
                                <RequireAuth>
                                  <GraphContextsProvider>
                                    <ActiveJobRouter>
                                      <DynamicTitle />

                                      <GraphLockOverlay />

                                      <Graph />

                                      <GlobalPositionContainer
                                        vertical="top"
                                        horizontal="left"
                                        zIndex={100}
                                      >
                                        <MenuBar />
                                      </GlobalPositionContainer>

                                      <GlobalPositionContainer
                                        vertical="top"
                                        horizontal="right"
                                        zIndex={100}
                                      >
                                        <AccountMenuButton />
                                      </GlobalPositionContainer>

                                      <GlobalPositionContainer
                                        vertical="top"
                                        horizontal="middle"
                                        zIndex={100}
                                      >
                                        <SaveStatusChip />
                                      </GlobalPositionContainer>

                                      <GlobalPositionContainer
                                        vertical="bottom"
                                        horizontal="right"
                                        zIndex={100}
                                      >
                                        <AddNodeButton />
                                        <JobRunControls hideWhenLocked />
                                      </GlobalPositionContainer>

                                      <GlobalPositionContainer
                                        vertical="bottom"
                                        horizontal="middle"
                                        zIndex={100}
                                        noPadding
                                      >
                                        <BottomPanel />
                                      </GlobalPositionContainer>
                                    </ActiveJobRouter>
                                  </GraphContextsProvider>
                                </RequireAuth>
                              }
                            />

                            <Route path="/nodes" element={<NodeList />} />
                            <Route
                              path="/"
                              element={
                                <RequireAuth>
                                  <ActiveJobRouter>
                                    <StartScreen />
                                  </ActiveJobRouter>
                                </RequireAuth>
                              }
                            />
                            <Route
                              path="*"
                              element={<Navigate to="/" replace />}
                            />
                          </Routes>
                        </ReactFlowProviderWrapper>
                      </GraphSummariesContextProvider>
                    </AnnouncementsDialogContextProvider>
                  ) : (
                    <NetworkErrorOverlay />
                  )}
                </IdentityContextsProvider>
              </UserPreferencesContextProvider>
            </ApolloProvider>
          </ErrorBoundary>
        </RollbarErrorBoundary>
      </RollbarProvider>
    </div>
  );
}

export default App;
