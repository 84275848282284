import { faRuler } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Kinematics Calculator',
  description:
    'This node calculates kinematic properties of the given tracked objects.',
  descriptionDetail:
    'After constructing an image/world correspondence for the input video, tracked bounding boxes can be transformed from pixel coordinates to world metres, and some kinematic properties extracted. This module also allows for the application of a low-pass filter for improved stability.',
  icon: faRuler,
  category: NodeCategory.TRACKING,
  moduleType: 'KinematicsCalculator',
  configTypes: [
    {
      key: 'lowpass_hz',
      type: PipelineConfigType.DECIMAL('Low-pass cutoff (Hz)')
        .MIN(1)
        .MAX(5)
        .DEFAULT(1)
        .NUMERIC_STEP(0.25)
        .DESCRIPTION(
          'The cutoff frequency for the low-pass filter. No filtering is applied if the value is removed, but 1hz is recommended for best results.'
        ),
    },
    {
      key: 'straight_line_motion',
      type: PipelineConfigType.BOOLEAN('Straight line motion')
        .DESCRIPTION(
          'Assume that tracked objects move only in a straight line. This improves accuracy for events like long jump, but should only be applied to short clips.'
        )
        .REQUIRED.DEFAULT(false).ADVANCED,
    },
    {
      key: 'km_per_hour',
      type: PipelineConfigType.BOOLEAN('Km/hr')
        .DESCRIPTION('Use km/hr instead of m/s for the velocity.')
        .REQUIRED.DEFAULT(false),
    },
  ],
  inputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
    {
      key: 'point_correspondence',
      type: PipelineDataType.POINT_CORRESPONDENCE.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'world_positions',
      type: PipelineDataType.TRACKED_WORLD_POSITIONS_2D,
    },
    {
      key: 'velocities',
      type: PipelineDataType.VELOCITIES,
    },
    {
      key: 'velocities_x',
      type: PipelineDataType.VELOCITIES.LABEL('X Velocities').DESCRIPTION(
        'The X component of velocities that correspond to tracked bounding boxes'
      ),
    },
    {
      key: 'velocities_y',
      type: PipelineDataType.VELOCITIES.LABEL('Y Velocities').DESCRIPTION(
        'The Y component of velocities that correspond to tracked bounding boxes'
      ),
    },
    {
      key: 'cumulative_distances',
      type: PipelineDataType.CUMULATIVE_DISTANCES,
    },
  ],
};
