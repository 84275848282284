import { Typography } from '@mui/material';
import VideoPreview from 'components/VideoPreview';
import { PipelineDataType } from 'graph/types';
import useInputBlobs from 'hooks/useInputBlobs';
import { Pipeline_BlobDataType } from '../../../graphql/graphql';
import styles from './index.module.scss';
import VideoMetadataRow from './VideoMetadataRow';

interface Props {
  nodeId: string;
  id: string;
  dataType: PipelineDataType;
}

const BlobMetadataRow = ({ nodeId, id, dataType }: Props) => {
  const { inputBlob } = useInputBlobs(nodeId, id);

  if (!inputBlob) return null;

  if (dataType.blobDataType === Pipeline_BlobDataType.Video) {
    return (
      <>
        <VideoMetadataRow
          nodeId={nodeId}
          id={id}
          dataType={dataType}
          inputBlob={inputBlob}
        />
        <VideoPreview videoBlob={inputBlob} startWithVideoPlayer />
        {inputBlob.metadata === undefined && (
          <Typography variant="body2" classes={{ root: styles['blob-error'] }}>
            This video is missing metadata, please download and re-upload to
            process it.
          </Typography>
        )}
      </>
    );
  }

  return null;
};

export default BlobMetadataRow;
