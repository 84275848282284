import { Blob } from 'contexts/Graph';
import {
  Pipeline_PointCorrespondenceTemplate,
  useGetPointCorrespondenceTemplatesQuery,
} from 'graphql/graphql';
import { useEffect, useState } from 'react';
import { Wizard } from 'react-use-wizard';
import RedirectStep from './RedirectStep';
import StepOne from './StepOne';
import StepTwo from './StepTwo';
import { PointCorrespondence } from './types';

interface Props {
  open: boolean;
  onClose: () => void;
  videoBlob: Blob | undefined;
  existingCorrespondence: PointCorrespondence | undefined;
  removeExistingCorrespondence: () => void;
  onSaveCorrespondence: (correspondence: PointCorrespondence) => void;
}

const PointCorrespondenceWizard = ({
  open,
  onClose,
  videoBlob,
  existingCorrespondence,
  removeExistingCorrespondence,
  onSaveCorrespondence,
}: Props) => {
  const {
    data: templates,
    refetch,
    loading,
  } = useGetPointCorrespondenceTemplatesQuery({
    fetchPolicy: 'no-cache',
    skip: !open,
  });

  const [selectedId, selectTemplateById] = useState<string | null>(null);
  const [selectedTemplate, setSelectedTemplate] = useState<
    Pipeline_PointCorrespondenceTemplate | undefined
  >(undefined);

  useEffect(() => {
    if (!loading) {
      if (selectedId) {
        setSelectedTemplate(
          templates?.Pipeline_allPointCorrespondenceTemplates?.find(
            (template) => template.id === selectedId
          ) as Pipeline_PointCorrespondenceTemplate
        );
      } else {
        setSelectedTemplate(undefined);
      }
    }
  }, [loading, selectedId, templates]);

  if (!open) {
    return null;
  }
  return (
    <Wizard>
      <RedirectStep
        selectedTemplate={selectedTemplate}
        existingCorrespondence={existingCorrespondence}
        selectTemplateById={selectTemplateById}
        loading={loading}
      />
      <StepOne
        correspondenceTemplates={
          templates?.Pipeline_allPointCorrespondenceTemplates as Pipeline_PointCorrespondenceTemplate[]
        }
        refetchTemplates={() => void refetch()}
        selectedTemplate={selectedTemplate}
        selectTemplateById={selectTemplateById}
        existingCorrespondence={existingCorrespondence}
        cancelSelectingTemplate={onClose}
      />
      <StepTwo
        selectedTemplate={selectedTemplate}
        videoBlob={videoBlob}
        existingCorrespondence={existingCorrespondence}
        removeExistingCorrespondence={removeExistingCorrespondence}
        onSave={onSaveCorrespondence}
        cancelEditingCorrespondence={onClose}
      />
    </Wizard>
  );
};

export default PointCorrespondenceWizard;
