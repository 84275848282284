import { faCopy, faXmark } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, IconButton, TableCell, TableRow } from '@mui/material';
import ColouredChip from 'components/ColouredChip';
import DelayedTooltip from 'components/DelayedTooltip';
import { hashStringAndGetHue, hslToHex } from 'utils/colour';
import { frameNumberToTimecode } from '../../utils';
import { FrameLabel } from '../types';
import styles from './index.module.scss';

interface Props {
  frameLabels: FrameLabel[];
  timestamp: number;
  videoFrameRate: number;
  removeFrameLabel: (frameLabel: FrameLabel) => void;
  duplicateFrameLabel: (frameLabel: FrameLabel) => void;
  jumpToFrameLabel: (frameLabel: FrameLabel) => void;
}

const FrameLabelRow = ({
  frameLabels,
  timestamp,
  videoFrameRate,
  removeFrameLabel,
  duplicateFrameLabel,
  jumpToFrameLabel,
}: Props) => {
  const [frame, ignored] = frameLabels[0];
  const videoCurrentFrame = Math.floor(timestamp * videoFrameRate);

  const pastFrameClass =
    videoCurrentFrame > frame ? styles['first-cell-past-frame'] : '';

  return (
    <TableRow classes={{ root: styles.row }}>
      <TableCell
        component="th"
        scope="row"
        classes={{ root: `${styles['first-cell']} ${pastFrameClass}` }}
      >
        <Button
          variant="text"
          size="small"
          onClick={() => jumpToFrameLabel(frameLabels[0])}
        >
          {frameNumberToTimecode(frame, videoFrameRate)}
        </Button>
      </TableCell>
      <TableCell>
        {frameLabels.map(([frame, label]) => {
          const hue = hashStringAndGetHue(label);
          const fore = hslToHex(hue, 100, 22);
          const back = hslToHex(hue, 100, 90);
          const border = hslToHex(hue, 80, 78);

          const buttonSx = {
            color: `${fore} !important`,
            '&:hover': {
              backgroundColor: `${back} !important`,
            },
          };
          return (
            <ColouredChip
              key={`${frame}-${label}`}
              size="small"
              label={label}
              foregroundColour={fore}
              backgroundColour={back}
              borderColour={border}
              avatar={
                <DelayedTooltip title="Duplicate this frame label on the current frame">
                  <IconButton
                    size="small"
                    onClick={(event) => {
                      duplicateFrameLabel([frame, label]);
                      event.stopPropagation();
                    }}
                    sx={buttonSx}
                  >
                    <FontAwesomeIcon icon={faCopy} />
                  </IconButton>
                </DelayedTooltip>
              }
              onClick={() => jumpToFrameLabel([frame, label])}
              onDelete={() => removeFrameLabel([frame, label])}
              deleteIcon={
                <DelayedTooltip title="Remove this frame label">
                  <IconButton
                    size="small"
                    sx={{ ...buttonSx, padding: '2px 4px' }}
                  >
                    <FontAwesomeIcon icon={faXmark} size="sm" />
                  </IconButton>
                </DelayedTooltip>
              }
            />
          );
        })}
      </TableCell>
    </TableRow>
  );
};

export default FrameLabelRow;
