import { faBraille } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Image Point Overlayer',
  description: 'Overlay image space points onto video frames.',
  descriptionDetail:
    'This node draws arbitrary 2D points such as calibration patterns or pose detections onto video frames.',
  icon: faBraille,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'ImagePointOverlayer',
  configTypes: [
    {
      key: 'colour',
      type: PipelineConfigType.COLOUR('Colour')
        .REQUIRED.DEFAULT([0, 255, 0])
        .DESCRIPTION('The colour to draw the points.'),
    },
    {
      key: 'opacity',
      type: PipelineConfigType.NORM_DECIMAL('Opacity')
        .DEFAULT(1)
        .DESCRIPTION('The opacity of the drawn points.')
        .ADVANCED.MIN(0.1)
        .SLIDER(0.5),
    },
    {
      key: 'radius',
      type: PipelineConfigType.INTEGER('Circle radius')
        .REQUIRED.DEFAULT(1)
        .MIN(1)
        .MAX(50)
        .SLIDER(1)
        .DESCRIPTION('The radius of the drawn circles.').ADVANCED,
    },
    {
      key: 'thickness',
      type: PipelineConfigType.INTEGER('Circle thickness')
        .REQUIRED.DEFAULT(3)
        .MIN(1)
        .MAX(10)
        .SLIDER(1)
        .DESCRIPTION('The thickness of the drawn circles.').ADVANCED,
    },
    {
      key: 'point_labels',
      type: PipelineConfigType.STRING('Label').DESCRIPTION(
        'A label to place at each point.'
      ).ADVANCED,
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
    {
      key: 'pattern',
      type: PipelineDataType.IMAGE_COORDINATES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.CALIBRATION_PATTERNS,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'overlaid_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
