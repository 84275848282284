import { faPanorama } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineDataType } from 'graph/types';

export default {
  name: 'Reprojector',
  description:
    'Project world-space 3d coordinates to image-space pixel coordinates.',
  descriptionDetail:
    'This node projected world-space coordinates onto the image-space view of a given camera, using the given camera extrinsic and intrinsic parameters.',
  icon: faPanorama,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'Reprojector',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060767619855',
  configTypes: [],
  inputTypes: [
    {
      key: 'world_points',
      type: PipelineDataType.WORLD_COORDINATES_3D.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.WORLD_CALIBRATION_PATTERNS,
      ]),
    },
    {
      key: 'proj_mat',
      type: PipelineDataType.CAMERA_PROJECTION_MATRIX.REQUIRED,
    },
    {
      key: 'dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'camera_points',
      type: PipelineDataType.IMAGE_COORDINATES.COMPATIBLE_WITH([
        PipelineDataType.IMAGE_COORDINATES,
        PipelineDataType.POSE_2D,
      ]).REQUIRED,
    },
  ],
};
