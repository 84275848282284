import { ReactElement } from 'react';

import styles from './index.module.scss';

interface Props {
  children: ReactElement[] | ReactElement;
  vertical: 'top' | 'middle' | 'bottom';
  horizontal: 'left' | 'middle' | 'right';
  zIndex?: number;
  className?: string;
  noPadding?: boolean;
}

const GlobalPositionContainer = ({
  children,
  vertical,
  horizontal,
  zIndex,
  className = '',
  noPadding = false,
}: Props) => {
  const vClassKey = `v-${vertical}`;
  const hClassKey = `h-${horizontal}`;

  let fullClassName = `${styles.container} ${styles[vClassKey]} ${styles[hClassKey]}`;
  if (noPadding) fullClassName = `${fullClassName} ${styles['no-padding']}`;
  fullClassName = `${fullClassName} ${className}`;

  return (
    <div className={fullClassName} style={{ zIndex }}>
      {children}
    </div>
  );
};

export default GlobalPositionContainer;
