import {
  Table,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import useGraphValidation from 'hooks/useGraphValidation';
import styles from './index.module.scss';

type NodeValDict = { [key: string]: string };

const GraphErrorList = () => {
  const {
    graphValidationState: { nodeValidation },
  } = useGraphValidation();

  const columnTitles: string[] = [];

  // Aggregate errors
  const validationTable = nodeValidation.flatMap((nodeVal) => {
    const tableRow: NodeValDict = {};
    if (nodeVal?.missingRequiredConfigs?.length) {
      tableRow['Missing Required Configs'] =
        nodeVal.missingRequiredConfigs.join(', ');
    }
    if (nodeVal?.missingRequiredInputs?.length) {
      tableRow['Missing Required Inputs'] =
        nodeVal.missingRequiredInputs.join(', ');
    }
    if (nodeVal?.missingRequiredOutputs?.length) {
      tableRow['Missing Required Outputs'] =
        nodeVal.missingRequiredOutputs.join(', ');
    }
    if (Object.keys(tableRow).length) {
      if (!columnTitles.includes('Node')) {
        columnTitles.push('Node');
      }
      Object.keys(tableRow).forEach((key) => {
        if (!columnTitles.includes(key)) {
          columnTitles.push(key);
        }
      });
      return {
        Node: nodeVal.id,
        ...tableRow,
      };
    }
    return [];
  }) as NodeValDict[];

  const renderRow = (nodeVal: NodeValDict) => {
    return (
      <TableRow>
        {columnTitles.map((title) => {
          return <TableCell key={title}>{nodeVal[title]}</TableCell>;
        })}
      </TableRow>
    );
  };

  if (!validationTable.length) {
    return (
      <Typography variant="body1" classes={{ root: styles['no-errors'] }}>
        No errors found
      </Typography>
    );
  }

  return (
    <Table size="small">
      <TableHead>
        <TableRow>
          {columnTitles.map((title) => {
            return <TableCell key={title}>{title}</TableCell>;
          })}
        </TableRow>
      </TableHead>
      {validationTable.map((nodeVal) => renderRow(nodeVal))}
    </Table>
  );
};

export default GraphErrorList;
