import { faSquarePen } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Rectangle Overlayer',
  description:
    'Overlay a static rectangle of a chosen colour onto video frames.',
  descriptionDetail:
    'This node draws a filled rectangle onto video frames, allowing users to cover regions with a solid colour. Useful when detecting calibration boards with many distracting shapes.',
  icon: faSquarePen,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'StaticMaskOverlayer',
  configTypes: [
    {
      key: 'mask_colour',
      type: PipelineConfigType.COLOUR('Colour')
        .REQUIRED.DEFAULT([0, 0, 0])
        .DESCRIPTION('The colour to draw the rectangle.'),
    },
    {
      key: 'static_mask_box',
      type: PipelineConfigType.INTEGER('Box coordinates')
        .TUPLE(['X0', 'Y0', 'X1', 'Y1'])
        .REQUIRED.DESCRIPTION(
          'The pixel coordinates corresponding to the left, top, right, and bottom of the rectangle, respectively.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'overlaid_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
