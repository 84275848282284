import { GQL_HOST, SPORT_AUS_CONNECT_GROUP } from 'config';

import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useNavigate } from 'react-router-dom';

interface AuthState {
  /** Whether the authentication context is still loading. */
  loading: boolean;
  /** Gets the logged in users email. */
  email?: string;
  /** The Pipelines roles to which the user belongs, as set in SportAusConnect */
  userRoles?: string[];
  /** A flag indicating whether the user has access to Pipelines */
  isPipeliner?: boolean;
}

interface IAuthContext extends AuthState {
  /** Gets whether a user is authenticated. */
  isAuthenticated: boolean;
  /** Logs in with a redirect. This will redirect the user to the authentication service. */
  loginWithRedirect: (redirect: string) => void;
  /** Logs the user out of the application. */
  logout: () => void;
  /** Resets the authentication state. */
  resetAuth: () => void;
}

/** Checks to see if the response body from /user-email contains an email attribute. */
const isEmailResponse = (body: unknown): body is { email: string } => {
  return Boolean(body && typeof body === 'object' && 'email' in body);
};

export const AuthContext = createContext<IAuthContext>(undefined!);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [{ loading, email, userRoles, isPipeliner }, setState] =
    useState<AuthState>({
      loading: true,
    });
  const navigate = useNavigate();

  const redirectBrowser = useCallback(
    (path: string) => {
      GQL_HOST ? window.location.replace(`${GQL_HOST}${path}`) : navigate(path);
    },
    [navigate]
  );

  useEffect(() => {
    fetch(`${GQL_HOST}/user/email`, {
      credentials: 'include',
      redirect: 'error',
    })
      .then(async (response) => {
        if (response.ok) {
          const body = await response.json();
          if (isEmailResponse(body)) {
            setState({ loading: true, email: body.email });

            void fetch(`${GQL_HOST}/user/application-roles`, {
              credentials: 'include',
              redirect: 'error',
            })
              .then(async (response) => {
                if (response.ok) {
                  const { roles } = await response.json();
                  // The user has access if the SAC app group exists
                  const isPipeliner = Boolean(roles?.[SPORT_AUS_CONNECT_GROUP]);
                  // Pull out the roles, defaulting to an empty array.
                  const roleMap: Map<string, boolean> | undefined =
                    roles?.[SPORT_AUS_CONNECT_GROUP]?.v1;
                  const userRoles = Object.keys(roleMap ?? {});

                  setState((currState) => ({
                    ...currState,
                    userRoles: userRoles,
                    isPipeliner,
                  }));
                }
              })
              .finally(() =>
                setState((currState) => ({ ...currState, loading: false }))
              );
          } else {
            setState({ loading: false, email: undefined });
          }
        } else {
          setState({ loading: false, email: undefined });
        }
      })
      .catch(() => {
        // Not logged in
        setState({ loading: false, email: undefined });
      });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <AuthContext.Provider
      value={{
        loading,
        email,
        isAuthenticated: Boolean(email),
        isPipeliner,
        userRoles: userRoles,
        resetAuth: () => setState({ loading: false, email: undefined }),
        loginWithRedirect: (redirectUrl: string) =>
          redirectBrowser(`/auth/login?redirect=${redirectUrl}`),
        logout: () =>
          redirectBrowser(`/auth/logout?redirect_url=${window.location.href}`),
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};
