import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import {
  faCheckCircle,
  faExclamationCircle,
  faSyncAlt,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Chip } from '@mui/material';
import { SaveStatus } from 'contexts/Graph';
import useGraph from 'hooks/useGraph';
import { useCallback, useEffect, useState } from 'react';
import styles from './index.module.scss';

const statusParams = (status: SaveStatus): [string, IconDefinition, string] => {
  switch (status) {
    default:
    case SaveStatus.UNSAVED: {
      return ['Unsaved changes', faExclamationCircle, 'orange'];
    }
    case SaveStatus.UP_TO_DATE: {
      return ['Up to date', faCheckCircle, 'green'];
    }
    case SaveStatus.SAVING: {
      return ['Saving...', faSyncAlt, 'orange'];
    }
    case SaveStatus.LOADING: {
      return ['Loading...', faSyncAlt, 'orange'];
    }
  }
};

const HIDE_DELAY_MS = 2000;

const SaveStatusChip = () => {
  const { saveStatus } = useGraph();
  const [msg, icon, colour] = statusParams(saveStatus);
  const [timeoutRef, setTimeoutRef] = useState<number | undefined>();
  const [isVisible, setIsVisible] = useState(true);

  const scheduleHide = useCallback(() => {
    setTimeoutRef(
      window.setTimeout(() => {
        setIsVisible(false);
        setTimeoutRef(undefined);
      }, HIDE_DELAY_MS)
    );
  }, []);

  const cancelHide = useCallback(() => {
    window.clearTimeout(timeoutRef);
    setTimeoutRef(undefined);
  }, [timeoutRef]);

  useEffect(() => {
    if (saveStatus === SaveStatus.UP_TO_DATE) {
      if (!timeoutRef) scheduleHide();
    } else {
      if (!isVisible) setIsVisible(true);
      if (timeoutRef) cancelHide();
    }
  }, [cancelHide, isVisible, saveStatus, scheduleHide, timeoutRef]);

  const spinClass = [SaveStatus.SAVING, SaveStatus.LOADING].includes(saveStatus)
    ? 'fa-spin'
    : '';

  return (
    <Chip
      icon={
        <FontAwesomeIcon
          icon={icon}
          size="lg"
          style={{ color: colour }}
          className={spinClass}
        />
      }
      className={`${styles.chip} ${isVisible ? styles['fade-in'] : styles['fade-out']
        }`}
      label={msg}
      variant="outlined"
    />
  );
};

export default SaveStatusChip;
