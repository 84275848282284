import { TextField, Typography } from '@mui/material';
import { Pipeline_Announcement } from 'graphql/graphql';

type Props = Pick<Pipeline_Announcement, 'overview'> & {
  onChangeOverview:
    | ((overview: Pipeline_Announcement['overview']) => void)
    | undefined;
};

const OverviewEdit = ({
  overview,
  onChangeOverview,
}: {
  overview: Pipeline_Announcement['overview'];
  onChangeOverview: (overview: Pipeline_Announcement['overview']) => void;
}) => {
  return (
    <TextField
      fullWidth
      label="Overview"
      variant="outlined"
      value={overview}
      onChange={(e) => onChangeOverview(e.target.value)}
      error={overview?.length === 0}
    />
  );
};

const AnnouncementOverview = ({ overview, onChangeOverview }: Props) => {
  if (onChangeOverview) {
    return (
      <OverviewEdit overview={overview} onChangeOverview={onChangeOverview} />
    );
  }
  return <Typography color="text.secondary">{overview}</Typography>;
};

export default AnnouncementOverview;
