import { Blob, IFlowGraph } from 'contexts/Graph';
import { NodeTypeKey, NodeTypes } from 'graph/NodeTypes';
import { PipelineDataType } from 'graph/types';
import { HandleType, OnConnectStartParams } from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import { Pipeline_BlobType } from '../graphql/graphql';
import useFlowGraph from './useFlowGraph';
import useFlowRenderer from './useFlowRenderer';
import useGraph from './useGraph';

type HandleValidationState = 'valid' | 'success' | 'invalid';

const isInputAndAlreadyConnected = (
  handleType: HandleType | null,
  nodeId: string,
  handleId: string,
  flowGraph: IFlowGraph
): boolean => {
  if (handleType === 'source') return false;
  return flowGraph.edges.some(
    (edge) => edge.target === nodeId && edge.targetHandle === handleId
  );
};

const validateProspectiveConnection = (
  nodeId: string,
  handleId: string,
  handleType: HandleType,
  dataType: PipelineDataType,
  prospectiveConnection: OnConnectStartParams,
  flowGraph: IFlowGraph
): HandleValidationState => {
  // Disallow target->target and source->source
  if (handleType === prospectiveConnection.handleType) return 'invalid';

  // The handle ID should never be null, but guard it anyway
  if (prospectiveConnection.handleId === null) return 'invalid';

  // It's the handle the user is dragging from - valid.
  if (
    prospectiveConnection.nodeId === nodeId &&
    prospectiveConnection.handleId === handleId
  )
    return 'valid';

  // Find the node that the user is trying to join
  const nodes = flowGraph.nodes.filter(
    (node) => node.id === prospectiveConnection.nodeId
  );

  // The connection is invalid by default if the source handle already has an input
  if (
    isInputAndAlreadyConnected(handleType, nodeId, handleId, flowGraph) ||
    (prospectiveConnection.nodeId &&
      isInputAndAlreadyConnected(
        prospectiveConnection.handleType,
        prospectiveConnection.nodeId,
        prospectiveConnection.handleId,
        flowGraph
      ))
  ) {
    return 'invalid';
  }

  if (nodes.length > 0) {
    // Get its NodeType of the connection
    const nodeType = NodeTypes[nodes[0]?.data.nodeType as NodeTypeKey];

    // Find the connection datatype, whether it's input or output
    let selectionDataType: PipelineDataType | undefined;
    if (prospectiveConnection.handleType === 'source' && nodeType.outputTypes) {
      const foundHandle = nodeType.outputTypes.filter(
        (t) => t.key === prospectiveConnection.handleId
      )[0];
      if (foundHandle) {
        selectionDataType = foundHandle.type;
      }
    } else if (
      prospectiveConnection.handleType === 'target' &&
      nodeType.inputTypes
    ) {
      const foundHandle = nodeType.inputTypes.filter(
        (t) => t.key === prospectiveConnection.handleId
      )[0];
      if (foundHandle) {
        selectionDataType = foundHandle.type;
      }
    }

    // We should always find the corresponding type, but if not, just assume it's an invalid connection.
    if (!selectionDataType) return 'invalid';

    // Check that the two datatypes are compatible
    if (!selectionDataType.compatibleWith(dataType)) return 'invalid';

    return 'success';
  }

  return 'invalid';
};

const validateExistingHandle = (
  nodeId: string,
  handleId: string,
  handleType: HandleType,
  dataType: PipelineDataType,
  flowGraph: IFlowGraph,
  inputs: Blob[]
): HandleValidationState => {
  // If it's not required, we don't care whether it's connected
  if (!dataType.required) return 'valid';

  // If the handle is an source, check if it's connected to a target
  if (
    handleType === 'source' &&
    flowGraph.edges.some(
      (edge) => edge.source === nodeId && edge.sourceHandle === handleId
    )
  ) {
    return 'valid';
  }
  // If the handle is a target, check if it's connected to a source
  if (
    handleType === 'target' &&
    flowGraph.edges.some(
      (edge) => edge.target === nodeId && edge.targetHandle === handleId
    )
  ) {
    return 'valid';
  }

  // It has an uploaded input associated with it
  if (getBlobByKey(inputs, nodeId, handleId, Pipeline_BlobType.Input)) {
    return 'valid';
  }

  // Otherwise, it's no good!
  return 'invalid';
};

const useHandleValidationState = (
  nodeId: string,
  handleId: string,
  handleType: HandleType,
  dataType: PipelineDataType
): HandleValidationState => {
  const { flowGraph } = useFlowGraph();
  const { inputBlobs: inputs } = useGraph();

  const { prospectiveConnection } = useFlowRenderer();

  if (prospectiveConnection) {
    // The user is in the middle of dragging a new connection, so check if this handle
    // is compatible with the prospective connection.
    return validateProspectiveConnection(
      nodeId,
      handleId,
      handleType,
      dataType,
      prospectiveConnection,
      flowGraph
    );
  }

  // The user isn't doing anything, so we can just check if the handle has its required inputs/outputs
  return validateExistingHandle(
    nodeId,
    handleId,
    handleType,
    dataType,
    flowGraph,
    inputs
  );
};

export default useHandleValidationState;
