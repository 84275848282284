import { Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import { Blob } from 'contexts/Graph';
import { Pipeline_PointCorrespondenceTemplate } from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { isEqual } from 'lodash-es';
import { useCallback, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { Vec2 } from 'utils/Vec2';
import ActionRow from '../ActionRow';
import { correspondenceToGraphState, PointCorrespondence } from '../types';
import PointCorrespondenceBuilder from './CorrespondenceBuilder';
import useCorrespondence from './CorrespondenceBuilder/useCorrespondence';

interface Props {
  selectedTemplate: Pipeline_PointCorrespondenceTemplate | undefined;
  videoBlob: Blob | undefined;
  existingCorrespondence: PointCorrespondence | undefined;
  removeExistingCorrespondence: () => void;
  onSave: (correspondence: PointCorrespondence) => void;
  cancelEditingCorrespondence: () => void;
}

const StepTwo = ({
  selectedTemplate,
  videoBlob,
  existingCorrespondence,
  removeExistingCorrespondence,
  cancelEditingCorrespondence,
  onSave,
}: Props) => {
  const { previousStep } = useWizard();
  const [localCorrespondence, setLocalCorrespondence] = useState<
    PointCorrespondence | undefined
  >(existingCorrespondence);
  const { triggerDialog } = useConfirmDialog();
  const correspondence = useCorrespondence(
    correspondenceToGraphState(existingCorrespondence)
  );

  const [localWorldOrigin, setLocalWorldOrigin] = useState<Vec2 | undefined>(
    existingCorrespondence?.templateWorldOrigin ?? {
      x: selectedTemplate?.worldOrigin[0] ?? 0,
      y: selectedTemplate?.worldOrigin[1] ?? 0,
    }
  );
  const [transPoints, setTransPoints] = useState<Vec2[]>([]);

  const correspondenceError = getCorrespondenceError(
    localCorrespondence,
    transPoints
  );

  const goBack = useCallback(() => {
    setLocalCorrespondence(undefined);
    if (existingCorrespondence) {
      removeExistingCorrespondence();
    }
    // Timeout for race condition :/
    setTimeout(() => previousStep(), 50);
  }, [existingCorrespondence, previousStep, removeExistingCorrespondence]);

  return (
    <DraggableDialog
      open={true}
      title="Build Image to World Correspondence"
      maxWidth="xl"
      fullWidth
      allowClose
      onClose={() => {
        if (!isEqual(localCorrespondence, existingCorrespondence)) {
          triggerDialog({
            title: 'Exit without saving?',
            message:
              'Are you sure you want to exit? Any unsaved changes to the correspondence will be lost.',
            onConfirm: cancelEditingCorrespondence,
            confirmButtonText: 'Discard changes',
          });
        } else {
          cancelEditingCorrespondence();
        }
      }}
      actions={
        <ActionRow
          helpText={correspondenceError}
          buttons={[
            <StyledButton
              key="cancel"
              styleName="danger"
              onClick={() => {
                if (localCorrespondence || existingCorrespondence) {
                  triggerDialog({
                    title: 'Discard correspondence?',
                    message:
                      'Are you sure you want to discard the correspondence and return to template selection? This cannot be undone.',
                    onConfirm: goBack,
                    confirmButtonText: 'Discard correspondence',
                  });
                } else {
                  goBack();
                }
              }}
              title="Return to select template, discarding your current correspondence."
            >
              Return to Select Template
            </StyledButton>,
            <StyledButton
              key="continue"
              styleName="primary"
              onClick={() => onSave(localCorrespondence!)}
              disabled={Boolean(correspondenceError)}
              title="Save the correspondence"
            >
              Save Correspondence
            </StyledButton>,
          ]}
        />
      }
    >
      <Typography variant="subtitle1">
        Indicate the correspondence between the image and the world by clicking
        on a point in the template to the left, and then clicking on the same
        point in the video to the right. After you have placed a minimum of 4
        points which don&apos;t lie on the same line, the view on the right will
        update to show the overlaid template. You can then adjust the handles
        until the overlaid template is as close to the video as possible.
      </Typography>

      <PointCorrespondenceBuilder
        selectedTemplate={selectedTemplate}
        videoBlob={videoBlob}
        correspondence={correspondence}
        setCorrespondence={setLocalCorrespondence}
        worldOrigin={localWorldOrigin}
        setWorldOrigin={setLocalWorldOrigin}
        transPoints={transPoints}
        setTransPoints={setTransPoints}
        onVideoError={goBack}
      />
    </DraggableDialog>
  );
};

export const getCorrespondenceError = (
  correspondence: PointCorrespondence | undefined,
  transPoints: Vec2[]
) => {
  if (
    !correspondence ||
    correspondence.imagePixelCoords.filter(Boolean).length < 4
  ) {
    return 'You must select at least 4 points.';
  }
  // See if any of the transformed points collapsed onto the same point, truncated to 4 decimal places
  const transformedPoints = transPoints.map((p) => [
    p.x.toFixed(4),
    p.y.toFixed(4),
  ]);
  const transformedPointSet = new Set(
    transformedPoints.map((p) => p.join(','))
  );
  if (transformedPointSet.size !== transformedPoints.length) {
    return 'At least three of the selected points must not lie on the same line. Please select more points.';
  }
};

export default StepTwo;
