import { faPaintBrush } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Segmentation',
  description: 'Perform per-pixel segmentation of video frames.',
  descriptionDetail:
    'This node classifies each pixel of a video frame into its most-likely category, from a select number of classes.',
  icon: faPaintBrush,
  category: NodeCategory.DETECTION,
  moduleType: 'DeepLabV3',
  configTypes: [
    {
      key: 'model_backbone',
      type: PipelineConfigType.STRING('Model backbone')
        .REQUIRED.OPTIONS(['resnet50', 'resnet101'])
        .DEFAULT('resnet50')
        .DESCRIPTION(
          'The neural network used. Larger models yield more accurate results but take longer to process.'
        ).ADVANCED,
    },
    {
      key: 'half',
      type: PipelineConfigType.BOOLEAN('Half-precision')
        .CONSTANT_VALUE(true)
        .ADVANCED.DESCRIPTION(
          'If true, 16 bit numbers are used instead of 32 bit. This will run faster at a small cost in accuracy.'
        ),
    },
    {
      key: 'label_filter',
      type: PipelineConfigType.STRING('Label filter').CONSTANT_VALUE('person'),
      // .OPTIONS([
      //   'bicycle',
      //   'boat',
      //   'bus',
      //   'car',
      //   'chair',
      //   'dog',
      //   'horse',
      //   'person',
      // ]) // Subset of COCO_SEGMENTATION_TORCHVISION
      // .DEFAULT('person')
      // .DESCRIPTION('The classes for which to perform segmentation.'),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'segmentation_masks',
      type: PipelineDataType.SEGMENTATION_MASKS.REQUIRED,
    },
  ],
};
