import { useContext } from 'react';

import { JobContext } from 'contexts/Job';

const useJobLogs = () => {
  const { logs } = useContext(JobContext);

  return logs;
};

export default useJobLogs;
