import React from 'react';

import { Chip } from '@mui/material';

interface Props extends React.ComponentProps<typeof Chip> {
  foregroundColour: string;
  backgroundColour: string;
  borderColour: string;
}

const ColouredChip = ({
  foregroundColour,
  backgroundColour,
  borderColour,
  ...rest
}: Props) => {
  const sx = {
    ...rest?.sx,
    border: `1px solid ${borderColour}`,
    color: foregroundColour,
    backgroundColor: backgroundColour,
    '&:hover': {
      backgroundColor: borderColour,
    },
  };
  return <Chip {...rest} sx={sx} />;
};

export default ColouredChip;
