import { TextField } from '@mui/material';
import { ChangeEvent } from 'react';

interface Props {
  maxLength: number;
  label: string;
  value: string;
  onChange: (e: ChangeEvent<any>) => void;
  [x: string]: any;
}
const MaxLengthTextField = ({
  maxLength,
  label,
  value,
  onChange,
  ...rest
}: Props) => {
  return (
    <TextField
      value={value}
      onChange={(e) => {
        e.target.value = e.target.value.slice(0, maxLength);
        onChange(e);
      }}
      label={`${label} [${value.length}/${maxLength}]`}
      {...rest}
    />
  );
};

export default MaxLengthTextField;
