import { Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import { MAX_NAME_LEN } from 'components/GraphMetaForm';
import MaxLengthTextField from 'components/MaxLengthTextField';
import StyledButton from 'components/StyledButton';
import {
  useGraphFromTemplateMutation,
  useSaveGraphMutation,
} from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useGraph from 'hooks/useGraph';
import useNotifications from 'hooks/useNotifications';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

const SaveAsDialog = ({ open, onClose }: Props) => {
  const {
    graphState: { id: currentGraphId },
  } = useGraph();
  const [value, setValue] = useState('');
  const navigate = useNavigate();
  const notify = useNotifications();
  const { triggerDialog } = useConfirmDialog();

  const [graphFromTemplate] = useGraphFromTemplateMutation();
  const [saveGraph] = useSaveGraphMutation();

  // Submission handler - create the graph and redirect or display an error
  const onSubmit = async () => {
    if (currentGraphId) {
      try {
        const res = await graphFromTemplate({
          variables: {
            templateId: currentGraphId,
          },
        });
        const newGraphId = res.data?.Pipeline_graphFromTemplate?.id;
        // Save the graph with the new name
        void (await saveGraph({
          variables: {
            graph: {
              id: newGraphId,
              name: value,
            },
          },
        }));
        notify.success('Saved successfully. Opening new pipeline.');
        navigate(`/graph/${newGraphId}`);
        onClose();
      } catch (e) {
        triggerDialog({
          title: 'Error saving as new pipeline',
          message:
            'An error occurred while trying to save this as a new pipeline. Please try again.',
          confirmButtonText: 'OK',
          hideCancelButton: true,
          onConfirm: () => null,
        });
      }
    }
  };

  const validName = value.length > 0;

  return (
    <DraggableDialog
      title="Save pipeline as..."
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg' }}
      allowClose
      actions={[
        <StyledButton
          key="create"
          styleName="primary"
          onClick={() => void onSubmit()}
          solid
          disabled={!validName}
        >
          Create Pipeline
        </StyledButton>,
      ]}
      className={styles.content}
    >
      <div>
        <Typography variant="subtitle1">
          {`Enter a new pipeline name here and click "save" to save this pipeline with a new name. Names don't have to be unique - but it sure helps!`}
        </Typography>
        <MaxLengthTextField
          label="New pipeline name"
          variant="standard"
          value={value}
          onChange={(e) => setValue(e.target.value as string)}
          maxLength={MAX_NAME_LEN}
          fullWidth
        />
      </div>
    </DraggableDialog>
  );
};

export default SaveAsDialog;
