export const KEYPOINT_LIST = [
  'right_ankle',
  'right_knee',
  'right_hip',
  'right_wrist',
  'right_elbow',
  'right_shoulder',
  'left_ankle',
  'left_knee',
  'left_hip',
  'left_wrist',
  'left_elbow',
  'left_shoulder',
  'head_top',
  'head',
  'neck',
  'spine',
  'pelvis',
  'right_toe_base',
  'right_heel',
  'left_toe_base',
  'left_heel',
];
