import DarkIcon from 'assets/icon-bt-128.png';
import DarkIconSmall from 'assets/icon-bt-40.png';
import LightIcon from 'assets/icon-wt-128.png';
import LightIconSmall from 'assets/icon-wt-40.png';
import { APP_NAME } from 'config';

interface Props {
  dark?: boolean;
  small?: boolean;
  [key: string]: any;
}

const images = {
  light: {
    small: LightIconSmall,
    large: LightIcon,
  },
  dark: {
    small: DarkIconSmall,
    large: DarkIcon,
  },
};

const AppIcon = ({ dark = false, small = false, ...rest }: Props) => {
  const logo = images[dark ? 'dark' : 'light'][small ? 'small' : 'large'];

  return <img src={logo} alt={`${APP_NAME} icon`} {...rest} />;
};

export default AppIcon;
