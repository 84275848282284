import { Card, Typography } from '@mui/material';
import AppLogo from 'components/AppLogo';
import BackgroundImage from 'components/BackgroundImage';
import StyledButton from 'components/StyledButton';
import { APP_NAME } from 'config';
import useAuth from 'hooks/useAuth';
import { Navigate, useLocation } from 'react-router-dom';
import styles from './index.module.scss';

interface Props {
  children: JSX.Element;
}

/**
 * A component that wraps the react-router route so we can lock off pages that need authentication.
 */
const RequireAuth = ({ children }: Props): JSX.Element => {
  const { isAuthenticated, loading, isPipeliner, logout } = useAuth();
  const location = useLocation();

  const getContents = () => {
    if (loading) {
      return (
        <Typography variant="h6" className={styles.subtitle}>
          Loading...
        </Typography>
      );
    }
    return (
      <>
        <Typography variant="h6" className={styles.subtitle}>
          You don&apos;t have permission to access {APP_NAME}, please log in
          with an account that has access.
        </Typography>
        <StyledButton size="large" styleName="dark" onClick={logout} fullWidth>
          Log Out
        </StyledButton>
      </>
    );
  };

  if (!loading) {
    if (!isAuthenticated) {
      if (location.pathname === '/login') {
        return children;
      }
      return <Navigate to={`/login`} state={{ previousLocation: location }} />;
    }
    if (isPipeliner) {
      return children;
    }
  }

  return (
    <div className={styles.container}>
      <BackgroundImage />

      <Card className={styles.card}>
        <AppLogo dark />
        <div className={styles.content}>{getContents()}</div>
      </Card>
    </div>
  );
};

export default RequireAuth;
