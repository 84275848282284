import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import {
  Pipeline_Announcement,
  Pipeline_AnnouncementType,
  useDeleteAnnouncementMutation,
  useGetAnnouncementsQuery,
  useSaveAnnouncementMutation,
} from 'graphql/graphql';
import useRolePermissions from 'hooks/useRolePermissions';
import { useEffect } from 'react';
import AnnouncementItem from './AnnouncementItem';

interface Props {
  open: boolean;
  onClose: () => void;
  onOpen: () => void;
  hasBeenShownThisSession: boolean;
}

const AnnouncementsDialog = ({
  open,
  onClose,
  onOpen,
  hasBeenShownThisSession,
}: Props) => {
  const { data, refetch } = useGetAnnouncementsQuery();
  const [saveAnnouncement] = useSaveAnnouncementMutation();
  const [deleteAnnouncement] = useDeleteAnnouncementMutation();
  const { canAddEditAnnouncements } = useRolePermissions();

  const announcements = data?.Pipeline_allAnnouncements ?? [];
  const announcementsVisibleToUser = canAddEditAnnouncements
    ? announcements
    : announcements.filter((announcement) => announcement.visible);

  useEffect(() => {
    // Open the dialog if there are any announcements that haven't been seen by the user, and we haven't
    // already shown the dialog this session
    if (
      !open &&
      !hasBeenShownThisSession &&
      announcementsVisibleToUser.some(
        (announcement) => !announcement.seenByUser
      )
    ) {
      onOpen();
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [announcementsVisibleToUser]);

  const onNewAnnouncement = async () => {
    // Create a new, empty announcement
    await saveAnnouncement({
      variables: {
        announcement: {
          title: 'New Announcement',
          type: Pipeline_AnnouncementType.Announcement,
          overview: 'This is a new announcement.',
          date: new Date(),
          visible: false,
        },
      },
    });
    void refetch();
  };

  const onSaveAnnouncement = async (announcement: Pipeline_Announcement) => {
    const { seenByUser: ignored, ...dto } = announcement;
    await saveAnnouncement({
      variables: {
        announcement: dto,
      },
    });
    void refetch();
  };

  const onDeleteAnnouncement = async (id: string) => {
    await deleteAnnouncement({ variables: { id } });
    void refetch();
  };

  return (
    <DraggableDialog
      title="Announcements"
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'sm', fullWidth: true }}
      allowClose
    >
      {canAddEditAnnouncements && (
        <StyledButton
          styleName="primary"
          fullWidth
          onClick={() => {
            void onNewAnnouncement();
          }}
        >
          New Announcement
        </StyledButton>
      )}
      {announcementsVisibleToUser.map((item) => (
        <AnnouncementItem
          key={item.id}
          announcement={item}
          onSaveAnnouncement={(newAnnouncement) =>
            void onSaveAnnouncement(newAnnouncement)
          }
          onDeleteAnnouncement={() => void onDeleteAnnouncement(item.id!)}
        />
      ))}
    </DraggableDialog>
  );
};

export default AnnouncementsDialog;
