import { Grid, Stack } from '@mui/material';
import Paper from '@mui/material/Paper';
import { styled } from '@mui/material/styles';
import DraggableDialog from 'components/DraggableDialog';
import keyboardShortcuts from '../../constants/hotkeys';
import { capitalize } from '../../utils/helpers';
import styles from './index.module.scss';

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
  ...theme.typography.body2,
  padding: theme.spacing(1),
  textAlign: 'center',
  color: theme.palette.text.secondary,
}));

interface Props {
  open: boolean;
  onClose: () => void;
}

const KeyboardShortcutDialog = ({ open, onClose }: Props) => {
  return (
    <>
      <DraggableDialog
        title={`Keyboard Shortcuts`}
        open={open}
        onClose={onClose}
        dialogProps={{ maxWidth: 'sm', fullWidth: true }}
        allowClose
        className={styles.dialog}
      >
        <Stack component="form">
          <div className={styles['grid-style']}>
            <Grid
              container
              rowSpacing={1}
              columnSpacing={{ xs: 1, sm: 2, md: 3 }}
            >
              {Object.entries(keyboardShortcuts).map(([key, keyValue]) => {
                return (
                  <Grid item xs={6} key={key}>
                    <Item>
                      {capitalize(keyValue.label.replace(/_/g, ' '))}:{' '}
                      {keyValue.shortcut}
                    </Item>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Stack>
      </DraggableDialog>
    </>
  );
};

export default KeyboardShortcutDialog;
