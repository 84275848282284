import { Options, useHotkeys as reactUseHotkeysHook } from 'react-hotkeys-hook';
import { HotkeyCallback, HotkeysEvent } from 'react-hotkeys-hook/dist/types';
import useGraphLockStatus from './useGraphLockStatus';

/**
 * Assigns a callback to a key combination.
 * @param keys - The key combination string - something like 'Ctrl+Shiftt+A'
 * @param callback - The callback itself
 * @param deps - A dependencies array for updating the callback, as the callback is wrapped in useCallback
 * @param preventDefault - If true, prevents the event from propagating up to the default browser handler
 * @param disallowWhenLocked - If true, disables the hotkey when the useGraphLockStatus().graphIsLocked is true
 * @param options - Options object passed through to react-hotkeys-hook
 */
export default function useHotkeys(
  keys: string,
  callback: HotkeyCallback,
  deps: any[],
  preventDefault = true,
  disallowWhenLocked = true,
  options?: Options
) {
  const { graphIsLocked, graphIsInteractive } = useGraphLockStatus();
  const locked = graphIsLocked || !graphIsInteractive;

  const wrappedCallback: HotkeyCallback = (
    keyboardEvent: KeyboardEvent,
    hotkeysEvent: HotkeysEvent
  ) => {
    if (preventDefault) {
      keyboardEvent.preventDefault();
    }
    if (!disallowWhenLocked || !locked) {
      return callback(keyboardEvent, hotkeysEvent);
    }
  };
  return reactUseHotkeysHook(keys, wrappedCallback, options, [
    ...deps,
    disallowWhenLocked,
    locked,
  ]);
}
