import { httpLink } from 'App';

import { createContext, ReactElement, useState } from 'react';

interface IConnectivityContext {
  isConnected: boolean;
  setNotConnected: () => void;
  checkConnection: () => void;
  isCheckingConnection: boolean;
  graphQLUri: string;
}

export const ConnectivityContext = createContext<IConnectivityContext>(
  undefined!
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const graphQLUri = String(httpLink.options.uri);
  const [isConnected, setIsConnected] = useState(true);
  const [isCheckingConnection, setIsCheckingConnection] = useState(false);

  const checkConnection = () => {
    setIsCheckingConnection(true);

    fetch(graphQLUri)
      .then(() => setIsConnected(true))
      .catch(() => setIsConnected(false))
      .finally(() => setIsCheckingConnection(false));
  };

  const setNotConnected = () => setIsConnected(false);

  return (
    <ConnectivityContext.Provider
      value={{
        isConnected,
        setNotConnected,
        isCheckingConnection,
        checkConnection,
        graphQLUri,
      }}
    >
      {children}
    </ConnectivityContext.Provider>
  );
};
