import {
  faFileArrowDown,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup } from '@mui/material';
import { Container } from '@mui/system';
import StyledButton from 'components/StyledButton';
import { Blob } from 'contexts/Graph';
import { Mat } from 'cv-homography';
import { Pipeline_PointCorrespondenceTemplate } from 'graphql/graphql';
import useNotifications from 'hooks/useNotifications';
import useTextFileIO from 'hooks/useTextFileIO';
import {
  lazy,
  Suspense,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useResizeDetector } from 'react-resize-detector';
import { useDebouncedCallback } from 'use-debounce';
import { nearestCoordinateIndex } from 'utils/konvaGraphUtils';
import { findTransformMatrix, transformPoints } from 'utils/lazyHomography';
import { flatCoordsToVec2, Vec2 } from 'utils/Vec2';
import {
  buildPointCorrespondence,
  correspondenceToGraphState,
  correspondenceToJsonString,
  getCorrespondenceTemplateMisalignmentError,
  jsonStringToCorrespondence,
  PointCorrespondence,
} from '../../types';
import styles from './index.module.scss';
import TemplateSelectorFallback from './TemplateSelector/Fallback';
import useCorrespondence from './useCorrespondence';
import VideoFrameSelectorFallback from './VideoFrameSelector/Fallback';

const PointCorrespondenceSelectorVideoFrame = lazy(
  () => import('./VideoFrameSelector')
);
const PointCorrespondenceSelectorTemplate = lazy(
  () => import('./TemplateSelector')
);

interface Props {
  selectedTemplate: Pipeline_PointCorrespondenceTemplate | undefined;
  correspondence: ReturnType<typeof useCorrespondence>;
  videoBlob: Blob | undefined;
  setCorrespondence: (correspondence: PointCorrespondence | undefined) => void;
  worldOrigin: Vec2 | undefined;
  setWorldOrigin: (origin: Vec2 | undefined) => void;
  transPoints: Vec2[];
  setTransPoints: (points: Vec2[]) => void;
  onVideoError: () => void;
}
const PointCorrespondenceBuilder = ({
  selectedTemplate,
  correspondence,
  videoBlob,
  setCorrespondence,
  worldOrigin,
  setWorldOrigin,
  transPoints,
  setTransPoints,
  onVideoError,
}: Props) => {
  const { download: exportCorrespondence, upload: importCorrespondence } =
    useTextFileIO('json');
  const { error } = useNotifications();
  const { height, width, ref } = useResizeDetector();
  const [transMat, setTransMat] = useState<Mat | undefined>();

  const handleExportCorrespondence = useCallback(() => {
    if (selectedTemplate && worldOrigin) {
      // {templateName}-yyyy-mm-dd_hh-mm.json
      const dateTime = new Date(
        Date.now() - new Date().getTimezoneOffset() * 1000 * 60
      )
        .toJSON()
        .slice(0, 16)
        .replace('T', '_')
        .replaceAll(':', '-');
      exportCorrespondence(
        correspondenceToJsonString(
          buildPointCorrespondence(
            correspondence.nodes,
            worldOrigin,
            selectedTemplate
          )
        ),
        `${selectedTemplate.name}-${dateTime}.json`
      );
    }
  }, [
    selectedTemplate,
    exportCorrespondence,
    correspondence.nodes,
    worldOrigin,
  ]);

  useEffect(() => {
    if (selectedTemplate && worldOrigin) {
      setCorrespondence(
        buildPointCorrespondence(
          correspondence.nodes,
          worldOrigin,
          selectedTemplate
        )
      );
    } else {
      setCorrespondence(undefined);
    }
  }, [correspondence.nodes, worldOrigin, selectedTemplate, setCorrespondence]);

  const handleImportCorrespondence = useCallback(() => {
    const imp = async () => {
      try {
        const correspondenceStr = await importCorrespondence();
        const imported = jsonStringToCorrespondence(correspondenceStr);
        if (imported) {
          const err = getCorrespondenceTemplateMisalignmentError(
            imported,
            selectedTemplate!
          );
          if (err) {
            const errDetail =
              imported.templateName !== selectedTemplate?.name
                ? `Please select the template "${imported.templateName}" to import this correspondence.`
                : `The template "${imported.templateName}" has been updated since the imported correspondence was created.`;
            error(`${err} ${errDetail}`, {
              autoClose: 5000,
            });
          } else {
            // Switch out the state with that imported
            setWorldOrigin(imported.templateWorldOrigin);
            correspondence.deleteNodes(Object.keys(correspondence.nodes));
            const [nodeNodes] = correspondenceToGraphState(imported);
            Object.entries(nodeNodes).forEach(([id, node]) => {
              correspondence.addNode([], node.pixelPos, true, id);
            });
            setCorrespondence(imported);
          }
        } else {
          throw new Error('Invalid correspondence file');
        }
      } catch (err) {
        error(String(err));
      }
    };
    void imp();
  }, [
    correspondence,
    error,
    importCorrespondence,
    selectedTemplate,
    setCorrespondence,
    setWorldOrigin,
  ]);

  const buildTransMat = useDebouncedCallback(
    async () => {
      if (
        selectedTemplate?.pixelCoordinates &&
        selectedTemplate?.worldOrigin &&
        Object.keys(correspondence.nodes).length >= 4
      ) {
        const srcPoints = flatCoordsToVec2(selectedTemplate.pixelCoordinates);
        const sourcePoints: Vec2[] = [];
        const targetPoints: Vec2[] = [];
        srcPoints.forEach((p, i) => {
          if (correspondence.nodes[i]) {
            sourcePoints.push(p);
            targetPoints.push(correspondence.nodes[i].pixelPos);
          }
        });
        const mat = await findTransformMatrix(sourcePoints, targetPoints);
        setTransMat(mat);
      } else {
        setTransMat(undefined);
      }
    },
    250,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    void buildTransMat();
  }, [buildTransMat, correspondence.nodes, selectedTemplate?.pixelCoordinates]);

  useEffect(() => {
    const run = async () => {
      if (
        transMat &&
        selectedTemplate?.pixelCoordinates &&
        worldOrigin &&
        Object.keys(correspondence.nodes).length >= 4
      ) {
        const srcPoints = flatCoordsToVec2(selectedTemplate.pixelCoordinates);
        setTransPoints(await transformPoints(srcPoints, transMat));
      } else {
        setTransPoints([]);
      }
    };
    void run();
  }, [
    correspondence.nodes,
    selectedTemplate,
    transMat,
    worldOrigin,
    setTransPoints,
  ]);

  const transPixelOrigin = useMemo(() => {
    if (worldOrigin && selectedTemplate) {
      const vec2WorldCoords = flatCoordsToVec2(
        selectedTemplate.worldCoordinates
      );
      const idx = nearestCoordinateIndex(worldOrigin, vec2WorldCoords);
      return correspondence.nodes[idx]?.pixelPos;
    }
  }, [correspondence.nodes, selectedTemplate, worldOrigin]);

  const componentWidth = (width || 400) / 2;
  return (
    <Container maxWidth="xl" disableGutters>
      <div className={styles['button-bar']}>
        <ButtonGroup>
          <StyledButton
            styleName="secondary"
            startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
            title="Import a correspondence from file"
            onClick={handleImportCorrespondence}
          >
            Import Correspondence
          </StyledButton>
          <StyledButton
            styleName="secondary"
            startIcon={<FontAwesomeIcon icon={faFileArrowDown} />}
            title="Export the selected correspondence to file"
            onClick={handleExportCorrespondence}
            disabled={!(selectedTemplate && worldOrigin)}
          >
            Export Correspondence
          </StyledButton>
        </ButtonGroup>
      </div>

      <Container
        ref={ref}
        maxWidth="xl"
        classes={{ root: styles.container }}
        disableGutters
      >
        <Suspense
          fallback={
            <TemplateSelectorFallback height={height} width={componentWidth} />
          }
        >
          <PointCorrespondenceSelectorTemplate
            pixelCoordinates={selectedTemplate?.pixelCoordinates}
            worldCoordinates={selectedTemplate?.worldCoordinates}
            worldOrigin={worldOrigin}
            setWorldOrigin={setWorldOrigin}
            edges={selectedTemplate?.edges}
            svgData={selectedTemplate?.svgData}
            height={height}
            width={componentWidth}
            selectNode={(id) => correspondence.setNodeSelected(id)}
            selectedNodeIds={correspondence.selectedNodeIds}
            correspondence={correspondence}
          />
        </Suspense>
        <Suspense
          fallback={
            <VideoFrameSelectorFallback
              height={height}
              width={componentWidth}
            />
          }
        >
          <PointCorrespondenceSelectorVideoFrame
            pixelOrigin={transPixelOrigin}
            edges={selectedTemplate?.edges}
            svgData={selectedTemplate?.svgData}
            correspondence={correspondence}
            height={height}
            width={componentWidth}
            videoBlob={videoBlob}
            seekSeconds={1}
            selectNode={(id) => correspondence.setNodeSelected(id)}
            transPoints={transPoints}
            transMat={transMat}
            onVideoError={onVideoError}
          />
        </Suspense>
      </Container>
    </Container>
  );
};

export default PointCorrespondenceBuilder;
