import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DelayedTooltip from 'components/DelayedTooltip';
import { PipelineConfigType } from 'graph/types';
import useRolePermissions from 'hooks/useRolePermissions';
import { lazy, ReactElement, Suspense } from 'react';
import { nullOrUndef } from 'utils/helpers';
import BooleanInput from './BooleanInput';
import ColourInput from './ColourInput';
import CommaSeparatedNumbersInput from './CommaSeparatedNumbersInput';
import styles from './index.module.scss';
import MarkdownInputFallback from './MarkdownInput/Fallback';
import NumericInput from './NumericInput';
import PointCorrespondenceInput from './PointCorrespondenceInput';
import SelectInput from './SelectInput';
import SliderInput from './SliderInput';
import StringArrayInput from './StringArrayInput';
import TextInput from './TextInput';

const MarkdownInput = lazy(() => import('./MarkdownInput'));

interface Props {
  nodeId: string;
  configKey: string;
  configType: PipelineConfigType;
  configValue: any | undefined;
  setConfigValue: (value: any) => void;
}

const ConfigInput = ({
  nodeId,
  configKey,
  configType,
  configValue,
  setConfigValue,
}: Props) => {
  const { canAccessApi } = useRolePermissions();

  const makeSingleComponent = (
    value: any,
    setValue: (value: any) => void,
    labelOverride?: string
  ): ReactElement => {
    const commonProps = {
      nodeId,
      value,
      setValue,
      configKey,
      configType,
    };
    // prettier-ignore
    if (configType.options) {
      return (
        <SelectInput
          {...commonProps}
          labelOverride={labelOverride}
          />
      );
    }
    // prettier-ignore
    switch (configType.key) {
      case PipelineConfigType.COLOUR().key:
        return (
          <ColourInput
          {...commonProps}
          labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.NORM_DECIMAL().key:
      case PipelineConfigType.DECIMAL().key:
      case PipelineConfigType.INTEGER().key:
        if (!nullOrUndef(configType.sliderStep)) {
          return (
            <SliderInput
              {...commonProps}
              labelOverride={labelOverride}
            />
          );
        }
        return (
          <NumericInput
            {...commonProps}
            labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.URL().key:
      case PipelineConfigType.STRING().key:
        return (
          <TextInput
            {...commonProps}
            labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.BOOLEAN().key:
        return (
          <BooleanInput
            {...commonProps}
            labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.POINT_CORRESPONDENCE().key:
        return (
          <PointCorrespondenceInput
            {...commonProps}
            labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.STRING_ARRAY().key:
        return (
          <StringArrayInput
            {...commonProps}
            labelOverride={labelOverride}
          />
        );
      case PipelineConfigType.MARKDOWN().key: {
        return <Suspense fallback={<MarkdownInputFallback />}><MarkdownInput {...commonProps} /></Suspense>;
      }
      case PipelineConfigType.COMMA_SEPARATED_INTEGERS().key: {
        return <CommaSeparatedNumbersInput {...commonProps} labelOverride={labelOverride} integerOnly uniqueOnly />
      }
      case PipelineConfigType.COMMA_SEPARATED_NORM_DECIMALS().key: {
        return <CommaSeparatedNumbersInput {...commonProps} labelOverride={labelOverride} />
      }
      default:
        return <span />;
    }
  };
  if (configType.hidden) {
    return null;
  }

  const makeTupleComponent = () => {
    // A tuple component is basically a bunch of inputs stacked l-r, setting the elements
    // in an array. For this reason, tuples should be only of length 2-3.
    if (configType.tupleFields) {
      return configType.tupleFields.map((name, idx) => {
        const value = configValue[idx];

        const setValue = (newVal: any) => {
          const localVal = [...configValue];
          localVal[idx] = newVal;
          setConfigValue(localVal);
        };
        return (
          <span key={`${name}_${idx}`}>
            {makeSingleComponent(value, setValue, name)}
          </span>
        );
      });
    }
    return <span>Something went wrong!</span>;
  };

  const makeComponent = () => {
    // Make multiple inputs if it's a tuple
    if (configType.tupleFields) {
      return makeTupleComponent();
    }
    // Otherwise make a single one
    return makeSingleComponent(configValue, setConfigValue);
  };

  let tooltip = configType.description;
  if (canAccessApi) {
    // Strip the final period from the description, if it exists
    tooltip = tooltip.replace(/\.$/, '');
    tooltip = `${tooltip}.\nConfig key: ${configKey}`;
  }

  return (
    <div className={styles.container}>
      {makeComponent()}
      <DelayedTooltip title={tooltip}>
        <span className={styles['info-btn']}>
          <FontAwesomeIcon icon={faInfoCircle} color="grey" />
        </span>
      </DelayedTooltip>
    </div>
  );
};

export default ConfigInput;
