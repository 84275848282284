import {
  Pipeline_PointCorrespondenceTemplate,
  Pipeline_PointCorrespondenceTemplateDto,
  useDeletePointCorrespondenceTemplateMutation,
  useSavePointCorrespondenceTemplateMutation,
} from 'graphql/graphql';
import useConfirmDialog from 'hooks/useConfirmDialog';
import { useCallback, useState } from 'react';
import { useWizard } from 'react-use-wizard';
import { IEdge, INodeMap, pixelsToWorld } from 'utils/konvaGraphUtils';
import { pairListToFlatEdges, vec2ToFlatCoords } from 'utils/Vec2';
import { PointCorrespondence } from '../types';
import PointCorrespondenceTemplateBuilder from './TemplateBuilder';
import PointCorrespondenceTemplateSelector from './TemplateSelector';
interface Props {
  correspondenceTemplates: Pipeline_PointCorrespondenceTemplate[] | undefined;
  refetchTemplates: () => void;
  selectedTemplate: Pipeline_PointCorrespondenceTemplate | undefined;
  selectTemplateById: (id: string | null) => void;
  cancelSelectingTemplate: () => void;
  existingCorrespondence: PointCorrespondence | undefined;
}

export const assembleTemplateDto = (
  id: string | undefined | null,
  name: string,
  description: string,
  nodes: INodeMap,
  edges: IEdge[],
  worldOrigin: [number, number]
): Pipeline_PointCorrespondenceTemplateDto => {
  const nodeIds = Object.keys(nodes);
  const pixelCoords = nodeIds.map((id) => nodes[id].pixelPos);
  const worldCoords = pixelCoords.map(pixelsToWorld);
  const edgePairs: [number, number][] = edges.map((edge) => [
    nodeIds.indexOf(edge.source),
    nodeIds.indexOf(edge.target),
  ]);
  const flatPixelCoords = vec2ToFlatCoords(pixelCoords);
  const flatWorldCoords = vec2ToFlatCoords(worldCoords);
  const flatEdgePairs = pairListToFlatEdges(edgePairs);

  return {
    id: id ?? null,
    name,
    description,
    pixelCoordinates: flatPixelCoords,
    worldCoordinates: flatWorldCoords,
    worldOrigin,
    edges: flatEdgePairs,
  };
};

const StepOne = ({
  correspondenceTemplates,
  refetchTemplates,
  selectedTemplate,
  selectTemplateById,
  existingCorrespondence,
  cancelSelectingTemplate,
}: Props) => {
  const { nextStep } = useWizard();
  if (existingCorrespondence) {
    void nextStep();
  }

  const [editingTemplate, setEditingTemplate] = useState(false);
  const [templateToEdit, setTemplateToEdit] = useState<
    Pipeline_PointCorrespondenceTemplate | undefined
  >();
  const { triggerDialog } = useConfirmDialog();

  const cancelEditTemplate = useCallback(() => {
    setEditingTemplate(false);
    setTemplateToEdit(undefined);
  }, []);

  const [saveTemplate] = useSavePointCorrespondenceTemplateMutation();

  const [deleteTemplate] = useDeletePointCorrespondenceTemplateMutation();

  const onSaveTemplate = useCallback(
    (template: Pipeline_PointCorrespondenceTemplateDto) => {
      saveTemplate({
        variables: {
          pointCorrespondenceTemplate: template,
        },
      })
        .then((res) => {
          refetchTemplates();
          const id = res.data?.Pipeline_savePointCorrespondenceTemplate?.id;
          if (id) {
            selectTemplateById(id);
          }
          cancelEditTemplate();
        })
        .catch((err) => console.error(err));
    },
    [cancelEditTemplate, refetchTemplates, saveTemplate, selectTemplateById]
  );

  if (editingTemplate) {
    return (
      <PointCorrespondenceTemplateBuilder
        templateToEdit={templateToEdit}
        onSubmit={onSaveTemplate}
        onCancel={cancelEditTemplate}
      />
    );
  }

  return (
    <PointCorrespondenceTemplateSelector
      correspondenceTemplates={correspondenceTemplates}
      selectedTemplate={selectedTemplate}
      selectTemplateById={selectTemplateById}
      editTemplate={(template) => {
        setTemplateToEdit(template);
        setEditingTemplate(true);
      }}
      deleteTemplate={(id) => {
        const del = () => {
          void deleteTemplate({ variables: { id } }).then(() => {
            refetchTemplates();
          });
        };
        triggerDialog({
          title: 'Confirm Deletion',
          message:
            'Are you sure you want to delete this template? This cannot be undone.',
          confirmButtonText: 'Delete',
          onConfirm: del,
        });
      }}
      onImportTemplate={onSaveTemplate}
      onSubmit={() => void nextStep()}
      onCancel={cancelSelectingTemplate}
    />
  );
};

export default StepOne;
