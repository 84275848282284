import { useEffect, useState } from 'react';

interface Props {
  percent: number | undefined;
}

const FaviconProgress = ({ percent }: Props) => {
  const [favicons, setFavicons] = useState<Element[]>([]);

  useEffect(() => {
    if (favicons.length === 0) {
      // Grab all icons
      const icons = Array.from(document.querySelectorAll('link[rel*="icon"]'));

      icons.forEach((icon) => {
        const originalHref = icon.getAttribute('href');
        // Store the original href on the element itself
        if (originalHref) {
          icon.setAttribute('data-original-href', originalHref);
        }
      });
      setFavicons(icons);
    }
    return () => {
      // Reset the favicons on unmount
      favicons.forEach((favicon) => {
        const originalHref = favicon.getAttribute('data-original-href');
        if (originalHref) {
          favicon.setAttribute('href', originalHref);
        }
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (favicons.length > 0) {
      if (!percent || percent === 1) {
        // Reset favicon
        favicons.forEach((favicon) => {
          const originalHref = favicon.getAttribute('data-original-href');
          if (originalHref) {
            favicon.setAttribute('href', originalHref);
          }
        });
      } else {
        // Draw progress bar on favicon
        favicons.forEach((favicon) => {
          drawProgressOnFavicon(favicon, percent);
        });
      }
    }
  }, [favicons, percent]);

  return null;
};

const drawProgressOnFavicon = (favicon: Element, percent: number) => {
  const canvas = document.createElement('canvas');
  const originalHref = favicon.getAttribute('data-original-href');
  if (!originalHref) {
    return;
  }

  // Load the favicon image and draw it to the canvas
  const img = new Image();
  img.onload = () => {
    // Set the canvas size to the favicon size
    canvas.width = img.width;
    canvas.height = img.height;
    // Draw the favicon image to the canvas
    const context = canvas.getContext('2d');

    if (context) {
      context.drawImage(img, 0, 0);

      // Draw the progress bar in yellow
      context.fillStyle = '#ffc709';
      const lineThickness = canvas.width / 16;

      // The progress bar runs clockwise around the border of the icon from the top-left,
      // so we just draw them in order, subtracting the "used up" length so far.
      const totalBorderLength = 2 * (canvas.width + canvas.height);
      const completionLength = totalBorderLength * percent!;
      let remainingLength = completionLength;
      // Top border
      const topLength = Math.min(remainingLength, canvas.width);
      context.fillRect(0, 0, topLength, lineThickness);
      remainingLength -= topLength;
      // Right border
      const rightLength = Math.min(remainingLength, canvas.height);
      context.fillRect(
        canvas.width - lineThickness,
        0,
        lineThickness,
        rightLength
      );
      remainingLength -= rightLength;
      // Bottom border
      const bottomLength = Math.min(remainingLength, canvas.width);
      context.fillRect(
        canvas.width - bottomLength,
        canvas.height - lineThickness,
        bottomLength,
        lineThickness
      );
      remainingLength -= bottomLength;
      // Left border
      const leftLength = Math.min(remainingLength, canvas.height);
      context.fillRect(
        0,
        canvas.height - leftLength,
        lineThickness,
        leftLength
      );

      // Replace favicon
      favicon.setAttribute('href', canvas.toDataURL('image/png'));
    }
  };
  img.src = originalHref;
};

export default FaviconProgress;
