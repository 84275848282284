import { ReactElement, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { useGetGraphsSummaryQuery } from 'graphql/graphql';
import useAuth from 'hooks/useAuth';
/**
 * Ask the server for a graph with a running job for the current user, and redirect
 * the user to that graph, if found. This prevents the user from having multiple jobs
 * running at once, as they're forced to return to the "graph processing" screen.
 */

interface Props {
  children: ReactElement[] | ReactElement;
}

const ActiveJobRouter = ({ children }: Props) => {
  const { pathname } = useLocation();
  const { isPipeliner } = useAuth();
  const navigate = useNavigate();
  const { refetch } = useGetGraphsSummaryQuery({
    variables: { active: true },
    skip: !isPipeliner,
  });

  // Fetch the graph for this user with an active job, if any. This is triggered whenever we change route
  useEffect(() => {
    const checkForActiveJobs = async () => {
      try {
        const res = await refetch();
        const activeGraphs = res?.data?.Pipeline_allGraphs;
        if (activeGraphs && activeGraphs.length > 0) {
          navigate(`/graph/${activeGraphs[0].id}`);
        }
      } catch (e) {
        console.error(e);
      }
    };
    void checkForActiveJobs();
  }, [navigate, pathname]); // eslint-disable-line react-hooks/exhaustive-deps

  return <>{children}</>;
};

export default ActiveJobRouter;
