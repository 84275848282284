import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Pattern Linearity Filter',
  description:
    "Filter out calibration pattern detections that don't meet linearity thresholds.",
  descriptionDetail:
    'Low-quality calibration pattern detections can diminish the accuracy of estimated camera parameters. This node removes pattern detections that appear not to be colinear - i.e. those with poor detections.',
  icon: faFilter,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'CalibrationPatternLinearityRefiner',
  configTypes: [
    {
      key: 'threshold',
      type: PipelineConfigType.DECIMAL('Linearity threshold')
        .REQUIRED.DEFAULT(0.25)
        .MIN(0.01)
        .MAX(1)
        .SLIDER(0.05)
        .DESCRIPTION(
          'The maximum allowable error for a line of best fit on each column and row.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED,
    },
    {
      key: 'world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED,
    },
  ],
};
