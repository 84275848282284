import { Backdrop, Typography } from '@mui/material';
import useGraphLockStatus from 'hooks/useGraphLockStatus';
import Spinner from 'components/Spinner';
import styles from './index.module.scss';
import JobProgressOverlay from './JobProgressOverlay';

const GraphLockOverlay = () => {
  const { graphIsLocked, lockReason } = useGraphLockStatus();

  return (
    <Backdrop open={graphIsLocked} className={styles['overlay']}>
      {lockReason === 'job_running' ? (
        <JobProgressOverlay />
      ) : (
        <div>
          <Typography variant="h3" className={styles['overlay-text']}>
            Loading...
          </Typography>

          <Typography variant="h5" className={styles['overlay-text']}>
            Please wait a moment
          </Typography>

          <Spinner />
        </div>
      )}
    </Backdrop>
  );
};

export default GraphLockOverlay;
