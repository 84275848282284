import { faFloppyDisk } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Autocomplete,
  createFilterOptions,
  IconButton,
  InputAdornment,
  TableCell,
  TableRow,
  TextField,
} from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { useState } from 'react';
import { frameNumberToTimecode } from '../../utils';
import { FrameLabel } from '../types';
import styles from './index.module.scss';

interface Props {
  existingLabels: FrameLabel[];
  timestamp: number;
  videoFrameRate: number;
  addFrameLabel: (frameLabel: FrameLabel) => void;
}
const filter = createFilterOptions<string>();

const CreateFrameLabelRow = ({
  existingLabels,
  timestamp,
  videoFrameRate,
  addFrameLabel,
}: Props) => {
  const [label, setLabel] = useState<string | null>(null);

  const labelOptions = Array.from(
    new Set(existingLabels.map((label) => label[1]))
  );

  const frameNumber = Math.floor(timestamp * videoFrameRate);

  return (
    <TableRow>
      <TableCell component="th" scope="row">
        {frameNumberToTimecode(frameNumber, videoFrameRate)}
      </TableCell>
      <TableCell
        classes={{
          root: styles['input-cell'],
        }}
      >
        <div>
          <DelayedTooltip
            title={`Enter a label for the current frame, e.g., "toe off" or "heel strike"`}
          >
            <Autocomplete
              className="nodrag"
              classes={{ inputRoot: styles.autocomplete }}
              size="small"
              filterOptions={(options, params) => {
                const filtered = filter(options, params);
                const { inputValue } = params;
                if (!inputValue) return filtered;
                if (
                  !labelOptions.some((value: string) => value === inputValue)
                ) {
                  filtered.push(inputValue);
                }
                return filtered;
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  variant="standard"
                  placeholder="Enter frame label..."
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          onClick={() => {
                            if (label) {
                              addFrameLabel([frameNumber, label]);
                              setLabel(null);
                            }
                          }}
                          edge="end"
                          disabled={!label}
                          color={label ? 'primary' : 'default'}
                        >
                          <FontAwesomeIcon icon={faFloppyDisk} />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              )}
              autoSelect
              blurOnSelect
              value={label ?? null}
              onChange={(event, newValue) => {
                setLabel(newValue ?? null);
              }}
              options={labelOptions}
            />
          </DelayedTooltip>
        </div>
      </TableCell>
    </TableRow>
  );
};

export default CreateFrameLabelRow;
