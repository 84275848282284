import { faClipboard } from '@fortawesome/free-regular-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Alert,
  FormControl,
  FormControlLabel,
  FormLabel,
  IconButton,
  InputAdornment,
  Radio,
  RadioGroup,
  TextField,
} from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import MaxLengthTextField from 'components/MaxLengthTextField';
import copy from 'copy-to-clipboard';
import useNotifications from 'hooks/useNotifications';
import useRolePermissions from 'hooks/useRolePermissions';
import { useEffect, useState } from 'react';
import { PrivacySetting } from '../../graphql/graphql';
import styles from './index.module.scss';

interface Props {
  id?: string | undefined;
  name: string | undefined;
  setName: (value: string) => void;
  description: string;
  setDescription: (value: string) => void;
  privacy?: PrivacySetting;
  setPrivacy?: (value: PrivacySetting) => void;
  flashPrivacy?: boolean;
}

export const MAX_NAME_LEN = 128;
const MAX_DESCRIP_LEN = 1024;

const GraphMetaForm = ({
  id,
  name,
  setName,
  description,
  setDescription,
  privacy,
  setPrivacy,
  flashPrivacy,
}: Props) => {
  const notify = useNotifications();
  const { canSetGraphAsTemplate } = useRolePermissions();
  const [publicUrl, setPublicUrl] = useState('');

  useEffect(() => {
    if (id) {
      setPublicUrl(new URL(`/graph/${id}`, window.location.origin).toString());
    }
  }, [id]);

  const nameStr = name ?? '';
  const nameModified = typeof name !== 'undefined';
  const nameMissing = !(name && name.length > 0);

  const copyToClipboard = () => {
    if (copy(publicUrl)) {
      notify.success('Copied to clipboard');
    } else {
      notify.error('Failed to copy to clipboard - please copy manually.');
    }
  };

  return (
    <div>
      <MaxLengthTextField
        label="Pipeline Name"
        placeholder="E.g. Pose Detection"
        value={nameStr}
        onChange={(e) => setName(e.target.value as string)}
        maxLength={MAX_NAME_LEN}
        error={nameMissing && nameModified}
        helperText={nameMissing && nameModified && 'Must provide a name'}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
      />

      <MaxLengthTextField
        label="Pipeline Description"
        placeholder="E.g. Track a person and detect their pose."
        value={description}
        onChange={(e) => setDescription(e.target.value as string)}
        maxLength={MAX_DESCRIP_LEN}
        fullWidth
        margin="normal"
        InputLabelProps={{
          shrink: true,
        }}
        multiline
      />
      <div className={flashPrivacy ? styles['privacy-flash'] : ''}>
        {privacy && (
          <FormControl>
            <FormLabel id="privacy-radiobuttons">Privacy</FormLabel>
            <RadioGroup
              row
              aria-labelledby="privacy-radiobuttons"
              value={privacy?.toString()}
              onChange={(e) => setPrivacy?.(e.target.value as PrivacySetting)}
            >
              <FormControlLabel
                value={PrivacySetting.Private.toString()}
                control={<Radio />}
                label={PrivacySetting.Private.toString()}
              />
              <FormControlLabel
                value={PrivacySetting.Public.toString()}
                control={<Radio />}
                label={PrivacySetting.Public.toString()}
              />
              <FormControlLabel
                value={PrivacySetting.Template.toString()}
                control={<Radio />}
                label={PrivacySetting.Template.toString()}
                disabled={!canSetGraphAsTemplate}
              />
            </RadioGroup>
          </FormControl>
        )}
        {privacy &&
          id &&
          [PrivacySetting.Public, PrivacySetting.Template].includes(
            privacy
          ) && (
            <div>
              <Alert severity="info">
                {privacy === PrivacySetting.Public
                  ? `With your pipeline set to public, you can share it with other users
                by copying the following URL. This will allow them to create a
                duplicate pipeline using yours as a template, but won't allow
                them to modify or view your inputs or outputs.`
                  : `This is a template pipeline - changing its privacy will remove it
                  from the list of templates available to all users. Please be
                  careful when changing its privacy.`}
              </Alert>
              <TextField
                label={
                  privacy === PrivacySetting.Public
                    ? 'Public URL'
                    : 'Template URL'
                }
                fullWidth
                className={styles['url-field']}
                value={publicUrl}
                InputProps={{
                  endAdornment: (
                    <DelayedTooltip
                      title="Copy URL to clipboard"
                      enterDelay={250}
                    >
                      <InputAdornment position="end">
                        <IconButton onClick={copyToClipboard}>
                          <FontAwesomeIcon icon={faClipboard} />
                        </IconButton>
                      </InputAdornment>
                    </DelayedTooltip>
                  ),
                  readOnly: true,
                }}
              />
            </div>
          )}
      </div>
    </div>
  );
};

export default GraphMetaForm;
