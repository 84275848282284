import { faWaveSquare } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Pose Low-Pass Filter',
  description: 'Applies a low-pass filter to tracked pose.',
  descriptionDetail:
    'Given a collection of tracked bounding boxes and the associated pose, this node applies a 3rd order low-pass Butterworth filter both forwards and backwards.',
  icon: faWaveSquare,
  category: NodeCategory.TRACKING,
  moduleType: 'PoseLowPassFilter',
  configTypes: [
    {
      key: 'low_pass_hz',
      type: PipelineConfigType.DECIMAL('Low-pass cutoff (Hz)')
        .REQUIRED.DEFAULT(10.0)
        .MIN(1)
        .MAX(50)
        .SLIDER(1)
        .DESCRIPTION(
          'The cutoff frequency for the low-pass filter. Must be below the Nyquist frequency (half the framerate).'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.POSE_3D,
      ]),
    },
  ],
  outputTypes: [
    {
      key: 'filtered_pose',
      type: PipelineDataType.POSE_2D.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.POSE_3D,
      ]),
    },
  ],
};
