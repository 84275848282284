import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import useConfirmDialog from 'hooks/useConfirmDialog';
import styles from './index.module.scss';

type Props = {
  onDelete: (() => void) | undefined;
};

const Delete = ({ onDelete }: Props) => {
  const { triggerDialog } = useConfirmDialog();
  return (
    <IconButton
      size="small"
      onClick={() => {
        if (onDelete) {
          triggerDialog({
            onConfirm: onDelete,
            title: 'Delete Announcement',
            message:
              'Are you sure you want to delete this announcement? This cannot be undone.',
          });
        }
      }}
      title={'Delete Announcement'}
      className={styles['delete-btn']}
    >
      <FontAwesomeIcon icon={faTrash} />
    </IconButton>
  );
};

export default Delete;
