import { faVectorSquare } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Image Geometry Transformer',
  description: 'Applies geometric transformations to image frames.',
  descriptionDetail:
    'This node provides basic functionality for geometric image transformation.',
  icon: faVectorSquare,
  category: NodeCategory.VIDEO_UTILS,
  moduleType: 'ImageGeometryTransformer',
  configTypes: [
    {
      key: 'flip_horizontally',
      type: PipelineConfigType.BOOLEAN('Flip horizontally')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION('Whether to flip the video horizontally'),
    },
    {
      key: 'flip_vertically',
      type: PipelineConfigType.BOOLEAN('Flip vertically')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION('Whether to flip the video vertically'),
    },
    {
      key: 'rotation',
      type: PipelineConfigType.STRING('Rotation')
        .OPTIONS([null, 'cw', 'ccw'])
        .DEFAULT(null)
        .DESCRIPTION(
          'Rotation to perform on the video - clockwise (cw) or counter-clockwise (ccw).'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'transformed_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
