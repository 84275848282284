import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { dropzoneAcceptObject } from '../../graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';
import StyledButton, { ButtonStyleName } from '../StyledButton';
import styles from './index.module.scss';

interface Props {
  buttonTitle: string;
  onSelect: (filename: string, file: File) => void;
  fileFormat: Pipeline_BlobDataType;
  maxFiles?: number;
  disabled?: boolean;
  className?: string;
  styleName?: ButtonStyleName;
}

const FilePicker = ({
  buttonTitle,
  onSelect,
  fileFormat,
  maxFiles = 1,
  disabled,
  className = '',
  styleName = 'secondary',
}: Props) => {
  const accept = dropzoneAcceptObject(fileFormat);

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file: File) => {
        onSelect(file.name, file);
      });
    },
    [onSelect]
  );

  const { getRootProps, getInputProps, isDragAccept, isDragReject } =
    useDropzone({
      onDrop,
      accept,
      maxFiles,
    });

  let btnClass = className;
  if (isDragAccept) btnClass = `${btnClass} ${styles.accept}`;
  if (isDragReject) btnClass = `${btnClass} ${styles.reject}`;

  return (
    <span>
      <StyledButton
        className={btnClass}
        {...getRootProps()}
        ref={undefined}
        innerRef={getRootProps().ref}
        styleName={styleName}
        fullWidth
        disabled={disabled}
      >
        <input {...getInputProps()} disabled={disabled} />
        {buttonTitle}
      </StyledButton>
    </span>
  );
};

export default FilePicker;
