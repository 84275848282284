import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Tracked Bounding Box Writer',
  description:
    'Writes tracked bounding boxes to a CSV and makes it available for download.',
  descriptionDetail:
    'Given a collection of tracked bounding boxes, this node writes it to a CSV file in the cloud and makes it available for download via the browser.',
  icon: faFileExport,
  category: NodeCategory.OUTPUT,
  moduleType: 'TrackedBoundingBoxWriter',
  configTypes: [
    {
      key: 'blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}.csv').REQUIRED
        .HIDDEN,
    },
    {
      key: 'overwrite_existing',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
  ],
  inputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
    {
      key: 'world_positions',
      type: PipelineDataType.TRACKED_WORLD_POSITIONS_2D,
    },
    {
      key: 'velocities',
      type: PipelineDataType.VELOCITIES,
    },
    {
      key: 'cumulative_distances',
      type: PipelineDataType.CUMULATIVE_DISTANCES,
    },
  ],
  outputTypes: [
    {
      key: 'output_data',
      type: PipelineDataType.ANY.BLOB_DATATYPE(
        Pipeline_BlobDataType.Csv
      ).NO_HANDLE.LABEL('Output Data').DOWNLOADABLE,
    },
  ],
};
