import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from '@mui/material';
import StyledButton from 'components/StyledButton';
import { secondsToFrameNumber } from '../utils';
import CreateFrameLabelRow from './CreateFrameLabelRow';
import FrameLabelRow from './FrameLabelRow';
import { FrameLabel } from './types';

interface Props {
  frameLabels: FrameLabel[] | undefined;
  setFrameLabels: (labels: FrameLabel[]) => void;
  videoCurrentTime: number;
  videoFrameRate: number | undefined;
  jumpToFrame: (frame: number) => void;
}

const FrameLabelsTable = ({
  frameLabels,
  setFrameLabels,
  videoCurrentTime,
  videoFrameRate,
  jumpToFrame,
}: Props) => {
  frameLabels = frameLabels ?? [];

  const groupedFrameLabels: { [key: number]: FrameLabel[] } = {};
  frameLabels.forEach((frameLabel) => {
    const [frame, label] = frameLabel;
    if (!groupedFrameLabels[frame]) {
      groupedFrameLabels[frame] = [];
    }
    groupedFrameLabels[frame].push([frame, label]);
  });
  // Sort each frame's labels by label
  Object.keys(groupedFrameLabels).forEach((frame) => {
    groupedFrameLabels[Number(frame)].sort((a, b) => {
      return a[1].localeCompare(b[1]);
    });
  });

  const addFrameLabel = (frameLabel: FrameLabel) => {
    const newLabels = [...(frameLabels ?? [])];

    const existingIndex = newLabels.findIndex(
      (l) => l[0] === frameLabel[0] && l[1] === frameLabel[1]
    );
    if (existingIndex !== -1) {
      return;
    }

    newLabels.push(frameLabel);
    setFrameLabels(newLabels);
  };

  const removeFrameLabel = (frameLabel: FrameLabel) => {
    const newLabels = [...(frameLabels ?? [])];

    const existingIndex = newLabels.findIndex(
      (l) => l[0] === frameLabel[0] && l[1] === frameLabel[1]
    );
    if (existingIndex === -1) {
      return;
    }

    newLabels.splice(existingIndex, 1);
    setFrameLabels(newLabels);
  };

  return (
    <div>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell title="The time corresponding to the label(s)">
              Time
            </TableCell>
            <TableCell title="The label(s) describing an event in video frame">
              Label
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          <CreateFrameLabelRow
            existingLabels={frameLabels}
            timestamp={videoCurrentTime}
            videoFrameRate={videoFrameRate ?? 0}
            addFrameLabel={addFrameLabel}
          />
          {frameLabels.length > 0 && (
            <TableRow>
              <TableCell />
              <TableCell />
            </TableRow>
          )}
          {Object.entries(groupedFrameLabels).map(
            ([frame, thisFrameLabels]) => (
              <FrameLabelRow
                key={frame}
                frameLabels={thisFrameLabels}
                timestamp={videoCurrentTime}
                videoFrameRate={videoFrameRate ?? 0}
                removeFrameLabel={removeFrameLabel}
                duplicateFrameLabel={(frameLabel: FrameLabel) => {
                  addFrameLabel([
                    secondsToFrameNumber(videoCurrentTime, videoFrameRate ?? 0),
                    frameLabel[1],
                  ]);
                }}
                jumpToFrameLabel={(frameLabel) => jumpToFrame(frameLabel[0])}
              />
            )
          )}
          {frameLabels.length > 0 && (
            <TableRow>
              <TableCell colSpan={2}>
                <StyledButton
                  styleName="danger"
                  variant="outlined"
                  size="small"
                  fullWidth
                  onClick={() => setFrameLabels([])}
                >
                  Clear all frame labels
                </StyledButton>
              </TableCell>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </div>
  );
};

export default FrameLabelsTable;
