import {
  faBug,
  faPlus,
  faStar,
  faTrash,
  faWrench,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  TextField,
  ToggleButton,
  ToggleButtonGroup,
} from '@mui/material';
import StyledButton from 'components/StyledButton';
import { Pipeline_Announcement } from 'graphql/graphql';
import styles from './index.module.scss';

export type ChangeItem = {
  type: 'bugfix' | 'feature' | 'improvement';
  description: string;
};

const changeItemIcon = (type: ChangeItem['type']) => {
  switch (type) {
    case 'bugfix':
      return {
        color: '#ffc709',
        icon: faBug,
      };
    case 'feature':
      return {
        color: '#9ecf7c',
        icon: faStar,
      };
    case 'improvement':
      return {
        color: '#64cbe8',
        icon: faWrench,
      };
  }
};

const TYPE_LABELS: {
  [key in ChangeItem['type']]: string;
} = {
  bugfix: 'Bugfix',
  feature: 'Feature',
  improvement: 'Improvement',
};

const TypeEdit = ({
  type,
  onChangeType,
}: {
  type: ChangeItem['type'];
  onChangeType: (type: ChangeItem['type']) => void;
}) => {
  return (
    <ToggleButtonGroup
      size="small"
      value={type}
      exclusive
      classes={{ root: styles['typeedit-button-group'] }}
      onChange={(e, type) => {
        if (type) {
          onChangeType(type as ChangeItem['type']);
        }
      }}
      onClick={(e) => e.stopPropagation()}
    >
      {Object.entries(TYPE_LABELS).map(([key, label]) => (
        <ToggleButton key={key} value={key} aria-label={label} title={label}>
          <FontAwesomeIcon {...changeItemIcon(key as ChangeItem['type'])} />
        </ToggleButton>
      ))}
    </ToggleButtonGroup>
  );
};

type Props = {
  changeItems: ChangeItem[];
  onChangeChangeItems:
    | ((changeItems: Pipeline_Announcement['changeItems']) => void)
    | undefined;
};

const ChangeItemView = ({ item }: { item: ChangeItem }) => {
  return (
    <>
      <ListItemIcon classes={{ root: styles['typeview-icon'] }}>
        <FontAwesomeIcon size="lg" {...changeItemIcon(item.type)} />
      </ListItemIcon>
      <ListItemText secondary={item.description} />
    </>
  );
};

const ChangeItemEdit = ({
  item,
  onChangeChangeItem,
  onDeleteChangeItem,
}: {
  item: ChangeItem;
  onChangeChangeItem: (item: ChangeItem) => void;
  onDeleteChangeItem: () => void;
}) => {
  return (
    <>
      <TypeEdit
        type={item.type}
        onChangeType={(type) => onChangeChangeItem({ ...item, type })}
      />
      <TextField
        fullWidth
        classes={{ root: styles['text-field'] }}
        multiline
        maxRows={2}
        variant="outlined"
        value={item.description}
        onChange={(e) =>
          onChangeChangeItem({ ...item, description: e.target.value })
        }
        error={item.description.length === 0}
      />
      <IconButton
        size="small"
        onClick={onDeleteChangeItem}
        classes={{ root: styles['delete-btn'] }}
      >
        <FontAwesomeIcon icon={faTrash} />
      </IconButton>
    </>
  );
};

const AnnouncementChangeItemList = ({
  changeItems,
  onChangeChangeItems,
}: Props) => {
  const onChangeSingle = (idx: number, item: ChangeItem) => {
    if (onChangeChangeItems) {
      const newItems = [...(changeItems ?? [])];
      if (idx === newItems.length) {
        newItems.push(item);
      } else {
        newItems[idx] = item;
      }
      onChangeChangeItems(newItems);
    }
  };

  const onAddChangeItem = () => {
    if (onChangeChangeItems) {
      onChangeChangeItems([
        ...(changeItems ?? []),
        { type: 'bugfix', description: '' },
      ]);
    }
  };

  const onDeleteChangeItem = (idx: number) => {
    if (onChangeChangeItems) {
      const newItems = [...(changeItems ?? [])];
      newItems.splice(idx, 1);
      onChangeChangeItems(newItems);
    }
  };

  return (
    <List dense>
      {onChangeChangeItems && (
        <ListItem>
          <ListItemText primary="Change items" />
        </ListItem>
      )}
      {changeItems?.map((item, i) => (
        <ListItem key={i}>
          {onChangeChangeItems ? (
            <ChangeItemEdit
              item={item}
              onChangeChangeItem={onChangeSingle.bind(null, i)}
              onDeleteChangeItem={onDeleteChangeItem.bind(null, i)}
            />
          ) : (
            <ChangeItemView item={item} />
          )}
        </ListItem>
      ))}
      {onChangeChangeItems && (
        <ListItem>
          <StyledButton
            styleName="secondary"
            fullWidth
            onClick={onAddChangeItem}
            title="Add change item"
          >
            <FontAwesomeIcon icon={faPlus} />
          </StyledButton>
        </ListItem>
      )}
    </List>
  );
};

export default AnnouncementChangeItemList;
