import { TextField, Typography } from '@mui/material';
import { Pipeline_Announcement } from 'graphql/graphql';

type Props = Pick<Pipeline_Announcement, 'detail'> & {
  onChangeDetail:
    | ((detail: Pipeline_Announcement['detail']) => void)
    | undefined;
};

const DetailEdit = ({
  detail,
  onChangeDetail,
}: {
  detail: Pipeline_Announcement['detail'];
  onChangeDetail: (detail: Pipeline_Announcement['detail']) => void;
}) => {
  return (
    <TextField
      fullWidth
      label="Detail"
      variant="outlined"
      value={detail}
      onChange={(e) => onChangeDetail(e.target.value)}
      multiline
      maxRows={4}
    />
  );
};

const AnnouncementDetail = ({ detail, onChangeDetail }: Props) => {
  if (onChangeDetail) {
    return <DetailEdit detail={detail} onChangeDetail={onChangeDetail} />;
  }
  if (detail) {
    return <Typography variant="body1">{detail}</Typography>;
  }
  return null;
};

export default AnnouncementDetail;
