import {
  faFile,
  faFileArrowDown,
  faFileArrowUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  ButtonGroup,
  FormControl,
  InputLabel,
  ListItemText,
  ListSubheader,
  MenuItem,
  Select,
  Typography,
} from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import ActionRow from 'components/PointCorrespondenceWizard/ActionRow';
import {
  jsonStringToTemplate,
  templateToJsonString,
} from 'components/PointCorrespondenceWizard/types';
import StyledButton from 'components/StyledButton';
import {
  Pipeline_PointCorrespondenceTemplate,
  Pipeline_PointCorrespondenceTemplateDto,
} from 'graphql/graphql';
import useNotifications from 'hooks/useNotifications';
import useTextFileIO from 'hooks/useTextFileIO';
import { lazy, Suspense, useCallback } from 'react';
import styles from './index.module.scss';
import PreviewFallback from './Preview/Fallback';

const PointCorrespondenceTemplatePreview = lazy(() => import('./Preview'));

interface Props {
  correspondenceTemplates: Pipeline_PointCorrespondenceTemplate[] | undefined;
  selectedTemplate: Pipeline_PointCorrespondenceTemplate | undefined;
  selectTemplateById: (id: string | null) => void;
  editTemplate: (id: Pipeline_PointCorrespondenceTemplate | undefined) => void;
  onImportTemplate: (template: Pipeline_PointCorrespondenceTemplateDto) => void;
  deleteTemplate: (id: string) => void;
  onSubmit: () => void;
  onCancel: () => void;
}
const PointCorrespondenceTemplateSelector = ({
  correspondenceTemplates,
  selectedTemplate,
  selectTemplateById,
  editTemplate,
  deleteTemplate,
  onImportTemplate,
  onSubmit,
  onCancel,
}: Props) => {
  const { download: exportTemplate, upload: importTemplate } =
    useTextFileIO('json');
  const { error, warning, success } = useNotifications();

  const options =
    correspondenceTemplates
      ?.map((template) => ({
        value: template.id!,
        label: template.name,
        description: template.description,
        predefined: Boolean(template.svgData),
      }))
      .reverse() ?? [];

  const selectedTemplateStr = selectedTemplate ? selectedTemplate.id : '';
  const submitDisabled = !selectedTemplate;
  const isUserTemplate = Boolean(selectedTemplate?.owner);

  const handleExportTemplate = useCallback(() => {
    if (selectedTemplate) {
      exportTemplate(
        templateToJsonString(selectedTemplate),
        `${selectedTemplate.name}.json`
      );
    }
  }, [exportTemplate, selectedTemplate]);

  const handleImportTemplate = useCallback(() => {
    const imp = async () => {
      try {
        const templateStr = await importTemplate();
        const template = jsonStringToTemplate(templateStr);
        if (template) {
          onImportTemplate(template as Pipeline_PointCorrespondenceTemplateDto);
          if (correspondenceTemplates?.find((t) => t.name === template.name)) {
            warning(
              'The imported template appears to already exist - continuing with import.'
            );
          } else {
            success('Template imported successfully.');
          }
        } else {
          throw new Error('Invalid template file');
        }
      } catch (err) {
        error(String(err));
      }
    };
    void imp();
  }, [
    correspondenceTemplates,
    error,
    importTemplate,
    onImportTemplate,
    success,
    warning,
  ]);

  const predefinedOptions = options.filter((opt) => opt.predefined);
  const userOptions = options.filter((opt) => !opt.predefined);

  return (
    <DraggableDialog
      open={true}
      title="Select Point Correspondence Template"
      maxWidth="lg"
      fullWidth
      allowClose
      onClose={onCancel}
      actions={
        <ActionRow
          helpText={
            submitDisabled ? 'Please select a template to continue.' : undefined
          }
          buttons={[
            <StyledButton
              key="cancel"
              styleName="danger"
              onClick={onCancel}
              title="Cancel selecting correspondence template."
            >
              Cancel
            </StyledButton>,
            <StyledButton
              key="continue"
              styleName="primary"
              onClick={onSubmit}
              disabled={submitDisabled}
              title="Select this template and continue to the next step"
            >
              Select Template
            </StyledButton>,
          ]}
        />
      }
    >
      <Typography variant="subtitle1">
        Point correspondence templates are used to define the correspondence
        between the coordinates of the image and the coordinates of the world,
        thus allowing real-world measurements to be taken from video data.
      </Typography>
      <Typography variant="subtitle1" gutterBottom>
        Choose a template from the list below, or can create a new one.
      </Typography>

      <FormControl fullWidth variant="standard">
        <InputLabel>Point Correspondence Template</InputLabel>
        <Select
          renderValue={(value) =>
            options?.find((option) => option.value === value)?.label
          }
          value={selectedTemplateStr}
          onChange={(event) => {
            selectTemplateById(event.target.value);
          }}
          // Blur on close
          onClose={() =>
            setTimeout(
              () => (document.activeElement as HTMLSelectElement)?.blur(),
              1
            )
          }
        >
          <ListSubheader component="div">Pre-defined Templates</ListSubheader>
          {predefinedOptions.length > 0 ? (
            predefinedOptions.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                <ListItemText primary={opt.label} secondary={opt.description} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <ListItemText
                primary="No pre-defined templates found."
                secondary="Create a user-defined template to continue."
              />
            </MenuItem>
          )}
          <ListSubheader component="div">User-defined Templates</ListSubheader>
          {userOptions.length > 0 ? (
            userOptions.map((opt) => (
              <MenuItem key={opt.value} value={opt.value}>
                <ListItemText primary={opt.label} secondary={opt.description} />
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>
              <ListItemText
                primary="No user-defined templates found."
                secondary="Create a new template or import one from a file."
              />
            </MenuItem>
          )}
        </Select>
      </FormControl>

      <div className={styles['button-bar']}>
        <ButtonGroup>
          <StyledButton
            styleName="primary"
            startIcon={<FontAwesomeIcon icon={faFile} />}
            onClick={() => editTemplate(undefined)}
            title="Create a new template"
          >
            New Template
          </StyledButton>
          <StyledButton
            styleName="secondary"
            startIcon={<FontAwesomeIcon icon={faFileArrowUp} />}
            title="Import a template from file"
            onClick={handleImportTemplate}
          >
            Import Template
          </StyledButton>
        </ButtonGroup>
        {isUserTemplate && (
          <ButtonGroup className="floatright">
            <StyledButton
              styleName="primary"
              onClick={() => editTemplate(selectedTemplate ?? undefined)}
              title="Edit the selected template"
            >
              Edit Template
            </StyledButton>
            <StyledButton
              styleName="danger"
              onClick={() => {
                if (selectedTemplate?.id) {
                  deleteTemplate(selectedTemplate.id);
                }
              }}
              title="Delete the selected template"
            >
              Delete Template
            </StyledButton>
            <StyledButton
              styleName="secondary"
              startIcon={<FontAwesomeIcon icon={faFileArrowDown} />}
              title="Export the selected template to file"
              onClick={handleExportTemplate}
            >
              Export Template
            </StyledButton>
          </ButtonGroup>
        )}
      </div>
      <Suspense fallback={<PreviewFallback />}>
        <PointCorrespondenceTemplatePreview
          pixelCoordinates={selectedTemplate?.pixelCoordinates}
          worldCoordinates={selectedTemplate?.worldCoordinates}
          worldOrigin={selectedTemplate?.worldOrigin}
          edges={selectedTemplate?.edges}
          svgData={selectedTemplate?.svgData}
        />
      </Suspense>
    </DraggableDialog>
  );
};

export default PointCorrespondenceTemplateSelector;
