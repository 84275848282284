import { faFolderOpen, faTrash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemSecondaryAction,
  ListItemText,
  Typography,
} from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import DraggableDialog from 'components/DraggableDialog';
import { PrivacySetting } from '../../graphql/graphql';
import styles from './index.module.scss';

export interface IGraphSummary {
  id: string;
  name: string;
  description?: string;
  privacy?: PrivacySetting;
}

interface Props {
  title: string;
  subtitle: string;
  openHint: string;
  open: boolean;
  onClose: () => void;
  onSelectGraph: (graphId: string | undefined) => void;
  onDeleteGraph?: (graphId: string | undefined) => void;
  graphs: IGraphSummary[] | undefined;
}

const GraphBrowserDialog = ({
  title,
  subtitle,
  openHint,
  open,
  onClose,
  onSelectGraph,
  graphs,
  onDeleteGraph,
}: Props) => {
  const makeListItems = () => {
    if (!graphs) {
      return <span>Loading...</span>;
    }
    if (graphs.length === 0) {
      return (
        <ListItem>
          <Typography
            variant="h4"
            gutterBottom
            color="textSecondary"
            className={styles['no-results']}
          >
            <div>No results!</div>
          </Typography>
        </ListItem>
      );
    }

    // Theme the avatar based on the graph privacy
    const getAvatarClass = (graph: IGraphSummary) => {
      switch (graph?.privacy) {
        case PrivacySetting.Public:
          return styles['public-graph-avatar'];
        case PrivacySetting.Template:
          return styles['template-graph-avatar'];
      }
      return '';
    };
    // Get a title for the avatar based on the graph privacy
    const getAvatarTitle = (graph: IGraphSummary) => {
      switch (graph?.privacy) {
        case PrivacySetting.Public:
          return 'Public pipeline';
        case PrivacySetting.Template:
          return 'Template pipeline';
      }
    };

    return graphs.map((graph) => (
      <ListItem key={graph.id} className="MuiListItem-button">
        {graph.name && (
          <ListItemAvatar title={getAvatarTitle(graph)}>
            <Avatar className={getAvatarClass(graph)}>
              {graph.name[0].toUpperCase()}
            </Avatar>
          </ListItemAvatar>
        )}
        <ListItemText
          className={styles['listitem-text']}
          primary={graph.name}
          secondary={graph.description}
        />
        <ListItemSecondaryAction>
          <DelayedTooltip title={openHint}>
            <IconButton onClick={() => onSelectGraph(graph.id)} size="large">
              <FontAwesomeIcon icon={faFolderOpen} size="sm" />
            </IconButton>
          </DelayedTooltip>

          {onDeleteGraph && (
            <DelayedTooltip title="Delete pipeline">
              <IconButton
                edge="end"
                onClick={() => onDeleteGraph(graph.id)}
                size="large"
              >
                <FontAwesomeIcon icon={faTrash} size="sm" />
              </IconButton>
            </DelayedTooltip>
          )}
        </ListItemSecondaryAction>
      </ListItem>
    ));
  };

  return (
    <DraggableDialog
      title={
        <>
          <div>{title}</div>
          <Typography variant="caption">{subtitle}</Typography>
        </>
      }
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg' }}
      className={styles['content-container']}
      allowClose
    >
      <List dense>{makeListItems()}</List>
    </DraggableDialog>
  );
};

export default GraphBrowserDialog;
