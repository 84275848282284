import { faExpandArrowsAlt } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Frame Resizer',
  description: 'Resize video frames.',
  descriptionDetail:
    'This node resizes video frames to the desired size, retaining the original aspect ratio if desired.',
  icon: faExpandArrowsAlt,
  category: NodeCategory.VIDEO_UTILS,
  moduleType: 'ImageResizer',
  configTypes: [
    {
      key: 'width',
      type: PipelineConfigType.INTEGER('Width')
        .REQUIRED.MIN(128)
        .MAX(1920)
        .DEFAULT(640),
    },
    {
      key: 'height',
      type: PipelineConfigType.INTEGER('Height')
        .REQUIRED.MIN(128)
        .MAX(1080)
        .DEFAULT(360),
    },
    {
      key: 'preserve_aspect_ratio',
      type: PipelineConfigType.BOOLEAN(
        'Preserve aspect ratio'
      ).REQUIRED.DEFAULT(true),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'resized_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
