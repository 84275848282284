import { List, ListItem, Typography } from '@mui/material';
import { Blob } from 'contexts/Graph';
import { CustomDataType, PipelineDataType } from 'graph/types';
import useFlowGraph from 'hooks/useFlowGraph';
import useGraph from 'hooks/useGraph';
import { useCallback } from 'react';
import { HandleType } from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import NodeItemList from '..';
import styles from '../shared.module.scss';
import Handle from './Handle';

interface Props {
  title?: string;
  nodeId: string;
  dataTypes: { key: string; type: PipelineDataType }[];
  handleType: HandleType;
  isConnectable: boolean;
}

const metaFieldExists = (
  inputBlobs: Blob[],
  nodeId: string,
  inputKey: string,
  metaKey: string
) => {
  const reqBlob = getBlobByKey(inputBlobs, nodeId, inputKey);
  if (!reqBlob) {
    return false;
  }
  if (!reqBlob.metadata?.[metaKey]) {
    return false;
  }
  return true;
};

const HandleList = ({
  title,
  nodeId,
  dataTypes,
  handleType,
  isConnectable,
}: Props) => {
  const { flowGraph } = useFlowGraph();
  const { inputBlobs } = useGraph();

  const renderListGroup = useCallback(
    (
      nodeId: string,
      groupKey: string | undefined,
      groupLabel: string | undefined,
      groupedElements: {
        key: string;
        type: CustomDataType;
      }[]
    ) => {
      return (
        <div key={groupKey}>
          <Typography variant="caption">{groupLabel}</Typography>
          <List className={styles['group-list']}>
            {groupedElements.map((dataType) => {
              const typeObj = dataType.type as PipelineDataType;

              // If it depends on a blob meta key that doesn't exist, skip it
              const reqBlobKey = typeObj.requiresBlobMetaKey;
              if (
                reqBlobKey &&
                !metaFieldExists(
                  inputBlobs,
                  nodeId,
                  reqBlobKey.inputKey,
                  reqBlobKey.metaKey
                )
              ) {
                return null;
              }

              // If it depends on a config key that doesn't exist, skip it
              const reqConfigKey = typeObj.requiresConfigKeyValue;
              if (
                reqConfigKey &&
                !flowGraph.nodes.find((node) => node.id === nodeId)?.data
                  ?.config[reqConfigKey]
              ) {
                return null;
              }

              return (
                <ListItem
                  key={dataType.key}
                  className={styles['handle-listitem']}
                >
                  <Handle
                    nodeId={nodeId}
                    id={dataType.key}
                    handleType={handleType}
                    dataType={typeObj}
                    isConnectable={isConnectable}
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      );
    },
    [flowGraph, handleType, inputBlobs, isConnectable]
  );

  return (
    <NodeItemList
      nodeId={nodeId}
      title={title}
      items={dataTypes}
      renderListGroup={renderListGroup}
    />
  );
};

export default HandleList;
