import { faPlus } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import DelayedTooltip from 'components/DelayedTooltip';
import NodeBrowser from 'components/NodeBrowser';
import StyledButton from 'components/StyledButton';
import { useState } from 'react';
import styles from './index.module.scss';

const AddNodeButton = () => {
  const [nodeBrowserDialogOpen, setNodeBrowserDialogOpen] = useState(false);
  return (
    <>
      <NodeBrowser
        open={nodeBrowserDialogOpen}
        onClose={() => setNodeBrowserDialogOpen(false)}
      />

      <DelayedTooltip title="Add a node">
        <div className={styles['button-container']}>
          <StyledButton
            styleName="primary"
            className={styles.button}
            onClick={() => setNodeBrowserDialogOpen(true)}
          >
            <FontAwesomeIcon icon={faPlus} size="2x" />
          </StyledButton>
        </div>
      </DelayedTooltip>
    </>
  );
};

export default AddNodeButton;
