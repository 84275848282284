import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';

interface Props {
  title: string;
  content: string;
  open: boolean;
  onClose: () => void;
}

const RawTextDialog = ({ title, open, onClose, content }: Props) => {
  const download = () => {
    const filename = `${title}.txt`;
    const element = document.createElement('a');
    element.setAttribute(
      'href',
      'data:text/plain;charset=utf-8,' + encodeURIComponent(content)
    );
    element.setAttribute('download', filename);

    element.style.display = 'none';
    document.body.appendChild(element);

    element.click();

    document.body.removeChild(element);
  };

  return (
    <DraggableDialog
      title={title}
      open={open}
      onClose={onClose}
      allowClose
      actions={
        <StyledButton key="download" styleName="primary" onClick={download}>
          Download
        </StyledButton>
      }
    >
      <div>
        {content.split('\n').flatMap((str, i) => [str, <br key={i} />])}
      </div>
    </DraggableDialog>
  );
};

export default RawTextDialog;
