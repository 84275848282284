import { Typography } from '@mui/material';
import { useSaveGraphMutation } from 'graphql/graphql';
import { useState } from 'react';
import { useNavigate } from 'react-router-dom';

import DraggableDialog from 'components/DraggableDialog';
import GraphMetaForm from 'components/GraphMetaForm';
import StyledButton from 'components/StyledButton';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

const NewGraphDialog = ({ open, onClose }: Props) => {
  const [name, setName] = useState<string | undefined>(undefined);
  const [description, setDescription] = useState('');
  const navigate = useNavigate();
  const [saveGraph] = useSaveGraphMutation();

  const submit = () => {
    saveGraph({
      variables: {
        graph: {
          name,
          description,
          flowGraph: [],
        },
      },
    })
      .then((res) => {
        const newId = res.data?.Pipeline_saveGraph?.id;
        if (newId) {
          navigate(`/graph/${newId}`);
        } else {
          throw new Error('Failed to create pipeline');
        }
      })
      .catch((err) => console.error(err))
      .finally(() => {
        setName('');
        setDescription('');
        onClose();
      });
  };

  const submitBtn = (
    <StyledButton
      key={0}
      styleName="primary"
      solid
      disabled={!name}
      onClick={submit}
    >
      Create Pipeline
    </StyledButton>
  );

  return (
    <DraggableDialog
      title={
        <>
          <div>New Pipeline</div>
          <Typography variant="caption">
            Create a new pipeline from scratch.
          </Typography>
        </>
      }
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'lg' }}
      className={styles['content-container']}
      actions={[submitBtn]}
      allowClose
    >
      <GraphMetaForm
        name={name}
        setName={setName}
        description={description}
        setDescription={setDescription}
      />
    </DraggableDialog>
  );
};

export default NewGraphDialog;
