import { createRoot } from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import UserStateContextProvider from './contexts/UserState';

import App from './App';
import { AuthContextsProvider, UtilityContextsProvider } from './contexts';
import './index.css';

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  <BrowserRouter>
    <UserStateContextProvider>
      <UtilityContextsProvider>
        <AuthContextsProvider>
          <App />
        </AuthContextsProvider>
      </UtilityContextsProvider>
    </UserStateContextProvider>
  </BrowserRouter>
);
