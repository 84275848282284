import {
  createContext,
  Dispatch,
  ReactElement,
  SetStateAction,
  useCallback,
  useState,
} from 'react';
import { OnConnectStartParams, ReactFlowInstance, XYPosition } from 'reactflow';

type UseStateType<T> = [T, Dispatch<SetStateAction<T>>];

interface IFlowRendererContext {
  instance: UseStateType<ReactFlowInstance | undefined>;
  projectToCanvas: (coords: XYPosition) => XYPosition | undefined;
  prospectiveConnection: OnConnectStartParams | undefined;
  setProspectiveConnection: Dispatch<
    SetStateAction<OnConnectStartParams | undefined>
  >;
}

export const FlowRendererContext = createContext<IFlowRendererContext>(
  undefined!
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [instance, setInstance] = useState<ReactFlowInstance | undefined>();
  const [prospectiveConnection, setProspectiveConnection] =
    useState<OnConnectStartParams>();

  const projectToCanvas = useCallback(
    (coords: XYPosition) => {
      return instance?.project(coords);
    },
    [instance]
  );

  return (
    <FlowRendererContext.Provider
      value={{
        instance: [instance, setInstance],
        projectToCanvas,
        prospectiveConnection,
        setProspectiveConnection,
      }}
    >
      {children}
    </FlowRendererContext.Provider>
  );
};
