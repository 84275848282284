import { FormControl, Input, InputLabel } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CommonProps } from '../types';
import styles from './index.module.scss';

// Colour conversion adapted from https://stackoverflow.com/a/5624139
function componentToHex(c: number) {
  const hex = c.toString(16);
  return hex.length == 1 ? '0' + hex : hex;
}
function rgbToHex(rgb: [number, number, number]): string {
  return `#${componentToHex(rgb[0])}${componentToHex(rgb[1])}${componentToHex(
    rgb[2]
  )}`;
}
function hexToRgb(hex: string): [number, number, number] {
  const result = /^#?([a-f\d]{2})([a-f\d]{2})([a-f\d]{2})$/i.exec(hex);

  if (!result) return [0, 0, 0];
  return [
    parseInt(result[1], 16),
    parseInt(result[2], 16),
    parseInt(result[3], 16),
  ];
}

interface Props extends CommonProps<[number, number, number], number> {
  labelOverride?: string;
}

const ColourInput = ({ value, setValue, configType, labelOverride }: Props) => {
  let validationMsg = configType.validate(value);
  const [inputIsChanging, setInputIsChanging] = useState(false);
  const [hexColour, setHexColour] = useState(rgbToHex(value ?? [0, 0, 0]));

  if (configType.required && !value) {
    validationMsg = 'Cannot be empty';
  }
  const label = labelOverride ?? configType.label;
  const labelId = `${label}_label`;

  // Set the actual node config value with a debounce
  const updateValueDebounced = useDebouncedCallback(
    (value: [number, number, number]) => {
      configType.setValue(value, setValue);
    },
    500,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    if (JSON.stringify(hexToRgb(hexColour)) !== JSON.stringify(value)) {
      if (inputIsChanging) {
        updateValueDebounced(hexToRgb(hexColour));
        setInputIsChanging(false);
      } else {
        setHexColour(rgbToHex(value ?? [0, 0, 0]));
      }
    } // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hexColour, updateValueDebounced, value]);

  return (
    <FormControl
      error={Boolean(validationMsg)}
      className={`${styles.formcontrol} nodrag`}
      key={labelId}
    >
      <InputLabel id={labelId}>{label}</InputLabel>
      <Input
        value={hexColour}
        onChange={(e) => {
          setInputIsChanging(true);
          setHexColour(e.target.value);
        }}
        inputProps={{
          type: 'color',
          className: styles['input-control'],
        }}
        className={styles['form-input']}
      />
    </FormControl>
  );
};

export default ColourInput;
