import { faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Avatar,
  Button,
  Grid,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  TextField,
  Typography,
} from '@mui/material';
import AppLogo from 'components/AppLogo';
import BackgroundImage from 'components/BackgroundImage';
import StyledButton from 'components/StyledButton';
import { NodeTypeKey, NodeTypes } from 'graph/NodeTypes';
import { FlowGraphNodeType } from 'graph/types';
import { useMemo, useState } from 'react';
import styles from './index.module.scss';

const defaultDescription =
  'Pipelines is a no-code platform for building and deploying machine \
learning pipelines on the cloud. Below are of some of the available \
nodes, which can be connected together to build complex video \
processing pipelines.';

// Nodes that are worth showing descriptions for
const defaultTopNodes: NodeTypeKey[] = [
  'BoundingBoxTracker',
  'CalibrationIntrinsicsComputer',
  'CalibrationPatternDetector',
  'JointAngleMeasurer',
  'KinematicsCalculator',
  'KinoveaWriter',
  'KinogramBuilder',
  'ObjectDetector',
  'PipelinesDataOutput',
  'PoseDetector',
  'PoseLowPassFilter',
  'PoseOverlayer',
  'PosePlotter3D',
  'Reprojector',
  'ScatterPlot2D',
  'ScatterPlot3D',
  'Segmentation',
  'StereoExtrinsicsCalculator',
  'StereoTriangulator',
  'StrideRate',
  'TimeSeriesPlot',
  'TrackedBoundingBoxWriter',
  'TrackedBoundingBoxCropExtractor',
  'TrackedPoseWriter',
  'TrackSelector',
  'VideoLoader',
  'VideoWriter',
  'WorldSpacePlotter',
];

interface NodeViewProps {
  node: FlowGraphNodeType;
  onClick: () => void;
}
const NodeView = ({ node, onClick }: NodeViewProps) => {
  return (
    <ListItemButton
      onClick={onClick}
      title="Click to remove this node from the highlighted list of nodes"
    >
      <ListItemAvatar>
        <Avatar>
          <FontAwesomeIcon icon={node.icon ?? faFileVideo} />
        </Avatar>
      </ListItemAvatar>
      <ListItemText primary={node.name} secondary={node.description} />
    </ListItemButton>
  );
};

const MiniNodeView = ({ node, onClick }: NodeViewProps) => {
  return (
    <Button
      variant="text"
      classes={{ text: styles['additional-node'] }}
      onClick={onClick}
      title="Click to add this node from the highlighted list of nodes"
    >
      {node.name}
    </Button>
  );
};

const Description = ({
  value,
  setValue,
}: {
  value: string;
  setValue: (value: string) => void;
}) => {
  const [editing, setEditing] = useState<boolean>(false);
  if (editing) {
    return (
      <div>
        <TextField
          label="Description"
          multiline
          fullWidth
          rows={4}
          value={value}
          onChange={(e) => setValue(e.target.value)}
        />
        <StyledButton
          styleName="secondary"
          onClick={() => setEditing(false)}
          fullWidth
        >
          Save
        </StyledButton>
      </div>
    );
  }
  return (
    <div>
      {value.split('\n').map((line, i) => (
        <Typography
          key={i}
          variant="body1"
          gutterBottom
          top="20px"
          onClick={() => setEditing(true)}
          title="Click to edit the description"
        >
          {line}
        </Typography>
      ))}
    </div>
  );
};

const NodeList = () => {
  const [description, setDescription] = useState<string>(defaultDescription);
  const [topNodes, setTopNodes] = useState<NodeTypeKey[]>(defaultTopNodes);
  const onRemoveTopNode = (nodeKey: NodeTypeKey) => {
    setTopNodes(topNodes.filter((key) => key !== nodeKey));
  };
  const onAddTopNode = (nodeKey: NodeTypeKey) => {
    setTopNodes([...topNodes, nodeKey]);
  };

  const [sortedTopNodes, sortedBottomNodes] = useMemo(() => {
    const sortedTopNodes = topNodes.sort((a, b) =>
      NodeTypes[a].name.localeCompare(NodeTypes[b].name)
    );
    // The nodes that *aren't* top nodes
    const bottomNodes = Object.keys(NodeTypes).filter(
      (node) => !topNodes.includes(node as NodeTypeKey)
    ) as NodeTypeKey[];
    const sortedBottomNodes = bottomNodes.sort((a, b) =>
      NodeTypes[a].name.localeCompare(NodeTypes[b].name)
    );
    return [sortedTopNodes, sortedBottomNodes];
  }, [topNodes]);

  return (
    <div className={styles.container}>
      <BackgroundImage hideVersionNumber />

      <div className={styles['content-container']}>
        <AppLogo />
        <div className={styles['on-paper']}>
          <Description value={description} setValue={setDescription} />

          <List sx={{ width: '100%', bgcolor: 'background.paper' }} dense>
            <Grid container>
              {sortedTopNodes.map((key) => (
                <Grid item xs={6} key={key}>
                  <NodeView
                    key={key}
                    node={NodeTypes[key]}
                    onClick={() => onRemoveTopNode(key)}
                  />
                </Grid>
              ))}
            </Grid>
          </List>

          <Typography variant="h6" gutterBottom top="20px">
            Additional Nodes
          </Typography>
          <Grid container>
            {sortedBottomNodes.map((key) => (
              <Grid item xs={6} key={key}>
                <MiniNodeView
                  key={key}
                  node={NodeTypes[key]}
                  onClick={() => onAddTopNode(key)}
                />
              </Grid>
            ))}
          </Grid>
        </div>
      </div>
    </div>
  );
};

export default NodeList;
