import { faPalette } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Image Colour Adjuster',
  description: 'Applies some colour adjustment to image frames.',
  descriptionDetail:
    'This node provides basic functionality for image colour adjustment. May be found useful when detecting calibration boards.',
  icon: faPalette,
  category: NodeCategory.VIDEO_UTILS,
  moduleType: 'ImageColourAdjuster',
  configTypes: [
    {
      key: 'brightness',
      type: PipelineConfigType.INTEGER('Brightness')
        .REQUIRED.DEFAULT(0)
        .MIN(-255)
        .MAX(255)
        .SLIDER(1)
        .DESCRIPTION(
          'The amount of brightness shifting to be applied to each pixel [-255, 255]'
        ),
    },
    {
      key: 'contrast',
      type: PipelineConfigType.DECIMAL('Contrast')
        .REQUIRED.DEFAULT(1)
        .MIN(0.25)
        .MAX(2)
        .SLIDER(0.05)
        .DESCRIPTION(
          'The amount of contrast to be applied to each pixel. Values below 1 will reduce contrast, values above 1 will increase contrast.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'adjusted_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
