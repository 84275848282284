import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { IS_DEV_MODE } from 'config';
import { Accept } from 'react-dropzone';
import { Node } from 'reactflow';
import {
  maybeParseFloat,
  maybeParseInt,
  nullOrUndef,
  uuid,
} from 'utils/helpers';
import { Pipeline_BlobDataType, Pipeline_BlobType } from '../graphql/graphql';
import { NodeCategory } from './nodeCategories';
import { NodeTypeKey } from './NodeTypes';
import {
  clamp,
  constraintsSetWarningString,
  valueViolatesMinMaxConstraints,
} from './typeUtils';

export class ExtendedEnum {
  // Read-only key
  private _key: string;
  get key(): string {
    return this._key;
  }

  label: string;
  description: string;

  constructor(key: string, label: string, description: string) {
    this._key = key;
    this.label = label;
    this.description = description;
  }

  DESCRIPTION(description: string): this {
    this.description = description;
    return this;
  }
}

/**
 * Pipeline config datatypes and input/output datatypes are basically classes pretending
 * to be enums, so we can attach extra properties and make use of the builder pattern to
 * allow adding additional properties like required, default, choices etc.
 *
 * E.g.
 * const urlType = PipelineConfigType.URL;
 * const requiredUrlType = PipelineConfigType.URL.REQUIRED;
 *
 * Ensure that you compare types using the key property:
 * x.key === y.key // true
 */
export class CustomDataType extends ExtendedEnum {
  required = false;
  hidden = false;
  array = false;
  tupleFields: string[] | undefined = undefined;
  options: any[] | undefined;
  groupKey: string | undefined;
  groupLabel: string | undefined;
  sliderStep: number | undefined;
  numericStep: number | undefined;
  sliderMarks: number[] | undefined;

  get REQUIRED(): this {
    this.required = true;
    return this;
  }

  get HIDDEN(): this {
    this.hidden = true;
    return this;
  }

  OPTIONS(opts: any[]): this {
    this.options = opts;
    return this;
  }

  UI_GROUP(groupKey: string, groupLabel: string): this {
    this.groupKey = groupKey;
    this.groupLabel = groupLabel;
    return this;
  }
}

interface IGenerateContext {
  nodeId: string;
  configId: string;
  node: FlowGraphNode;
}
type SetFunc = (
  instance: PipelineConfigType,
  newValue: any,
  setValue: (val: any) => void
) => void;
type ValFunc = (
  instance: PipelineConfigType,
  newValue: any
) => string | undefined;
type GenerateFunc = (
  instance: PipelineConfigType,
  context: IGenerateContext
) => void;

export class PipelineConfigType extends CustomDataType {
  setValue: (newValue: any, setValue: (val: any) => void) => void;
  validate: (newValue: any) => string | undefined;
  generate: ((context: IGenerateContext) => void) | undefined;
  defaultValue: any | undefined;
  constantValue: any | undefined;
  minValue: number | undefined;
  maxValue: number | undefined;
  advanced = false;
  dependentOn: string | undefined;
  colourCoded = false;
  blobMetavalueConstraint:
    | {
        blobType: Pipeline_BlobType;
        propertyId: string;
        metaKey: string;
        constrainFunc: (currValue: any, metaValue: any) => any;
      }
    | undefined;
  inputHint: string | undefined;

  static defaultSetValueFunc: SetFunc = (
    instance: PipelineConfigType,
    v: any,
    set: (val: any) => void
  ) => set(v);
  static defaultValidateFunc: ValFunc = () => undefined;

  constructor(
    key: string,
    label: string,
    description: string,
    setValue: SetFunc = PipelineConfigType.defaultSetValueFunc,
    validate: ValFunc = PipelineConfigType.defaultValidateFunc,
    generate?: GenerateFunc
  ) {
    super(key, label, description);

    // Wrap the set and validate methods to pass in this instance as an argument
    this.setValue = (val, set) => setValue(this, val, set);
    this.validate = (v: any) => validate(this, v);
    this.generate = generate
      ? (v: IGenerateContext) => generate(this, v)
      : undefined;
  }

  DEFAULT(value: any): this {
    this.defaultValue = value;
    return this;
  }

  CONSTANT_VALUE(value: any): this {
    this.constantValue = value;
    this.hidden = true;
    return this;
  }

  get ADVANCED(): this {
    this.advanced = true;
    return this;
  }

  get ARRAY(): this {
    this.array = true;
    return this;
  }

  get COLOUR_CODED(): this {
    this.colourCoded = true;
    return this;
  }

  DEV_OVERRIDE(modifyFunc: (instance: PipelineConfigType) => void): this {
    if (IS_DEV_MODE) {
      modifyFunc(this);
    }
    return this;
  }

  NUMERIC_STEP(step: number): this {
    if (step <= 0) {
      throw Error('NUMERIC_STEP step must be greater than 0');
    }
    this.numericStep = step;
    return this;
  }

  SLIDER(step: number, additionalMarks?: number[] | undefined): this {
    if (nullOrUndef(this.minValue) || nullOrUndef(this.maxValue)) {
      throw Error('MIN and MAX must be set before SLIDER');
    }
    if (nullOrUndef(this.defaultValue)) {
      throw Error('DEFAULT must be set before SLIDER');
    }
    if (step <= 0) {
      throw Error('SLIDER step must be greater than 0');
    }
    this.sliderStep = step;
    // Check if any of the marks are outside the min/max range
    if (additionalMarks) {
      for (const mark of additionalMarks) {
        if (mark < this.minValue || mark > this.maxValue) {
          throw Error(
            `SLIDER marks must be within the range ${this.minValue} - ${this.maxValue}`
          );
        }
      }
    }
    this.sliderMarks = [this.minValue, this.defaultValue, this.maxValue].concat(
      additionalMarks || []
    );

    return this;
  }

  TUPLE(fields: string[]): this {
    this.tupleFields = fields;
    // If no default has been set, set it to an array of undefined - one for each tuple field.
    if (typeof this.defaultValue == 'undefined') {
      this.defaultValue = Array(fields.length);
    }
    return this;
  }

  MIN(value: number): this {
    if (!nullOrUndef(this.maxValue) && value >= this.maxValue) {
      throw Error('MIN must be less than MAX');
    }
    this.minValue = value;
    return this;
  }

  MAX(value: number): this {
    if (!nullOrUndef(this.minValue) && value <= this.minValue) {
      throw Error('MAX must be greater than MIN');
    }
    this.maxValue = value;
    return this;
  }

  CONSTRAIN_BY_BLOB_METAVALUE(
    blobType: Pipeline_BlobType,
    propertyId: string,
    metaKey: string,
    constrainFunc: (currValue: any, metaValue: any) => any
  ): this {
    this.blobMetavalueConstraint = {
      blobType,
      propertyId,
      metaKey,
      constrainFunc,
    };
    return this;
  }
  //////////////////////////////////////////////
  ////////////// TYPE DEFINITIONS //////////////
  //////////////////////////////////////////////

  static STRING(label = 'STRING') {
    return new PipelineConfigType(
      'STRING',
      label,
      'A plain text value',
      (instance, newValue, setValue) =>
        setValue(newValue && newValue.length > 0 ? newValue : undefined)
    );
  }

  static STRING_ARRAY(label = 'STRING_ARRAY') {
    return new PipelineConfigType(
      'STRING_ARRAY',
      label,
      'A list of unique string values'
    );
  }
  static URL(label = 'URL') {
    return new PipelineConfigType(
      'URL',
      label,
      'The URL of an internet resource, starting with http:// or https://',
      (instance, newValue, setValue) => {
        let outVal: string = newValue ?? '';
        if (newValue)
          if (!outVal.startsWith('http') && !outVal.startsWith('https')) {
            outVal = `http://${outVal}`;
          }
        setValue(outVal);
      },
      (instance, newValue: string | undefined) => {
        // Adapted from https://stackoverflow.com/a/43467144
        if (newValue) {
          try {
            const url = new URL(newValue);
            if (!['http:', 'https:'].includes(url.protocol)) {
              return 'Must be http or https';
            }
          } catch (_) {
            return 'Invalid URL';
          }
        }
      }
    );
  }

  static INTEGER(label = 'Integer') {
    return new PipelineConfigType(
      'INTEGER',
      label,
      'A whole number',
      (instance, newValue: string | undefined, setValue) => {
        if (typeof newValue === 'string' && newValue.length === 0) {
          newValue = undefined;
        }
        if (nullOrUndef(newValue)) {
          if (
            instance.required &&
            !nullOrUndef(instance.defaultValue) &&
            !instance.tupleFields
          ) {
            setValue(instance.defaultValue);
          } else {
            setValue(newValue);
          }
        } else {
          const parsed = parseInt(newValue);
          setValue(clamp(parsed, instance.minValue, instance.maxValue));
        }
      },
      (instance, value: string | number | undefined) => {
        if (!nullOrUndef(value)) {
          const parsed = maybeParseFloat(value);
          if (!Number.isInteger(parsed)) {
            return 'Must enter an integer value';
          }
          if (
            valueViolatesMinMaxConstraints(
              parsed,
              instance.minValue,
              instance.maxValue
            )
          ) {
            return constraintsSetWarningString(
              instance.minValue,
              instance.maxValue
            );
          }
        }
      }
    );
  }

  static DECIMAL(label = 'Decimal') {
    return new PipelineConfigType(
      'DECIMAL',
      label,
      'A decimal number',
      (instance, newValue: number | string | undefined, setValue) => {
        if (typeof newValue === 'string' && newValue.length === 0) {
          newValue = undefined;
        }
        if (nullOrUndef(newValue)) {
          if (instance.required && !nullOrUndef(instance.defaultValue)) {
            setValue(instance.defaultValue);
          } else {
            setValue(newValue);
          }
        } else {
          const parsed = maybeParseFloat(newValue);
          setValue(clamp(parsed, instance.minValue, instance.maxValue));
        }
      },
      (instance, value: number | string | undefined) => {
        if (!nullOrUndef(value)) {
          const parsed = maybeParseFloat(value);
          if (!Number.isFinite(parsed)) {
            return 'Must enter a decimal value';
          }
          if (
            valueViolatesMinMaxConstraints(
              parsed,
              instance.minValue,
              instance.maxValue
            )
          ) {
            return constraintsSetWarningString(
              instance.minValue,
              instance.maxValue
            );
          }
        }
      }
    );
  }

  static NORM_DECIMAL(label = 'Normalised decimal') {
    return new PipelineConfigType(
      'NORM_DECIMAL',
      label,
      'A decimal number in [0, 1]',
      (instance, newValue: number | string | undefined, setValue) => {
        if (typeof newValue === 'string' && newValue.length === 0) {
          newValue = undefined;
        }
        if (nullOrUndef(newValue)) {
          if (instance.required && !nullOrUndef(instance.defaultValue)) {
            setValue(instance.defaultValue);
          } else {
            setValue(newValue);
          }
        } else {
          const parsed = maybeParseFloat(newValue);
          setValue(clamp(parsed, 0, 1));
        }
      },
      (instance, value: number | string | undefined) => {
        if (!nullOrUndef(value)) {
          const parsed = maybeParseFloat(value);
          if (!Number.isFinite(parsed)) {
            return 'Must enter a decimal value';
          }
          if (valueViolatesMinMaxConstraints(parsed, 0, 1)) {
            return constraintsSetWarningString(0, 1);
          }
        }
      }
    )
      .MIN(0)
      .MAX(1);
  }

  static BOOLEAN(label = 'Boolean') {
    return new PipelineConfigType('BOOLEAN', label, 'A true or false value.');
  }

  static MARKDOWN(label = 'Markdown') {
    return new PipelineConfigType(
      'MARKDOWN',
      label,
      'A text area supporting markdown formatting.'
    );
  }

  static COLOUR(label = 'Colour') {
    return new PipelineConfigType(
      'COLOUR',
      label,
      'An RGB colour value',
      (
        instance,
        newValue:
          | [number, number, number]
          | [string, string, string]
          | undefined,
        setValue
      ) => {
        // Cast to float
        if (typeof newValue === 'undefined') {
          setValue(newValue);
        } else {
          const [r, g, b] = newValue;

          setValue([
            clamp(maybeParseInt(r), 0, 255),
            clamp(maybeParseInt(g), 0, 255),
            clamp(maybeParseInt(b), 0, 255),
          ]);
        }
      },
      (
        instance,
        value: [number, number, number] | [string, string, string]
      ) => {
        if (value) {
          const valid = value
            .map((v: string | number) => maybeParseInt(`${v}`))
            .every((v: number) => Number.isFinite(v) && v >= 0 && v <= 255);

          if (!valid) {
            return 'Must be an RGB tripled in [0, 255].';
          }
        }
      }
    );
  }

  static COMMA_SEPARATED_INTEGERS(
    label = 'Comma separated integers',
    inputHint = 'Enter integer value'
  ) {
    // Note that no validation or setting is performed here, as it's better handled by the
    // component itself.
    const t = new PipelineConfigType(
      'COMMA_SEPARATED_INTEGERS',
      label,
      'A list of integers separated by commas'
    );
    t.inputHint = inputHint;
    return t;
  }

  static COMMA_SEPARATED_NORM_DECIMALS(
    label = 'Comma separated numbers in [0, 1]',
    inputHint = 'Enter a number in [0, 1]'
  ) {
    // Note that no validation or setting is performed here, as it's better handled by the
    // component itself.
    const t = new PipelineConfigType(
      'COMMA_SEPARATED_NORM_DECIMALS',
      label,
      'A list of numbers in [0, 1] separated by commas'
    );
    t.inputHint = inputHint;
    t.minValue = 0;
    t.maxValue = 1;
    return t;
  }

  static POINT_CORRESPONDENCE(
    label = 'Image/World Correspondence',
    dependentOn?: string
  ) {
    const t = new PipelineConfigType(
      'POINT_CORRESPONDENCE',
      label,
      'A list of image/world point correspondences to convert between image and world coordinates.'
    );
    t.dependentOn = dependentOn;
    return t;
  }

  static FRAME_LABELS(label = 'Frame Labels') {
    const t = new PipelineConfigType(
      'FRAME_LABELS',
      label,
      'The labels of key events in the video.'
    );
    return t;
  }

  static GENERATED_STRING(template: string, label = 'Generated String') {
    const generate: GenerateFunc = (instance, context) =>
      stringSub(template, instance, context);
    return new PipelineConfigType(
      'GENERATED_STRING',
      label,
      'An automatically generated string',
      undefined,
      undefined,
      generate
    );
  }

  static UUID({ prefix = '', suffix = '' } = {}, label = 'UUID') {
    // Generate a UUID, using the existing value if already set
    const generate: GenerateFunc = (instance, context) => {
      prefix = stringSub(prefix, instance, context);
      suffix = stringSub(suffix, instance, context);
      return String(
        context.node.data.config[context.configId] ??
          `${prefix}${uuid()}${suffix}`
      );
    };

    return new PipelineConfigType(
      'UUID',
      label,
      'An automatically generated unique id',
      undefined,
      undefined,
      generate
    );
  }
}

/*
 * The "accept" string to be used in the file picker to filter candidate files.
 */
export const filePickerAcceptString = (format: Pipeline_BlobDataType) => {
  switch (format) {
    case Pipeline_BlobDataType.Csv:
      return '.csv';
    case Pipeline_BlobDataType.Json:
      return '.json';
    case Pipeline_BlobDataType.Video:
      return 'video/mp4,video/x-m4v,video/*';
  }
};

/**
 * The Accept object used by react-dropzone to filter candidate files.
 */
export const dropzoneAcceptObject = (
  format: Pipeline_BlobDataType
): Accept | undefined => {
  switch (format) {
    case Pipeline_BlobDataType.Csv:
      return {
        'text/csv': ['.csv'],
      };
    case Pipeline_BlobDataType.Json:
      return {
        'application/json': ['.json'],
      };
    case Pipeline_BlobDataType.Video:
      return {
        'video/3gpp': ['.3gp'],
        'video/x-ms-asf': ['.asf'],
        'application/vnd.ms-asf': ['.asf'],
        'video/x-msvideo': ['.avi'],
        'video/x-f4v': ['.f4v'],
        'video/x-flv': ['.flv'],
        'video/hevc': ['.hevc'],
        'video/mp2t': ['.m2ts', '.mts', '.ts'],
        'video/mpeg': ['.m2v', '.mpeg', '.mpg'],
        'video/x-m4v': ['.m4v'],
        'video/x-motion-jpeg': ['.mjpeg'],
        'video/x-matroska': ['.mkv'],
        'video/quicktime': ['.mov'],
        'video/mp4': ['.mp4'],
        'application/mxf': ['.mxf'],
        'video/ogg': ['.ogg'],
        'video/x-theora+ogg': ['.ogg'],
        'video/ogv': ['.ogv'],
        'application/x-shockwave-flash': ['.swf'],
        'video/x-ms-vob': ['.vob'],
        'video/webm': ['.webm'],
        'video/x-ms-wmv': ['.wmv'],
        'video/x-ms-wtv': ['.wtv'],
      };
  }
};

/*
 * The extension that should be used for files of the given format. Returns `undefined` if the format
 * is too generic to have a single extension.
 */
export const blobDataTypeExtension = (format: Pipeline_BlobDataType) => {
  switch (format) {
    case Pipeline_BlobDataType.Csv:
      return 'csv';
    case Pipeline_BlobDataType.Json:
      return 'json';
  }
};

/**
 * Instead of checking that the blob datatypes are simply identical, here we take into consideration that
 * CSV is compatible with PIPE_CSV and JSON is compatible with PIPE_JSON.
 */
const blobDataTypesCompatible = (
  a: Pipeline_BlobDataType,
  b: Pipeline_BlobDataType
) => {
  if (a === b) return true;
  const check = (left: Pipeline_BlobDataType, right: Pipeline_BlobDataType) =>
    (left === Pipeline_BlobDataType.Csv &&
      right === Pipeline_BlobDataType.PipeCsv) ||
    (left === Pipeline_BlobDataType.Json &&
      right === Pipeline_BlobDataType.PipeJson);
  return check(a, b) || check(b, a);
};

export class PipelineDataType extends CustomDataType {
  private _compatibleTypeKeys: string[];
  get compatibleTypeKeys(): string[] {
    return this._compatibleTypeKeys;
  }
  blobDataType: Pipeline_BlobDataType | undefined;
  handleHidden = false;
  requiresInputWithFileFormat = false;
  downloadable = false;
  uploadable = false;
  requiresConfigKeyValue: string | undefined;
  requiresBlobMetaKey:
    | {
        inputKey: string;
        metaKey: string;
      }
    | undefined;

  constructor(key: string, label: string, description: string) {
    super(key, label, description);
    this._compatibleTypeKeys = [key];
  }

  compatibleWith(other: PipelineDataType): boolean {
    // By default this is just checking that they have the same key, but it allows for
    // either to add extra compatible types by using the COMPATIBLE_WITH method during building.
    const keysCompatible =
      this.compatibleTypeKeys.includes(other.key) ||
      other.compatibleTypeKeys.includes(this.key);

    // If both datatypes have a blobDataType, check their compatibility
    const blobTypesMatch =
      !this.blobDataType ||
      !other.blobDataType ||
      blobDataTypesCompatible(this.blobDataType, other.blobDataType);

    return keysCompatible && blobTypesMatch;
  }

  COMPATIBLE_WITH(compatibleTypes: PipelineDataType[]): this {
    this._compatibleTypeKeys = compatibleTypes.map((dataType) => dataType.key);
    if (this.key !== 'ANY') {
      // Insert this own node's key into the list of compatible types, unless it's an ANY type
      this._compatibleTypeKeys.unshift(this.key);
    }

    return this;
  }

  BLOB_DATATYPE(dataType: Pipeline_BlobDataType): this {
    this.blobDataType = dataType;
    return this;
  }
  get DOWNLOADABLE(): this {
    this.downloadable = true;
    return this;
  }
  get UPLOADABLE(): this {
    this.uploadable = true;
    return this;
  }

  get NO_HANDLE(): this {
    this.handleHidden = true;
    return this;
  }

  REQUIRES_BLOB_METAKEY(inputKey: string, metaKey: string): this {
    this.requiresBlobMetaKey = {
      inputKey,
      metaKey,
    };
    return this;
  }

  REQUIRES_CONFIG_KEY_VALUE(configKey: string): this {
    this.requiresConfigKeyValue = configKey;
    return this;
  }

  LABEL(label: string): this {
    this.label = label;
    return this;
  }

  get REQUIRES_INPUT_WITH_FILEFORMAT(): this {
    this.requiresInputWithFileFormat = true;
    return this;
  }

  //////////////////////////////////////////////
  ////////////// TYPE DEFINITIONS //////////////
  //////////////////////////////////////////////

  static get ANY() {
    return new PipelineDataType('ANY', 'Any', 'Any data type');
  }

  static get VIDEO_FRAME() {
    return new PipelineDataType(
      'VIDEO_FRAME',
      'Video frames',
      'Sequential video frames.'
    );
  }

  static get SEGMENTATION_MASKS() {
    return new PipelineDataType(
      'SEGMENTATION_MASKS',
      'Segmentation masks',
      'Per-pixel segmentation masks.'
    );
  }

  static get BOUNDING_BOXES() {
    return new PipelineDataType(
      'BOUNDING_BOXES',
      'Bounding boxes',
      'Predicted bounding box coordinates.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get TRACKED_BOUNDING_BOXES() {
    return new PipelineDataType(
      'TRACKED_BOUNDING_BOXES',
      'Tracked bounding boxes',
      'Predicted bounding box coordinates after tracking.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get ANY_BOUNDING_BOXES() {
    const d = new PipelineDataType(
      'ANY_BOUNDING_BOXES',
      'Bounding boxes',
      'Predicted bounding box coordinates, either with or without tracking.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
    // This datatype is essentially the union of the two bounding box types, so it's compatible with either of those keys
    d.compatibleWith = (other: PipelineDataType): boolean => {
      return [
        PipelineDataType.BOUNDING_BOXES.key,
        PipelineDataType.TRACKED_BOUNDING_BOXES,
      ].includes(other.key);
    };
    return d;
  }

  static get TRACKED_WORLD_POSITIONS_2D() {
    return new PipelineDataType(
      'TRACKED_WORLD_POSITIONS',
      'Tracked world positions',
      'World position coordinates that correspond to tracked bounding boxes.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get CLASS_LABELS() {
    return new PipelineDataType(
      'CLASS_LABELS',
      'Class labels',
      'Predicted class labels.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get CLASS_SCORES() {
    return new PipelineDataType(
      'CLASS_SCORES',
      'Prediction confidences',
      'Per-object prediction confidences.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get POSE_2D() {
    return new PipelineDataType(
      'POSE_2D',
      '2D Pose',
      '2D predicted pose.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get POSE_3D() {
    return new PipelineDataType(
      'POSE_3D',
      '3D Pose',
      '3d predicted pose.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get POINT_CORRESPONDENCE() {
    return new PipelineDataType(
      'POINT_CORRESPONDENCE',
      'Image/World Correspondence',
      'Image and world correspondence.'
    );
  }

  static get CALIBRATION_PATTERNS() {
    return new PipelineDataType(
      'CALIBRATION_PATTERNS',
      'Calibration Patterns',
      'Detected calibration patterns in camera space.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get WORLD_CALIBRATION_PATTERNS() {
    return new PipelineDataType(
      'WORLD_CALIBRATION_PATTERNS',
      'World Calibration Patterns',
      'Detected calibration patterns in world space.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get WORLD_TRANSFORMATION_MATRIX() {
    return new PipelineDataType(
      'WORLD_TRANSFORMATION_MATRIX',
      'World transformation matrix',
      'A matrix to convert from image pixels to world metres.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }
  static get CAMERA_INTRINSIC_MATRIX() {
    return new PipelineDataType(
      'CAMERA_INTRINSIC_MATRIX',
      'Camera intrinsic matrix',
      'A 3x3 matrix representing the camera intrinsics.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }
  static get CAMERA_DISTORTION_COEFFICIENTS() {
    return new PipelineDataType(
      'CAMERA_DISTORTION_COEFFICIENTS',
      'Camera distortion coefficients',
      "An array of length 5 of camera distortion coefficients, ordered the same as OpenCV's definition."
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }
  static get CAMERA_PROJECTION_MATRIX() {
    return new PipelineDataType(
      'CAMERA_PROJECTION_MATRIX',
      'Camera projection matrix',
      'A 3x4 matrix projection matrix, transforming world-space points to image pixels.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get IMAGE_COORDINATES() {
    return new PipelineDataType(
      'IMAGE_COORDINATES',
      'Image coordinates',
      'Coordinates in image pixel space.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }
  static get WORLD_COORDINATES_3D() {
    return new PipelineDataType(
      'WORLD_COORDINATES',
      'World coordinates',
      'Coordinates in world-space coordinates.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Json);
  }

  static get SCALAR() {
    return new PipelineDataType(
      'SCALAR',
      'Scalar value',
      'A numerical value.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get VELOCITIES() {
    return new PipelineDataType(
      'VELOCITIES',
      'Velocities',
      'Velocities that correspond to tracked bounding boxes'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get CUMULATIVE_DISTANCES() {
    return new PipelineDataType(
      'CUMULATIVE_DISTANCES',
      'Cumulative distances',
      'Cumulative distances that correspond to tracked bounding boxes'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Csv);
  }

  static get VIDEO_FILE() {
    return new PipelineDataType(
      'VIDEO_FILE',
      'Video File',
      'A downloadable video file.'
    ).BLOB_DATATYPE(Pipeline_BlobDataType.Video);
  }

  static get GCP_VIDEO_FILE() {
    return new PipelineDataType(
      'GCP_VIDEO_FILE',
      'GCP Video File',
      'A video file downloadable from GCP.'
    );
  }
}

const stringSub = (
  str: string,
  instance: PipelineConfigType,
  context: IGenerateContext
) => {
  const replacers = {
    '{{nodeId}}': () => String(context.nodeId),
    '{{configId}}': () => String(context.configId),
    '{{extension}}': () =>
      String(
        blobDataTypeExtension(
          context.node.data?.config?.file_format as Pipeline_BlobDataType
        )
      ),
  };

  let outStr = str;
  let replacing = true;
  while (replacing) {
    replacing = false;
    Object.entries(replacers).forEach(([key, generate]) => {
      if (outStr.indexOf(key) !== -1) {
        outStr = outStr.replaceAll(key, generate());
        replacing = true;
      }
    });
  }

  return outStr;
};

export type FlowGraphNodeType = {
  name: string;
  description: string;
  descriptionDetail: string;
  icon?: IconDefinition;
  category: NodeCategory;
  moduleType: string | null;
  zendeskDocsLink?: string;
  blobInputAffectorKey?: string;
  configTypes?: {
    key: string;
    type: PipelineConfigType;
  }[];
  inputTypes?: {
    key: string;
    type: PipelineDataType;
  }[];
  outputTypes?: {
    key: string;
    type: PipelineDataType;
  }[];
};

export interface IFlowGraphNodeData {
  nodeType: NodeTypeKey;
  moduleType: string | null;
  collapsed: boolean;
  config?:
    | {
        [key: string]: any;
      }
    | undefined;
}
export type FlowGraphNode<T = any> = Node<T> & {
  data?: IFlowGraphNodeData;
};

export type OriginDataType = {
  key: string;
  type: PipelineDataType;
};
