import { faChevronUp } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineDataType } from 'graph/types';

export default {
  name: 'Stereo Triangulator',
  description:
    'Triangulate coordinates in stereo images to produce real world positions.',
  descriptionDetail:
    'When given a pair of calibrated cameras, this node triangulate their associated pixel coordinates into real world coordinates. Note that the IDs of the first camera are used as the IDs of the produced points.',
  icon: faChevronUp,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'StereoTriangulator',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060566880783',
  configTypes: [],
  inputTypes: [
    {
      key: 'first_points',
      type: PipelineDataType.IMAGE_COORDINATES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.CALIBRATION_PATTERNS,
        PipelineDataType.POSE_2D,
      ])
        .DESCRIPTION('The image coordinates for first camera.')
        .UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_camera_proj_mat',
      type: PipelineDataType.CAMERA_PROJECTION_MATRIX.REQUIRED.DESCRIPTION(
        'The projection matrix for the first camera.'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_camera_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.DESCRIPTION(
        'The distortion coefficients for the first camera.'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'second_points',
      type: PipelineDataType.IMAGE_COORDINATES.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.CALIBRATION_PATTERNS,
        PipelineDataType.POSE_2D,
      ])
        .DESCRIPTION('The image coordinates for second camera.')
        .UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_camera_proj_mat',
      type: PipelineDataType.CAMERA_PROJECTION_MATRIX.REQUIRED.DESCRIPTION(
        'The distortion coefficients for the second camera.'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_camera_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.DESCRIPTION(
        'The distortion coefficients for the second camera.'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
  ],
  outputTypes: [
    {
      key: 'world_points',
      type: PipelineDataType.WORLD_COORDINATES_3D.REQUIRED.COMPATIBLE_WITH([
        PipelineDataType.POSE_3D,
        PipelineDataType.WORLD_COORDINATES_3D,
      ]),
    },
  ],
};
