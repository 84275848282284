import { FlowGraphNodeType } from 'graph/types';
import useConfirmDialog from 'hooks/useConfirmDialog';

import { createContext, ReactNode } from 'react';
import { ConfirmDialogProps } from './ConfirmDialog';

interface NodeInfoDialogContext {
  showNodeInfoDialog: (nodeTypeObj: FlowGraphNodeType) => void;
}

export const NodeInfoDialogContext = createContext<NodeInfoDialogContext>(
  undefined!
);

interface Props {
  children: ReactNode;
}
export default ({ children }: Props) => {
  const { triggerDialog } = useConfirmDialog();

  const showNodeInfoDialog = (nodeTypeObj: FlowGraphNodeType) => {
    const dialogProps: ConfirmDialogProps = {
      title: nodeTypeObj.name,
      message: nodeTypeObj.descriptionDetail,
      confirmButtonText: 'OK',
      onConfirm: () => null,
      confirmButtonStyle: 'primary',
      hideCancelButton: true,
    };
    if (nodeTypeObj.zendeskDocsLink) {
      dialogProps.cancelButtonText = 'Documentation';
      dialogProps.onCancel = () =>
        window.open(nodeTypeObj.zendeskDocsLink, '_blank');
      dialogProps.hideCancelButton = false;
    }
    triggerDialog(dialogProps);
  };

  return (
    <NodeInfoDialogContext.Provider
      value={{
        showNodeInfoDialog,
      }}
    >
      {children}
    </NodeInfoDialogContext.Provider>
  );
};
