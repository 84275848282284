import { Blob } from 'contexts/Graph';
import { Pipeline_BlobType } from '../graphql/graphql';

/**
 * Splits the given blobs into input and output blobs.
 */
export const splitBlobs = (blobs: Blob[]) => {
  const inputs: Blob[] = [];
  const outputs: Blob[] = [];
  blobs.forEach((blob) => {
    if (blob.blobType === Pipeline_BlobType.Input) inputs.push(blob);
    else if (blob.blobType === Pipeline_BlobType.Output) outputs.push(blob);
  });

  return { inputs, outputs };
};

/**
 * Find and returns the blob in the list with the given node and property IDs. If blobType is provided, only
 * blobs of that type will be considered in the search.
 */
export const getBlobByKey = (
  blobs: Blob[],
  nodeId: string,
  propertyId: string,
  blobType?: Pipeline_BlobType
) =>
  blobs.find(
    (blob) =>
      blob.nodeId === nodeId &&
      blob.propertyId === propertyId &&
      (!blobType || blob.blobType === blobType)
  );
