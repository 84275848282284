import { TextField, Typography } from '@mui/material';
import { Pipeline_Announcement } from 'graphql/graphql';

type Props = Pick<Pipeline_Announcement, 'title'> & {
  onChangeTitle: ((title: Pipeline_Announcement['title']) => void) | undefined;
};

const MAX_TITLE_LEN = 255;
const TitleEdit = ({
  title,
  onChangeTitle,
}: {
  title: Pipeline_Announcement['title'];
  onChangeTitle: (title: Pipeline_Announcement['title']) => void;
}) => {
  return (
    <TextField
      fullWidth
      label="Title"
      variant="outlined"
      value={title}
      onChange={(e) => onChangeTitle(e.target.value.slice(0, MAX_TITLE_LEN))}
      error={title?.length === 0}
    />
  );
};

const AnnouncementTitle = ({ title, onChangeTitle }: Props) => {
  if (onChangeTitle) {
    return <TitleEdit title={title} onChangeTitle={onChangeTitle} />;
  }
  return <Typography variant="h5">{title}</Typography>;
};

export default AnnouncementTitle;
