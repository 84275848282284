import { Pipeline_PointCorrespondenceTemplate } from 'graphql/graphql';
import { useEffect } from 'react';
import { useWizard } from 'react-use-wizard';
import { PointCorrespondence } from '../types';

interface Props {
  selectedTemplate: Pipeline_PointCorrespondenceTemplate | undefined;
  existingCorrespondence: PointCorrespondence | undefined;
  selectTemplateById: (id: string | null) => void;
  loading: boolean;
}
const RedirectStep = ({
  existingCorrespondence,
  loading,
  selectTemplateById,
}: Props) => {
  const { goToStep } = useWizard();

  // Select template if correspondence is passed in
  useEffect(() => {
    if (!loading) {
      if (existingCorrespondence) {
        selectTemplateById(existingCorrespondence.templateId);
        goToStep(2);
      } else {
        goToStep(1);
      }
    }
  }, [existingCorrespondence, goToStep, loading, selectTemplateById]);

  return null;
};

export default RedirectStep;
