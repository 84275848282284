/**
 * This provides a lazy loading wrapper around the homography module, minimising the
 * amount of data that needs to be loaded on initial page load.
 */

import { Mat } from 'cv-homography';
import { Vec2 } from './Vec2';

export const warpPerspective = async (
  image: HTMLImageElement,
  transMat: Mat,
  outputWidth: number,
  outputHeight: number,
  inputImageRescaleFactor = 1
) => {
  const homography = await import('./homography');
  return homography.warpPerspective(
    image,
    transMat,
    outputWidth,
    outputHeight,
    inputImageRescaleFactor
  );
};

export const findTransformMatrix = async (
  sourcePoints: Vec2[],
  destPoints: Vec2[]
) => {
  const homography = await import('./homography');
  return homography.findTransformMatrix(sourcePoints, destPoints);
};

export const transformPoints = async (sourcePoints: Vec2[], transMat: Mat) => {
  const homography = await import('./homography');
  return homography.transformPoints(sourcePoints, transMat);
};
