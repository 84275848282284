import { faMessage } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType } from 'graph/types';

export default {
  name: 'Markdown Text Editor',
  description: 'Write miscellaneous Markdown-formatted notes.',
  descriptionDetail:
    'This node allows you to annotate your graph or leave notes using a rich text editor.',
  icon: faMessage,
  category: NodeCategory.MISCELLANEOUS,
  moduleType: null,
  configTypes: [
    {
      key: 'notes',
      type: PipelineConfigType.MARKDOWN('Notes')
        .DEFAULT({
          value:
            '## Markdown Editor\n\nThis text field supports **Markdown** formatting. Simply click the area and type away!\n\n*Unfamiliar with Markdown? Visit [MarkdownGuide.org](https://www.markdownguide.org/cheat-sheet/) for hints.*',
          height: 230,
        })
        .DESCRIPTION('The markdown-formatted text.'),
    },
  ],
};
