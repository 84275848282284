import { IGraphSummary } from 'components/GraphBrowserDialog';
import useAuth from 'hooks/useAuth';
import { createContext, ReactElement } from 'react';
import { PrivacySetting, useGetGraphsSummaryQuery } from '../graphql/graphql';

export interface IGraphSummariesContext {
  userGraphs: IGraphSummary[];
  publicOrTemplateGraphs: IGraphSummary[];
  refetchGraphSummaries: () => Promise<void>;
  graphSummariesLoading: boolean;
}
export const GraphSummariesContext = createContext<IGraphSummariesContext>(
  undefined!
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { isPipeliner } = useAuth();
  const {
    data,
    refetch,
    loading: graphSummariesLoading,
  } = useGetGraphsSummaryQuery({
    skip: !isPipeliner,
  });

  const userGraphs = (data?.Pipeline_allGraphs.filter(
    (graph) => graph?.isOwner
  ) ?? []) as IGraphSummary[];
  const publicOrTemplateGraphs = (data?.Pipeline_allGraphs.filter((graph) =>
    [PrivacySetting.Public, PrivacySetting.Template].includes(graph?.privacy)
  ) ?? []) as IGraphSummary[];

  return (
    <GraphSummariesContext.Provider
      value={{
        userGraphs,
        publicOrTemplateGraphs,
        refetchGraphSummaries: async () => {
          await refetch();
        },
        graphSummariesLoading,
      }}
    >
      {children}
    </GraphSummariesContext.Provider>
  );
};
