import { renameNode, updateNode } from 'graph';
import { applyCorrectionsAndMigrations } from 'graph/correctionsAndMigrations';
import { IFlowGraphNodeData } from 'graph/types';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useFlowRenderer from 'hooks/useFlowRenderer';
import useGraphFromTemplate from 'hooks/useGraphFromTemplate';
import useGraphSummaries from 'hooks/useGraphSummaries';
import useNotifications from 'hooks/useNotifications';
import usePrevious from 'hooks/usePrevious';
import useUndo, { IUseUndo, SetValueCallback } from 'hooks/useUndo';
import { omit, pick } from 'lodash-es';
import {
  Pipeline_BlobDataType,
  Pipeline_BlobDto,
  Pipeline_BlobType,
  Pipeline_FormatConfigFlag,
  Pipeline_Graph,
  Pipeline_GraphDto,
  PrivacySetting,
  useGetGraphFullQuery,
  useSaveGraphMutation,
} from '../graphql/graphql';

import {
  createContext,
  ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { Edge, Node, NodeChange, useStore } from 'reactflow';
import { useDebouncedCallback } from 'use-debounce';
import { splitBlobs } from 'utils/blobs';
import { isJsonEqual, replaceNullsWithUndefs } from 'utils/helpers';

interface IGraphMeta {
  id?: string | undefined;
  name?: string | undefined;
  description?: string | undefined;
  templateGraph?: string | undefined;
  privacy?: PrivacySetting | undefined;
}

export type IFlowGraph = {
  edges: Edge[];
  nodes: Node[];
};

export interface IGraphCanonical extends IGraphMeta {
  flowGraph?: IFlowGraph;
}

export type Blob = {
  id?: string;
  blobDataType: Pipeline_BlobDataType;
  blobType: Pipeline_BlobType;
  formatConfigFlags: Pipeline_FormatConfigFlag[];
  nodeId: string;
  propertyId: string;
  blobName: string;
  originalFilename: string;
  metadata?: any;
  downloadUrl?: {
    url: string;
    expiryDate: Date;
  };
  uploadUrl?: {
    url: string;
    expiryDate: Date;
  };
  namespace?: {
    id: string;
    bucketName: string;
  };
  uploadPending?: boolean;
};

/**
 * The save status during the lifecycle of a graph:
 * When the user opens a pipeline: LOADING
 * After the loading completed: LOADING -> UP_TO_DATE
 * If the user makes a change: UP_TO_DATE -> UNSAVED
 * When the user stops making the change: UNSAVED -> SAVING -> UP_TO_DATE
 */
export enum SaveStatus {
  UNSAVED = 'UNSAVED',
  UP_TO_DATE = 'UP_TO_DATE',
  SAVING = 'SAVING',
  LOADING = 'LOADING',
}

const DEFAULT_GRAPH_META: IGraphMeta = {
  description: undefined,
  id: undefined,
  name: undefined,
  templateGraph: undefined,
  privacy: PrivacySetting.Private,
};
const DEFAULT_GRAPH_STATE: IGraphCanonical = {
  ...DEFAULT_GRAPH_META,
  flowGraph: {
    edges: [],
    nodes: [],
  },
};

const assembleBlobDto = (graphId: string, blob: Blob): Pipeline_BlobDto => {
  return {
    id: blob.id,
    blobDataType: blob.blobDataType,
    blobType: blob.blobType,
    formatConfigFlags: blob.formatConfigFlags,
    blobName: blob.blobName,
    originalFilename: blob.originalFilename,
    metadata: blob.metadata,
    graph: graphId,
    nodeId: blob.nodeId,
    propertyId: blob.propertyId,
    uploadPending: blob.uploadPending,
  };
};

const assembleGraphDto = (
  graph: IGraphCanonical,
  blobs: Blob[]
): Pipeline_GraphDto => {
  return {
    id: graph.id,
    name: graph.name,
    description: graph.description,
    flowGraph: graph.flowGraph,
    blobs: blobs.map((b) => assembleBlobDto(graph.id!, b)).filter(Boolean),
    privacy: graph.privacy,
  };
};

const useAbstractedGraph = (
  graphId: string | undefined
): {
  setGraphState: (
    newGraphState: IGraphCanonical | SetValueCallback<IGraphCanonical>,
    triggeringChange?: NodeChange
  ) => void;
  graphState: IGraphCanonical;
  refetchGraph: () => void;
  blobs: Blob[];
  setBlobs: (newBlobs: Blob[] | SetValueCallback<Blob[]>) => void;
  saveStatus: SaveStatus;
  flowGraphHistory: IUseUndo<IFlowGraph>;
} => {
  const { publicOrTemplateGraphs } = useGraphSummaries();
  ////////////////////////// Local state //////////////////////////////
  const [saveStatus, setSaveStatus] = useState<SaveStatus>(SaveStatus.LOADING);
  const [localGraphMeta, setLocalGraphMeta] = useState<
    IGraphMeta | undefined
  >();
  const [localBlobs, setLocalBlobs] = useState<Blob[]>([]);
  const undoableFlowGraph = useUndo<IFlowGraph>(
    { nodes: [], edges: [] },
    100,
    true
  );
  const refreshTimeoutRef = useRef<number | undefined>(undefined);
  const [rapidEventsFiring, setRapidEventsFiring] = useState(false);
  ////////////////////////// Local state END //////////////////////////

  const navigate = useNavigate();
  const { triggerDialog } = useConfirmDialog();
  const graphFromTemplateAndRedirect = useGraphFromTemplate();

  ////////////////////////// GraphQL //////////////////////////////
  const { data, loading, error, refetch } = useGetGraphFullQuery({
    fetchPolicy: 'no-cache',
    variables: { id: graphId ?? '' },
    skip: !graphId,
  });
  const [save] = useSaveGraphMutation();
  ////////////////////////// GraphQL END //////////////////////////////

  ////////////////////////// useCallback hooks //////////////////////////////
  /**
   * Save the graph stored in local state to the server.
   *
   * @param blobsOverride If provided, use these blobs instead of the local blobs. This avoids
   *  a race condition.
   */
  const saveGraph = useCallback(
    async (blobsOverride?: Blob[]) => {
      setSaveStatus(SaveStatus.SAVING);
      const blobsToSave = blobsOverride ?? localBlobs;
      if (localGraphMeta?.id) {
        await save({
          variables: {
            graph: assembleGraphDto(
              {
                ...localGraphMeta,
                flowGraph: undoableFlowGraph.present,
              },
              blobsToSave
            ),
          },
          refetchQueries: ['GetGraphFull'],
        });
      }
      setSaveStatus(SaveStatus.UP_TO_DATE);
    },
    [save, localGraphMeta, undoableFlowGraph.present, localBlobs]
  );

  /**
   * Save the graph stored in local state to the server with a trailing debounce of 500ms.
   */
  const debouncedSaveGraph = useDebouncedCallback(saveGraph, 500, {
    leading: false,
    trailing: true,
  });

  /**
   * Set the local graph state and initiate a debounced server save.
   */
  const setGraphState = useCallback(
    (
      newGraphState: IGraphCanonical | SetValueCallback<IGraphCanonical>,
      triggeringEvent?: NodeChange
    ) => {
      // If the graph is not empty, set the save status to unsaved, otherwise it should be loading.
      if (newGraphState !== DEFAULT_GRAPH_STATE) {
        setSaveStatus(SaveStatus.UNSAVED);
      }
      if (typeof newGraphState === 'function') {
        newGraphState = newGraphState({
          ...(localGraphMeta ?? DEFAULT_GRAPH_STATE),
          flowGraph: undoableFlowGraph.present,
        });
      }

      const newCanonicalGraph: IGraphCanonical = {
        ...(localGraphMeta ?? DEFAULT_GRAPH_STATE),
        ...newGraphState,
      };
      if (newCanonicalGraph.flowGraph) {
        const [correctedFlowGraph, correctedBlobs] =
          applyCorrectionsAndMigrations(
            newCanonicalGraph.flowGraph,
            localBlobs
          );

        /*
        This is what the triggering events look like for different actions, which
        has unfortunately led to some complexity in the code below.
          DRAGGING (new selection):
            { type: 'select', selected: true }     replace
            { type: 'position', dragging: true }    append
            ...
            { type: 'position', dragging: true }   replace
            { type: 'position', dragging: false }  replace
          DRAGGING (existing selection):
            { type: 'position', dragging: true }   append
            ...
            { type: 'position', dragging: true }   replace
            { type: 'position', dragging: false }  replace
          SELECTING:
            { type: 'select', selected: true }     replace
            { type: 'position', dragging: false }  replace
          DESELECT:
            { type: 'select', selected: false }    append
        */
        if (!triggeringEvent) {
          // append, end potential rapid fire
          undoableFlowGraph.set(correctedFlowGraph);
          if (rapidEventsFiring) {
            setRapidEventsFiring(false);
          }
        } else {
          if (triggeringEvent?.type === 'select' && triggeringEvent?.selected) {
            // Replace
            undoableFlowGraph.overwritePresent(correctedFlowGraph);
          } else if (
            triggeringEvent?.type === 'position' &&
            !triggeringEvent?.dragging
          ) {
            // Replace and end rapid fire
            undoableFlowGraph.overwritePresent(correctedFlowGraph);
            setRapidEventsFiring(false);
          } else if (
            triggeringEvent?.type === 'position' &&
            triggeringEvent?.dragging
          ) {
            // Check if first event in the rapid fire chain
            setRapidEventsFiring((wasRapidFiring) => {
              if (wasRapidFiring) {
                // Replace
                undoableFlowGraph.overwritePresent(correctedFlowGraph);
              } else {
                // append and start rapid fire
                undoableFlowGraph.set(correctedFlowGraph);
              }
              return true;
            });
          } else if (
            triggeringEvent?.type === 'select' &&
            !triggeringEvent?.selected
          ) {
            // Replace
            undoableFlowGraph.overwritePresent(correctedFlowGraph);
          }
        }

        setLocalBlobs(correctedBlobs);
      }
      const newGraphMeta: IGraphMeta = omit(newCanonicalGraph, ['flowGraph']);
      setLocalGraphMeta(newGraphMeta);

      void debouncedSaveGraph();
    },
    [
      debouncedSaveGraph,
      localBlobs,
      localGraphMeta,
      rapidEventsFiring,
      setRapidEventsFiring,
      undoableFlowGraph,
    ]
  );

  /**
   * Set the local blobs and initiate a debounced server save.
   */
  const setBlobs = useCallback(
    (newBlobs: Blob[] | SetValueCallback<Blob[]>) => {
      setSaveStatus(SaveStatus.UNSAVED);
      if (typeof newBlobs === 'function') {
        newBlobs = newBlobs(localBlobs);
      }
      setLocalBlobs(newBlobs);
      void debouncedSaveGraph(newBlobs);
    },
    [localBlobs, debouncedSaveGraph]
  );

  /**
   * Set the result from a graph query/mutation into local state.
   */
  const setRetrievedData = useCallback(
    (retrievedData: Pipeline_Graph) => {
      const cleanData = replaceNullsWithUndefs(retrievedData);
      const [correctedFlowGraph, correctedBlobs] =
        applyCorrectionsAndMigrations(
          cleanData.flowGraph as IFlowGraph,
          cleanData.blobs as Blob[]
        );

      // Save if the corrections changed anything
      if (
        cleanData.flowGraph &&
        (!isJsonEqual(cleanData.flowGraph, correctedFlowGraph) ||
          !isJsonEqual(cleanData.blobs, correctedBlobs))
      ) {
        void debouncedSaveGraph();
      }

      // Set the blobs no matter what
      setLocalBlobs(correctedBlobs);

      // Only overwrite local state if we've changed graph or are loading it the first time
      if (!localGraphMeta?.id || localGraphMeta?.id !== cleanData.id) {
        // Set the graph meta
        const newGraphMeta = pick(cleanData, Object.keys(DEFAULT_GRAPH_META));
        setLocalGraphMeta(newGraphMeta);

        // Push to the undoable flow graph state if it's different from current
        if (
          cleanData.flowGraph &&
          !isJsonEqual(undoableFlowGraph.present, correctedFlowGraph)
        ) {
          undoableFlowGraph.set(correctedFlowGraph);
        }
      }

      // Look for the blob with the earliest-expiring upload or download URL and set a timer to refresh it
      const currMs = new Date().getTime();
      const millisToFirstExpiration = Math.min(
        ...correctedBlobs.flatMap((blob: Blob) => {
          const millisToExpirations = [];
          if (blob?.downloadUrl?.expiryDate) {
            millisToExpirations.push(
              new Date(blob.downloadUrl.expiryDate).getTime() - currMs
            );
          }
          if (blob?.uploadUrl?.expiryDate) {
            millisToExpirations.push(
              new Date(blob.uploadUrl.expiryDate).getTime() - currMs
            );
          }
          return millisToExpirations;
        })
      );
      if (millisToFirstExpiration > 0) {
        window.clearTimeout(refreshTimeoutRef.current);
        refreshTimeoutRef.current = window.setTimeout(() => {
          void refetch();
        }, millisToFirstExpiration);
      }

      setSaveStatus(SaveStatus.UP_TO_DATE);
    },
    [debouncedSaveGraph, localGraphMeta?.id, refetch, undoableFlowGraph]
  );

  /**
   * Wrap undo/redo calls so the graph is saved
   */
  const undoWithSave = useCallback(() => {
    if (undoableFlowGraph.canUndo) {
      undoableFlowGraph.undo();
      void debouncedSaveGraph();
    }
  }, [undoableFlowGraph, debouncedSaveGraph]);
  const redoWithSave = useCallback(() => {
    if (undoableFlowGraph.canRedo) {
      undoableFlowGraph.redo();
      void debouncedSaveGraph();
    }
  }, [undoableFlowGraph, debouncedSaveGraph]);

  ////////////////////////// useCallback hooks END //////////////////////////

  ////////////////////////// useEffect hooks //////////////////////////
  /**
   * Set the state to loading if the graph is loading.
   */
  useEffect(() => {
    if (loading) {
      setSaveStatus(SaveStatus.LOADING);
    }
  }, [loading]);

  /**
   * Reroute to the index if an error occurs while loading the graph. If it's an "item missing" error, it may be that
   * we've opened a public link and wish to use it as a template to create a new graph.
   */
  useEffect(() => {
    if (error) {
      const errorCode = error.graphQLErrors?.[0].extensions.code;
      if (errorCode === 'FORBIDDEN' && graphId) {
        if (publicOrTemplateGraphs?.find((g) => g.id === graphId)) {
          triggerDialog({
            title: 'New pipeline from template',
            message: `This is a public/template pipeline. Would you like to create a new pipeline from this template?`,
            onConfirm: () => void graphFromTemplateAndRedirect(graphId),
            onCancel: () => navigate('/'),
            confirmButtonStyle: 'primary',
          });
        }
      } else if (errorCode === 'INTERNAL_SERVER_ERROR') {
        // It either doesn't exist or is private. Don't tell the user which though!
        navigate('/');
      }
    }
  }, [error, publicOrTemplateGraphs, graphId]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Set the local state to the graph data if the graph is loaded.
   */
  useEffect(() => {
    if (data) {
      setRetrievedData(data?.Pipeline_graph as Pipeline_Graph);
    } else if (loading) {
      // Set an empty graph while loading a different one
      setGraphState(DEFAULT_GRAPH_STATE);
    }
  }, [data, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  ////////////////////////// useEffect hooks END //////////////////////////

  return {
    setGraphState,
    graphState: {
      ...(localGraphMeta ?? DEFAULT_GRAPH_STATE),
      flowGraph: undoableFlowGraph.present,
    },
    flowGraphHistory: {
      ...undoableFlowGraph,
      undo: undoWithSave,
      redo: redoWithSave,
    },
    blobs: localBlobs,
    setBlobs,
    saveStatus,
    refetchGraph: () => void refetch(),
  };
};

export interface IGraphContext {
  flowGraphHistory: IUseUndo<IFlowGraph>;
  saveStatus: SaveStatus;
  graphState: IGraphCanonical;
  refetchGraph: () => void;
  setGraphState: (
    newGraphState: IGraphCanonical | SetValueCallback<IGraphCanonical>
  ) => void;
  inputBlobs: Blob[];
  outputBlobs: Blob[];
  setBlobMetaField: (blobId: string, metaKey: string, metaValue: any) => void;
  setFlowGraph: (
    value: IFlowGraph | SetValueCallback<IFlowGraph>,
    triggeringAction?: NodeChange
  ) => void;
  updateNodeId: (oldId: string, newId: string) => void;
  updateNodeData: (id: string, nodeData: IFlowGraphNodeData) => void;
  deleteNode: (id: string) => void;
  collapseNode: (id: string) => void;
  expandNode: (id: string) => void;
  reassociateBlobs: (previousNodeId: string, newNodeId: string) => void;
  setBlob: (blob: Blob) => void;
  removeBlob: (nodeId: string, propertyId: string) => void;
  graphIsInteractive: boolean;
}

export const GraphContext = createContext<IGraphContext>(undefined!);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const { gid: graphIdParam } = useParams<{ gid: string }>();
  const graphIsInteractive = useStore(
    (store) => store.nodesDraggable && store.nodesConnectable
  );

  const {
    setGraphState,
    graphState,
    flowGraphHistory,
    blobs,
    setBlobs,
    saveStatus,
    refetchGraph,
  } = useAbstractedGraph(graphIdParam);
  const prevSaveStatus = usePrevious(saveStatus);
  const {
    instance: [flowRenderer],
  } = useFlowRenderer();
  const notify = useNotifications();
  const { triggerDialog } = useConfirmDialog();
  /**
   * Set the flow graph state into the greater graph state, wrapping callbacks
   * into the setGraphState callback.
   */
  const setFlowGraph = useCallback(
    (
      flowGraph: IFlowGraph | SetValueCallback<IFlowGraph>,
      triggeringAction?: NodeChange
    ) => {
      if (typeof flowGraph === 'function') {
        setGraphState((prevState) => {
          return {
            ...prevState,
            flowGraph: flowGraph(
              prevState.flowGraph ?? { nodes: [], edges: [] }
            ),
          };
        }, triggeringAction);
      } else {
        setGraphState({ flowGraph }, triggeringAction);
      }
    },
    [setGraphState]
  );

  /**
   * Change the ID of a node in the flowGraph, ensuring edges are updated accordingly.
   */
  const updateNodeId = useCallback(
    (oldId: string, newId: string) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setFlowGraph((flowGraph) => renameNode(flowGraph, graphId, oldId, newId));
    },
    [graphState, notify, setFlowGraph]
  );

  /**
   * Set the data of a node in the flowGraph.
   */
  const updateNodeData = useCallback(
    (id: string, nodeData: IFlowGraphNodeData) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setFlowGraph((flowGraph) =>
        updateNode(flowGraph, id, (prevNode) => {
          return { ...prevNode, data: { ...nodeData } };
        })
      );
    },
    [graphState, notify, setFlowGraph]
  );

  /**
   * Delete a node from the flowGraph.
   */
  const deleteNode = useCallback(
    (id: string) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }
      // Find the blob associated with the node, if exists
      const blob = blobs.find((b) => b.nodeId === id);

      const doDelete = () => {
        setFlowGraph((prevFlowGraph) =>
          updateNode(prevFlowGraph, id, () => null)
        );
        if (blob) {
          setBlobs(blobs.filter((b) => b.nodeId !== id));
        }
      };

      if (blob) {
        triggerDialog({
          title: 'Are you sure?',
          message: `There is a file attached to this node which will be deleted along with the node. This is irreversible and will require you to re-upload or regenerate the file if you change your mind.`,
          onConfirm: doDelete,
          confirmButtonStyle: 'danger',
          confirmButtonText: 'Delete',
        });
      } else {
        doDelete();
      }
    },
    [blobs, graphState, notify, setBlobs, setFlowGraph, triggerDialog]
  );

  /**
   * Collapse a node in the flowGraph.
   */
  const collapseNode = useCallback(
    (id: string) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setFlowGraph((prevFlowGraph) =>
        updateNode(prevFlowGraph, id, (prevNode) => ({
          ...prevNode,
          data: {
            ...prevNode.data,
            collapsed: true,
          },
        }))
      );
    },
    [graphState, notify, setFlowGraph]
  );

  /**
   * Expand a node in the flowGraph.
   */
  const expandNode = useCallback(
    (id: string) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setFlowGraph((prevFlowGraph) =>
        updateNode(prevFlowGraph, id, (prevNode) => ({
          ...prevNode,
          data: {
            ...prevNode.data,
            collapsed: false,
          },
        }))
      );
    },
    [graphState, notify, setFlowGraph]
  );

  /**
   * Set a blob that corresponds to given node/property. Not providing a blob will remove the blob.
   */
  const setBlobById = useCallback(
    (nodeId: string, propertyId: string, blob?: Blob) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setBlobs((prevBlobs) => {
        // Remove the blob if it already exists.
        const newBlobs = prevBlobs.filter(
          (blob) => !(blob.nodeId === nodeId && blob.propertyId === propertyId)
        );
        // Append the new blob, if provided.
        if (blob) {
          newBlobs.push(blob);
        }

        return newBlobs;
      });
    },
    [graphState, notify, setBlobs]
  );

  /**
   * Set a blob in the graph blobs array, updating or replacing to resolve any conflicts.
   */
  const setBlob = useCallback(
    (blob: Blob) => {
      return setBlobById(blob.nodeId, blob.propertyId, blob);
    },
    [setBlobById]
  );

  /**
   * Remove the blob that corresponds to given node/property.
   */
  const removeBlob = (nodeId: string, propertyId: string) =>
    setBlobById(nodeId, propertyId);

  /**
   * If a node has been renamed, ensure that any blobs that reference the old ID are updated.
   */
  const reassociateBlobs = useCallback(
    (previousNodeId: string, newNodeId: string) => {
      const { id: graphId } = graphState;
      if (!graphId) {
        notify.error('An unexpected error occurred - graph id is missing');
        return;
      }

      setBlobs((prevBlobs) =>
        prevBlobs.map((blob) => {
          if (blob.nodeId === previousNodeId) {
            return {
              ...blob,
              nodeId: newNodeId,
            };
          }
          return blob;
        })
      );
    },
    [graphState, notify, setBlobs]
  );
  /**
   * Actions to perform when the graph is first loaded.
   */
  useEffect(() => {
    if (
      prevSaveStatus === SaveStatus.LOADING &&
      saveStatus === SaveStatus.UP_TO_DATE
    ) {
      // After a small delay to allow the graph to render, fit the graph into view.
      setTimeout(() => flowRenderer?.fitView(), 100);

      // Display a toast notification if the graph is public/template, warning the user to be careful of modifications.
      if (
        graphState?.privacy &&
        [PrivacySetting.Public, PrivacySetting.Template].includes(
          graphState?.privacy
        )
      ) {
        notify.warning(
          'This graph is publicly shared. Use caution when making changes as these will be reflected in any future instances.',
          { autoClose: 5000 }
        );
      }
    }
  }, [saveStatus, prevSaveStatus, flowRenderer, graphState?.privacy, notify]);

  const setBlobMetaField = useCallback(
    (blobId: string, metaKey: string, metaValue: any) => {
      setBlobs((prevBlobs) => {
        const newBlobs = prevBlobs.map((blob) => {
          if (blob.id === blobId) {
            return {
              ...blob,
              metadata: {
                ...blob.metadata,
                [metaKey]: metaValue,
              },
            };
          }
          return blob;
        });

        return newBlobs;
      });
    },
    [setBlobs]
  );

  const { inputs: inputBlobs, outputs: outputBlobs } = useMemo(
    () => splitBlobs(blobs),
    [blobs]
  );
  return (
    <GraphContext.Provider
      value={{
        setGraphState,
        graphState,
        flowGraphHistory,
        inputBlobs,
        outputBlobs,
        setBlobMetaField,
        saveStatus,
        setFlowGraph,
        updateNodeId,
        updateNodeData,
        deleteNode,
        collapseNode,
        expandNode,
        reassociateBlobs,
        setBlob,
        removeBlob,
        refetchGraph,
        graphIsInteractive,
      }}
    >
      {children}
    </GraphContext.Provider>
  );
};
