import hotkeys from 'constants/hotkeys';
import {
  CONNECTION_LINE_STYLE,
  EDGE_STYLE,
  generateUniqueNodeId,
  getNodeTypeFromNode,
  renameNode,
} from 'graph';
import { NodeCategory } from 'graph/nodeCategories';
import { FlowGraphNode, PipelineDataType } from 'graph/types';
import useAllJobInputs from 'hooks/useAllJobInputs';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useFlowGraph from 'hooks/useFlowGraph';
import useFlowRenderer from 'hooks/useFlowRenderer';
import useGraph from 'hooks/useGraph';
import { Fragment, useCallback, useEffect, useState } from 'react';
import {
  addEdge,
  applyEdgeChanges,
  applyNodeChanges,
  Background,
  Connection,
  Controls,
  Edge,
  OnConnectEnd,
  OnConnectStart,
  OnConnectStartParams,
  OnEdgesChange,
  OnNodesChange,
  ReactFlow,
  Viewport,
} from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import { Pipeline_BlobType } from '../../graphql/graphql';
import NodeBrowser from '../NodeBrowser';
import CustomNode from './CustomNode';
import styles from './index.module.scss';

const NODE_TYPES: any = { CustomNode };
const DEFAULT_VIEWPORT: Viewport = { zoom: 1, x: 0, y: 0 };

const Graph = () => {
  const {
    instance: [instance, setInstance],
    setProspectiveConnection,
  } = useFlowRenderer();
  const { triggerDialog } = useConfirmDialog();
  const { flowGraph, setFlowGraph } = useFlowGraph();
  const [isEdgeFound, setEdgeFound] = useState<boolean>(true);
  const [destination, setDestination] = useState(false);
  const [nodeBrowserDialogOpen, setNodeBrowserDialogOpen] = useState(false);
  const [originDataType, setOriginDataType] = useState<{
    key: string;
    type: PipelineDataType;
  }>();
  const [originConnectionParams, setOriginConnectionParams] =
    useState<OnConnectStartParams>();
  const {
    graphState: { id: graphId },
    reassociateBlobs,
  } = useGraph();
  const { inputs, removeInputValue } = useAllJobInputs();
  const [prevFlowGraphWasEmpty, setPrevFlowGraphWasEmpty] = useState(true);
  const [targetViewport, setTargetViewport] = useState(DEFAULT_VIEWPORT);
  const [targetEdgeUsed, setTargetEdgeUsed] = useState(false);

  const onConnectStart: OnConnectStart = useCallback(
    (event, params) => {
      setEdgeFound(true);
      setDestination(false);
      setProspectiveConnection(params);

      const originHandleId = params.handleId;
      const originHandleType = params.handleType;

      if (flowGraph.nodes.length > 0) {
        const originNode = flowGraph.nodes.find((n) => n.id === params.nodeId)!;

        if (originNode) {
          const originNodeTypeData = getNodeTypeFromNode(
            originNode as FlowGraphNode
          );

          if (originHandleType === 'target') {
            setTargetEdgeUsed(false);
            const selectedEdgeExist = flowGraph?.edges?.find((edge) => {
              return (
                edge.target === originNode.id &&
                edge.targetHandle === params.handleId
              );
            });

            if (selectedEdgeExist) {
              // Disable origin use when an edge already exists
              setTargetEdgeUsed(true);
              return;
            }
          } else {
            setTargetEdgeUsed(false);
          }

          // Initialize with a default value to prevent issues during initial render
          const selectedOriginDataType:
            | {
                key: string;
                type: PipelineDataType;
              }
            | undefined =
            originHandleType === 'source'
              ? originNodeTypeData?.outputTypes?.find(
                  (outputType) => outputType.key === originHandleId
                )
              : originNodeTypeData?.inputTypes?.find(
                  (inputType) => inputType.key === originHandleId
                );

          setOriginDataType(selectedOriginDataType);
          setOriginConnectionParams(params);
        } else {
          // Handle the case when the sourceNode is not found
          console.error(`Node with id ${params.nodeId} not found.`);
        }
      }
    },
    [
      setProspectiveConnection,
      setEdgeFound,
      setDestination,
      setOriginDataType,
      setOriginConnectionParams,
      setTargetEdgeUsed,
      flowGraph,
    ]
  );

  useEffect(() => {
    if (!isEdgeFound && !destination && !targetEdgeUsed) {
      setNodeBrowserDialogOpen(true);
      setEdgeFound(true);
      setDestination(false);
    }
  }, [isEdgeFound, destination, targetEdgeUsed]);

  const onConnectEnd: OnConnectEnd = useCallback(
    (event: MouseEvent | TouchEvent) => {
      const clientX =
        'touches' in event ? event.touches[0].clientX : event.clientX;
      const clientY =
        'touches' in event ? event.touches[0].clientY : event.clientY;

      setTargetViewport({
        zoom: 1,
        x: clientX,
        y: clientY,
      });

      setProspectiveConnection(undefined);
      setEdgeFound(false);
    },
    [setProspectiveConnection, setEdgeFound]
  );

  const onConnect = useCallback(
    (connection: Edge | Connection) => {
      if (connection.target && connection.targetHandle) {
        setDestination(true);
        const makeConnection = () => {
          setFlowGraph((prevFlowGraph) => {
            let newFlowGraph = {
              ...prevFlowGraph,
            };

            const targetNode = newFlowGraph.nodes.find(
              (n) => n.id === connection.target
            )!;

            // If the target node is an output type, rename it to be more descriptive
            const targetNodeType = getNodeTypeFromNode(
              targetNode as FlowGraphNode
            );
            if (
              targetNodeType.category.key === NodeCategory.OUTPUT.key &&
              targetNodeType.blobInputAffectorKey === connection.targetHandle
            ) {
              const key = `${connection.source}_${connection.sourceHandle}`;

              // Rename the node, being careful not to produce a duplicate name
              const receivingNodeId = `${key}_output`;
              const newId = generateUniqueNodeId(
                newFlowGraph,
                targetNode,
                receivingNodeId
              );

              if (newId !== targetNode.id) {
                // Rename the node and update the connection source/target IDs
                newFlowGraph = renameNode(
                  prevFlowGraph,
                  graphId!,
                  targetNode.id,
                  newId
                );
                if (connection.source === targetNode.id) {
                  connection.source = newId;
                } else if (connection.target === targetNode.id) {
                  connection.target = newId;
                }
                reassociateBlobs(targetNode.id, newId);
              }
            }

            return {
              ...newFlowGraph,
              edges: addEdge(
                { ...connection, style: EDGE_STYLE },
                newFlowGraph.edges
              ),
            };
          });
        };

        // Prompt if the target has inputs already, otherwise just connect
        if (
          getBlobByKey(
            inputs,
            connection.target,
            connection.targetHandle,
            Pipeline_BlobType.Input
          )
        ) {
          // When connecting, remove the existing inputs
          const connectAndRemoveInputs = () => {
            makeConnection();
            if (connection.target && connection.targetHandle) {
              removeInputValue(connection.target, connection.targetHandle);
            }
          };
          triggerDialog({
            title: 'Add connection?',
            message:
              'The target node already has an uploaded file attached. If you make this connection, the file will be removed and the new connection used instead.\nYou will have to reupload the file if you wish to revert this change.',
            confirmButtonText: 'Connect',
            onConfirm: connectAndRemoveInputs,
          });
        } else {
          makeConnection();
        }
      }
    },
    [
      graphId,
      inputs,
      reassociateBlobs,
      removeInputValue,
      setFlowGraph,
      triggerDialog,
      setDestination,
    ]
  );

  const onNodeDragStop = useCallback(() => {
    setFlowGraph((currFlowGraph) => currFlowGraph, {
      id: '',
      type: 'position',
    });
  }, [setFlowGraph]);

  const onNodesChange: OnNodesChange = useCallback(
    (changes) => {
      const changesToApply = changes.filter((change) => {
        if (change.type === 'select' || change.type === 'remove') {
          return true;
        }
        if (change.type === 'position' && change.dragging) {
          return true;
        }
        return false;
      });
      if (changesToApply.length > 0) {
        setFlowGraph((currFlowGraph) => {
          const newNodes = applyNodeChanges(
            changesToApply,
            currFlowGraph.nodes
          );
          return {
            ...currFlowGraph,
            nodes: newNodes,
          };
        }, changes[0]);
      }
    },
    [setFlowGraph]
  );

  const onEdgesChange: OnEdgesChange = useCallback(
    (changes) => {
      const changesToApply = changes.filter((change) => {
        return ['position', 'select', 'remove'].includes(change.type);
      });
      if (changesToApply.length > 0) {
        setFlowGraph((currFlowGraph) => {
          const { edges } = currFlowGraph;
          // Sometimes a "remove" change is sent for edges that aren't selected, and the node
          // still exists. This is to prevent those changes from being applied.
          const changesToApply = changes.filter((change) => {
            // ALlow all non-remove changes
            if (change.type !== 'remove') {
              return true;
            }
            const edge = edges.find((e) => e.id === change.id)!;
            // Allow if it's not an edge
            if (!edge) {
              return true;
            }
            const node = currFlowGraph.nodes.find(
              (n) => n.id === edge.source || n.id === edge.target
            );
            // Allow if the edge is missing
            if (!node) {
              return true;
            }
            // Allow if the edge is selected
            return edge.selected;
          });

          return {
            ...currFlowGraph,
            edges: applyEdgeChanges(changesToApply, currFlowGraph.edges),
          };
        });
      }
    },
    [setFlowGraph]
  );

  // When the graph changes, fit the view to the new graph
  useEffect(() => {
    if (prevFlowGraphWasEmpty && instance && flowGraph?.nodes?.length > 0) {
      setTimeout(instance.fitView, 100);
      setPrevFlowGraphWasEmpty(false);
    }
    if (flowGraph?.nodes?.length === 0) {
      setPrevFlowGraphWasEmpty(true);
    }
  }, [flowGraph, instance, prevFlowGraphWasEmpty]);

  return (
    <Fragment>
      <NodeBrowser
        open={nodeBrowserDialogOpen}
        onClose={() => setNodeBrowserDialogOpen(false)}
        compatibleNodes={true}
        originDataType={originDataType}
        originConnectionParams={originConnectionParams}
        targetViewport={targetViewport}
      />

      <ReactFlow
        className={`${styles.graph} graph-handle-validation`}
        nodes={flowGraph.nodes}
        edges={flowGraph.edges}
        onInit={setInstance}
        onConnect={onConnect}
        onNodeDragStop={onNodeDragStop}
        deleteKeyCode={hotkeys.DELETE_NODE.shortcut}
        minZoom={0.25}
        maxZoom={2.5}
        defaultViewport={DEFAULT_VIEWPORT}
        nodeTypes={NODE_TYPES}
        connectionLineStyle={CONNECTION_LINE_STYLE}
        onConnectStart={onConnectStart}
        onConnectEnd={onConnectEnd}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
      >
        <Controls />
        <Background color="#aaa" gap={20} size={0.6} />
      </ReactFlow>
    </Fragment>
  );
};

export default Graph;
