import { isEqual } from 'lodash-es';
import { useState } from 'react';

export type SetValueCallback<T> = (newValue: T) => T;
interface IUndoState<T> {
  past: T[];
  present: T;
  future: T[];
  canUndo: boolean;
  canRedo: boolean;
}
export interface IUseUndo<T> extends IUndoState<T> {
  set: (newValue: T | SetValueCallback<T>) => void;
  overwritePresent: (newValue: T | SetValueCallback<T>) => void;
  undo: () => void;
  redo: () => void;
  clearHistory: () => void;
}

const useUndo = <T>(
  initialState: T,
  maxHistory: number,
  replaceInitialState?: boolean
): IUseUndo<T> => {
  const [state, setState] = useState<IUndoState<T>>({
    past: [],
    present: initialState,
    future: [],
    canUndo: false,
    canRedo: false,
  });

  // Set the state with either a bare value or a callback which takes the current state
  // and returns the updated state.
  const set = (newValue: T | SetValueCallback<T>, overwrite = false) => {
    setState(({ past, present }) => {
      if (typeof newValue === 'function') {
        const callback = newValue as SetValueCallback<T>;
        newValue = callback(present);
      }
      if (
        replaceInitialState &&
        past.length === 0 &&
        isEqual(present, initialState)
      ) {
        overwrite = true;
      }
      let newPast = [...past];
      if (!overwrite) {
        newPast = [...past, present];
        newPast = newPast.slice(-maxHistory);
      }

      return {
        past: newPast,
        present: newValue,
        future: [],
        canUndo: newPast.length > 0,
        canRedo: false,
      };
    });
  };

  const undo = () => {
    setState((currState) => {
      const { past, present, future, canUndo } = currState;
      if (canUndo) {
        const newPresent = past[past.length - 1];
        const newPast = past.slice(0, -1);

        return {
          past: newPast,
          present: newPresent,
          future: [present, ...future],
          canUndo: newPast.length > 0,
          canRedo: true,
        };
      }
      return currState;
    });
  };

  const redo = () => {
    setState((currState) => {
      const { past, present, future, canRedo } = currState;
      if (canRedo) {
        const newPresent = future[0];
        const newFuture = future.slice(1);

        return {
          past: [...past, present],
          present: newPresent,
          future: newFuture,
          canUndo: true,
          canRedo: newFuture.length > 0,
        };
      }
      return currState;
    });
  };

  const clearHistory = () => {
    /* Clear the past and present */
    setState((currState) => ({
      ...currState,
      past: [],
      future: [],
      canUndo: false,
      canRedo: false,
    }));
  };

  return {
    ...state,
    set,
    overwritePresent: (newvalue) => set(newvalue, true),
    undo,
    redo,
    clearHistory,
  };
};

export default useUndo;
