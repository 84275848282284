import { NodeTypeKey, NodeTypes } from 'graph/NodeTypes';
import { IFlowGraphNodeData } from 'graph/types';
import useGraph from 'hooks/useGraph';
import { ReactElement, ReactNode, useCallback, useMemo } from 'react';
import styles from './index.module.scss';
import './index.scss';
import NodeHeader from './NodeHeader';
import ConfigList from './NodeItemList/ConfigList';
import HandleList from './NodeItemList/HandleList';

type ReactEl = ReactElement | string | number;
interface Props {
  id: string;
  children: ReactEl | ReactEl[];
  data?: any;
  isConnectable: boolean;
}

const CustomNode = ({ id, data, isConnectable }: Props): ReactNode => {
  const { updateNodeData } = useGraph();

  const setConfigValue = useCallback(
    (key: string, val: any) => {
      const newConfig = {
        ...data.config,
        [key]: val,
      };
      const newData = {
        ...data,
        config: newConfig,
      };
      updateNodeData(id, newData as IFlowGraphNodeData);
    },
    [data, id, updateNodeData]
  );

  const nodeTypeObj = NodeTypes[data.nodeType as NodeTypeKey];
  const { configTypes, inputTypes, outputTypes } = nodeTypeObj;

  const { collapsed } = data;

  let containerClassName = styles.container;
  if ((data.nodeType as NodeTypeKey) === 'MarkdownEditor') {
    containerClassName = `${containerClassName} ${styles['markdown-node']}`;
  }

  const [hasVisibleConfig, showCollapseButton] = useMemo(() => {
    const userVisibleConfigs = Object.values(configTypes ?? {}).filter(
      (configType) => !configType.type.hidden
    );
    const advancedConfigs = userVisibleConfigs.filter(
      (conf) => conf.type.advanced
    );
    const hasAdvancedConfigs = (advancedConfigs ?? []).length > 0;
    const allConfigsAdvanced =
      advancedConfigs?.length === userVisibleConfigs.length;
    const hasNoVisibleConfig =
      userVisibleConfigs.length === 0 || (allConfigsAdvanced && collapsed);
    const showCollapseButton = hasAdvancedConfigs;
    return [!hasNoVisibleConfig, showCollapseButton];
  }, [configTypes, collapsed]);

  return (
    <div className={containerClassName}>
      <NodeHeader
        id={id}
        data={data}
        nodeTypeObj={nodeTypeObj}
        showCollapseButton={showCollapseButton}
      />

      {configTypes && hasVisibleConfig && (
        <ConfigList
          nodeId={id}
          setConfigValue={setConfigValue}
          collapsed={collapsed}
          configTypes={configTypes}
          configValues={data.config}
        />
      )}

      {inputTypes && inputTypes.length > 0 && (
        <HandleList
          title="Inputs / Outputs"
          nodeId={id}
          handleType="target"
          dataTypes={inputTypes}
          isConnectable={isConnectable}
        />
      )}

      {outputTypes && outputTypes.length > 0 && (
        <HandleList
          nodeId={id}
          handleType="source"
          dataTypes={outputTypes}
          isConnectable={isConnectable}
        />
      )}
    </div>
  );
};

export default CustomNode;
