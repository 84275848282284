import { faObjectUngroup } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Athlete Detector',
  description: 'Predict athlete bounding boxes from video frames.',
  descriptionDetail:
    'This node predicts the location of athletes on each video frame using only visual features.',
  icon: faObjectUngroup,
  category: NodeCategory.DETECTION,
  moduleType: 'YOLO5',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060230458767',
  configTypes: [
    {
      key: 'image_size',
      type: PipelineConfigType.INTEGER('Image size')
        .OPTIONS([320, 416, 512, 608, 1280])
        .CONSTANT_VALUE(1280)
        .DESCRIPTION(
          'The size of the image input to the model. Larger images yield more accurate results but take longer to process.'
        ),
    },
    {
      key: 'model_weights',
      type: PipelineConfigType.STRING('Model size')
        .REQUIRED.OPTIONS([
          'AIS_YOLOv5-S',
          'AIS_YOLOv5-M',
          'AIS_YOLOv5-L',
          'AIS_YOLOv5-X',
          'YOLOv5-S',
          'YOLOv5-M',
          'YOLOv5-L',
          'YOLOv5-X',
        ])
        .DEFAULT('AIS_YOLOv5-X')
        .DESCRIPTION(
          'The size of the model (small/medium/large/extra-large), with larger models yielding more accurate results but taking longer to process. AIS models perform better on court and pitch sports.'
        ),
    },
    {
      key: 'flip',
      type: PipelineConfigType.BOOLEAN('Flip & average')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'Whether to flip images and average predictions. Doing so may yield more accurate results but will take twice as long to process.'
        ),
    },
    {
      key: 'half',
      type: PipelineConfigType.BOOLEAN('Half-precision')
        .CONSTANT_VALUE(true)
        .ADVANCED.DESCRIPTION(
          'If true, 16 bit numbers are used instead of 32 bit. This will run faster at a small cost in accuracy.'
        ),
    },
    {
      key: 'score_threshold',
      type: PipelineConfigType.NORM_DECIMAL('Score threshold')
        .REQUIRED.DEFAULT(0.5)
        .SLIDER(0.05)
        .DESCRIPTION(
          'The threshold below which bounding boxes are discarded. Must be between 0 and 1.'
        ).ADVANCED,
    },
    {
      key: 'label_filter',
      type: PipelineConfigType.STRING('Label filter')
        .ARRAY.OPTIONS([
          'person',
          'bicycle',
          'car',
          'chair',
          'motorcycle',
          'airplane',
          'bus',
          'truck',
          'boat',
          'horse',
          'skis',
          'snowboard',
          'sports ball',
          'kite',
          'baseball bat',
          'baseball glove',
          'skateboard',
          'surfboard',
          'tennis racket',
        ]) // Subset of COCO_80
        .CONSTANT_VALUE(['person'])
        .DESCRIPTION(
          'The classes for which bounding boxes will be predicted. If empty, all classes are used.'
        ),
    },
    {
      key: 'model_cache_dir',
      type: PipelineConfigType.STRING('Model cache directory').CONSTANT_VALUE(
        '/app/.cache/torch/hub'
      ),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'bounding_boxes',
      type: PipelineDataType.BOUNDING_BOXES.REQUIRED,
    },
  ],
};
