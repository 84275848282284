import {
  faChevronLeft,
  faChevronRight,
  faToggleOff,
  faToggleOn,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { Blob } from 'contexts/Graph';
import { IFlowGraphNodeData } from 'graph/types';
import { Pipeline_BlobType } from 'graphql/graphql';
import useGraph from 'hooks/useGraph';
import { useCallback, useMemo, useRef, useState } from 'react';
import FrameLabelsTable from './FrameLabelsTable';
import styles from './index.module.scss';
import StartEndSlider from './StartEndSlider';
import { frameNumberToSeconds } from './utils';

interface Props {
  videoBlob: Blob | undefined;
  onError: () => void;
  maxWidth?: number | string;
}

// Seeking has a small value added to ensure we land on the desired frame.
const SEEK_EPS = 0.001;

/**
 * Round a number to a certain number of decimal places, returning a number. Uses exponential notation to handle edge cases.
 */
const roundNumber = (num: number, places: number) => {
  num = Math.round(Number(`${num}e${places}`));
  return Number(`${num}e${-places}`);
};

const VideoPlayer = ({ videoBlob, onError, maxWidth = '100%' }: Props) => {
  const ref = useRef<HTMLVideoElement>(null);
  const [videoCurrentTime, setVideoCurrentTime] = useState<number>(0);
  const [showVideoControls, setShowVideoControls] = useState<boolean>(true);

  const { updateNodeData, graphState } = useGraph();

  const parentNode = useMemo(
    () => graphState.flowGraph?.nodes.find((n) => n.id === videoBlob?.nodeId),
    [graphState.flowGraph?.nodes, videoBlob?.nodeId]
  );

  const existingStartEndTime = useMemo(() => {
    if (!parentNode) return undefined;
    const data = parentNode?.data;
    return {
      startTime: data?.config?.start_offset_secs,
      endTime: data?.config?.end_time_secs,
    };
  }, [parentNode]);

  const setTimeProperty = useCallback(
    (key: string, time: number) => {
      if (!parentNode) return;

      const data = parentNode?.data;
      time = roundNumber(time, 3);
      const newConfig = {
        ...data.config,
        [key]: time,
      };
      if (
        time === 0 ||
        (videoBlob?.metadata?.mediaInfo?.duration &&
          time === videoBlob.metadata?.mediaInfo.duration)
      ) {
        delete newConfig[key];
      }
      const newData = {
        ...data,
        config: newConfig,
      };
      updateNodeData(parentNode.id, newData as IFlowGraphNodeData);
    },
    [parentNode, updateNodeData, videoBlob?.metadata?.mediaInfo.duration]
  );

  const setStartTime = useCallback(
    (startTime: number) => {
      setTimeProperty('start_offset_secs', startTime);
    },
    [setTimeProperty]
  );

  const setEndTime = useCallback(
    (endTime: number) => {
      setTimeProperty('end_time_secs', endTime);
    },
    [setTimeProperty]
  );

  const setFrameLabels = useCallback(
    (frameLabels: [number, string][]) => {
      if (!parentNode) return;
      const data = parentNode?.data;

      const newConfig = {
        ...data.config,
        frame_labels: frameLabels,
      };
      const newData = {
        ...data,
        config: newConfig,
      };
      updateNodeData(parentNode.id, newData as IFlowGraphNodeData);
    },
    [parentNode, updateNodeData]
  );

  const stepFrames = useCallback(
    (frameDelta: number) => {
      if (ref.current) {
        const frameRate = videoBlob?.metadata?.mediaInfo?.videoFrameRate ?? 50;
        const singleFrameStep = 1 / frameRate;
        let newTime = ref.current.currentTime + frameDelta * singleFrameStep;
        newTime = Math.max(newTime, 0);
        newTime = Math.min(newTime, ref.current.duration);
        if (newTime !== ref.current.currentTime) {
          ref.current.currentTime = newTime + SEEK_EPS;
        }
      }
    },
    [videoBlob?.metadata?.mediaInfo?.videoFrameRate]
  );

  if (!videoBlob?.downloadUrl?.url) {
    return null;
  }

  const duration = videoBlob?.metadata?.mediaInfo?.duration ?? 0;
  const startTime = existingStartEndTime?.startTime ?? 0;
  const endTime = existingStartEndTime?.endTime ?? duration;
  const metadataExist =
    videoBlob.blobType === Pipeline_BlobType.Input &&
    videoBlob.metadata !== undefined;

  return (
    <div>
      {metadataExist && (
        <StartEndSlider
          duration={duration}
          startTime={startTime}
          endTime={endTime}
          onJumpToStart={() => {
            if (ref.current) {
              ref.current.currentTime =
                ((existingStartEndTime?.startTime ?? 0) as number) + SEEK_EPS;
            }
          }}
          onJumpToEnd={() => {
            if (ref.current) {
              ref.current.currentTime =
                ((existingStartEndTime?.endTime ?? 0) as number) + SEEK_EPS;
            }
          }}
          setStartTime={setStartTime}
          setEndTime={setEndTime}
        />
      )}

      <div className={`nodrag ${styles['video-container']}`}>
        <video
          ref={ref}
          onTimeUpdate={(e) => {
            setVideoCurrentTime(e.currentTarget.currentTime);
          }}
          onError={() => {
            onError();
          }}
          className={styles.video}
          src={videoBlob?.downloadUrl?.url}
          controls={showVideoControls}
          style={{
            maxWidth,
          }}
          disablePictureInPicture
          muted
        />

        {metadataExist && (
          <div className={styles['video-button-overlay']}>
            <DelayedTooltip title="Jump to previous frame">
              <IconButton
                classes={{
                  root: `${styles['video-button']} ${styles['left-video-button']}`,
                }}
                onClick={() => stepFrames(-1)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
              </IconButton>
            </DelayedTooltip>

            <div className={styles['video-button-spacer']} />

            <DelayedTooltip title="Show/hide video controls">
              <IconButton
                size="small"
                classes={{
                  root: `${styles['video-button']} ${styles['middle-video-button']}`,
                }}
                onClick={() => setShowVideoControls(!showVideoControls)}
              >
                <FontAwesomeIcon
                  icon={showVideoControls ? faToggleOn : faToggleOff}
                />
              </IconButton>
            </DelayedTooltip>

            <div className={styles['video-button-spacer']} />

            <DelayedTooltip title="Jump to next frame">
              <IconButton
                classes={{
                  root: `${styles['video-button']} ${styles['right-video-button']}`,
                }}
                onClick={() => stepFrames(1)}
              >
                <FontAwesomeIcon icon={faChevronRight} />
              </IconButton>
            </DelayedTooltip>
          </div>
        )}
      </div>

      {metadataExist && (
        <FrameLabelsTable
          frameLabels={parentNode?.data?.config?.frame_labels}
          setFrameLabels={setFrameLabels}
          videoCurrentTime={videoCurrentTime}
          videoFrameRate={videoBlob?.metadata?.mediaInfo?.videoFrameRate}
          jumpToFrame={(frameNumber: number) => {
            const frameRate = videoBlob?.metadata?.mediaInfo?.videoFrameRate;
            if (ref.current && frameRate) {
              ref.current.currentTime =
                frameNumberToSeconds(frameNumber, frameRate as number) +
                SEEK_EPS;
            }
          }}
        />
      )}
    </div>
  );
};

export default VideoPlayer;
