import { ReactElement } from 'react';
import AuthContextProvider from './Auth';
import ConfirmDialogContextProvider from './ConfirmDialog';
import ConnectivityContextProvider from './Connectivity';
import ContextComposer from './ContextComposer';
import FlowRendererContextProvider from './FlowRenderer';
import GraphContextProvider from './Graph';
import GraphValidationContextProvider from './GraphValidation';
import JobContextProvider from './Job';
import NodeInfoDialogContextProvider from './NodeInfoDialog';
import NotificationProvider from './Notification';
import UserContextProvider from './User';
import UserPreferencesContextProvider from './UserPreferences';
export const GraphContextsProvider = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => (
  <ContextComposer
    contextProviders={[
      NodeInfoDialogContextProvider,
      FlowRendererContextProvider,
      GraphContextProvider,
      JobContextProvider,
      GraphValidationContextProvider,
    ]}
  >
    {children}
  </ContextComposer>
);

export const UtilityContextsProvider = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => (
  <ContextComposer
    contextProviders={[
      NotificationProvider,
      ConfirmDialogContextProvider,
      ConnectivityContextProvider,
    ]}
  >
    {children}
  </ContextComposer>
);

export const IdentityContextsProvider = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => (
  <ContextComposer
    contextProviders={[UserContextProvider, UserPreferencesContextProvider]}
  >
    {children}
  </ContextComposer>
);

export const AuthContextsProvider = ({
  children,
}: {
  children: ReactElement[] | ReactElement;
}) => (
  <ContextComposer contextProviders={[AuthContextProvider]}>
    {children}
  </ContextComposer>
);
