type HotkeyType = {
  label: string;
  shortcut: string;
};
const hotkeys: { [key: string]: HotkeyType } = {
  OPEN_BROWSE: { label: 'Open pipeline', shortcut: 'Ctrl+O' },
  UNDO: { label: 'Undo', shortcut: 'Ctrl+Z' },
  REDO: { label: 'Redo', shortcut: 'Ctrl+Shift+Z' },
  ADD_NODE: { label: 'Add node', shortcut: 'Ctrl+A' },
  SELECT_ALL: { label: 'Select all nodes', shortcut: 'Ctrl+Shift+A' },
  DESELECT_ALL: { label: 'Deselect all nodes', shortcut: 'Ctrl+Shift+D' },
  DUPLICATE_NODE: { label: 'Duplicate node', shortcut: 'Ctrl+D' },
  DELETE_NODE: { label: 'Delete node', shortcut: 'Delete' },
  RUN_GRAPH: { label: 'Start job', shortcut: 'Ctrl+R' },
  CANCEL_RUN_GRAPH: { label: 'Cancel job', shortcut: 'Ctrl+Shift+R' },
  SAVE_GRAPH: { label: 'Save pipeline', shortcut: 'Ctrl+S' },
};

export default hotkeys;
