import { faPaintRoller } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Frame Combiner',
  description:
    'Combine video frames from two sources into one by stacking them.',
  descriptionDetail:
    'When provided two video frame sources, this node stacks them vertically or horizontally and produces a single video source as output.',
  icon: faPaintRoller,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'FrameCombiner',
  configTypes: [
    {
      key: 'axis',
      type: PipelineConfigType.STRING('Axis')
        .REQUIRED.OPTIONS(['horizontal', 'vertical'])
        .DEFAULT('vertical')
        .DESCRIPTION('The axis along which frames should be combined.'),
    },
  ],
  inputTypes: [
    {
      key: 'first_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED.UI_GROUP(
        'image_1',
        'First Video'
      ),
    },
    {
      key: 'second_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED.UI_GROUP(
        'image_2',
        'Second Video'
      ),
    },
  ],
  outputTypes: [
    {
      key: 'combined_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
