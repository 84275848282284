import {
  faGear,
  faKey,
  faSignOutAlt,
  faUser,
  faUserGear,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Typography } from '@mui/material';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { MenuDivider } from '@szhsin/react-menu';
import AccountDialog from 'components/AccountDialog';
import ApiKeyDialog from 'components/ApiKeyDialog';
import { MenuItemContents } from 'components/MenuBar';
import StyledButton from 'components/StyledButton';
import useAuth from 'hooks/useAuth';
import useRolePermissions from 'hooks/useRolePermissions';
import useUser from 'hooks/useUser';
import { useState } from 'react';
import UserSettingsDialog from '../UserPreferencesDialog';
import styles from './index.module.scss';

const AccountMenuButton = () => {
  const { logout } = useAuth();
  const { user } = useUser();
  const { canAccessApi } = useRolePermissions();

  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const open = Boolean(anchorEl);

  const [accountDialogOpen, setAccountDialogOpen] = useState(false);
  const [userSettingsDialogOpen, setUserSettingsDialogOpen] = useState(false);
  const [apiKeyDialogOpen, setApiKeyDialogOpen] = useState(false);
  const handleClose = () => setAnchorEl(null);

  // Use the user initials if we have them, otherwise a generic user icon
  let userInitials: string | undefined;
  if (user?.firstName) {
    userInitials = user.firstName.substring(0, 1);
    if (user?.lastName) {
      userInitials = `${userInitials}${user.lastName.substring(0, 1)}`;
    }
  }

  return (
    <>
      <StyledButton
        styleName="light"
        size="small"
        className={styles.button}
        onClick={(e) => setAnchorEl(e.currentTarget as Element)}
      >
        {userInitials ? (
          <Typography variant="h5" className={styles.initials}>
            {userInitials}
          </Typography>
        ) : (
          <FontAwesomeIcon icon={faUser} size="2x" />
        )}
      </StyledButton>

      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => setAccountDialogOpen(true)}>
          <MenuItemContents title="Account Details..." icon={faUserGear} />
        </MenuItem>
        <MenuItem onClick={() => setUserSettingsDialogOpen(true)}>
          <MenuItemContents title="Preferences..." icon={faGear} />
        </MenuItem>

        {canAccessApi && <MenuDivider />}
        {canAccessApi && (
          <MenuItem onClick={() => setApiKeyDialogOpen(true)}>
            <MenuItemContents title="API Access..." icon={faKey} />
          </MenuItem>
        )}

        <MenuDivider />
        <MenuItem onClick={logout}>
          <MenuItemContents title="Log Out" icon={faSignOutAlt} />
        </MenuItem>
      </Menu>

      <AccountDialog
        open={accountDialogOpen}
        onClose={() => setAccountDialogOpen(false)}
      />

      <UserSettingsDialog
        open={userSettingsDialogOpen}
        onClose={() => setUserSettingsDialogOpen(false)}
      />

      <ApiKeyDialog
        open={apiKeyDialogOpen}
        onClose={() => setApiKeyDialogOpen(false)}
      />
    </>
  );
};

export default AccountMenuButton;
