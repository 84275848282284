import { IConstraint } from './konvaGraphUtils';
export interface Vec2 {
  x: number;
  y: number;
}

export const sub = (a: Vec2, b: Vec2 | number) => ({
  x: a.x - (typeof b === 'number' ? b : b.x),
  y: a.y - (typeof b === 'number' ? b : b.y),
});
export const add = (a: Vec2, b: Vec2 | number) => ({
  x: a.x + (typeof b === 'number' ? b : b.x),
  y: a.y + (typeof b === 'number' ? b : b.y),
});
export const mul = (a: Vec2, b: Vec2 | number) => ({
  x: a.x * (typeof b === 'number' ? b : b.x),
  y: a.y * (typeof b === 'number' ? b : b.y),
});
export const div = (a: Vec2, b: Vec2 | number) => ({
  x: a.x / (typeof b === 'number' ? b : b.x),
  y: a.y / (typeof b === 'number' ? b : b.y),
});
export const dot = (a: Vec2, b: Vec2) => a.x * b.x + a.y * b.y;
export const round = (a: Vec2) => ({
  x: Math.round(a.x),
  y: Math.round(a.y),
});
export const eq = (a: Vec2, b: Vec2) => a.x === b.x && a.y === b.y;
export const distSq = (a: Vec2, b: Vec2) => (a.x - b.x) ** 2 + (a.y - b.y) ** 2;
export const dist = (a: Vec2, b: Vec2) => Math.sqrt(distSq(a, b));
export const norm = (a: Vec2) => Math.sqrt(a.x ** 2 + a.y ** 2);
export const normed = (a: Vec2) => div(a, norm(a));
export const lerp = (a: Vec2, b: Vec2, t: number) =>
  add(mul(a, 1 - t), mul(b, t));
export const midpoint = (a: Vec2, b: Vec2) => lerp(a, b, 0.5);
export const elwiseMin = (a: Vec2, b: Vec2) => ({
  x: Math.min(a.x, b.x),
  y: Math.min(a.y, b.y),
});
export const elwiseMax = (a: Vec2, b: Vec2) => ({
  x: Math.max(a.x, b.x),
  y: Math.max(a.y, b.y),
});
export const nearestPointOnLine = (
  point: Vec2,
  lineStart: Vec2,
  lineEnd: Vec2
) => {
  const line = sub(lineEnd, lineStart);
  const pointToStart = sub(point, lineStart);
  const lineLength = dist(lineStart, lineEnd);
  const dot = (pointToStart.x * line.x + pointToStart.y * line.y) / lineLength;
  return add(lineStart, mul(line, dot / lineLength));
};

export const distSqPointToLine = (
  point: Vec2,
  lineStart: Vec2,
  lineEnd: Vec2
) => {
  const closestPoint = nearestPointOnLine(point, lineStart, lineEnd);
  return distSq(point, closestPoint);
};

export const distPointToLine = (point: Vec2, lineStart: Vec2, lineEnd: Vec2) =>
  Math.sqrt(distSqPointToLine(point, lineStart, lineEnd));

export const nearestPointOnLineSegment = (
  point: Vec2,
  lineStart: Vec2,
  lineEnd: Vec2
) => {
  const line = sub(lineEnd, lineStart);
  const pointToStart = sub(point, lineStart);
  const lineLength = dist(lineStart, lineEnd);
  const dot = (pointToStart.x * line.x + pointToStart.y * line.y) / lineLength;
  if (dot < 0) {
    return lineStart;
  }
  if (dot > lineLength) {
    return lineEnd;
  }
  return add(lineStart, mul(line, dot / lineLength));
};

export const distSqPointToLineSegment = (
  point: Vec2,
  lineStart: Vec2,
  lineEnd: Vec2
) => {
  const closestPoint = nearestPointOnLineSegment(point, lineStart, lineEnd);
  return distSq(point, closestPoint);
};

export const distPointToLineSegment = (
  point: Vec2,
  lineStart: Vec2,
  lineEnd: Vec2
) => Math.sqrt(distSqPointToLineSegment(point, lineStart, lineEnd));

export const findXYFromConstraintNotStrict = (
  x: number | undefined,
  y: number | undefined,
  lines: [Vec2, Vec2][],
  defaultPoint: Vec2
): Vec2 => {
  if (x !== undefined) {
    return {
      x,
      y: defaultPoint.y,
    };
  }
  if (y !== undefined) {
    return {
      x: defaultPoint.x,
      y,
    };
  }
  if (lines.length === 1) {
    const line = lines[0];
    const [p1, p2] = line;
    return nearestPointOnLineSegment(defaultPoint, p1, p2);
  }
  return defaultPoint;
};

export const findXYFromConstraint = (
  x: number | undefined,
  y: number | undefined,
  lines: [Vec2, Vec2][]
): Vec2 | undefined => {
  // Tries to get a point from a constraint. Returns undefined if it can't.
  if (x !== undefined && y !== undefined) {
    return { x, y };
  }
  if (lines.length === 1) {
    const line = lines[0];
    if (x !== undefined) {
      // Evaluate the line at the x value
      const [p1, p2] = line;
      if (p1.x !== p2.x) {
        const t = (x - p1.x) / (p2.x - p1.x);
        return { x, y: p1.y + t * (p2.y - p1.y) };
      }
    }
    if (y !== undefined) {
      // Evaluate the line at the y value
      const [p1, p2] = line;
      if (p1.y !== p2.y) {
        const t = (y - p1.y) / (p2.y - p1.y);
        return { x: p1.x + t * (p2.x - p1.x), y };
      }
    }
  } else if (lines.length === 2) {
    // Find the intersection of two lines
    const [p1, p2] = lines[0];
    const [p3, p4] = lines[1];

    const denom = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);
    if (denom === 0) {
      // Lines are parallel
      return undefined;
    }
    const t =
      ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denom;
    const u =
      ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denom;
    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      // Intersection
      return {
        x: p1.x + t * (p2.x - p1.x),
        y: p1.y + t * (p2.y - p1.y),
      };
    }
  }
};

export const findFirstSatisfactoryConstraint = (
  point: Vec2,
  constraints: IConstraint[],
  threshold: number,
  allowedTypes: (
    | 'xy'
    | 'x'
    | 'y'
    | 'pointOnLine'
    | 'xOrYOnLine'
    | 'lineLineIntersection'
  )[]
): Vec2 | undefined => {
  // Calculate all possible constraint combinations, and find the one which results in the smallest
  // movement, below the threshold.
  const toLineLineIntersection = (line1: [Vec2, Vec2], line2: [Vec2, Vec2]) => {
    const [p1, p2] = line1;
    const [p3, p4] = line2;

    const denom = (p1.x - p2.x) * (p3.y - p4.y) - (p1.y - p2.y) * (p3.x - p4.x);
    if (denom === 0) {
      // Lines are parallel
      return undefined;
    }
    const t =
      ((p4.x - p3.x) * (p1.y - p3.y) - (p4.y - p3.y) * (p1.x - p3.x)) / denom;
    const u =
      ((p2.x - p1.x) * (p1.y - p3.y) - (p2.y - p1.y) * (p1.x - p3.x)) / denom;
    if (t >= 0 && t <= 1 && u >= 0 && u <= 1) {
      // Intersection
      return {
        x: p1.x + t * (p2.x - p1.x),
        y: p1.y + t * (p2.y - p1.y),
      };
    }
  };

  const toXConstraint = (x: number) => ({ x, y: point.y });
  const toYConstraint = (y: number) => ({ x: point.x, y });

  const toXYConstraint = (x: number, y: number) => ({ x, y });

  let sqDistPoints: {
    sqDist: number;
    newPoint: Vec2;
  }[] = [];

  constraints.forEach((constraint) => {
    if (
      allowedTypes.includes('xy') &&
      constraint.x !== undefined &&
      constraint.y !== undefined
    ) {
      const newPoint = toXYConstraint(constraint.x, constraint.y);
      sqDistPoints.push({
        sqDist: distSq(point, newPoint),
        newPoint,
      });
    } else if (
      allowedTypes.includes('x') &&
      constraint.x !== undefined &&
      constraint.y === undefined
    ) {
      const newPoint = toXConstraint(constraint.x);
      sqDistPoints.push({
        sqDist: distSq(point, newPoint),
        newPoint,
      });
    } else if (
      allowedTypes.includes('y') &&
      constraint.y !== undefined &&
      constraint.x === undefined
    ) {
      const newPoint = toYConstraint(constraint.y);
      sqDistPoints.push({
        sqDist: distSq(point, newPoint),
        newPoint,
      });
    } else if (allowedTypes.includes('pointOnLine') && constraint.line) {
      const newPoint = nearestPointOnLineSegment(point, ...constraint.line);
      sqDistPoints.push({
        sqDist: distSq(point, newPoint),
        newPoint,
      });
    }
  });

  // Check all pairs of x-y constraints
  if (allowedTypes.includes('xy')) {
    const xConstraints = constraints.filter(
      (c) => c.x !== undefined && c.y === undefined
    );
    const yConstraints = constraints.filter(
      (c) => c.y !== undefined && c.x === undefined
    );
    for (const xConstraint of xConstraints) {
      for (const yConstraint of yConstraints) {
        if (xConstraint === yConstraint) continue;

        const newPoint = toXYConstraint(xConstraint.x!, yConstraint.y!);
        if (newPoint) {
          sqDistPoints.push({
            sqDist: distSq(point, newPoint),
            newPoint,
          });
        }
      }
    }
  }

  if (allowedTypes.includes('xOrYOnLine')) {
    let lineConstraints = constraints.filter((c) => c.line);
    const xConstraints = constraints.filter(
      (c) => c.x !== undefined && c.y === undefined
    );
    const yConstraints = constraints.filter(
      (c) => c.y !== undefined && c.x === undefined
    );

    const xLineConstraints: IConstraint[] = xConstraints.map((c) => ({
      line: [
        { x: c.x!, y: point.y - 100000 },
        { x: c.x!, y: point.y + 100000 },
      ],
    }));
    const yLineConstraints: IConstraint[] = yConstraints.map((c) => ({
      line: [
        { x: point.x - 100000, y: c.y! },
        { x: point.x + 100000, y: c.y! },
      ],
    }));

    lineConstraints = lineConstraints.concat(
      xLineConstraints,
      yLineConstraints
    );
    for (let i = 0; i < lineConstraints.length; i++) {
      for (let j = 0; j < lineConstraints.length; j++) {
        if (i === j) continue;

        const newPoint = toLineLineIntersection(
          lineConstraints[i].line!,
          lineConstraints[j].line!
        );
        if (newPoint) {
          sqDistPoints.push({
            sqDist: distSq(point, newPoint),
            newPoint,
          });
        }
      }
    }
  }

  // Check all pairs of lines
  if (allowedTypes.includes('lineLineIntersection')) {
    const lineConstraints = constraints.filter((c) => c.line);
    for (let i = 0; i < lineConstraints.length; i++) {
      for (let j = 0; j < lineConstraints.length; j++) {
        if (i === j) continue;

        const newPoint = toLineLineIntersection(
          lineConstraints[i].line!,
          lineConstraints[j].line!
        );
        if (newPoint) {
          sqDistPoints.push({
            sqDist: distSq(point, newPoint),
            newPoint,
          });
        }
      }
    }
  }

  sqDistPoints = sqDistPoints.filter(
    ({ sqDist }) => sqDist < threshold * threshold
  );
  sqDistPoints.sort((a, b) => a.sqDist - b.sqDist);
  if (sqDistPoints.length > 0) {
    return sqDistPoints[0].newPoint;
  }
};

export const flatCoordsToVec2 = (coords: number[]): Vec2[] => {
  const out: Vec2[] = [];
  for (let i = 0; i < coords.length; i += 2) {
    out.push({ x: coords[i], y: coords[i + 1] });
  }
  return out;
};

export const vec2ToFlatCoords = (points: Vec2[]): number[] => {
  const out: number[] = [];
  for (const p of points) {
    out.push(p.x, p.y);
  }
  return out;
};

export const flatEdgesToPairList = (edges: number[]): [number, number][] => {
  const out: [number, number][] = [];
  for (let i = 0; i < edges.length; i += 2) {
    out.push([edges[i], edges[i + 1]]);
  }
  return out;
};
export const pairListToFlatEdges = (pairs: [number, number][]): number[] => {
  const out: number[] = [];
  for (const p of pairs) {
    out.push(p[0], p[1]);
  }
  return out;
};
