import { ReactElement } from 'react';
import {
  Avatar,
  Divider,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Typography,
} from '@mui/material';
import { faCheck, faExclamation } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

import useJobLogs from 'hooks/useJobLogs';
import styles from './index.module.scss';
import { JobStatus } from 'contexts/Job';

const avatar = (status: JobStatus): ReactElement => {
  switch (status) {
    case JobStatus.CANCELLED:
    case JobStatus.FAILED:
    case JobStatus.FAILED_TO_START:
      return (
        <Avatar className={styles['avatar-error']}>
          <FontAwesomeIcon icon={faExclamation} />
        </Avatar>
      );
    case JobStatus.COMPLETE:
      return (
        <Avatar className={styles['avatar-success']}>
          <FontAwesomeIcon icon={faCheck} />
        </Avatar>
      );
    default:
      return <span />;
  }
};
const RunList = () => {
  const logs = useJobLogs();

  return (
    <List>
      {logs.length > 0 ? (
        logs.map((log, i) => (
          <ListItem
            key={log.time?.toISOString() ?? i}
            className="MuiListItem-button"
          >
            <ListItemAvatar>{avatar(log.status)}</ListItemAvatar>
            <ListItemText
              primary={
                <div>
                  <Typography variant="subtitle1" className={styles.title}>
                    {log.title}
                  </Typography>
                  <Typography variant="caption">
                    {log.time &&
                      `${log.time?.toLocaleDateString()} - ${log.time?.toLocaleTimeString()}`}
                  </Typography>
                </div>
              }
              secondary={log.message}
            />
            <Divider light />
          </ListItem>
        ))
      ) : (
        <ListItem className="MuiListItem-button">
          <ListItemText secondary="No recent runs" />
        </ListItem>
      )}
    </List>
  );
};

export default RunList;
