import { Pipeline_PointCorrespondenceTemplate } from 'graphql/graphql';
import useUndo, { SetValueCallback } from 'hooks/useUndo';
import { cloneDeep } from 'lodash-es';
import { useCallback, useState } from 'react';
import { uuid } from 'utils/helpers';
import {
  addAdjacency,
  deleteAdjacenciesForNode,
  IAdjacencyMap,
  IEdge,
  INode,
  INodeMap,
  makeEdgeText,
  pixelsToWorld,
  removeAdjacency,
  roundPixelsToCm,
} from 'utils/konvaGraphUtils';
import { dist, midpoint, Vec2 } from 'utils/Vec2';
import { insertRightTriangleEdges } from './StepOne/TemplateBuilder/validation';

const constructEdges = (nodes: INodeMap, adjacencies: IAdjacencyMap): IEdge[] =>
  Object.entries(adjacencies).flatMap(([source, targets]) =>
    Array.from(targets)
      .filter((target) => target < source) // Only add each edge once
      .map((target) => {
        const distance = dist(
          pixelsToWorld(nodes[source].pixelPos),
          pixelsToWorld(nodes[target].pixelPos)
        );
        return {
          id: `${source}-${target}`,
          source,
          target,
          distance,
          text: makeEdgeText(distance),
        };
      })
  );

export const addNodeAndAdjacencies = (
  nodes: INodeMap,
  adjacencies: IAdjacencyMap,
  connections: string[],
  id: string,
  pixelPos: Vec2
) => {
  const newNode: INode = {
    id,
    pixelPos,
  };
  nodes[newNode.id] = newNode;
  adjacencies[newNode.id] = new Set();
  connections.forEach((connection) => {
    addAdjacency(adjacencies, connection, newNode.id);
  });
};

export const nodesAndAdjacenciesFromGQLFormat = (
  gqlFormat: Pipeline_PointCorrespondenceTemplate
): [INodeMap, IAdjacencyMap] => {
  if (!gqlFormat.edges) {
    return [{}, {}];
  }

  const nodes: INodeMap = {};
  const adjacencies: IAdjacencyMap = {};
  const indexToId: string[] = [];
  for (let i = 0; i < gqlFormat.pixelCoordinates.length; i += 2) {
    const id = uuid();
    nodes[id] = {
      id,
      pixelPos: {
        x: gqlFormat.pixelCoordinates[i],
        y: gqlFormat.pixelCoordinates[i + 1],
      },
    };
    adjacencies[id] = new Set();
    indexToId.push(id);
  }
  for (let i = 0; i < gqlFormat.edges.length; i += 2) {
    addAdjacency(
      adjacencies,
      indexToId[gqlFormat.edges[i]],
      indexToId[gqlFormat.edges[i + 1]]
    );
  }

  return [nodes, adjacencies];
};

const useGraph = (initialState: [INodeMap, IAdjacencyMap]) => {
  const { past, present, future, set, redo, undo, canRedo, canUndo } = useUndo<
    [INodeMap, IAdjacencyMap]
  >(initialState, 50);
  const [workingState, _setWorkingState] = useState<
    [INodeMap, IAdjacencyMap] | undefined
  >(undefined);
  const [workingNodes, workingAdjacencies] = workingState
    ? workingState
    : [undefined, undefined];
  const [selectedNodeIds, setSelectedNodeIds] = useState<string[]>([]);

  const setWorkingState = useCallback(
    (
      newState:
        | [INodeMap, IAdjacencyMap]
        | SetValueCallback<[INodeMap, IAdjacencyMap]>
    ) => {
      _setWorkingState((prev) => {
        const [prevNodes, prevAdjacencies] = prev ?? present;
        if (typeof newState === 'function') {
          newState = newState([prevNodes, prevAdjacencies]);
        }
        return newState;
      });
    },
    [present]
  );

  const clearWorkingState = useCallback(
    (
      callback: (workingState: [INodeMap, IAdjacencyMap] | undefined) => void
    ) => {
      _setWorkingState((prev) => {
        callback(prev);
        return undefined;
      });
    },
    []
  );

  const pushAdjacenciesToHistory = useCallback(
    (adjacencies: IAdjacencyMap | SetValueCallback<IAdjacencyMap>) => {
      set(([prevNodes, prevAdjacencies]) => {
        if (typeof adjacencies === 'function') {
          adjacencies = adjacencies(prevAdjacencies);
        }
        return [prevNodes, adjacencies];
      });
    },
    [set]
  );

  const pushToHistory = useCallback(
    (
      newValues:
        | [INodeMap, IAdjacencyMap]
        | SetValueCallback<[INodeMap, IAdjacencyMap]>
    ) => {
      set(([prevNodes, prevAdjacencies]) => {
        if (typeof newValues === 'function') {
          newValues = newValues([prevNodes, prevAdjacencies]);
        }
        return newValues;
      });
    },
    [set]
  );

  const correctEdges = useCallback(() => {
    pushToHistory((values) => {
      const [nodes, adjacencies] = values;
      const newNodes = cloneDeep(nodes);
      const newAdjacencies = cloneDeep(adjacencies);
      insertRightTriangleEdges(newNodes, newAdjacencies);
      return [newNodes, newAdjacencies];
    });
  }, [pushToHistory]);

  const commitChanges = useCallback(() => {
    clearWorkingState((wrkState) => {
      pushToHistory(wrkState ?? present);
    });
  }, [clearWorkingState, present, pushToHistory]);

  const addEdge = useCallback(
    (source: string, target: string) => {
      pushAdjacenciesToHistory((adjacencies: IAdjacencyMap) => {
        const newAdjacencies = cloneDeep(adjacencies);
        addAdjacency(newAdjacencies, source, target);
        return newAdjacencies;
      });
    },
    [pushAdjacenciesToHistory]
  );

  const removeEdge = useCallback(
    (source: string, target: string) => {
      pushToHistory((values: [INodeMap, IAdjacencyMap]) => {
        const [nodes, adjacencies] = values;
        const newNodes = cloneDeep(nodes);
        const newAdjacencies = cloneDeep(adjacencies);
        removeAdjacency(newAdjacencies, source, target);

        // If either node is now isolated, remove it unless it's the last node in the graph.
        [source, target].forEach((id) => {
          if (Object.keys(newNodes).length === 1) {
            return;
          }
          if (newAdjacencies[id].size === 0) {
            delete newAdjacencies[id];
            delete newNodes[id];
          }
        });

        return [newNodes, newAdjacencies];
      });
    },
    [pushToHistory]
  );

  const addNode = useCallback(
    (
      connections: string[],
      pixelPos: Vec2,
      skipCommit = false,
      id: string = uuid()
    ) => {
      const setFunc = skipCommit ? setWorkingState : pushToHistory;
      setFunc((values: [INodeMap, IAdjacencyMap]) => {
        const newNodes = cloneDeep(values[0]);
        const newAdjacencies = cloneDeep(values[1]);
        addNodeAndAdjacencies(
          newNodes,
          newAdjacencies,
          connections,
          id,
          pixelPos
        );
        return [newNodes, newAdjacencies];
      });
      return id;
    },
    [pushToHistory, setWorkingState]
  );

  const moveNodesPixel = useCallback(
    (nodeIds: string[], pixelPositions: Vec2[], skipRoundToCm?: boolean) => {
      setWorkingState((values: [INodeMap, IAdjacencyMap] | undefined) => {
        const [prevNodes, prevAdjacencies] = values ?? present;
        const newNodes = cloneDeep(prevNodes);
        nodeIds.forEach((id, i) => {
          newNodes[id] = {
            ...newNodes[id],
            pixelPos: skipRoundToCm
              ? pixelPositions[i]
              : roundPixelsToCm(pixelPositions[i]),
          };
        });
        return [newNodes, prevAdjacencies];
      });
    },
    [present, setWorkingState]
  );

  const deleteNodes = useCallback(
    (
      ids: string[],
      skipCommit = false,
      skipPropagation = false,
      allowDeleteLast = false
    ) => {
      const setFunc = skipCommit ? setWorkingState : pushToHistory;
      setFunc((values: [INodeMap, IAdjacencyMap]) => {
        const newNodes = cloneDeep(values[0]);
        const newAdjacencies = cloneDeep(values[1]);

        // Delete the nodes
        const deletedIds: string[] = [];
        ids.forEach((id) => {
          if (!newNodes[id]) {
            console.error('Trying to delete a node that does not exist:', id);
            return;
          }
          if (Object.keys(newNodes).length === 1 && !allowDeleteLast) {
            console.error('Trying to delete the last node in the graph.');
            return;
          }

          delete newNodes[id];
          deletedIds.push(id);
        });

        // Delete the adjacencies
        deletedIds.forEach((id) => {
          deleteAdjacenciesForNode(newAdjacencies, id);
        });

        if (!skipPropagation) {
          // Delete any newly isolated nodes unless it's the last node in the graph.
          Object.keys(newNodes).forEach((id) => {
            if (newAdjacencies[id].size === 0) {
              if (Object.keys(newNodes).length === 1) {
                return;
              }
              delete newAdjacencies[id];
              delete newNodes[id];
            }
          });
        }

        return [newNodes, newAdjacencies];
      });
    },
    [pushToHistory, setWorkingState]
  );

  const replaceNodeWithOther = useCallback(
    (from: string, to: string, skipCommit = false) => {
      const setFunc = skipCommit ? setWorkingState : pushToHistory;
      setFunc((values: [INodeMap, IAdjacencyMap]) => {
        const [nodes, adjacencies] = values;
        const newAdjacencies = cloneDeep(adjacencies);
        const newNodes = cloneDeep(nodes);

        const existingAdjacencies = newAdjacencies[from];
        existingAdjacencies?.forEach((adjacency) => {
          if (to !== adjacency) {
            addAdjacency(newAdjacencies, to, adjacency);
          }
        });

        // Delete the old adjacencies
        deleteAdjacenciesForNode(newAdjacencies, from);

        delete newNodes[from];
        return [newNodes, newAdjacencies];
      });
    },
    [pushToHistory, setWorkingState]
  );

  const splitEdge = useCallback(
    (source: string, target: string, skipCommit = false) => {
      const id = uuid();
      const setFunc = skipCommit ? setWorkingState : pushToHistory;
      setFunc((values: [INodeMap, IAdjacencyMap]) => {
        const newNodes = cloneDeep(values[0]);
        const newAdjacencies = cloneDeep(values[1]);
        const sourceNode = newNodes[source];
        const targetNode = newNodes[target];
        const pixelPos = roundPixelsToCm(
          midpoint(sourceNode.pixelPos, targetNode.pixelPos)
        );
        addNodeAndAdjacencies(
          newNodes,
          newAdjacencies,
          [source, target],
          id,
          pixelPos
        );
        removeAdjacency(newAdjacencies, source, target);
        return [newNodes, newAdjacencies];
      });
      return id;
    },
    [pushToHistory, setWorkingState]
  );

  const splitEdgesWithNode = useCallback(
    (newNodeId: string, edges: [string, string][], skipCommit = false) => {
      const setFunc = skipCommit ? setWorkingState : pushToHistory;
      setFunc((values: [INodeMap, IAdjacencyMap]) => {
        const [nodes, adjacencies] = values;
        const newAdjacencies = cloneDeep(adjacencies);
        const newNodes = cloneDeep(nodes);

        edges.forEach(([sourceNodeId, targetNodeId]) => {
          // Remove the adjacencies that connected the two previous nodes
          // Add the new node and adjacencies that connect it to the two previous nodes
          removeAdjacency(newAdjacencies, sourceNodeId, targetNodeId);
          addAdjacency(newAdjacencies, sourceNodeId, newNodeId);
          addAdjacency(newAdjacencies, newNodeId, targetNodeId);
        });
        return [newNodes, newAdjacencies];
      });
    },
    [pushToHistory, setWorkingState]
  );

  const setNodeSelected = useCallback((id: string | null) => {
    if (id === null) {
      setSelectedNodeIds([]);
    } else {
      setSelectedNodeIds([id]);
    }
  }, []);

  const addNodeToSelection = useCallback((id: string) => {
    setSelectedNodeIds((prev) => [...prev, id]);
  }, []);

  const removeNodeFromSelection = useCallback((id: string) => {
    setSelectedNodeIds((prev) => prev.filter((nodeId) => nodeId !== id));
  }, []);

  return {
    nodes: workingNodes ?? present[0],
    adjacencies: workingAdjacencies ?? present[1],
    edges: constructEdges(
      workingNodes ?? present[0],
      workingAdjacencies ?? present[1]
    ),
    addEdge,
    removeEdge,
    replaceNodeWithOther,
    splitEdgesWithNode,
    splitEdge,
    addNode,
    moveNodesPixel,
    deleteNodes,
    commitChanges,
    undo,
    redo,
    canUndo,
    canRedo,
    pastLength: past.length,
    futureLength: future.length,
    correctEdges,
    selectedNodeIds,
    setSelectedNodeIds,
    setNodeSelected,
    addNodeToSelection,
    removeNodeFromSelection,
  };
};

export default useGraph;
