import { faPlug } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Backdrop, ButtonGroup, Card, Typography } from '@mui/material';
import BackgroundImage from 'components/BackgroundImage';
import StyledButton from 'components/StyledButton';
import { IS_DEV_MODE } from 'config';
import useConnectivity from 'hooks/useConnectivity';
import { useEffect, useState } from 'react';
import styles from './index.module.scss';

const WAIT_SECONDS = 5;

const NetworkErrorOverlay = () => {
  const { checkConnection, isCheckingConnection, graphQLUri } =
    useConnectivity();
  const [countdown, setCountdown] = useState(WAIT_SECONDS);

  useEffect(() => {
    const interval = setInterval(() => {
      const newCount = countdown - 1;

      if (newCount < 0) {
        // If countdown is below zero, check the connection and reset the countdown
        checkConnection();
        setCountdown(WAIT_SECONDS);
      } else if (!isCheckingConnection) {
        // if the countdown is non-zero and we're not checking, decrement
        setCountdown(newCount);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, [checkConnection, countdown, isCheckingConnection]);

  // Trigger a connection check whenever we gain window focus
  useEffect(() => {
    const triggerNetworkCheck = () => setCountdown(0);
    window.addEventListener('focus', triggerNetworkCheck);
    return () => window.removeEventListener('focus', triggerNetworkCheck);
  }, []);

  return (
    <>
      <BackgroundImage />
      <Backdrop open invisible className={styles.backdrop}>
        <div className={styles['outer-container']}>
          <Card variant="outlined" className={styles.container}>
            <FontAwesomeIcon icon={faPlug} size="4x" color="gray" />

            <Typography variant="h4" gutterBottom component="div">
              Network Error
            </Typography>

            <Typography variant="body1" gutterBottom component="div">
              {`We're having trouble connecting to the server.`}
            </Typography>

            <Typography variant="overline" component="div">
              {isCheckingConnection || countdown <= 0
                ? 'Checking server connection...'
                : `Checking again in ${countdown} seconds...`}
            </Typography>
          </Card>

          {/* Additional UI element during dev mode, as when the local SSL certificate trust expires we'll
              be redirected here and it can be a little confusing. */}
          {IS_DEV_MODE && (
            <Card
              variant="outlined"
              className={`${styles.container} ${styles['dev-card']}`}
            >
              <Typography variant="h4" gutterBottom component="div">
                Developer Note
              </Typography>

              <Typography variant="body1" gutterBottom component="div">
                {`The network error may be occurring due to using untrusted SSL certificates in dev mode. `}
                {`If this is the case, you'll need to manually visit the API and trust the certificate. `}
                {`Otherwise you can disable SSL checking in your browser. For Chrome, this can be done by `}
                {`enabling the allow-insecure-localhost flag.`}
              </Typography>

              <Typography variant="overline" component="div">
                {`Note: This card is only visible in development mode.`}
              </Typography>
              <ButtonGroup fullWidth>
                <StyledButton
                  styleName="secondary"
                  href="chrome://flags/#allow-insecure-localhost"
                  target="_blank"
                >
                  Open Chrome Flags
                </StyledButton>
                <StyledButton
                  styleName="secondary"
                  href={graphQLUri}
                  target="_blank"
                >
                  Visit API
                </StyledButton>
              </ButtonGroup>
            </Card>
          )}
        </div>
      </Backdrop>
    </>
  );
};

export default NetworkErrorOverlay;
