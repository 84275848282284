import { JobStatus } from 'contexts/Job';
import useGraph from './useGraph';
import useJobInfo from './useJobInfo';

type LockReason = 'fetching_job' | 'job_running';

const useGraphLockStatus = () => {
  const { jobStatus } = useJobInfo();
  const { graphIsInteractive } = useGraph();

  let graphIsLocked = false;
  let lockReason: LockReason | undefined;

  if (jobStatus === JobStatus.NOT_FETCHED || jobStatus === JobStatus.FETCHING) {
    graphIsLocked = true;
    lockReason = 'fetching_job';
  }
  if (
    [
      JobStatus.QUEUED,
      JobStatus.STARTING,
      JobStatus.RUNNING,
      JobStatus.CANCELLING,
    ].includes(jobStatus)
  ) {
    graphIsLocked = true;
    lockReason = 'job_running';
  }

  return {
    graphIsLocked,
    lockReason,
    graphIsInteractive,
  };
};

export default useGraphLockStatus;
