import ConfirmDialog from 'components/ConfirmDialog';
import { ButtonStyleName } from 'components/StyledButton';

import { createContext, ReactElement, useState } from 'react';

export interface ConfirmDialogProps {
  title: string;
  message: string;
  confirmButtonText?: string;
  cancelButtonText?: string;
  onConfirm: () => void;
  onCancel?: () => void;
  hideCancelButton?: boolean;
  allowClose?: boolean;
  confirmButtonStyle?: ButtonStyleName;
}
const closedProps: ConfirmDialogProps = {
  title: '',
  message: '',
  onConfirm: () => undefined,
};

interface IConfirmDialogContext {
  triggerDialog: (props: ConfirmDialogProps) => void;
  closeDialog: () => void;
}

export const ConfirmDialogContext = createContext<IConfirmDialogContext>(
  undefined!
);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [open, setOpen] = useState(false);
  const [dialogProps, setDialogProps] = useState<ConfirmDialogProps>();

  return (
    <ConfirmDialogContext.Provider
      value={{
        triggerDialog: (props) => {
          setDialogProps(props);
          setOpen(true);
        },
        closeDialog: () => {
          setDialogProps(undefined);
          setOpen(false);
        },
      }}
    >
      {children}
      <ConfirmDialog
        {...(dialogProps ?? closedProps)}
        open={open}
        onClose={() => setOpen(false)}
      />
    </ConfirmDialogContext.Provider>
  );
};
