import { faTimeline } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Frame Label Timeline Overlayer',
  description: 'Overlay a timeline of frame labels onto video frames.',
  descriptionDetail:
    'This node draws a timeline of frame labels onto video frames, to provide visual feedback of when particular events occur. The timeline is centered on the current frame, and the length of the timeline can be configured.',
  icon: faTimeline,
  category: NodeCategory.VIDEO_COMPOSITORS,
  moduleType: 'FrameLabelTimelineOverlayer',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/5363686121103',
  configTypes: [
    {
      key: 'timeline_length_secs',
      type: PipelineConfigType.DECIMAL('Timeline length (seconds)')
        .DESCRIPTION(
          'The time period that timeline covers, half before and half after the current frame.'
        )
        .DEFAULT(10)
        .REQUIRED.MIN(1)
        .MAX(60)
        .SLIDER(1),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'overlaid_image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
};
