import { JSXElementConstructor, PropsWithChildren, ReactNode } from 'react';

interface Props {
  contextProviders: Array<JSXElementConstructor<PropsWithChildren<any>>>;
  children: ReactNode;
}

const ContextComposer = ({ contextProviders = [], children }: Props) => (
  <>
    {contextProviders.reduceRight((acc, Ctx) => {
      return <Ctx>{acc}</Ctx>;
    }, children)}
  </>
);

export default ContextComposer;
