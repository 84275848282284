import { faInfoCircle } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ListItemText } from '@mui/material';
import BlobMetadataRow from 'components/Blobs/BlobMetadataRow';
import InputBlobUploadButton from 'components/Blobs/InputBlobUploadButton';
import OutputBlobDownloadButton from 'components/Blobs/OutputBlobDownloadButton';
import DelayedTooltip from 'components/DelayedTooltip';
import { PipelineDataType } from 'graph/types';
import useGraphValidation from 'hooks/useGraphValidation';
import useHandleValidationState from 'hooks/useHandleValidationState';
import useRolePermissions from 'hooks/useRolePermissions';
import { Handle as FlowHandle, HandleType, Position } from 'reactflow';
import styles from './index.module.scss';
import './index.scss';

interface Props {
  nodeId: string;
  id: string;
  handleType: HandleType;
  dataType: PipelineDataType;
  isConnectable: boolean;
}

const Handle = ({ nodeId, id, handleType, dataType, isConnectable }: Props) => {
  const { isValidConnection } = useGraphValidation();
  const handleValidState = useHandleValidationState(
    nodeId,
    id,
    handleType,
    dataType
  );
  const { canAccessApi } = useRolePermissions();
  const className = `${styles.handle} ${styles[handleType]} ${styles[handleValidState]}`;

  const position = handleType === 'target' ? Position.Left : Position.Right;

  let tooltip = dataType.description;
  if (canAccessApi && (dataType.uploadable || dataType.downloadable)) {
    const typeStr = dataType.uploadable ? 'Upload' : 'Download';
    // Strip the final period from the description, if it exists
    tooltip = tooltip.replace(/\.$/, '');
    tooltip = `${tooltip}.\n${typeStr} key: ${id}`;
  }

  return (
    <div className={styles['outer-container']}>
      <div className={styles.container}>
        <ListItemText>{dataType.label}</ListItemText>
        <DelayedTooltip title={tooltip}>
          <span className={styles['info-btn']}>
            <FontAwesomeIcon icon={faInfoCircle} color="grey" />
          </span>
        </DelayedTooltip>
      </div>

      {dataType.handleHidden || (
        <FlowHandle
          className={className}
          type={handleType}
          position={position}
          id={id}
          isValidConnection={isValidConnection}
          isConnectable={isConnectable}
        />
      )}

      {dataType.downloadable && dataType.blobDataType && (
        <OutputBlobDownloadButton nodeId={nodeId} id={id} dataType={dataType} />
      )}
      {dataType.uploadable && dataType.blobDataType && (
        <>
          <InputBlobUploadButton nodeId={nodeId} id={id} dataType={dataType} />
          <BlobMetadataRow nodeId={nodeId} id={id} dataType={dataType} />
        </>
      )}
    </div>
  );
};

export default Handle;
