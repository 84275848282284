import { faCalculator } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Stereo Extrinsics Calculator',
  description:
    'Calculate the camera extrinsic parameters for a stereo pair of cameras.',
  descriptionDetail:
    'When provided calibration pattern detections and camera intrinsic parameters, this node estimates the projection matrices for a pair of cameras. The projection matrices represent both the intrinsic and extrinsic parameters of each camera.',
  icon: faCalculator,
  category: NodeCategory.CAMERA_CALIBRATION,
  moduleType: 'CalibrationExtrinsicsComputerSimple',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060523567759',
  configTypes: [
    {
      key: 'max_calibration_frames',
      type: PipelineConfigType.INTEGER('Max number of calibration frames')
        .REQUIRED.DEFAULT(20)
        .MIN(1)
        .MAX(150)
        .SLIDER(1)
        .DESCRIPTION(
          'The maximum number of frames used for calibration. More frames means better calibration but longer processing.'
        ),
    },
    {
      key: 'exception_on_failure',
      type: PipelineConfigType.BOOLEAN('Exception on failure')
        .CONSTANT_VALUE(true)
        .DESCRIPTION('Throw an exception if calibration fails.'),
    },
  ],
  inputTypes: [
    {
      key: 'first_pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED.LABEL(
        'Calibration patterns'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED.LABEL(
        'World calibration patterns'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_intrinsic_matrix',
      type: PipelineDataType.CAMERA_INTRINSIC_MATRIX.REQUIRED.LABEL(
        'Camera intrinsic matrix'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.LABEL(
        'Camera distortion coefficients'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'second_pattern',
      type: PipelineDataType.CALIBRATION_PATTERNS.REQUIRED.LABEL(
        'Calibration patterns'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_world_pattern',
      type: PipelineDataType.WORLD_CALIBRATION_PATTERNS.REQUIRED.LABEL(
        'World calibration patterns'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_intrinsic_matrix',
      type: PipelineDataType.CAMERA_INTRINSIC_MATRIX.REQUIRED.LABEL(
        'Camera intrinsic matrix'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.LABEL(
        'Camera distortion coefficients'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
  ],
  outputTypes: [
    {
      key: 'first_projection_matrix',
      type: PipelineDataType.CAMERA_PROJECTION_MATRIX.REQUIRED.LABEL(
        'Camera projection matrix'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'first_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.LABEL(
        'Camera distortion coefficients'
      ).UI_GROUP('camera_1', 'Camera 1'),
    },
    {
      key: 'second_projection_matrix',
      type: PipelineDataType.CAMERA_PROJECTION_MATRIX.REQUIRED.LABEL(
        'Camera projection matrix'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
    {
      key: 'second_dist_coeffs',
      type: PipelineDataType.CAMERA_DISTORTION_COEFFICIENTS.LABEL(
        'Camera distortion coefficients'
      ).UI_GROUP('camera_2', 'Camera 2'),
    },
  ],
};
