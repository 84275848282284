import { ButtonGroup, DialogActions, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { useWizard } from 'react-use-wizard';
import styles from './index.module.scss';

interface Props {
  helpText?: string | undefined;
  buttons: ReactNode[];
}

const ActionRow = ({ helpText, buttons }: Props) => {
  const { activeStep, stepCount } = useWizard();
  // Decrement stepCount as we have a special, hidden step at the start of the wizard
  const adjustedStepCount = stepCount - 1;
  return (
    <div className={styles.container}>
      <Typography variant="subtitle1" classes={{ root: styles.step }}>
        {`${activeStep} / ${adjustedStepCount}`}
      </Typography>
      <DialogActions>
        {helpText && (
          <Typography variant="subtitle2" classes={{ root: styles.help }}>
            {helpText}
          </Typography>
        )}

        <ButtonGroup>{buttons}</ButtonGroup>
      </DialogActions>
    </div>
  );
};

export default ActionRow;
