import AnnouncementsDialog from 'components/AnnouncementsDialog';

import { createContext, ReactElement, useEffect, useState } from 'react';

interface IAnnouncementsDialogContext {
  openAnnouncementsDialog: () => void;
  closeAnnouncementsDialog: () => void;
  announcementsDialogOpen: boolean;
}

export const AnnouncementsDialogContext =
  createContext<IAnnouncementsDialogContext>(undefined!);

export default ({ children }: { children: ReactElement[] | ReactElement }) => {
  const [announcementsDialogOpen, setAnnouncementsDialogOpen] = useState(false);
  const [hasBeenShownThisSession, setHasBeenShownThisSession] = useState(false);

  useEffect(() => {
    if (announcementsDialogOpen && !hasBeenShownThisSession) {
      setHasBeenShownThisSession(true);
    }
  }, [announcementsDialogOpen, hasBeenShownThisSession]);

  return (
    <AnnouncementsDialogContext.Provider
      value={{
        announcementsDialogOpen,
        openAnnouncementsDialog: () => setAnnouncementsDialogOpen(true),
        closeAnnouncementsDialog: () => setAnnouncementsDialogOpen(false),
      }}
    >
      {children}
      <AnnouncementsDialog
        open={announcementsDialogOpen}
        onOpen={() => setAnnouncementsDialogOpen(true)}
        onClose={() => setAnnouncementsDialogOpen(false)}
        hasBeenShownThisSession={hasBeenShownThisSession}
      />
    </AnnouncementsDialogContext.Provider>
  );
};
