import { ExtendedEnum } from './types';

export class NodeCategory extends ExtendedEnum {
  static get VIDEO_LOADERS() {
    return new NodeCategory(
      'VIDEO_LOADERS',
      'Video Loaders',
      'Nodes which load video frames from some source.'
    );
  }
  static get DATA_LOADERS() {
    return new NodeCategory(
      'DATA_LOADERS',
      'Data Loaders',
      'Nodes which load data from a file.'
    );
  }
  static get DETECTION() {
    return new NodeCategory(
      'DETECTION',
      'Detection',
      'Nodes which perform some form of detection on video frames.'
    );
  }
  static get VIDEO_COMPOSITORS() {
    return new NodeCategory(
      'VIDEO_COMPOSITORS',
      'Video Compositors',
      'Nodes which composite video frames and annotations.'
    );
  }
  static get VIDEO_UTILS() {
    return new NodeCategory(
      'VIDEO_UTILS',
      'Video Utilities',
      'Nodes which perform utility functions on video frames.'
    );
  }
  static get TRACKING() {
    return new NodeCategory(
      'TRACKING',
      'Tracking',
      'Nodes pertaining to tracking on bounding boxes or pose.'
    );
  }
  static get OUTPUT() {
    return new NodeCategory(
      'OUTPUT',
      'Output',
      'Nodes which generate some form of output for download.'
    );
  }
  static get CAMERA_CALIBRATION() {
    return new NodeCategory(
      'CAMERA_CALIBRATION',
      'Camera Calibration',
      'Nodes which perform camera calibration operations.'
    );
  }
  static get GRAPH_PLOTTING() {
    return new NodeCategory(
      'GRAPH_PLOTTING',
      'Graph Plotting',
      'Nodes which produce graph plots.'
    );
  }
  static get CONSTANTS() {
    return new NodeCategory(
      'CONSTANTS',
      'Constant Values',
      'Nodes which produce a constant value.'
    );
  }
  static get MISCELLANEOUS() {
    return new NodeCategory(
      ' MISCELLANEOUS', // Prefixed with a hidden character to push this to the bottom lexicographically - tricky!
      'Miscellaneous',
      "Miscellaneous nodes which don't necessarily perform any computation."
    );
  }
}
