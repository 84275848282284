import { faBezierCurve } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Track Interpolator',
  description:
    'Interpolate tracked bounding boxes, filling in missing detections.',
  descriptionDetail:
    'This node fills in missing tracked bounding box detections using an interpolation method of your choice. A larger interpolation window will produce longer tracks, but increases the likelihood of error.',
  icon: faBezierCurve,
  category: NodeCategory.TRACKING,
  moduleType: 'TrackInterpolator',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060440712335',
  configTypes: [
    {
      key: 'interpolation_window',
      type: PipelineConfigType.INTEGER('Interpolation window')
        .REQUIRED.DEFAULT(10)
        .MIN(1)
        .MAX(100)
        .SLIDER(1)
        .DESCRIPTION(
          'The maximum number of missing detections over which to interpolate.'
        ),
    },
    {
      key: 'interpolation_method',
      type: PipelineConfigType.STRING('Interpolation method')
        .DEFAULT('linear')
        .OPTIONS([
          'linear',
          'nearest',
          'zero',
          'slinear',
          'quadratic',
          'cubic',
          'previous',
          'next',
          'kalman',
        ])
        .DESCRIPTION(
          'The method used for interpolation. Method names are as defined in the SciPy method interp1d; "kalman" uses Kalman filter estimates for interpolation.'
        ).ADVANCED,
    },
  ],
  inputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
};
