import {
  add,
  distSq,
  distSqPointToLine,
  div,
  findFirstSatisfactoryConstraint,
  mul,
  nearestPointOnLineSegment,
  round,
  sub,
  Vec2,
} from './Vec2';

export const PX_PER_METRE = 100;

export interface INode {
  id: string;
  pixelPos: Vec2;
}
export interface INodeMap {
  [id: string]: INode;
}
export interface IAdjacencyMap {
  [source: string]: Set<string>;
}

export interface IEdge {
  id: string;
  source: string;
  target: string;
  distance: number;
  text: string;
}

const getMapEntryWithDefault = (
  map: IAdjacencyMap,
  key: string
): Set<string> => {
  if (!map[key]) {
    map[key] = new Set();
  }
  return map[key];
};

export const addAdjacency = (
  adjacencies: IAdjacencyMap,
  source: string,
  target: string
) => {
  getMapEntryWithDefault(adjacencies, source).add(target);
  getMapEntryWithDefault(adjacencies, target).add(source);
};

const callNumOrVec2Impl = <T extends Vec2 | number>(
  v: T,
  fNum: (v: number) => number,
  fVec2: (v: Vec2) => Vec2
) => {
  if (typeof v === 'number') {
    return fNum(v) as T;
  }
  return fVec2(v) as T;
};

// Convert world metres to pixels
export const worldToPixels = <T extends Vec2 | number>(worldPos: T) =>
  callNumOrVec2Impl(
    worldPos,
    (v) => roundWorldToCm(v) * PX_PER_METRE,
    (v) => mul(roundWorldToCm(v), PX_PER_METRE)
  );

// Convert pixels to world metres
export const pixelsToWorld = <T extends Vec2 | number>(pixelPos: T) =>
  callNumOrVec2Impl(
    pixelPos,
    (v) => roundWorldToCm(v / PX_PER_METRE),
    (v) => roundWorldToCm(div(v, PX_PER_METRE))
  );

// Round the world metres to the nearest cm
export const roundWorldToCm = <T extends Vec2 | number>(worldPos: T) =>
  callNumOrVec2Impl(
    worldPos,
    (v) => Math.round(v * PX_PER_METRE) / PX_PER_METRE,
    (v) => div(round(mul(v, PX_PER_METRE)), PX_PER_METRE)
  );

// Round the pixels to the nearest cm
export const roundPixelsToCm = <T extends Vec2 | number>(worldPos: T) =>
  worldToPixels(roundWorldToCm(pixelsToWorld(worldPos)));

export const removeAdjacency = (
  adjacencies: IAdjacencyMap,
  source: string,
  target: string
) => {
  getMapEntryWithDefault(adjacencies, source).delete(target);
  getMapEntryWithDefault(adjacencies, target).delete(source);
};

export const deleteAdjacenciesForNode = (
  adjacencies: IAdjacencyMap,
  nodeId: string
) => {
  delete adjacencies[nodeId];
  Object.keys(adjacencies).forEach((key) => {
    adjacencies[key].delete(nodeId);
  });
};

export const makeEdgeText = (distance: number) => `${distance.toFixed(2)}m`;

interface ISnapSettings {
  snapToNodes?: boolean; // Snap to nodes themselves
  snapToNodeAxes?: boolean; // Snap to the axes of nodes
  snapToEdges?: boolean; // Snap to edges connecting nodes
}

export interface IConstraint {
  x?: number;
  y?: number;
  line?: [Vec2, Vec2];
}

export const snapToOthers = (
  id: string,
  point: Vec2,
  nodes: INodeMap,
  adjacencies: IAdjacencyMap,
  selectedNodeIds: string[],
  coordinateScale: number,
  threshold: number,
  settings: ISnapSettings = {}
): [Vec2, [Vec2, Vec2][]] => {
  const { snapToNodes, snapToNodeAxes, snapToEdges } = settings;

  const neighbourNodeIds = Array.from(adjacencies[id]);
  const nodesButThis = Object.keys(nodes).filter(
    (id) => !selectedNodeIds.includes(id)
  );

  const allowedSnapAxisNodes = neighbourNodeIds.filter(
    (id) => !selectedNodeIds.includes(id)
  );
  const allowedSnapEdgeNodes = nodesButThis.filter(
    (id) => !selectedNodeIds.includes(id)
  );
  const allowedSnapNodes = allowedSnapEdgeNodes;

  const scaledPoint = mul(point, coordinateScale);

  const sqThreshold = threshold ** 2;
  const sqDistConstraints: {
    sqDist: number;
    constraint: IConstraint;
  }[] = [];
  const hintOverhang = 100 / coordinateScale;
  const snapHintLines: [Vec2, Vec2][] = [];

  if (snapToEdges) {
    // Check if we're close to any edges connecting nodes
    for (const sourceId in adjacencies) {
      if (!allowedSnapEdgeNodes.includes(sourceId)) continue;
      const sourcePoint = nodes[sourceId].pixelPos;
      const scaledSourcePoint = mul(sourcePoint, coordinateScale);

      for (const targetId of Array.from(adjacencies[sourceId])) {
        if (!allowedSnapEdgeNodes.includes(targetId)) continue;
        if (targetId < sourceId) continue; // Only check each edge once
        const targetPoint = nodes[targetId].pixelPos;
        const scaledTargetPoint = mul(
          nodes[targetId].pixelPos,
          coordinateScale
        );

        const candidatePoint = nearestPointOnLineSegment(
          scaledPoint,
          scaledSourcePoint,
          scaledTargetPoint
        );

        const sqDist = distSq(scaledPoint, candidatePoint);
        const constraint: IConstraint = { line: [sourcePoint, targetPoint] };
        sqDistConstraints.push({ sqDist, constraint });
        if (sqDist < sqThreshold) {
          // Add a snap hint line that extends 100px beyond each end of the edge
          const edgeVec = sub(targetPoint, sourcePoint);
          const edgeLength = Math.sqrt(distSq(sourcePoint, targetPoint));
          const edgeUnitVec = mul(edgeVec, 1 / edgeLength);

          snapHintLines.push([
            add(sourcePoint, mul(edgeUnitVec, -hintOverhang)),
            add(targetPoint, mul(edgeUnitVec, hintOverhang)),
          ]);
        }
      }
    }
  }
  if (snapToNodeAxes) {
    // Check if we're close to the x or y axis of any nodes
    for (const nodeId of allowedSnapAxisNodes) {
      const node = nodes[nodeId];
      const lineStart = node.pixelPos;
      const xLineEnd = add(node.pixelPos, { x: 1, y: 0 });
      const yLineEnd = add(node.pixelPos, { x: 0, y: 1 });
      const xLineSqDist = distSqPointToLine(
        scaledPoint,
        mul(lineStart, coordinateScale),
        mul(xLineEnd, coordinateScale)
      );
      const yLineSqDist = distSqPointToLine(
        scaledPoint,
        mul(lineStart, coordinateScale),
        mul(yLineEnd, coordinateScale)
      );
      const constraint1: IConstraint = { y: node.pixelPos.y };
      sqDistConstraints.push({ sqDist: xLineSqDist, constraint: constraint1 });

      if (xLineSqDist < sqThreshold) {
        const sign = node.pixelPos.x < point.x ? -1 : 1;
        snapHintLines.push([
          { x: point.x - sign * hintOverhang, y: node.pixelPos.y },
          { x: node.pixelPos.x + sign * hintOverhang, y: node.pixelPos.y },
        ]);
      }
      const constraint2: IConstraint = { x: node.pixelPos.x };
      sqDistConstraints.push({ sqDist: yLineSqDist, constraint: constraint2 });
      if (yLineSqDist < sqThreshold) {
        const sign = node.pixelPos.y < point.y ? -1 : 1;
        snapHintLines.push([
          { x: node.pixelPos.x, y: point.y - sign * hintOverhang },
          { x: node.pixelPos.x, y: node.pixelPos.y + sign * hintOverhang },
        ]);
      }
    }
  }

  if (snapToNodes) {
    // Check if we're close to any nodes
    for (const nodeId of allowedSnapNodes) {
      const node = nodes[nodeId];
      const sqDist = distSq(scaledPoint, mul(node.pixelPos, coordinateScale));

      const constraint: IConstraint = {
        x: node.pixelPos.x,
        y: node.pixelPos.y,
      };
      sqDistConstraints.push({ sqDist, constraint });
      if (sqDist < sqThreshold) {
        snapHintLines.push([
          { x: node.pixelPos.x - hintOverhang, y: node.pixelPos.y },
          { x: node.pixelPos.x + hintOverhang, y: node.pixelPos.y },
        ]);
        snapHintLines.push([
          { x: node.pixelPos.x, y: node.pixelPos.y - hintOverhang },
          { x: node.pixelPos.x, y: node.pixelPos.y + hintOverhang },
        ]);
      }
    }
  }

  const allConstraints = sqDistConstraints.map((c) => c.constraint);
  // Scale up the constraints to account for zoom
  const scaledConstraints: IConstraint[] = allConstraints.map((c) => ({
    x: c.x !== undefined ? c.x * coordinateScale : undefined,
    y: c.y !== undefined ? c.y * coordinateScale : undefined,
    line: c.line
      ? [mul(c.line[0], coordinateScale), mul(c.line[1], coordinateScale)]
      : undefined,
  }));
  // Here we apply each of the constraints by type consecutively, until we find one that works (or not)
  const finalPoint =
    findFirstSatisfactoryConstraint(scaledPoint, scaledConstraints, threshold, [
      'xy',
    ]) ??
    findFirstSatisfactoryConstraint(scaledPoint, scaledConstraints, threshold, [
      'xOrYOnLine',
    ]) ??
    findFirstSatisfactoryConstraint(scaledPoint, scaledConstraints, threshold, [
      'x',
      'y',
      'lineLineIntersection',
      'pointOnLine',
    ]);
  // Scale back to the regular coordinate space
  const outPoint = div(finalPoint ?? scaledPoint, coordinateScale);

  return [outPoint, snapHintLines];
};

/**
 * Returns the nearest node to the query pixel position
 */
export const nearestNodeToPixelPos = (location: Vec2, nodes: INodeMap) => {
  let nearestNode: INode | undefined;
  let nearestDSq = Infinity;
  for (const nodeId in nodes) {
    const node = nodes[nodeId];
    const dSq = distSq(location, node.pixelPos);
    if (dSq < nearestDSq) {
      nearestDSq = dSq;
      nearestNode = node;
    }
  }
  return nearestNode;
};

/**
 * Returns the nearest coordinate to the query coordinate
 */
export const nearestCoordinateIndex = (query: Vec2, coordinates: Vec2[]) => {
  let nearestIndex = -1;
  let nearestDSq = Infinity;
  for (let i = 0; i < coordinates.length; i++) {
    const coordinate = coordinates[i];
    const dSq = distSq(query, coordinate);
    if (dSq < nearestDSq) {
      nearestDSq = dSq;
      nearestIndex = i;
    }
  }
  return nearestIndex;
};

export const nearestCoordinate = (query: Vec2, coordinates: Vec2[]) => {
  const nearestIndex = nearestCoordinateIndex(query, coordinates);
  return nearestIndex === -1 ? undefined : coordinates[nearestIndex];
};
