/**
 * Hashes a string and returns a hue value in the range [0, 360). This implementation
 * matches pipeline/utilities/image_utilities.py:hash_string_and_get_hue in the Pipelines
 * backend frontend, so we can use the same colours for the same labels.
 *
 * @param {string} inputStr - The string to hash.
 * @returns {number} A hue value in the range [0, 360).
 */
export const hashStringAndGetHue = (inputStr: string): number => {
  inputStr = `${inputStr}${inputStr.length}`;

  let hash = 0;
  for (let i = 0; i < inputStr.length; i++) {
    hash = ((hash * 33) ^ inputStr.charCodeAt(i)) >>> 0;
  }

  return Math.abs(hash) % 360;
};

/**
 * Converts an HSL colour to a hex colour.
 * @param {number} h - The hue value.
 * @param {number} s - The saturation value.
 * @param {number} l - The lightness value.
 * @returns {string} The hex colour.
 */
export const hslToHex = (h: number, s: number, l: number) => {
  l /= 100;
  const a = (s * Math.min(l, 1 - l)) / 100;
  const f = (n: number) => {
    const k = (n + h / 30) % 12;
    const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
    return Math.round(255 * color)
      .toString(16)
      .padStart(2, '0'); // convert to Hex and prefix "0" if needed
  };
  return `#${f(0)}${f(8)}${f(4)}`;
};
