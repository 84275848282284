import { APP_NAME } from 'config';
import useGraph from 'hooks/useGraph';
import { useEffect } from 'react';

const DynamicTitle = () => {
  const {
    graphState: { name },
  } = useGraph();

  useEffect(() => {
    if (name) {
      document.title = `${name} | ${APP_NAME}`;
    }
    return () => {
      document.title = APP_NAME;
    };
  }, [name]);

  return <span />;
};

export default DynamicTitle;
