import GraphBrowserDialog from 'components/GraphBrowserDialog';
import useGraphFromTemplate from 'hooks/useGraphFromTemplate';
import useGraphSummaries from 'hooks/useGraphSummaries';
import { useEffect } from 'react';
import { PrivacySetting } from '../../graphql/graphql';

interface Props {
  open: boolean;
  onClose: () => void;
}

const TemplatePicker = ({ open, onClose }: Props) => {
  const { publicOrTemplateGraphs, refetchGraphSummaries } = useGraphSummaries();
  const graphFromTemplateAndRedirect = useGraphFromTemplate();
  const templateGraphs = publicOrTemplateGraphs.filter(
    (graph) => graph?.privacy === PrivacySetting.Template
  );

  useEffect(() => {
    if (open) {
      void refetchGraphSummaries();
    }
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const selectGraph = async (templateId: string | undefined) => {
    if (templateId) {
      await graphFromTemplateAndRedirect(templateId);
      onClose();
    }
  };

  return (
    <GraphBrowserDialog
      title="Create from Template"
      subtitle="Create a new pipeline from a template."
      openHint="Create pipeline from this template"
      open={open}
      onClose={onClose}
      onSelectGraph={(graphId) => void selectGraph(graphId)}
      graphs={templateGraphs}
    />
  );
};

export default TemplatePicker;
