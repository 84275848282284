import { Blob, IFlowGraph, SaveStatus } from 'contexts/Graph';
import {
  IGraphValidationState,
  INodeValidationState,
} from 'contexts/GraphValidation';
import { JobStatus } from 'contexts/Job';
import { Connection, Edge, getIncomers, getOutgoers, Node } from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import { Pipeline_BlobType } from '../graphql/graphql';
import { NodeTypeKey, NodeTypes } from './NodeTypes';
import { PipelineDataType } from './types';

/**
 * Perform DFS starting from a given node, appending the visited nodes to `visited`
 * @param node - the starting node
 * @param flowGraph - all flow graph elements (nodes & edges)
 * @param visited - ordered list of visited nodes
 * @param reverse - if true, traverses the graph in reverse
 */
const dfs = (
  node: Node,
  flowGraph: IFlowGraph,
  visited: Node[],
  reverse = false
) => {
  if (!visited.includes(node)) {
    visited.push(node);
  }
  const getConnectedNodes = reverse ? getIncomers : getOutgoers;

  getConnectedNodes(node, flowGraph.nodes, flowGraph.edges).forEach(
    (nextNode) => {
      if (!visited.includes(nextNode)) {
        dfs(nextNode, flowGraph, visited, reverse);
      }
    }
  );
};

const dataTypeIsRequired = (
  dataType: PipelineDataType,
  node: Node,
  inputBlobs: Blob[]
) => {
  // It's obviously not required if it's not marked as required
  if (!dataType.required) return false;

  // However, if it's required and depends on a specific blob meta key existing to be required,
  // check if that meta key exists
  if (dataType.requiresBlobMetaKey) {
    const { inputKey, metaKey } = dataType.requiresBlobMetaKey;
    const relatedBlob = inputBlobs.find(
      (blob) => blob.nodeId === node.id && blob.propertyId === inputKey
    );
    if (!relatedBlob) {
      return false;
    }
    if (relatedBlob.metadata?.[metaKey]) {
      return true;
    }
  } else if (dataType.requiresConfigKeyValue) {
    return Boolean(node.data?.config?.[dataType.requiresConfigKeyValue]);
  }

  return true;
};

const validateNode = (
  node: Node,
  edges: Edge[],
  inputBlobs: Blob[]
): INodeValidationState => {
  const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
  const { configTypes, inputTypes, outputTypes } = nodeTypeObj;

  const requiredConfigKeys = (configTypes ?? [])
    .filter((configType) => configType.type.required)
    .map((configType) => configType.key);
  const requiredInputKeys = (inputTypes ?? [])
    .filter((inputType) => dataTypeIsRequired(inputType.type, node, inputBlobs))
    .map((inputType) => inputType.key);
  const requiredOutputKeys = (outputTypes ?? [])
    .filter((outputType) =>
      dataTypeIsRequired(outputType.type, node, inputBlobs)
    )
    .map((outputType) => outputType.key);

  const configValExists = (value: any) =>
    value !== null && typeof value !== 'undefined';
  const inputExists = (nodeId: string, handleId: string) =>
    Boolean(
      edges.find(
        (edge) => edge.target === nodeId && edge.targetHandle === handleId
      )
    ) ||
    Boolean(
      getBlobByKey(inputBlobs, nodeId, handleId, Pipeline_BlobType.Input)
    );
  const outputExists = (nodeId: string, handleId: string) =>
    edges.find(
      (edge) => edge.source === nodeId && edge.sourceHandle === handleId
    );

  const missingRequiredConfigs = requiredConfigKeys.filter(
    (key) => !configValExists(node.data?.config[key])
  );
  const hasRequiredConfig = missingRequiredConfigs.length === 0;

  const missingRequiredInputs = requiredInputKeys.filter(
    (key) => !inputExists(node.id, key)
  );
  const hasRequiredInputs = missingRequiredInputs.length === 0;

  const missingRequiredOutputs = requiredOutputKeys.filter(
    (key) => !outputExists(node.id, key)
  );
  const hasRequiredOutputs = missingRequiredOutputs.length === 0;

  const valid = hasRequiredConfig && hasRequiredInputs && hasRequiredOutputs;

  return {
    id: node.id,
    hasRequiredConfig,
    hasRequiredInputs,
    hasRequiredOutputs,
    valid,
    missingRequiredConfigs,
    missingRequiredInputs,
    missingRequiredOutputs,
  };
};

export const validateGraph = (
  flowGraph: IFlowGraph,
  inputBlobs: Blob[]
): IGraphValidationState => {
  const validationState: IGraphValidationState = {
    isFullyConnected: true,
    hasRequiredConfig: true,
    hasRequiredInputs: true,
    hasRequiredOutputs: true,
    hasSomeNodes: false,
    valid: true,
    nodeValidation: [],
  };
  // Validate nodes and aggregate
  flowGraph.nodes.forEach((node) => {
    const nodeVal = validateNode(node, flowGraph.edges, inputBlobs);
    validationState.hasRequiredConfig =
      validationState.hasRequiredConfig && nodeVal.hasRequiredConfig;
    validationState.hasRequiredInputs =
      validationState.hasRequiredInputs && nodeVal.hasRequiredInputs;
    validationState.hasRequiredOutputs =
      validationState.hasRequiredOutputs && nodeVal.hasRequiredOutputs;
    validationState.hasSomeNodes = true;

    validationState.nodeValidation.push(nodeVal);
  });

  // Check fully connected
  if (flowGraph.nodes.length > 0) {
    const visited: Node[] = [];
    flowGraph.nodes.forEach((node) => {
      dfs(node, flowGraph, visited);
      dfs(node, flowGraph, visited, true);
    });
    validationState.isFullyConnected =
      visited.length === flowGraph.nodes.length;
  }

  // Compute overall graph validation
  validationState.valid =
    validationState.isFullyConnected &&
    validationState.hasRequiredConfig &&
    validationState.hasRequiredInputs &&
    validationState.hasSomeNodes &&
    validationState.hasRequiredOutputs;

  return validationState;
};

export const isValidConnection = (
  flowGraph: IFlowGraph,
  connection: Connection
): boolean => {
  const { source, target, sourceHandle, targetHandle } = connection;
  if (!sourceHandle || !targetHandle) {
    // Only allow connections with handles specified
    return false;
  }

  const sourceNode = flowGraph.nodes.find((node) => node.id === source);
  const targetNode = flowGraph.nodes.find((node) => node.id === target);

  let valid = true;

  if (sourceNode && targetNode) {
    // Get the source and target node types
    const sourceNodeType = NodeTypes[sourceNode.data.nodeType as NodeTypeKey];
    const targetNodeType = NodeTypes[targetNode.data.nodeType as NodeTypeKey];

    // Get the data types for the corresponding input/output
    const sourceOutputType = sourceNodeType?.outputTypes?.find(
      (dataType) => dataType.key === sourceHandle
    )?.type;
    const targetInputType = targetNodeType?.inputTypes?.find(
      (dataType) => dataType.key === targetHandle
    )?.type;

    // Valid if the source and target handles are compatible with one another
    valid = Boolean(
      sourceOutputType &&
        targetInputType &&
        sourceOutputType.compatibleWith(targetInputType)
    );
  }

  // Invalid if the target handle already has an input
  flowGraph.edges.forEach((edge) => {
    if (
      edge.target === connection.target &&
      edge.targetHandle === connection.targetHandle
    ) {
      valid = false;
    }
  });

  return valid;
};

export const getInputDataType = (
  flowGraph: IFlowGraph,
  connection: Connection
): PipelineDataType | undefined => {
  const { target, targetHandle } = connection;
  if (!targetHandle) return;

  const targetNode = flowGraph.nodes.find((node) => node.id === target);

  if (targetNode) {
    // Get the source node type
    const targetNodeType = NodeTypes[targetNode.data.nodeType as NodeTypeKey];

    // Get the data type for the corresponding input
    const targetInputType = targetNodeType?.inputTypes?.find(
      (dataType) => dataType.key === targetHandle
    )?.type;
    return targetInputType;
  }
};
export const getOutputDataType = (
  flowGraph: IFlowGraph,
  connection: Connection
): PipelineDataType | undefined => {
  const { source, sourceHandle } = connection;
  if (!sourceHandle) return;

  const sourceNode = flowGraph.nodes.find((node) => node.id === source);

  if (sourceNode) {
    // Get the source node type
    const sourceNodeType = NodeTypes[sourceNode.data.nodeType as NodeTypeKey];

    // Get the data type for the corresponding output
    const sourceOutputType = sourceNodeType?.outputTypes?.find(
      (dataType) => dataType.key === sourceHandle
    )?.type;

    return sourceOutputType;
  }
};

export interface ICanStartStopJob {
  canStartJob: boolean;
  canCancelJob: boolean;
}

export const canStartStopJob = (
  jobStatus: JobStatus,
  graphValidation: IGraphValidationState | undefined,
  saveStatus: SaveStatus,
  uploadingCount: number
): ICanStartStopJob => {
  const upToDate = saveStatus == SaveStatus.UP_TO_DATE;
  const filesUploading = uploadingCount > 0;
  const canCancelJob =
    upToDate &&
    Boolean(graphValidation) &&
    [JobStatus.QUEUED, JobStatus.STARTING, JobStatus.RUNNING].includes(
      jobStatus
    );
  const canStartJob =
    upToDate &&
    Boolean(graphValidation) &&
    !canCancelJob &&
    Boolean(graphValidation?.valid) &&
    !filesUploading;

  return {
    canStartJob,
    canCancelJob,
  };
};
