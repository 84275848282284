import { faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Video Writer',
  description: 'Construct a video file and make it available for download.',
  descriptionDetail:
    'This node writes input frames to a video file and uploads it to the cloud, making it available for download via the browser.',
  icon: faFileVideo,
  category: NodeCategory.OUTPUT,
  blobInputAffectorKey: 'image', // This is a special field which tells us which of the inputs controls the blob name
  moduleType: 'VideoWriter',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060731298831',
  configTypes: [
    {
      key: 'blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}.mp4').REQUIRED
        .HIDDEN,
    },
    {
      key: 'ffmpeg_compression_profile',
      type: PipelineConfigType.STRING('Compression Profile')
        .DEFAULT('balanced')
        .DESCRIPTION(
          'The compression profile to use for the video, compensating between quality and file size.'
        )
        .REQUIRED.OPTIONS(['high_quality', 'balanced', 'high_compression'])
        .ADVANCED,
    },
    {
      key: 'fps_override',
      type: PipelineConfigType.DECIMAL('Frame Rate Override (Hz)')
        .MIN(1)
        .DESCRIPTION(
          'Override the frame rate of the video. If not set, the frame rate will be the same as the input video. This may be required in some cases, such as plotting data loaded from a file with no timing information.'
        ).ADVANCED,
    },
    {
      key: 'overlay_frame_num',
      type: PipelineConfigType.BOOLEAN('Overlay frame number')
        .DEFAULT(true)
        .DESCRIPTION(
          'Whether to overlay the frame number on the corner of the video.'
        ),
    },
    {
      key: 'frame_num_colour',
      type: PipelineConfigType.COLOUR('Frame number colour')
        .REQUIRED.DEFAULT([0, 0, 255])
        .ADVANCED.DESCRIPTION('The colour to draw the frame number.'),
    },
    {
      key: 'overlay_timestamp',
      type: PipelineConfigType.BOOLEAN('Overlay timestamp')
        .DEFAULT(true)
        .DESCRIPTION(
          'Whether to overlay the timestamp on the corner of the video.'
        ),
    },
    {
      key: 'timestamp_colour',
      type: PipelineConfigType.COLOUR('Timestamp colour')
        .REQUIRED.DEFAULT([0, 0, 255])
        .ADVANCED.DESCRIPTION('The colour to draw the frame number.'),
    },
    {
      key: 'overwrite_existing',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
    {
      key: 'overlay_pipelines_logo',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
    {
      key: 'extension',
      type: PipelineConfigType.STRING().CONSTANT_VALUE('.mp4'),
    },
    {
      key: 'encoding',
      type: PipelineConfigType.STRING().CONSTANT_VALUE('h264'),
    },
    {
      key: 'use_ffmpeg',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
  ],
  inputTypes: [
    {
      key: 'image',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'video',
      type: PipelineDataType.VIDEO_FILE.DOWNLOADABLE.NO_HANDLE,
    },
  ],
};
