import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button, ButtonGroup } from '@mui/material';
import useGraph from 'hooks/useGraph';
import { useState } from 'react';
import { currDateTimeString, splitFileExtension } from 'utils/helpers';
import { getMediaInfo } from 'utils/mediainfo';
import { Blob, IFlowGraph, SaveStatus } from '../../../contexts/Graph';
import { blobDataTypeExtension, PipelineDataType } from '../../../graph/types';
import {
  Pipeline_BlobDataType,
  Pipeline_BlobType,
} from '../../../graphql/graphql';
import useFlowGraph from '../../../hooks/useFlowGraph';
import useJobInfo from '../../../hooks/useJobInfo';
import useNotifications from '../../../hooks/useNotifications';
import DelayedTooltip from '../../DelayedTooltip';
import FilePicker from '../../FilePicker';
import StyledButton from '../../StyledButton';
import styles from './index.module.scss';
import UploadInProgressButton from './UploadInProgressButton';

interface Props {
  blob: Blob | undefined;
  setBlob: (blob: Blob) => void;
  removeBlob: () => void;
  nodeId: string;
  propertyId: string;
  dataType: PipelineDataType;
  blobType: Pipeline_BlobType;
}

/**
 * Check if a video was uploaded and a point correspondence was already set in
 * the node's config. Return true if so.
 */
const videoUploadedAndCorrespondenceSet = (
  flowGraph: IFlowGraph,
  nodeId: string,
  dataType: PipelineDataType,
  blobType: Pipeline_BlobType
) => {
  const thisNode = flowGraph.nodes.find((node) => node.id === nodeId);
  if (
    thisNode &&
    blobType === Pipeline_BlobType.Input &&
    dataType.key === PipelineDataType.VIDEO_FILE.key &&
    thisNode.data?.config?.pointCorrespondence
  ) {
    return true;
  }
  return false;
};

/**
 * Check if a video was uploaded and frame labels were already set in
 * the node's config. Return true if so.
 */
const videoUploadedAndFrameLabelsSet = (
  flowGraph: IFlowGraph,
  nodeId: string,
  dataType: PipelineDataType,
  blobType: Pipeline_BlobType
) => {
  const thisNode = flowGraph.nodes.find((node) => node.id === nodeId);
  if (
    thisNode &&
    blobType === Pipeline_BlobType.Input &&
    dataType.key === PipelineDataType.VIDEO_FILE.key &&
    thisNode.data?.config?.frame_labels?.length
  ) {
    return true;
  }
  return false;
};

const BlobUploadButton = ({
  blob,
  setBlob,
  removeBlob,
  blobType,
  nodeId,
  propertyId,
  dataType,
}: Props) => {
  const [file, setFile] = useState<File | undefined>();
  const [readingMeta, setReadingMeta] = useState(false);
  const { saveStatus } = useGraph();
  const notify = useNotifications();
  const { flowGraph: flowGraph } = useFlowGraph();
  const { incrementUploadingCount, decrementUploadingCount } = useJobInfo();

  const originalFilename = blob?.originalFilename;
  const downloadUrl = blob?.downloadUrl?.url;
  const uploadUrl = blob?.uploadUrl?.url;

  const canModify = saveStatus === SaveStatus.UP_TO_DATE;

  if (readingMeta) {
    return (
      <Button variant="outlined" color="primary" disabled fullWidth>
        Reading metadata...
      </Button>
    );
  }

  const disabledTooltip = 'Disabled while the pipeline is being saved.';
  if (uploadUrl) {
    if (blob?.uploadPending && !file) {
      // It shouldn't be possible for there to be an upload pending without a file, so just remove the blob
      removeBlob();
    }
    if (file) {
      return (
        <UploadInProgressButton
          file={file}
          uploadUrl={uploadUrl}
          onUploadComplete={() => {
            if (blob) {
              const newBlob: Blob = {
                ...blob,
                uploadPending: false,
              };
              setBlob(newBlob);
              setFile(undefined);
              decrementUploadingCount();
            }
            if (
              videoUploadedAndCorrespondenceSet(
                flowGraph,
                nodeId,
                dataType,
                blobType
              )
            ) {
              notify.warning(
                'Video uploaded, but point correspondence already set. Verify that the correspondence is aligned with the new video.'
              );
            }
            if (
              videoUploadedAndFrameLabelsSet(
                flowGraph,
                nodeId,
                dataType,
                blobType
              )
            ) {
              notify.warning(
                'Video uploaded, but frame labels already set. Verify that the frame labels are aligned with the new video, or clear them.'
              );
            }
            notify.success('Upload complete');
          }}
          onUploadCancelled={() => {
            removeBlob();
            setFile(undefined);
            decrementUploadingCount();
            notify.error('Upload cancelled');
          }}
          onUploadFailed={(msg) => {
            removeBlob();
            setFile(undefined);
            decrementUploadingCount();
            notify.error(`Upload failed: ${msg}`);
          }}
        />
      );
    }
  }

  if (originalFilename && downloadUrl) {
    return (
      <DelayedTooltip
        title={canModify ? `Download or delete the file` : disabledTooltip}
      >
        <span>
          <ButtonGroup fullWidth>
            <StyledButton
              className={`nodrag ${styles['open-btn']}`}
              styleName="primary"
              onClick={() => window.open(downloadUrl, '_blank')}
              disabled={!canModify}
            >
              {originalFilename}
            </StyledButton>

            <StyledButton
              className={`nodrag ${styles['delete-btn']}`}
              styleName="danger"
              onClick={removeBlob}
              disabled={!canModify}
            >
              <FontAwesomeIcon icon={faTimes} />
            </StyledButton>
          </ButtonGroup>
        </span>
      </DelayedTooltip>
    );
  }

  let uploadTxt = `Click to upload the ${dataType.label.toLowerCase()} as ${
    dataType.blobDataType
  }.`;
  const isVideoFile = dataType.key === PipelineDataType.VIDEO_FILE.key;
  // A simpler message for video files, instead of saying "upload video as video"
  if (isVideoFile) {
    uploadTxt = 'Click to upload a video file.';
  }

  return (
    <DelayedTooltip title={canModify ? uploadTxt : disabledTooltip}>
      <span>
        <FilePicker
          className="nodrag"
          buttonTitle={`Upload ${dataType.blobDataType}`}
          onSelect={(filename, file) => {
            function createBlob(metadata: any = {}) {
              // Strip the existing file extension, matching the entire extension for the datatype
              const expectedExtension = blobDataTypeExtension(
                dataType.blobDataType!
              );
              const [extensionless, ext] = splitFileExtension(
                filename,
                expectedExtension
              );

              const newBlob: Blob = {
                blobType,
                blobDataType: dataType.blobDataType!,
                formatConfigFlags: [],
                nodeId: nodeId,
                propertyId: propertyId,
                blobName: `${extensionless}_${currDateTimeString()}.${ext}`,
                originalFilename: filename,
                uploadPending: true,
                metadata,
              };

              setBlob(newBlob);
              setFile(file);
              incrementUploadingCount();

              notify.info('Starting upload...');
            }

            if (isVideoFile) {
              const processVideo = async () => {
                setReadingMeta(true);
                try {
                  const mediaInfo = await getMediaInfo(file);
                  createBlob({ mediaInfo });
                } catch (e) {
                  notify.error(`Error reading video metadata: ${e}`);
                } finally {
                  setReadingMeta(false);
                }
              };
              void processVideo();
            } else {
              // Read the file to verify that it's the correct type
              const reader = new FileReader();
              reader.onload = () => {
                const text = reader.result as string;
                try {
                  if (
                    dataType.blobDataType &&
                    dataType.blobDataType === Pipeline_BlobDataType.Json
                  ) {
                    // Do some basic JSON validation
                    try {
                      const array = JSON.parse(text);
                      if (!Array.isArray(array)) {
                        throw new Error(
                          'The JSON file must contain an array of objects. Check the file and try again.'
                        );
                      }
                      // Check that all have the keys 'frame' and 'data'
                      for (const item of array) {
                        if (!('frame' in item) || !('data' in item)) {
                          throw new Error(
                            'Each object in the JSON file must have the keys "frame" and "data". Check the file and try again.'
                          );
                        }
                      }
                    } catch (e) {
                      throw new Error(
                        'The JSON file is not valid. Check the file and try again.'
                      );
                    }
                  } else if (
                    dataType.blobDataType &&
                    dataType.blobDataType == Pipeline_BlobDataType.Csv
                  ) {
                    // Not really any validation we can do here...
                  }
                  createBlob();
                } catch (e: any) {
                  notify.error(e.message as string);
                }
              };
              reader.readAsText(file);
            }
          }}
          fileFormat={dataType.blobDataType!}
          disabled={!canModify}
          styleName={dataType.required ? 'danger' : undefined}
        />
      </span>
    </DelayedTooltip>
  );
};

export default BlobUploadButton;
