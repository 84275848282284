export const getVideoFrame = (
  videoUrl: string,
  seekSeconds: number
): Promise<string> => {
  return new Promise(
    (
      resolve: (base64Frame: string) => void,
      reject: (error: string) => void
    ) => {
      const canvas: HTMLCanvasElement = document.createElement('canvas');
      const context: CanvasRenderingContext2D = canvas.getContext('2d')!;

      const video = document.createElement('video');
      video.preload = 'auto';
      video.crossOrigin = 'anonymous';

      const eventCallback = () => {
        video.removeEventListener('seeked', eventCallback);
        context.drawImage(video, 0, 0, video.videoWidth, video.videoHeight);
        const dataUrl = canvas.toDataURL('image/jpeg');
        if (dataUrl === 'data:,') {
          return reject('Could not get frame from video');
        }
        // Stop the video and remove its src to prevent any further downloading
        video.pause();
        video.src = '';
        return resolve(dataUrl);
      };
      video.addEventListener('seeked', eventCallback);

      video.addEventListener('loadeddata', () => {
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;

        // Clamp seek to [0, duration - 0.1] to prevent seeking outside video
        const seekTime = Math.min(
          Math.max(0, seekSeconds),
          video.duration - 0.1
        );
        video.currentTime = seekTime;
      });
      video.addEventListener('error', () => {
        reject('Could not load video');
      });
      video.src = videoUrl;
      video.load();
    }
  );
};
