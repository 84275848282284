import { FormControlLabel, Switch } from '@mui/material';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<any, any> {
  labelOverride?: string;
  [key: string]: any;
}

const BooleanInput = ({
  value,
  setValue,
  configType,
  labelOverride,
  nodeId: _1,
  configKey: _2,
  ...rest
}: Props) => {
  const label = labelOverride ?? configType.label;
  return (
    <FormControlLabel
      label={label}
      className={`${styles.switch} nodrag`}
      key={label}
      control={
        <Switch
          checked={value}
          color="primary"
          onChange={(e) => {
            configType.setValue(e.target.checked, setValue);
          }}
          {...rest}
        />
      }
    />
  );
};

export default BooleanInput;
