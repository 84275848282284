import { faFilter } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';

export default {
  name: 'Track Selector',
  description:
    'Select the best track from a set of tracks based on a heuristic function.',
  descriptionDetail:
    'This node selects the best track from a set of tracks based on the track with the min/max value of a heuristic function. IMPORTANT: This caches the tracks for the entire duration of the video before selection, so it is not suitable for long videos as it may otherwise cause an out-of-memory error and crash the pipeline.',
  icon: faFilter,
  category: NodeCategory.TRACKING,
  moduleType: 'TrackSelector',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/9430328640399',
  configTypes: [
    {
      key: 'heuristic',
      type: PipelineConfigType.STRING('Heuristic')
        .DEFAULT('area')
        .OPTIONS(['area', 'duration', 'movement', 'displacement'])
        .DESCRIPTION(
          'The heuristic function used to select the best track. area: total bounding box area; duration: total duration of the track; movement: cumulative movement of the track; displacement: start->finish displacement of the track.'
        ),
    },
    {
      key: 'mode',
      type: PipelineConfigType.STRING('Selection Mode')
        .DEFAULT('max')
        .OPTIONS(['min', 'max'])
        .DESCRIPTION(
          'The selection mode used to select the best track. Select the track with either the min or max value of the heuristic function.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'tracked_bounding_boxes',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'selected_bounding_box',
      type: PipelineDataType.TRACKED_BOUNDING_BOXES.REQUIRED,
    },
  ],
};
