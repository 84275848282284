import { Blob } from 'contexts/Graph';
import { useEffect, useState } from 'react';
import { getVideoFrame } from './frames';
import styles from './index.module.scss';

interface Props {
  videoBlob: Blob | undefined;
  seekSeconds: number;
  onError: () => void;
  maxWidth?: number | string;
}

const VideoFramePreview = ({
  videoBlob,
  seekSeconds,
  onError,
  maxWidth = '100%',
}: Props) => {
  const [previewFrame, setPreviewFrame] = useState<string | undefined>(
    undefined
  );
  const [previewFrameUrl, setPreviewFrameUrl] = useState<string | undefined>(
    undefined
  );

  useEffect(() => {
    const url = videoBlob?.downloadUrl?.url;
    if (url) {
      if (url !== previewFrameUrl) {
        void getVideoFrame(url, seekSeconds)
          .then((base64Data: string) => {
            setPreviewFrame(base64Data);
            setPreviewFrameUrl(url);
          })
          .catch(onError);
      }
    } else {
      setPreviewFrame(undefined);
      setPreviewFrameUrl(undefined);
    }
  }, [videoBlob?.downloadUrl?.url, seekSeconds, previewFrameUrl, onError]);

  if (!videoBlob || !previewFrame) {
    return null;
  }
  return (
    <img
      crossOrigin="anonymous"
      src={previewFrame}
      alt="preview"
      style={{
        maxWidth,
      }}
      className={styles.image}
    />
  );
};

export default VideoFramePreview;
