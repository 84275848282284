/**
 * Clamps a value to the range (inclusive) [minValue, maxValue], if provided.
 */
export const clamp = (
  value: number,
  minValue: number | undefined,
  maxValue: number | undefined
): number => {
  if (typeof minValue !== 'undefined') {
    value = Math.max(value, minValue);
  }
  if (typeof maxValue !== 'undefined') {
    value = Math.min(value, maxValue);
  }
  return value;
};

/**
 * Returns true if the value provided is within the min/max (inclusive) range. Constraints may be undefined.
 */
export const valueViolatesMinMaxConstraints = (
  value: number,
  minValue: number | undefined,
  maxValue: number | undefined
): boolean => {
  return (
    (typeof minValue !== 'undefined' && value < minValue) ||
    (typeof maxValue !== 'undefined' && value > maxValue)
  );
};

/**
 * Returns a pretty string representation of the constraint in set notation.
 */
export const constraintsSetString = (
  minValue: number | undefined,
  maxValue: number | undefined
): string => {
  let outStr = '';
  if (typeof minValue === 'undefined') {
    outStr = '(-inf, ';
  } else {
    outStr = `[${minValue}, `;
  }
  if (typeof maxValue === 'undefined') {
    outStr = `${outStr}inf)`;
  } else {
    outStr = `${outStr}${maxValue}]`;
  }
  return outStr;
};

/**
 * Returns a  warning message with a pretty string representation of the constraint in set notation.
 */
export const constraintsSetWarningString = (
  minValue: number | undefined,
  maxValue: number | undefined
): string => {
  return `Value must be in ${constraintsSetString(minValue, maxValue)}`;
};
