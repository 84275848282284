import { Stack, TextField, Typography } from '@mui/material';
import { Box } from '@mui/system';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import useUser from 'hooks/useUser';
import styles from './index.module.scss';

interface Props {
  open: boolean;
  onClose: () => void;
}

const AccountDialog = ({ open, onClose }: Props) => {
  const { user } = useUser();

  return (
    <DraggableDialog
      title="Account Details"
      open={open}
      onClose={onClose}
      dialogProps={{ maxWidth: 'md' }}
      actions={[
        <StyledButton key="ok" styleName="primary" onClick={onClose} solid>
          OK
        </StyledButton>,
      ]}
      className={styles.dialog}
    >
      {user ? (
        <Stack component="form" spacing={2}>
          <Box sx={{ display: 'flex' }}>
            <TextField
              label="User ID"
              value={user.id}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flex: 1 }}
            />
            <TextField
              label="Namespace"
              value={user.namespace}
              InputProps={{
                readOnly: true,
              }}
              sx={{ flex: 4 }}
            />
          </Box>
          <TextField
            fullWidth
            label="Name"
            value={`${user.firstName} ${user.lastName}`}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            fullWidth
            label="Email Address"
            value={user.email}
            InputProps={{
              readOnly: true,
            }}
          />

          <TextField
            fullWidth
            label="Date Joined"
            value={new Date(user.dateJoined as string).toLocaleDateString()}
            InputProps={{
              readOnly: true,
            }}
          />
        </Stack>
      ) : (
        <Typography variant="h5">Loading...</Typography>
      )}
    </DraggableDialog>
  );
};

export default AccountDialog;
