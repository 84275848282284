import { Skeleton } from '@mui/material';
import styles from './Fallback.module.scss';

const MarkdownInputFallback = () => (
  <div className={styles['fallback']}>
    <Skeleton animation="wave" height={100} />
    <Skeleton animation="wave" height={30} />
    <Skeleton animation="wave" height={30} />
    <Skeleton animation="wave" height={30} />
  </div>
);

export default MarkdownInputFallback;
