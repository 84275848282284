import { faFileExport } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from '../../graphql/graphql';

export default {
  name: 'Kinovea Writer',
  description: 'Exports athlete pose data to Kinovea formatted files.',
  descriptionDetail:
    'This node exports athlete pose data to .kva files, which can be opened in Kinovea for further analysis. Two flavours of Kinovea file are exported, one containing the tracks corresponding to athlete joints, and the other containing key joint angles.\nNote that the exported files are unaware of the "start time" provided in the video loader, so they will not be aligned with the input video if start time is not zero. This will be fixed in a future release.',
  icon: faFileExport,
  category: NodeCategory.OUTPUT,
  moduleType: 'KinoveaWriter',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060608752143',
  configTypes: [
    {
      key: 'tracks_blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}-tracks.kva')
        .REQUIRED.HIDDEN,
    },
    {
      key: 'angles_blob_name',
      type: PipelineConfigType.GENERATED_STRING('{{nodeId}}-angles.kva')
        .REQUIRED.HIDDEN,
    },
    {
      key: 'overwrite_existing',
      type: PipelineConfigType.BOOLEAN().CONSTANT_VALUE(true),
    },
    {
      key: 'align_with_ref_vid_start_time',
      type: PipelineConfigType.BOOLEAN('Align with reference video start time')
        .DEFAULT(false)
        .DESCRIPTION(
          'If this is enabled, the exported files will be aligned with the start time of the reference video.'
        ),
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED,
    },
    {
      key: 'reference_video',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'tracks_output_data',
      type: PipelineDataType.ANY.BLOB_DATATYPE(
        Pipeline_BlobDataType.Kva
      ).NO_HANDLE.LABEL('Kinovea Tracks').DOWNLOADABLE,
    },
    {
      key: 'angles_output_data',
      type: PipelineDataType.ANY.BLOB_DATATYPE(
        Pipeline_BlobDataType.Kva
      ).NO_HANDLE.LABEL('Kinovea Angles').DOWNLOADABLE,
    },
  ],
};
