import { GraphContext } from 'contexts/Graph';
import { useContext } from 'react';

const useFlowGraphHistory = () => {
  const { flowGraphHistory } = useContext(GraphContext);

  return flowGraphHistory;
};

export default useFlowGraphHistory;
