import { JobContext } from 'contexts/Job';
import { useContext } from 'react';

const useJobInfo = () => {
  const {
    logs: ignored1,
    startJob: ignored3,
    cancelJob: ignored4,
    ...rest
  } = useContext(JobContext);

  return rest;
};

export default useJobInfo;
