import { faFileVideo } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobType } from '../../graphql/graphql';

const constrainTimeToVideoDuration = (
  timestamp: any,
  videoDuration: any
): any => {
  // Ensure that the given timestamp is not longer than the given video duration
  if (timestamp > videoDuration) {
    return videoDuration;
  }
  return timestamp;
};

export default {
  name: 'Video Loader',
  description: 'Load a video file into the pipeline.',
  descriptionDetail:
    'This node allows for the upload of a video file to the cloud for use in a pipeline.',
  icon: faFileVideo,
  category: NodeCategory.DATA_LOADERS,
  moduleType: 'VideoLoader',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060223216399',
  configTypes: [
    {
      key: 'start_offset_secs',
      type: PipelineConfigType.DECIMAL('Start time (seconds)')
        .MIN(0)
        .DESCRIPTION(
          'Start offset of the video in seconds - must be before end time'
        )
        .CONSTRAIN_BY_BLOB_METAVALUE(
          Pipeline_BlobType.Input,
          'source',
          'mediaInfo.duration',
          constrainTimeToVideoDuration
        ),
    },
    {
      key: 'end_time_secs',
      type: PipelineConfigType.DECIMAL('End time (seconds)')
        .MIN(0)
        .DESCRIPTION(
          'The end time of the video in seconds - must be after start offset'
        )
        .CONSTRAIN_BY_BLOB_METAVALUE(
          Pipeline_BlobType.Input,
          'source',
          'mediaInfo.duration',
          constrainTimeToVideoDuration
        ),
    },
    {
      key: 'flip_horizontally',
      type: PipelineConfigType.BOOLEAN('Flip horizontally')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION('Whether to flip the video horizontally').ADVANCED,
    },
    {
      key: 'flip_vertically',
      type: PipelineConfigType.BOOLEAN('Flip vertically')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION('Whether to flip the video vertically').ADVANCED,
    },
    {
      key: 'rotation',
      type: PipelineConfigType.STRING('Rotation')
        .OPTIONS([null, 'cw', 'ccw'])
        .DEFAULT(null)
        .DESCRIPTION(
          'Rotation to perform on the video - clockwise (cw) or counter-clockwise (ccw).'
        ).ADVANCED,
    },
    {
      key: 'brightness',
      type: PipelineConfigType.INTEGER('Brightness')
        .REQUIRED.DEFAULT(0)
        .MIN(-255)
        .MAX(255)
        .SLIDER(1)
        .DESCRIPTION(
          'The amount of brightness shifting to be applied to each pixel [-255, 255]'
        ).ADVANCED,
    },
    {
      key: 'contrast',
      type: PipelineConfigType.DECIMAL('Contrast')
        .REQUIRED.DEFAULT(1)
        .MIN(0.25)
        .MAX(2)
        .SLIDER(0.05)
        .DESCRIPTION(
          'The amount of contrast to be applied to each pixel. Values below 1 will reduce contrast, values above 1 will increase contrast.'
        ).ADVANCED,
    },
    {
      key: 'pointCorrespondence',
      type: PipelineConfigType.POINT_CORRESPONDENCE(
        'Image/World Correspondence',
        'source'
      ),
    },
    {
      key: 'frame_labels',
      // The frame labels are hidden as they are integrated into the video player
      // and thus don't require a config input type to be displayed.
      type: PipelineConfigType.FRAME_LABELS('Frame Labels').HIDDEN,
    },
  ],
  inputTypes: [
    {
      key: 'source',
      type: PipelineDataType.VIDEO_FILE.UPLOADABLE.REQUIRED.NO_HANDLE,
    },
  ],
  outputTypes: [
    {
      key: 'source',
      type: PipelineDataType.VIDEO_FRAME.REQUIRED,
    },
    {
      key: 'point_correspondence',
      type: PipelineDataType.POINT_CORRESPONDENCE.REQUIRES_CONFIG_KEY_VALUE(
        'pointCorrespondence'
      ),
    },
  ],
};
