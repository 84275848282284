import { faFloppyDisk, faPencil } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  Card,
  CardActionArea,
  CardContent,
  Collapse,
  IconButton,
} from '@mui/material';
import { Pipeline_Announcement } from 'graphql/graphql';
import useRolePermissions from 'hooks/useRolePermissions';
import { useEffect, useState } from 'react';
import AnnouncementChangeItemList from './ChangeItemList';
import AnnouncementDate from './Date';
import Delete from './Delete';
import AnnouncementDetail from './Detail';
import styles from './index.module.scss';
import AnnouncementOverview from './Overview';
import AnnouncementTitle from './Title';
import Visibility from './Visibility';

interface Props {
  announcement: Pipeline_Announcement;
  onSaveAnnouncement: (announcement: Pipeline_Announcement) => void;
  onDeleteAnnouncement: () => void;
}

const MaybeCardActionArea = ({
  toggleExpanded,
  editing,
  children,
}: {
  toggleExpanded?: () => void;
  editing: boolean;
  children: React.ReactNode;
}) => {
  if (editing) {
    return <>{children}</>;
  }

  return <CardActionArea onClick={toggleExpanded}>{children}</CardActionArea>;
};

const AnnouncementItem = ({
  announcement,
  onSaveAnnouncement,
  onDeleteAnnouncement,
}: Props) => {
  const [localAnnouncement, setLocalAnnouncement] = useState(announcement);
  const [expanded, setExpanded] = useState(false);
  const { canAddEditAnnouncements } = useRolePermissions();
  const [editing, setEditing] = useState(false);

  useEffect(() => {
    // Reset local announcement when the announcement prop changes
    setLocalAnnouncement(announcement);
  }, [announcement]);

  const wrapChangeHandler = <F extends (...args: any[]) => void>(fn: F) => {
    if (!canAddEditAnnouncements || !editing) {
      return undefined;
    }
    return fn;
  };

  const onChangeType = (type: Pipeline_Announcement['type']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      type,
    });
  };

  const onChangeVisibility = (visible: Pipeline_Announcement['visible']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      visible,
    });
  };

  const onChangeDate = (date: Pipeline_Announcement['date']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      date,
    });
  };

  const onChangeTitle = (title: Pipeline_Announcement['title']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      title,
    });
  };

  const onChangeOverview = (overview: Pipeline_Announcement['overview']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      overview,
    });
  };

  const onChangeDetail = (detail: Pipeline_Announcement['detail']) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      detail,
    });
  };

  const onChangeChangeItems = (
    changeItems: Pipeline_Announcement['changeItems']
  ) => {
    setLocalAnnouncement({
      ...localAnnouncement,
      changeItems,
    });
  };

  return (
    <Card sx={{ minWidth: 275, marginBottom: 1 }} variant="outlined">
      <MaybeCardActionArea
        toggleExpanded={() => setExpanded(!expanded)}
        editing={editing}
      >
        <CardContent>
          <AnnouncementDate
            date={localAnnouncement.date}
            type={localAnnouncement.type}
            seenByUser={localAnnouncement.seenByUser}
            onChangeDate={wrapChangeHandler(onChangeDate)}
            onChangeType={wrapChangeHandler(onChangeType)}
          />
          {canAddEditAnnouncements && (
            <div className="floatright">
              {editing && <Delete onDelete={onDeleteAnnouncement} />}
              <Visibility
                visible={localAnnouncement.visible}
                onChangeVisibility={wrapChangeHandler(onChangeVisibility)}
              />

              <IconButton
                size="small"
                onClick={(e) => {
                  e.stopPropagation();
                  if (editing) {
                    onSaveAnnouncement(localAnnouncement);
                  }
                  setEditing(!editing);
                }}
              >
                <FontAwesomeIcon icon={editing ? faFloppyDisk : faPencil} />
              </IconButton>
            </div>
          )}

          <div className={styles['title-and-overview']}>
            <AnnouncementTitle
              title={localAnnouncement.title}
              onChangeTitle={wrapChangeHandler(onChangeTitle)}
            />
            <AnnouncementOverview
              overview={localAnnouncement.overview}
              onChangeOverview={wrapChangeHandler(onChangeOverview)}
            />
          </div>
        </CardContent>
        <Collapse in={expanded || editing} timeout="auto" unmountOnExit>
          <CardContent>
            <AnnouncementDetail
              detail={localAnnouncement.detail}
              onChangeDetail={wrapChangeHandler(onChangeDetail)}
            />
            <AnnouncementChangeItemList
              changeItems={localAnnouncement.changeItems}
              onChangeChangeItems={wrapChangeHandler(onChangeChangeItems)}
            />
          </CardContent>
        </Collapse>
      </MaybeCardActionArea>
    </Card>
  );
};

export default AnnouncementItem;
