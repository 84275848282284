import { faImages } from '@fortawesome/free-regular-svg-icons';
import {
  faFilm,
  faForward,
  faVideo,
  IconDefinition,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Table, TableBody, TableCell, TableRow } from '@mui/material';
import { Blob } from 'contexts/Graph';
import { PipelineDataType } from 'graph/types';
import { nullOrUndef } from 'utils/helpers';
import { MediaInfo } from 'utils/mediainfo';

interface Props {
  nodeId: string;
  id: string;
  dataType: PipelineDataType;
  inputBlob: Blob;
}

function renderItem(title: string, value: string, icon: IconDefinition) {
  if (nullOrUndef(value)) return null;

  return (
    <TableRow key={title}>
      <TableCell>
        <FontAwesomeIcon icon={icon} />
      </TableCell>
      <TableCell>{title}</TableCell>
      <TableCell>{value}</TableCell>
    </TableRow>
  );
}

const VideoMetadataRow = ({ inputBlob }: Props) => {
  const mediaInfo = inputBlob?.metadata?.[VideoMetadataRow.KEY] as
    | MediaInfo
    | undefined;
  if (!mediaInfo) return null;

  const { videoWidth, videoHeight, duration, videoFrameCount, videoFrameRate } =
    mediaInfo;

  const items: { title: string; value: string; icon: IconDefinition }[] = [];

  if (videoWidth && videoHeight) {
    items.push({
      title: 'Resolution',
      value: `${videoWidth}x${videoHeight}`,
      icon: faFilm,
    });
  }

  if (duration) {
    items.push({
      title: 'Duration',
      value: `${duration?.toFixed(2)} seconds`,
      icon: faVideo,
    });
  }

  if (videoFrameCount) {
    items.push({
      title: 'Frame Count',
      value: `${videoFrameCount}`,
      icon: faImages,
    });
  }

  if (videoFrameRate) {
    // Round to 2 places if float
    items.push({
      title: 'Frame Rate',
      value: `${
        videoFrameRate % 1 === 0 ? videoFrameRate : videoFrameRate.toFixed(2)
      } fps`,
      icon: faForward,
    });
  }

  return (
    <div>
      <Table size="small">
        <TableBody>
          {items.map(({ title, value, icon }) =>
            renderItem(title, value, icon)
          )}
        </TableBody>
      </Table>
    </div>
  );
};
VideoMetadataRow.KEY = 'mediaInfo';

export default VideoMetadataRow;
