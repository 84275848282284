import { CircularProgress, CircularProgressProps } from '@mui/material';

import styles from './index.module.scss';

interface Props {
  percent?: number | null;
}

const Spinner = ({ percent }: Props) => {
  if (percent === null) percent = undefined;

  let variant: CircularProgressProps['variant'] = 'indeterminate';
  if (typeof percent !== 'undefined') {
    percent *= 100;
    variant = 'determinate';
  }

  return (
    <CircularProgress
      thickness={3}
      className={styles.spinner}
      value={percent}
      variant={variant}
    />
  );
};

export default Spinner;
