import { Card, Typography } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import EmailDevelopersButton from 'components/EmailDevelopersButton';
import React from 'react';
import { downloadErrorReport } from '../../utils/helpers';
import AppLogo from '../AppLogo';
import BackgroundImage from '../BackgroundImage';
import StyledButton from '../StyledButton';
import styles from './index.module.scss';

interface ErrorBoundaryFallbackProps {
  error: Error;
  resetErrorBoundary: () => void;
}

const ErrorBoundaryFallback: React.FC<ErrorBoundaryFallbackProps> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <div className={styles.container}>
      <BackgroundImage />

      <Card className={styles.card}>
        <AppLogo dark />
        <div className={styles.content}>
          <DelayedTooltip title={`Error: ${error.message}`}>
            <Typography variant="h6" className={styles.subtitle}>
              Something went wrong...
            </Typography>
          </DelayedTooltip>
          <Typography className={styles.subtitle}>
            The application encountered an error and wasn&apos;t able to
            recover. Don&apos;t worry though, here are a few options you can
            try:
          </Typography>
          <StyledButton
            size="large"
            styleName="dark"
            onClick={resetErrorBoundary}
            fullWidth
          >
            Reset the application
          </StyledButton>
          <StyledButton
            size="large"
            styleName="dark"
            onClick={() => window.location.reload()}
            fullWidth
          >
            Refresh the page
          </StyledButton>
          <hr />
          <Typography>
            If the other options didn&apos;t work, please download the error
            report and send it to the system administrators.
          </Typography>
          <StyledButton
            size="large"
            styleName="dark"
            onClick={() => downloadErrorReport(error)}
            fullWidth
          >
            Download error report
          </StyledButton>
          <EmailDevelopersButton />
        </div>
      </Card>
    </div>
  );
};

export default ErrorBoundaryFallback;
