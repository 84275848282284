import { IconDefinition } from '@fortawesome/fontawesome-svg-core';
import { faCheckSquare as faCheckSquareOutline } from '@fortawesome/free-regular-svg-icons';
import {
  faBook,
  faBug,
  faBullhorn,
  faCheckSquare,
  faClone,
  faCommentAlt,
  faCopy,
  faFile,
  faFileAlt,
  faFolderOpen,
  faInfoCircle,
  faKeyboard,
  faLink,
  faPlay,
  faPlusSquare,
  faProjectDiagram,
  faRedo,
  faSave,
  faShareAlt,
  faShareSquare,
  faStop,
  faTrash,
  faUndo,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Button } from '@mui/material';
import {
  Menu,
  MenuDivider,
  MenuHeader,
  MenuItem,
  SubMenu,
} from '@szhsin/react-menu';
import AboutDialog from 'components/AboutDialog';
import AppIcon from 'components/AppIcon';
import EditGraphMetaDialog from 'components/EditGraphMetaDialog';
import GraphPicker from 'components/GraphPicker';
import NewGraphDialog from 'components/NewGraphDialog';
import NodeBrowser from 'components/NodeBrowser';
import PublicLinkLoader from 'components/PublicLinkLoader';
import SaveAsDialog from 'components/SaveAsDialog';
import TemplatePicker from 'components/TemplatePicker';
import hotkeys from 'constants/hotkeys';
import {
  constructBugReportUrl,
  NODE_REQUEST_URL,
  PIPELINES_HOME,
} from 'constants/zendesk';
import { duplicateNode } from 'graph';
import autoConnectNodes from 'graph/autoConnectNodes';
import useAnnouncementsDialog from 'hooks/useAnnouncementsDialog';
import useConfirmDialog from 'hooks/useConfirmDialog';
import useFlowGraph from 'hooks/useFlowGraph';
import useFlowGraphHistory from 'hooks/useFlowGraphHistory';
import useGraphValidation from 'hooks/useGraphValidation';
import useHotkeys from 'hooks/useHotkeys';
import useJobInfo from 'hooks/useJobInfo';
import useNotifications from 'hooks/useNotifications';
import useStartStopJob from 'hooks/useStartStopJob';
import { useCallback, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import {
  applyEdgeChanges,
  applyNodeChanges,
  Edge,
  isEdge,
  isNode,
  Node,
  useStoreApi,
} from 'reactflow';
import KeyboardShortcutDialog from '../KeyboardShortcutDialog';
import styles from './index.module.scss';

export const MenuItemContents = ({
  icon,
  title,
  hotkey,
  hint,
}: {
  title: string;
  icon?: IconDefinition;
  hotkey?: string;
  hint?: string | false;
}) => (
  <>
    <span key="icon" className={styles.icon} title={hint || undefined}>
      {icon && <FontAwesomeIcon icon={icon} />}
    </span>
    <span
      key="text"
      className={styles['button-text']}
      title={hint || undefined}
    >
      {title}
    </span>
    <span key="hotkey" className={styles.hotkey} title={hint || undefined}>
      {hotkey}
    </span>
  </>
);

const UPLOADS_IN_PROG_MSG = 'Wait for uploads to finish.';

const MenuBar = () => {
  const { setFlowGraph } = useFlowGraph();
  const {
    undo: undoFlowGraph,
    redo: redoFlowGraph,
    canUndo: canUndoFlowGraph,
    canRedo: canRedoFlowGraph,
    present: flowGraph,
  } = useFlowGraphHistory();
  const { startJob, cancelJob } = useStartStopJob();
  const { canStartJob, canCancelJob } = useGraphValidation();
  const { uploadingCount } = useJobInfo();
  const { openAnnouncementsDialog } = useAnnouncementsDialog();
  const uploadsInProgress = uploadingCount > 0;

  const { triggerDialog } = useConfirmDialog();

  const navigate = useNavigate();

  const notify = useNotifications();

  const flowStore = useStoreApi();
  const { resetSelectedElements, addSelectedEdges, addSelectedNodes } =
    flowStore.getState();

  const selectedNodes = flowGraph.nodes.filter((n) => n.selected);
  const selectedEdges = flowGraph.edges.filter((e) => e.selected);

  const setSelectedElements = useCallback(
    (els: (Node | Edge)[]) => {
      resetSelectedElements();
      if (els) {
        const nodes = els.filter((el) => isNode(el)).map((n) => n.id);
        const edges = els.filter((el) => isEdge(el)).map((n) => n.id);
        addSelectedNodes(nodes);
        addSelectedEdges(edges);
      }
    },
    [resetSelectedElements, addSelectedEdges, addSelectedNodes]
  );

  const [nodeBrowserDialogOpen, setNodeBrowserDialogOpen] = useState(false);
  const [newGraphDialogOpen, setNewGraphDialogOpen] = useState(false);
  const [editGraphMetaOpen, setEditGraphmetaOpen] = useState(false);
  const [flashPrivacy, setFlashPrivacy] = useState(false);
  const [graphPickerOpen, setGraphPickerOpen] = useState(false);
  const [templatePickerOpen, setTemplatePickerOpen] = useState(false);
  const [publicLinkLoaderOpen, setPublicLinkLoaderOpen] = useState(false);
  const [saveAsDialogOpen, setSaveAsDialogOpen] = useState(false);
  const [aboutDialogOpen, setAboutDialogOpen] = useState(false);
  const [keyboardShortcutDialogOpen, setKeyboardShortcutDialogOpen] =
    useState(false);

  const nodesSelected = selectedNodes.length > 0;
  const edgesSelected = selectedEdges.length > 0;
  const somethingSelected = nodesSelected || edgesSelected;

  const deleteSelectedElements = () => {
    if (somethingSelected) {
      setFlowGraph((currFlowGraph) => ({
        edges: edgesSelected
          ? applyEdgeChanges(
              selectedEdges.map((e) => ({ id: e.id, type: 'remove' })),
              currFlowGraph.edges
            )
          : currFlowGraph.edges,
        nodes: nodesSelected
          ? applyNodeChanges(
              selectedNodes.map((n) => ({ id: n.id, type: 'remove' })),
              currFlowGraph.nodes
            )
          : currFlowGraph.nodes,
      }));
    }
  };

  const duplicateSelectedNodes = useCallback(() => {
    if (nodesSelected) {
      setFlowGraph((currFlowGraph) => {
        const updatedFlowGraph = {
          nodes: [...currFlowGraph.nodes],
          edges: [...currFlowGraph.edges],
        };
        selectedNodes.forEach((node) => {
          updatedFlowGraph.nodes.push(duplicateNode(node, updatedFlowGraph));
        });
        resetSelectedElements();
        return updatedFlowGraph;
      });
    }
  }, [nodesSelected, selectedNodes, resetSelectedElements, setFlowGraph]);

  const selectAllElements = useCallback(() => {
    setSelectedElements([...flowGraph.nodes, ...flowGraph.edges]);
  }, [setSelectedElements, flowGraph]);
  const deselectAllElements = useCallback(() => {
    resetSelectedElements();
  }, [resetSelectedElements]);

  useHotkeys(
    hotkeys.OPEN_BROWSE.shortcut,
    () => {
      if (!uploadsInProgress) {
        setGraphPickerOpen(true);
      }
    },
    []
  );
  useHotkeys(
    hotkeys.SAVE_GRAPH.shortcut,
    () =>
      notify.info('Changes are saved automatically, no need to save.', {
        silent: true,
      }),
    []
  );
  useHotkeys(hotkeys.UNDO.shortcut, undoFlowGraph, [
    canUndoFlowGraph,
    undoFlowGraph,
  ]);
  useHotkeys(hotkeys.REDO.shortcut, redoFlowGraph, [
    canRedoFlowGraph,
    redoFlowGraph,
  ]);
  useHotkeys(hotkeys.ADD_NODE.shortcut, () => setNodeBrowserDialogOpen(true), [
    setNodeBrowserDialogOpen,
  ]);
  useHotkeys(hotkeys.SELECT_ALL.shortcut, selectAllElements, [
    selectAllElements,
  ]);
  useHotkeys(hotkeys.DESELECT_ALL.shortcut, deselectAllElements, [
    selectAllElements,
  ]);
  useHotkeys(hotkeys.DUPLICATE_NODE.shortcut, duplicateSelectedNodes, [
    somethingSelected,
    duplicateSelectedNodes,
  ]);
  useHotkeys(hotkeys.RUN_GRAPH.shortcut, startJob, [canStartJob, startJob]);
  useHotkeys(hotkeys.CANCEL_RUN_GRAPH.shortcut, cancelJob, [
    canCancelJob,
    cancelJob,
  ]);

  return (
    <div className={styles.container}>
      <NewGraphDialog
        open={newGraphDialogOpen}
        onClose={() => setNewGraphDialogOpen(false)}
      />
      <NodeBrowser
        open={nodeBrowserDialogOpen}
        onClose={() => setNodeBrowserDialogOpen(false)}
      />
      <EditGraphMetaDialog
        open={editGraphMetaOpen}
        onClose={() => {
          setEditGraphmetaOpen(false);
          setFlashPrivacy(false);
        }}
        flashPrivacy={flashPrivacy}
      />
      <GraphPicker
        open={graphPickerOpen}
        onClose={() => setGraphPickerOpen(false)}
      />
      <TemplatePicker
        open={templatePickerOpen}
        onClose={() => setTemplatePickerOpen(false)}
      />
      <PublicLinkLoader
        open={publicLinkLoaderOpen}
        onClose={() => setPublicLinkLoaderOpen(false)}
      />
      <SaveAsDialog
        open={saveAsDialogOpen}
        onClose={() => setSaveAsDialogOpen(false)}
      />
      <KeyboardShortcutDialog
        open={keyboardShortcutDialogOpen}
        onClose={() => setKeyboardShortcutDialogOpen(false)}
      />
      <AboutDialog
        open={aboutDialogOpen}
        onClose={() => setAboutDialogOpen(false)}
      />

      <div className={styles['button-bar']}>
        <Link
          to={uploadsInProgress ? '#' : '/'}
          className={styles.logo}
          title={uploadsInProgress ? UPLOADS_IN_PROG_MSG : undefined}
        >
          <AppIcon dark small />
        </Link>

        <Menu menuButton={<Button size="large">File</Button>}>
          <MenuItem
            onClick={() => setNewGraphDialogOpen(true)}
            disabled={uploadsInProgress}
          >
            <MenuItemContents
              title="New..."
              icon={faFile}
              hint={uploadsInProgress && UPLOADS_IN_PROG_MSG}
            />
          </MenuItem>

          <MenuItem
            onClick={() => setGraphPickerOpen(true)}
            disabled={uploadsInProgress}
          >
            <MenuItemContents
              title="Open..."
              icon={faFolderOpen}
              hotkey={hotkeys.OPEN_BROWSE.shortcut}
              hint={uploadsInProgress && UPLOADS_IN_PROG_MSG}
            />
          </MenuItem>

          <SubMenu
            label={
              <MenuItemContents
                title="Import from"
                icon={faCopy}
                hint={uploadsInProgress && UPLOADS_IN_PROG_MSG}
              />
            }
            disabled={uploadsInProgress}
          >
            <MenuItem onClick={() => setTemplatePickerOpen(true)}>
              <MenuItemContents title="Template..." icon={faFileAlt} />
            </MenuItem>
            <MenuItem onClick={() => setPublicLinkLoaderOpen(true)}>
              <MenuItemContents title="Public Link..." icon={faLink} />
            </MenuItem>
          </SubMenu>

          <MenuItem
            onClick={() => setSaveAsDialogOpen(true)}
            disabled={uploadsInProgress}
          >
            <MenuItemContents
              title="Save as..."
              icon={faSave}
              hint={uploadsInProgress && UPLOADS_IN_PROG_MSG}
            />
          </MenuItem>

          <MenuDivider />

          <MenuItem onClick={() => navigate('/')} disabled={uploadsInProgress}>
            <MenuItemContents
              title="Close"
              icon={faXmark}
              hint={uploadsInProgress && UPLOADS_IN_PROG_MSG}
            />
          </MenuItem>

          <MenuDivider />

          <SubMenu label={<MenuItemContents title="Share" icon={faShareAlt} />}>
            <MenuItem
              onClick={() => {
                setFlashPrivacy(true);
                setEditGraphmetaOpen(true);
                notify.warning(
                  'Change graph privacy to "public" to enable sharing'
                );
              }}
            >
              <MenuItemContents title="Public Link..." icon={faLink} />
            </MenuItem>

            <MenuDivider />

            <MenuItem disabled>
              <MenuItemContents
                title="Submit as Template..."
                icon={faShareSquare}
              />
            </MenuItem>
          </SubMenu>
        </Menu>

        <Menu
          menuButton={
            <Button size="large" color="primary">
              Edit
            </Button>
          }
        >
          <MenuItem disabled={!canUndoFlowGraph} onClick={undoFlowGraph}>
            <MenuItemContents
              title="Undo"
              icon={faUndo}
              hotkey={hotkeys.UNDO.shortcut}
            />
          </MenuItem>
          <MenuItem disabled={!canRedoFlowGraph} onClick={redoFlowGraph}>
            <MenuItemContents
              title="Redo"
              icon={faRedo}
              hotkey={hotkeys.REDO.shortcut}
            />
          </MenuItem>

          <MenuDivider />

          <MenuItem onClick={() => setEditGraphmetaOpen(true)}>
            <MenuItemContents title="Pipeline Metadata..." icon={faFileAlt} />
          </MenuItem>
        </Menu>

        <Menu
          menuButton={
            <Button size="large" color="primary">
              Nodes
            </Button>
          }
        >
          <MenuItem onClick={() => setNodeBrowserDialogOpen(true)}>
            <MenuItemContents
              title="Add..."
              icon={faPlusSquare}
              hotkey={hotkeys.ADD_NODE.shortcut}
            />
          </MenuItem>

          <MenuItem
            onClick={() => {
              triggerDialog({
                title: 'Confirm Auto-Connect',
                message:
                  'This will automatically connect as many nodes as possible in the current graph, by connecting handles to their nearest compatible handle. This is an inherently ambiguous task, so you must carefully verify the edges to ensure the correct flow of data.',
                confirmButtonText: 'Connect',
                confirmButtonStyle: 'primary',
                onConfirm: () =>
                  setFlowGraph(
                    (currFlowGraph) => autoConnectNodes(currFlowGraph).flowGraph
                  ),
              });
            }}
          >
            <MenuItemContents title="Auto-connect" icon={faProjectDiagram} />
          </MenuItem>

          <MenuDivider />

          <MenuHeader>Selection</MenuHeader>
          <MenuItem onClick={selectAllElements}>
            <MenuItemContents
              title="Select all"
              icon={faCheckSquare}
              hotkey={hotkeys.SELECT_ALL.shortcut}
            />
          </MenuItem>
          <MenuItem onClick={deselectAllElements}>
            <MenuItemContents
              title="Deselect all"
              icon={faCheckSquareOutline}
              hotkey={hotkeys.DESELECT_ALL.shortcut}
            />
          </MenuItem>

          <MenuDivider />

          <MenuHeader>
            {nodesSelected ? 'With selected node(s)' : 'No node selected'}
          </MenuHeader>
          <MenuItem disabled={!nodesSelected} onClick={duplicateSelectedNodes}>
            <MenuItemContents
              title="Duplicate"
              icon={faClone}
              hotkey={hotkeys.DUPLICATE_NODE.shortcut}
            />
          </MenuItem>
          <MenuItem disabled={!nodesSelected} onClick={deleteSelectedElements}>
            <MenuItemContents
              title="Delete"
              icon={faTrash}
              hotkey={hotkeys.DELETE_NODE.shortcut}
            />
          </MenuItem>
        </Menu>

        <Menu
          menuButton={
            <Button size="large" color="primary">
              Run
            </Button>
          }
        >
          <MenuItem onClick={startJob} disabled={!canStartJob}>
            <MenuItemContents
              title="Start"
              icon={faPlay}
              hotkey={hotkeys.RUN_GRAPH.shortcut}
            />
          </MenuItem>
          <MenuItem onClick={cancelJob} disabled={!canCancelJob}>
            <MenuItemContents
              title="Stop"
              icon={faStop}
              hotkey={hotkeys.CANCEL_RUN_GRAPH.shortcut}
            />
          </MenuItem>
        </Menu>

        <Menu
          menuButton={
            <Button size="large" color="primary">
              Help
            </Button>
          }
        >
          <MenuItem onClick={openAnnouncementsDialog}>
            <MenuItemContents title="Announcements" icon={faBullhorn} />
          </MenuItem>

          <MenuDivider />

          <MenuItem
            href={PIPELINES_HOME}
            target="_blank"
            title="Note - you may need to log in to view content"
          >
            <MenuItemContents title="Knowledge Base" icon={faBook} />
          </MenuItem>

          <MenuItem href={constructBugReportUrl()} target="_blank">
            <MenuItemContents title="Report a bug..." icon={faBug} />
          </MenuItem>
          <MenuItem href={NODE_REQUEST_URL} target="_blank">
            <MenuItemContents title="Request a Node..." icon={faCommentAlt} />
          </MenuItem>

          <MenuDivider />

          <MenuItem onClick={() => setKeyboardShortcutDialogOpen(true)}>
            <MenuItemContents title="Keyboard Shortcuts" icon={faKeyboard} />
          </MenuItem>
          <MenuItem onClick={() => setAboutDialogOpen(true)}>
            <MenuItemContents title="About" icon={faInfoCircle} />
          </MenuItem>
        </Menu>
      </div>
    </div>
  );
};

export default MenuBar;
