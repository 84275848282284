import {
  Checkbox,
  FormControl,
  FormHelperText,
  InputLabel,
  ListItemText,
  MenuItem,
  Select,
} from '@mui/material';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<string | string[], string> {
  labelOverride?: string;
  [key: string]: any;
}

const SelectInput = ({
  value,
  setValue,
  configType,
  labelOverride,
  nodeId: _1,
  configKey: _2,
  ...rest
}: Props) => {
  let validationMsg = configType.validate(value);

  if (configType.required) {
    // Check for either an empty array or a missing single value
    if (
      (configType.array && Array.isArray(value) && value.length === 0) ||
      !value
    ) {
      validationMsg = 'Cannot be empty';
    }
  }
  const label = labelOverride ?? configType.label;
  const labelId = `${label}_label`;

  const formatOption = (option: string) => {
    const out = option.replaceAll('_', ' ').replaceAll('-', ' ');
    return out.charAt(0).toUpperCase() + out.slice(1);
  };

  return (
    <FormControl
      error={Boolean(validationMsg)}
      className={styles.formcontrol}
      key={labelId}
    >
      <InputLabel id={labelId}>{label}</InputLabel>

      {configType.array ? (
        <Select
          className="nodrag"
          variant="standard"
          labelId={labelId}
          value={value}
          onChange={(e) => {
            configType.setValue(e.target.value, setValue);
          }}
          multiple
          renderValue={(selected) => (selected as string[]).join(', ')}
          {...rest}
        >
          {Array.isArray(value) &&
            configType.options?.map((option) => (
              <MenuItem key={option ?? 'None'} value={option}>
                <Checkbox checked={value.indexOf(option as string) > -1} />
                <ListItemText
                  primary={formatOption((option as string) ?? 'None')}
                />
              </MenuItem>
            ))}
        </Select>
      ) : (
        <Select
          className="nodrag"
          variant="standard"
          labelId={labelId}
          value={value ?? ''}
          onChange={(e) => {
            configType.setValue(e.target.value, setValue);
          }}
          {...rest}
        >
          {configType.options?.map((option) => (
            <MenuItem key={option ?? 'None'} value={option}>
              {formatOption((option as string) ?? 'None')}
            </MenuItem>
          ))}
        </Select>
      )}

      {validationMsg && <FormHelperText>{validationMsg}</FormHelperText>}
    </FormControl>
  );
};

export default SelectInput;
