import { faWalking } from '@fortawesome/free-solid-svg-icons';
import { NodeCategory } from 'graph/nodeCategories';
import { PipelineConfigType, PipelineDataType } from 'graph/types';
import { Pipeline_BlobDataType } from './../../graphql/graphql';

export default {
  name: 'Stride Rate Estimator',
  description: 'Estimate the stride rate from pose.',
  descriptionDetail:
    'This node estimates the stride rate (Hz) of tracked pose detections. First, steps are detected by tracking their position relative to the pelvis, then the stride rate is calculated as the number of steps per second.',
  icon: faWalking,
  category: NodeCategory.TRACKING,
  moduleType: 'StrideRate',
  zendeskDocsLink: 'https://mlpt.zendesk.com/hc/en-us/articles/7060908891919',
  configTypes: [
    {
      key: 'step_rate_flag',
      type: PipelineConfigType.BOOLEAN('Step rate')
        .REQUIRED.DEFAULT(false)
        .DESCRIPTION(
          'If true, the step rate is returned instead - calculated as stride rate / 2.'
        ),
    },
    {
      key: 'pose_type',
      type: PipelineConfigType.STRING('Pose type')
        .CONSTANT_VALUE('ASPSET')
        .OPTIONS(['MPII', 'COCO', 'ASPSET'])
        .DESCRIPTION('The format of the pose keypoints.'),
    },
    {
      key: 'min_stride_rate_hz',
      type: PipelineConfigType.DECIMAL('Minimum stride rate (Hz)')
        .REQUIRED.DEFAULT(0.5)
        .MIN(0.2)
        .MAX(1)
        .SLIDER(0.1)
        .DESCRIPTION(
          'The minimum allowable stride rate. Must be less than max stride rate. Recommended to leave at default (1Hz).'
        ).ADVANCED,
    },
    {
      key: 'max_stride_rate_hz',
      type: PipelineConfigType.DECIMAL('Maximum stride rate (Hz)')
        .REQUIRED.DEFAULT(2.5)
        .MIN(1)
        .MAX(3)
        .SLIDER(0.1)
        .DESCRIPTION(
          'The maximum allowable stride rate. Must be greater than min stride rate. Recommended to leave at default (6Hz).'
        ).ADVANCED,
    },
  ],
  inputTypes: [
    {
      key: 'pose',
      type: PipelineDataType.POSE_2D.REQUIRED,
    },
  ],
  outputTypes: [
    {
      key: 'rate',
      type: PipelineDataType.SCALAR.LABEL('Rate (Hz)')
        .DESCRIPTION(
          'The stride or step rate (Hz) of the tracked pose detections.'
        )
        .BLOB_DATATYPE(Pipeline_BlobDataType.Csv),
    },
    {
      key: 'rate_per_minute',
      type: PipelineDataType.SCALAR.LABEL('Rate (per minute)')
        .DESCRIPTION(
          'The stride or step rate (per minute) of the tracked pose detections.'
        )
        .BLOB_DATATYPE(Pipeline_BlobDataType.Csv),
    },
  ],
};
