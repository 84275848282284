import { useContext } from 'react';

import { JobContext } from 'contexts/Job';

const useStartStopJob = () => {
  const { startJob, cancelJob } = useContext(JobContext);

  return {
    startJob,
    cancelJob,
  };
};

export default useStartStopJob;
