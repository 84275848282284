import { ClickAwayListener, TextField, Typography } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import { KeyboardEvent, useState } from 'react';

interface Props {
  label: string;
  value: string;
  tooltip?: string;
  setValue: (newVal: string) => void;
  getErrorMsg?: (value: string) => string | undefined;
  textFieldProps?: { [key: string]: any };
  typographyProps?: { [key: string]: any };
}

const EditableText = ({
  label,
  value,
  tooltip,
  setValue,
  getErrorMsg = () => undefined,
  textFieldProps = {},
  typographyProps = {},
}: Props) => {
  const [editing, setEditing] = useState(false);
  const [internalVal, setInternalVal] = useState(value);

  const errorMsg = getErrorMsg(internalVal.trim());

  const updateValue = () => {
    if (!errorMsg) {
      setValue(internalVal.trim());
    }
    setInternalVal(value);
    setEditing(false);
  };

  if (editing) {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === 'Enter') {
        updateValue();
      }
    };

    return (
      <ClickAwayListener onClickAway={updateValue}>
        <TextField
          label={label}
          value={internalVal}
          onChange={(e) => setInternalVal(e.target.value)}
          onKeyDown={onKeyDown}
          error={Boolean(errorMsg)}
          helperText={errorMsg}
          {...textFieldProps}
        />
      </ClickAwayListener>
    );
  }

  return (
    <DelayedTooltip title={tooltip ?? 'Click to edit'}>
      <Typography onClick={() => setEditing(true)} {...typographyProps}>
        {value}
      </Typography>
    </DelayedTooltip>
  );
};

export default EditableText;
