import { TextField } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDebouncedCallback } from 'use-debounce';
import { CommonProps } from '../types';

interface Props extends CommonProps<any, any> {
  labelOverride?: string;
  [key: string]: any;
}

const TextInput = ({
  value,
  setValue,
  configType,
  labelOverride,
  nodeId: _1,
  configKey: _2,
  ...rest
}: Props) => {
  const [inputIsChanging, setInputIsChanging] = useState(false);
  const [tempVal, setTempVal] = useState(value);

  // Set the actual value with a debounce
  const setValueDebounced = useDebouncedCallback(
    (value: string) => {
      configType.setValue(value, setValue);
    },
    500,
    { leading: false, trailing: true }
  );

  useEffect(() => {
    if (tempVal !== value) {
      if (inputIsChanging) {
        setValueDebounced(tempVal as string);
        setInputIsChanging(false);
      } else {
        configType.setValue(value, setTempVal);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [setValueDebounced, tempVal, value]);

  let validationMsg = configType.validate(tempVal);
  if (configType.required && typeof tempVal === 'undefined')
    validationMsg = 'Cannot be empty';

  const label = labelOverride ?? configType.label;
  return (
    <TextField
      variant="standard"
      label={label}
      error={Boolean(validationMsg)}
      helperText={validationMsg}
      value={tempVal ?? ''}
      onChange={(e) => {
        setInputIsChanging(true);
        setTempVal(e.target.value);
      }}
      onBlur={() => {
        configType.setValue(tempVal, setTempVal);
      }}
      className="nodrag"
      {...rest}
    />
  );
};

export default TextInput;
