import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = {} as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  DateTime: any;
  JSON: any;
};

/** The name of the auth group the user belongs to */
export type AuthGroup = {
  __typename?: 'AuthGroup';
  name: Scalars['String'];
};

/** The details of the user */
export type AuthUser = {
  __typename?: 'AuthUser';
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  groups: Array<AuthGroup>;
  isAdminUser?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
};

/** A class of boat. */
export type BoatClass = {
  __typename?: 'BoatClass';
  /** A slug-style ID. */
  id: Scalars['String'];
  /** The name of the boat class. */
  name: Scalars['String'];
};

/** A sports event that encapsulates metadata and a list of videos captured during the event. */
export type CapturedEvent = {
  __typename?: 'CapturedEvent';
  /** The attempt meta-data for athletics */
  attempt?: Maybe<Scalars['String']>;
  /** The class of boat */
  boatClass?: Maybe<BoatClass>;
  competition?: Maybe<Competition>;
  /** This field can contain any data that isn't already associated with the event. */
  data?: Maybe<Scalars['JSON']>;
  /** The discipline of captured-event. */
  discipline?: Maybe<Discipline>;
  distance?: Maybe<Distance>;
  /** The event type of captured-event. */
  eventType?: Maybe<EventType>;
  /** The access type for user to resource */
  hasFullAccess?: Maybe<Scalars['String']>;
  /** A UUID style unique id. */
  id: Scalars['ID'];
  /** A list of participants involved in the event. */
  participants: Array<Participant>;
  race?: Maybe<Race>;
  /** Search rank for captured event */
  rank?: Maybe<Scalars['Float']>;
  /** The system that generated the event. */
  source?: Maybe<SourceSystem>;
  /** The sport the event is associated with. */
  sport: Sport;
  /** The teams with which the captured event is shared */
  teams?: Maybe<Array<Scalars['String']>>;
  /** The timestamp of when the event occurred. */
  timestamp?: Maybe<Scalars['DateTime']>;
  /** The timestamp of when the event was uploaded. */
  uploadedDate?: Maybe<Scalars['DateTime']>;
  /** The venue the event occurred at. */
  venue?: Maybe<Venue>;
  /** A list of videos that were captured at the event. */
  videos: Array<Video>;
};

/** A paginated result from querying CapturedEvents where the query supports pagination. */
export type CapturedEventPage = {
  __typename?: 'CapturedEventPage';
  /** The total number of captured events the query results in. */
  count: Scalars['Int'];
  /** The list of CapturedEvents. */
  data: Array<CapturedEvent>;
};

/** A competition. */
export type Competition = {
  __typename?: 'Competition';
  /** A slug-style ID. */
  id: Scalars['String'];
  /** The name of the competition. */
  name: Scalars['String'];
};

export type CompetitionDto = {
  /** The Id for the competition */
  id: Scalars['String'];
  /** The name for the competition */
  name: Scalars['String'];
};

/** An input type for creating a new event (and uploading videos). */
export type CreateCapturedEventDto = {
  /** The attempt meta-data field for athletics */
  attempt?: InputMaybe<Scalars['String']>;
  /** The boat class if applicable for the captured event */
  boatClass?: InputMaybe<Scalars['String']>;
  /** The competition if applicable for the captured event */
  competition?: InputMaybe<Scalars['String']>;
  /** This field can contain any data that isn't already associated with the event. */
  data?: InputMaybe<Scalars['JSON']>;
  /** The discipline if applicable for captured event */
  discipline?: InputMaybe<Scalars['String']>;
  /** The distance if applicable for the captured event */
  distance?: InputMaybe<Scalars['String']>;
  /** The event type for captured event */
  eventType?: InputMaybe<Scalars['String']>;
  /** A list of UUID style id's that belong to the participants involved in the event. */
  participantIds?: InputMaybe<Array<Scalars['String']>>;
  /** The race if applicable for the captured event */
  race?: InputMaybe<Scalars['String']>;
  /** The UUID style id of the sourceSystem that generated the event. */
  sourceSystem?: InputMaybe<Scalars['String']>;
  /** The id of the sport the event is associated with. */
  sportId: Scalars['String'];
  /** The teams with which the captured event is to be shared */
  teams?: InputMaybe<Array<Scalars['String']>>;
  /** The timestamp of when the event occurred. */
  timestamp?: InputMaybe<Scalars['DateTime']>;
  /** The UUID style id of the venue the event occurred at. */
  venueId?: InputMaybe<Scalars['String']>;
  /** New videos to be uploaded. */
  videos?: InputMaybe<Array<VideoDto>>;
};

export type DeleteCapturedEventDto = {
  /** The filename of the video to be deleted */
  filename: Scalars['String'];
  /** The id of the captured event to be deleted */
  id: Scalars['ID'];
};

export type DeleteCapturedEventResult = {
  __typename?: 'DeleteCapturedEventResult';
  /** The data for the deletion job. This field can contain data of the files/events that were not deleted. */
  data?: Maybe<Scalars['JSON']>;
  /** The status of the deletion job */
  status: Scalars['Boolean'];
};

/** A sport specific discipline. */
export type Discipline = {
  __typename?: 'Discipline';
  /** UUID for the discipline */
  id: Scalars['ID'];
  /** The name of the discipline */
  name: Scalars['String'];
  /** The sport it is associated with */
  sport: Scalars['String'];
};

export type DisciplineDto = {
  /** The name of discipline */
  name: Scalars['String'];
  /** The sport for the discipline */
  sport: Scalars['String'];
};

/** A specific race distance. */
export type Distance = {
  __typename?: 'Distance';
  /** A slug-style ID. */
  id: Scalars['String'];
  /** The distance in metres. */
  metres: Scalars['Int'];
};

/** The event type of captured event */
export type EventType = {
  __typename?: 'EventType';
  /** The id of the event type. */
  id: Scalars['String'];
  /** The type of the event-type. */
  name: Scalars['String'];
};

export enum JobStatus {
  Cancelled = 'CANCELLED',
  Complete = 'COMPLETE',
  Failed = 'FAILED',
  FailedToStart = 'FAILED_TO_START',
  Pending = 'PENDING',
  Queued = 'QUEUED',
  Running = 'RUNNING',
  Starting = 'STARTING'
}

export type Mutation = {
  __typename?: 'Mutation';
  Pipeline_blobUploadFinished: Pipeline_Blob;
  /**
   * Deletes the announcement with the given id.
   * This mutation is only available to internal managers.
   */
  Pipeline_deleteAnnouncement: Scalars['Boolean'];
  /** Deletes the graph with the given id. */
  Pipeline_deleteGraph: Scalars['Boolean'];
  /** Deletes the graph with the given id. */
  Pipeline_deletePointCorrespondenceTemplate: Scalars['Boolean'];
  /** Creates a new API key and returns it to the user. */
  Pipeline_generateApiKey: Pipeline_ApiKey;
  /** Creates a new graph from a template, found by its id. */
  Pipeline_graphFromTemplate: Pipeline_Graph;
  /**
   * Creates a new Announcement if id is included in the Dto, otherwise it will update the Announcement with the id given in Dto.
   * This mutation is only available to internal managers.
   */
  Pipeline_saveAnnouncement: Pipeline_Announcement;
  /**
   * Creates a new Blob if id is included in BlobDto, otherwise it will update the Blob with the id given in BlobDto.
   * Newly created blobs are marked with uploadPending=True and have a valid signed upload url.
   * Users should hit this endpoint again with uploadPending=False once the upload has finalised. This will remove the
   * signed upload url and instead return a signed download url.
   */
  Pipeline_saveBlob: Pipeline_Blob;
  /** Creates a new Graph if id is included in GraphDto, otherwise it will update the Graph with the available id in GraphDto. */
  Pipeline_saveGraph: Pipeline_Graph;
  /** Creates a new Job if id is included in JobDto, otherwise it will update the Job with the available id in JobDto. */
  Pipeline_saveJob: Pipeline_Job;
  /**
   * Creates a new PointCorrespondenceTemplate if id is included in PointCorrespondenceTemplateDto,
   * otherwise it will update the PointCorrespondenceTemplate with the id given in PointCorrespondenceTemplateDto.
   */
  Pipeline_savePointCorrespondenceTemplate: Pipeline_PointCorrespondenceTemplate;
  /** Creates a Preference if id is included in the Dto, otherwise it will update the Preferences with the id given in Dto. */
  Pipeline_saveUserPreferences: Pipeline_UserPreferences;
  /** Deletes a single capturedEvent and associated video included in the DeleteCapturedEventDto */
  deleteCapturedEvent: DeleteCapturedEventResult;
  /** Deletes more than one capturedEvent and associated videos included in array of DeleteCapturedEventDto */
  deleteCapturedEvents: DeleteCapturedEventResult;
  /** Deletes a team */
  deleteTeam?: Maybe<Scalars['Boolean']>;
  /** Finalise the upload process for a video. */
  finaliseUpload: Video;
  /** Initiate the upload process for a list of Video's */
  initiateUpload: CapturedEvent;
  /** Creates a new CapturedEvent if id is included in CapturedEventDto, otherwise it will update the CapturedEvent with the available id in CapturedEventDto. */
  saveCapturedEvent: CapturedEvent;
  /** Creates a new Competition */
  saveCompetition: Competition;
  /** Create a new discipline for sport. If ID is included, update sport discipline */
  saveDiscipline: Discipline;
  /** Creates a new Participant if id is included in ParticipantDto, otherwise it will update the Participant with the available id in ParticipantDto. */
  saveParticipant: Participant;
  /** Creates a new Team if id is included in TeamDto, otherwise it will update the Team with the available id in TeamDto. */
  saveTeam: Team;
  /** Creates a new User if id is included in UserDto, otherwise it will update the User with the available id in UserDto. */
  saveUser: User;
  /** Creates a new Venue if id is included in VenueDto, otherwise it will update the Video with the available id in VenueDto. */
  saveVenue: Venue;
  /** Creates a new Video if id is included in VideoDto, otherwise it will update the Video with the available id in VideoDto. */
  saveVideo: Video;
};


export type MutationPipeline_BlobUploadFinishedArgs = {
  id: Scalars['ID'];
};


export type MutationPipeline_DeleteAnnouncementArgs = {
  id: Scalars['ID'];
};


export type MutationPipeline_DeleteGraphArgs = {
  id: Scalars['ID'];
};


export type MutationPipeline_DeletePointCorrespondenceTemplateArgs = {
  id: Scalars['ID'];
};


export type MutationPipeline_GraphFromTemplateArgs = {
  templateId: Scalars['ID'];
};


export type MutationPipeline_SaveAnnouncementArgs = {
  announcement: Pipeline_AnnouncementDto;
};


export type MutationPipeline_SaveBlobArgs = {
  blob: Pipeline_BlobDto;
};


export type MutationPipeline_SaveGraphArgs = {
  graph: Pipeline_GraphDto;
};


export type MutationPipeline_SaveJobArgs = {
  job: Pipeline_JobDto;
};


export type MutationPipeline_SavePointCorrespondenceTemplateArgs = {
  pointCorrespondenceTemplate: Pipeline_PointCorrespondenceTemplateDto;
};


export type MutationPipeline_SaveUserPreferencesArgs = {
  userPreferences: Pipeline_UserPreferencesDto;
};


export type MutationDeleteCapturedEventArgs = {
  capturedEvent: DeleteCapturedEventDto;
};


export type MutationDeleteCapturedEventsArgs = {
  capturedEvents: Array<DeleteCapturedEventDto>;
};


export type MutationDeleteTeamArgs = {
  id: Scalars['String'];
};


export type MutationFinaliseUploadArgs = {
  videoId: Scalars['ID'];
};


export type MutationInitiateUploadArgs = {
  capturedEvent: CreateCapturedEventDto;
};


export type MutationSaveCapturedEventArgs = {
  capturedEvent: UpdateCapturedEventDto;
};


export type MutationSaveCompetitionArgs = {
  competition: CompetitionDto;
};


export type MutationSaveDisciplineArgs = {
  discipline: DisciplineDto;
};


export type MutationSaveParticipantArgs = {
  participant: ParticipantDto;
};


export type MutationSaveTeamArgs = {
  team: TeamDto;
};


export type MutationSaveUserArgs = {
  user: UserDto;
};


export type MutationSaveVenueArgs = {
  venue: VenueDto;
};


export type MutationSaveVideoArgs = {
  video: VideoDto;
};

/** A person that has been associated to an event. */
export type Participant = {
  __typename?: 'Participant';
  /** Date of birth for the participant if available. */
  dateOfBirth?: Maybe<Scalars['Date']>;
  /** A list of CapturedEvents that a participant is associated with. */
  events: Array<CapturedEvent>;
  /** A UUID style unique id. */
  id: Scalars['ID'];
  /** The name of the participant, usually this will be of the form 'firstName surname'. */
  name: Scalars['String'];
};

/** An input type to create or update a Participant. */
export type ParticipantDto = {
  /** The date of birth of the Participant. */
  dateOfBirth?: InputMaybe<Scalars['Date']>;
  /** A UUID style id. This should only be included when updating a Participant, if omitted a new Participant will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the Participant, usually this will be of the form 'firstName surname'. */
  name: Scalars['String'];
};

/** An announcement */
export type Pipeline_Announcement = {
  __typename?: 'Pipeline_Announcement';
  /** The change items of the announcement, if any. */
  changeItems?: Maybe<Scalars['JSON']>;
  /** The date on which the announcement occurred. */
  date?: Maybe<Scalars['DateTime']>;
  /** The detail body of the announcement. */
  detail?: Maybe<Scalars['String']>;
  /** A UUID style unique id. */
  id?: Maybe<Scalars['ID']>;
  /** An overview of the announcement. */
  overview: Scalars['String'];
  /** True if the user has seen the announcement */
  seenByUser: Scalars['Boolean'];
  /** The title of the announcement. */
  title: Scalars['String'];
  /** The type of the announcement. */
  type: Pipeline_AnnouncementType;
  /** Whether or not the announcement is visible to users. */
  visible: Scalars['Boolean'];
};

/** An input type for creating or updating an Announcement. */
export type Pipeline_AnnouncementDto = {
  /** The change items of the announcement, if any. */
  changeItems?: InputMaybe<Scalars['JSON']>;
  /** The date on which the announcement occurred. */
  date?: InputMaybe<Scalars['DateTime']>;
  /** The detail body of the announcement. */
  detail?: InputMaybe<Scalars['String']>;
  /** A UUID style id. This should only be included when updating a Announcement, if omitted a new Announcement will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** An overview of the announcement. */
  overview: Scalars['String'];
  /** The title of the announcement. */
  title: Scalars['String'];
  /** The type of the announcement. */
  type: Pipeline_AnnouncementType;
  /** Whether or not the announcement is visible to users. */
  visible: Scalars['Boolean'];
};

/** The data type of the stored blob. */
export enum Pipeline_AnnouncementType {
  Announcement = 'ANNOUNCEMENT',
  Maintenance = 'MAINTENANCE',
  Release = 'RELEASE'
}

/** An API key. */
export type Pipeline_ApiKey = {
  __typename?: 'Pipeline_ApiKey';
  /** A UUID style unique id. */
  apiKey: Scalars['ID'];
  /** The date at which the API key was created, as seconds since the epoch. */
  createdAt: Scalars['Int'];
  /** The date at which the API key was created, in a human readable format. */
  createdAtReadable: Scalars['DateTime'];
  /** The date at which the API key expires, as seconds since the epoch. */
  expiresAt: Scalars['Int'];
  /** The date at which the API key expires, in a human readable format. */
  expiresAtReadable: Scalars['DateTime'];
  /** The group prefix of the API key. */
  groupPrefix: Scalars['String'];
  /** The groups that the API key has access to. */
  groups: Array<Scalars['String']>;
};

/** A data blob associated with a Graph and Job(s). */
export type Pipeline_Blob = {
  __typename?: 'Pipeline_Blob';
  /** The data type of the blob. */
  blobDataType: Pipeline_BlobDataType;
  /** The blob name used in the bucket. */
  blobName: Scalars['String'];
  /** The type of the blob. */
  blobType: Pipeline_BlobType;
  /** A url that can be used for downloading the blob. */
  downloadUrl?: Maybe<Pipeline_SignedUrl>;
  /** File format flags for the blob. */
  formatConfigFlags: Array<Pipeline_FormatConfigFlag>;
  /** The Graph to which this Blob relates. */
  graph?: Maybe<Pipeline_Graph>;
  /** A UUID style unique id. */
  id?: Maybe<Scalars['ID']>;
  /** The metadata associated with the blob. Arbitrary, optional JSON. */
  metadata?: Maybe<Scalars['JSON']>;
  /** The namespace in which this blob resides */
  namespace: Pipeline_Namespace;
  /** The ID of the node to which this Blob relates. */
  nodeId: Scalars['String'];
  /** The name of the file that was uploaded. This is not used as the filename in the bucket. */
  originalFilename: Scalars['String'];
  /** The ID of the property (in the related node) to which this Blob relates. */
  propertyId: Scalars['String'];
  /** A flag which is true when a signed upload url is required. */
  uploadPending?: Maybe<Scalars['Boolean']>;
  /** The upload url of the blob if it is still uploading. */
  uploadUrl?: Maybe<Pipeline_SignedUrl>;
};

/** The data type of the stored blob. */
export enum Pipeline_BlobDataType {
  Csv = 'CSV',
  Json = 'JSON',
  Kva = 'KVA',
  PipeCsv = 'PIPE_CSV',
  PipeJson = 'PIPE_JSON',
  Video = 'VIDEO'
}

/** An input type for creating or updating a Blob. */
export type Pipeline_BlobDto = {
  /** The data type of the blob. */
  blobDataType: Pipeline_BlobDataType;
  /** The blob name used in the bucket. */
  blobName: Scalars['String'];
  /** The type of the blob. */
  blobType: Pipeline_BlobType;
  /** Timestamp of when the graph was created. */
  createdDate?: InputMaybe<Scalars['DateTime']>;
  /** File format flags for the blob. */
  formatConfigFlags?: InputMaybe<Array<Pipeline_FormatConfigFlag>>;
  /** The graph to which this blob relates. */
  graph?: InputMaybe<Scalars['ID']>;
  /** A UUID style id. This should only be included when updating a Blob, if omitted a new Blob will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The metadata associated with the blob. Arbitrary, optional JSON. */
  metadata?: InputMaybe<Scalars['JSON']>;
  /** Timestamp of when the graph was last modified. */
  modifiedDate?: InputMaybe<Scalars['DateTime']>;
  /** The ID of the node to which this Blob relates. */
  nodeId: Scalars['String'];
  /** The name of the file that was uploaded. This is not used as the filename in the bucket. */
  originalFilename: Scalars['String'];
  /** The ID of the property (in the related node) to which this Blob relates. */
  propertyId: Scalars['String'];
  /** A flag which is true when a signed upload url is required. */
  uploadPending?: InputMaybe<Scalars['Boolean']>;
};

/** The type of blob: a node input value or a node-produced output. */
export enum Pipeline_BlobType {
  Input = 'INPUT',
  Output = 'OUTPUT'
}

/** Any flags which are required to parse the blob */
export enum Pipeline_FormatConfigFlag {
  HasHeaderRow = 'HAS_HEADER_ROW',
  KeyColumnsByTrackIndex = 'KEY_COLUMNS_BY_TRACK_INDEX',
  SingleData = 'SINGLE_DATA'
}

/** A pipeline graph. */
export type Pipeline_Graph = {
  __typename?: 'Pipeline_Graph';
  /** The blobs which relate to this graph. */
  blobs: Array<Pipeline_Blob>;
  /** Timestamp of when the blob was created. */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** An optional description for the graph. */
  description?: Maybe<Scalars['String']>;
  /** The graph data. This data is used for visual editing in the frontend and is converted to a computation graph on the fly when processing. */
  flowGraph?: Maybe<Scalars['JSON']>;
  /** A UUID style id. This should only be included when updating a Graph, if omitted a new Graph will be created. */
  id?: Maybe<Scalars['ID']>;
  /** True if the requesting user is the owner of the graph. */
  isOwner: Scalars['Boolean'];
  /** Timestamp of when the blob was last modified. */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** The name of the graph. */
  name: Scalars['String'];
  /** The user which owns the graph. */
  owner: Pipeline_User;
  /** A graph's privacy setting, which determines the availability to non-owner users. */
  privacy: PrivacySetting;
  /** If a graph instance was instantiated from a template graph, this field will contain the original template ID. */
  templateGraph?: Maybe<Scalars['ID']>;
};

/** An input type for creating or updating a Graph. */
export type Pipeline_GraphDto = {
  /** The blobs for this graph. */
  blobs?: InputMaybe<Array<Pipeline_BlobDto>>;
  /** An optional description for the graph. */
  description?: InputMaybe<Scalars['String']>;
  /** The graph data. This data is used for visual editing in the frontend and is converted to a computation graph on the fly when processing. */
  flowGraph?: InputMaybe<Scalars['JSON']>;
  /** A UUID style id. This should only be included when updating a Graph, if omitted a new Graph will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the graph. */
  name?: InputMaybe<Scalars['String']>;
  /** A graph's privacy setting, which determines the availability to non-owner users. */
  privacy?: InputMaybe<PrivacySetting>;
};

export type Pipeline_GraphNodeConfigDto = {
  /** The config data of the node. */
  nodeConfig?: InputMaybe<Scalars['JSON']>;
  /** The id of the node. */
  nodeId?: InputMaybe<Scalars['ID']>;
};

/** A pipeline job. */
export type Pipeline_Job = {
  __typename?: 'Pipeline_Job';
  /** The progress of the job as a normalised percentange in [0, 1]. */
  completionPercent?: Maybe<Scalars['Float']>;
  /** Timestamp of when the job was created. */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** The error reason, if any. */
  error?: Maybe<Scalars['JSON']>;
  /** Timestamp of when the job finished processing (successfully or unsuccessfully). */
  finishedDate?: Maybe<Scalars['DateTime']>;
  /** The graph to which this job relates. */
  graph: Pipeline_Graph;
  /** A UUID style id. This should only be included when updating a Job, if omitted a new Job will be created. */
  id?: Maybe<Scalars['ID']>;
  /** Timestamp of when the job was last modified. */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Timestamp of when the job's completionPercent field was last updated - may help to identify stale jobs. */
  percentageReportedDate?: Maybe<Scalars['DateTime']>;
  /** Timestamp of when the job was picked up by a pipeline server for processing. */
  startedDate?: Maybe<Scalars['DateTime']>;
  /** The status of the job. */
  status: JobStatus;
};

/** An input type for creating or updating a Job. */
export type Pipeline_JobDto = {
  /** The graph to which this job relates. */
  graph: Scalars['ID'];
  /** A UUID style id. This should only be included when updating a Job, if omitted a new Job will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** An (optional) set of config values which will override those found in the flowgraph at time of execution. */
  nodeConfigValues?: InputMaybe<Array<Pipeline_GraphNodeConfigDto>>;
  /** The status of the job. */
  status: JobStatus;
};

/** A namespace belonging to a sport/org which separates blobs and jobs. */
export type Pipeline_Namespace = {
  __typename?: 'Pipeline_Namespace';
  /** The associated bucket name. */
  bucketName: Scalars['String'];
  /** Timestamp of when the namespace was created. */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** A unique slug-style ID. */
  id?: Maybe<Scalars['String']>;
  /** Timestamp of when the namespace was last modified. */
  modifiedDate?: Maybe<Scalars['DateTime']>;
};

/** A template for associating points between image and world space. */
export type Pipeline_PointCorrespondenceTemplate = {
  __typename?: 'Pipeline_PointCorrespondenceTemplate';
  /** An optional description for the template. */
  description?: Maybe<Scalars['String']>;
  /**
   * A list of edges between points, stored as a flattened array like [p0, p1, p1, p2, ...].
   * If empty, it's a system template.
   */
  edges?: Maybe<Array<Scalars['Int']>>;
  /** A UUID style unique id. */
  id?: Maybe<Scalars['ID']>;
  /** The name of the template. */
  name: Scalars['String'];
  /** The user which created this template. If null, it's a system template. */
  owner?: Maybe<Pipeline_User>;
  /**
   * A list of points in image space, stored as a flattened array like [x0, y1, x1, y1, ...].
   * One-to-one correspondence with the world space points.
   */
  pixelCoordinates: Array<Scalars['Float']>;
  /** An SVG image to serve as a visualisation of the template. If empty, this is a custom template. */
  svgData?: Maybe<Scalars['String']>;
  /**
   * A list of points in world space, stored as a flattened array like [x0, y1, z0, x1, y1, z1, ...].
   * One-to-one correspondence with the image space points.
   */
  worldCoordinates: Array<Scalars['Float']>;
  /** The world coordinates that should be considered the origin of the world coordinate system */
  worldOrigin: Array<Scalars['Float']>;
};

/**
 * An input type for creating or updating a PointCorrespondenceTemplates. Note that this DTO is only used
 * for the modification of custom templates (where edges and owner are not null). System templates are
 * immutable and can only be created by the system.
 */
export type Pipeline_PointCorrespondenceTemplateDto = {
  /** An optional description for the template. */
  description?: InputMaybe<Scalars['String']>;
  /** A list of edges between points, stored as a flattened array like [p0, p1, p1, p2, ...]. */
  edges: Array<Scalars['Int']>;
  /** A UUID style id. This should only be included when updating a PointCorrespondenceTemplate, if omitted a new PointCorrespondenceTemplate will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the template. */
  name: Scalars['String'];
  /**
   * A list of points in image space, stored as a flattened array like [x0, y1, x1, y1, ...].
   * One-to-one correspondence with the world space points.
   */
  pixelCoordinates: Array<Scalars['Float']>;
  /**
   * A list of points in world space, stored as a flattened array like [x0, y1, z0, x1, y1, z1, ...].
   * One-to-one correspondence with the image space points.
   */
  worldCoordinates: Array<Scalars['Float']>;
  /** The world coordinates that should be considered the origin of the world coordinate system */
  worldOrigin: Array<Scalars['Float']>;
};

export type Pipeline_User = {
  __typename?: 'Pipeline_User';
  /** Timestamp of when the user was created. */
  dateJoined: Scalars['DateTime'];
  /** The user's email address. */
  email?: Maybe<Scalars['String']>;
  /** The user's first name. */
  firstName?: Maybe<Scalars['String']>;
  /** A UUID style id. */
  id?: Maybe<Scalars['ID']>;
  /** If the user is active. */
  isActive: Scalars['Boolean'];
  /** If the user is staff. */
  isStaff: Scalars['Boolean'];
  /** If the user is a superuser. */
  isSuperuser: Scalars['Boolean'];
  /** Timestamp of when the user last logged in. */
  lastLogin?: Maybe<Scalars['DateTime']>;
  /** The user's last name. */
  lastName?: Maybe<Scalars['String']>;
  /** Timestamp of when the user was last modified. */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** The namespace to which the user belongs. */
  namespace: Scalars['String'];
  /** The user's username - actually a UUID string */
  username: Scalars['String'];
};

/** User preferences belongs to user account. */
export type Pipeline_UserPreferences = {
  __typename?: 'Pipeline_UserPreferences';
  /** Timestamp of when the preference was created. */
  createdDate?: Maybe<Scalars['DateTime']>;
  /** A unique slug-style ID. */
  id?: Maybe<Scalars['String']>;
  /** Timestamp of when the preference was last modified. */
  modifiedDate?: Maybe<Scalars['DateTime']>;
  /** Whether to play sounds in the frontend. */
  soundEnabled: Scalars['Boolean'];
};

/** An input type for creating or updating an user preference. */
export type Pipeline_UserPreferencesDto = {
  /** Timestamp of when the preference was created. */
  createdDate?: InputMaybe<Scalars['DateTime']>;
  /** A UUID style id. This should only be included when updating an user preference. */
  id?: InputMaybe<Scalars['ID']>;
  /** Timestamp of when the preference was last modified. */
  modifiedDate?: InputMaybe<Scalars['DateTime']>;
  /** Whether to play sounds in the frontend. */
  soundEnabled: Scalars['Boolean'];
};

export type Pipeline_SignedUrl = {
  __typename?: 'Pipeline_signedUrl';
  /** Timestamp of when the signed url expires. */
  expiryDate?: Maybe<Scalars['DateTime']>;
  /** A signed url string. */
  url?: Maybe<Scalars['String']>;
};

export enum PrivacySetting {
  Private = 'PRIVATE',
  Public = 'PUBLIC',
  Template = 'TEMPLATE'
}

export type Query = {
  __typename?: 'Query';
  /** Get all announcements. */
  Pipeline_allAnnouncements: Array<Pipeline_Announcement>;
  /** Get all blobs. */
  Pipeline_allBlobs: Array<Pipeline_Blob>;
  /** Get all known graphs. */
  Pipeline_allGraphs: Array<Pipeline_Graph>;
  /** Get all known jobs. */
  Pipeline_allJobs: Array<Pipeline_Job>;
  /** Get all namespaces. */
  Pipeline_allNamespaces: Array<Pipeline_Namespace>;
  /** Get all point correspondence templates. */
  Pipeline_allPointCorrespondenceTemplates: Array<Pipeline_PointCorrespondenceTemplate>;
  /** Get a single blob by id. */
  Pipeline_blob: Pipeline_Blob;
  /** Get a single graph by id. */
  Pipeline_graph: Pipeline_Graph;
  /** Get a single job by id. */
  Pipeline_job: Pipeline_Job;
  /** Get a single namespace by name. */
  Pipeline_namespace: Pipeline_Namespace;
  /** Get a single point correspondence template by id. */
  Pipeline_pointCorrespondenceTemplate: Pipeline_PointCorrespondenceTemplate;
  Pipeline_user: Pipeline_User;
  /** Get user preferences. */
  Pipeline_userPreferences: Pipeline_UserPreferences;
  /** Get all known boat classes. */
  allBoatClasses: Array<BoatClass>;
  /** Retrieves all CapturedEvents in paginated form. */
  allCapturedEvents: CapturedEventPage;
  /** Get all known competitions. */
  allCompetitions: Array<Competition>;
  /** Get all disciplines. */
  allDisciplines: Array<Discipline>;
  /** Get all known distances. */
  allDistances: Array<Distance>;
  /** Retrieve all types of event-types */
  allEventTypes: Array<EventType>;
  /** Get all known Participants. */
  allParticipants: Array<Participant>;
  /** Get all known races. */
  allRaces: Array<Race>;
  /** Retrieve all the available SourceSystems */
  allSourceSystems: Array<SourceSystem>;
  /** Get all sport specific disciplines */
  allSportDisciplines: Array<Discipline>;
  /** Retrieve all the available Sports */
  allSports: Array<Sport>;
  /** Get all known Teams. */
  allTeams: Array<Team>;
  /** Retrieve all available Venues. */
  allVenues: Array<Venue>;
  /** Retrieve all available Videos. */
  allVideos: Array<Video>;
  /** Get a single boat class by id. */
  boatClass: BoatClass;
  /** Retrieves a single event by id. */
  capturedEvent: CapturedEvent;
  /** Get a single competition by id. */
  competition: Competition;
  /** Get a single Discipline by ID */
  discipline: Discipline;
  /** Get a single distance by id. */
  distance: Distance;
  /** Get a single Participant by id. */
  participant: Participant;
  /** Get a single race by id. */
  race: Race;
  /** Get a single SourceSystem by id. */
  sourceSystem: SourceSystem;
  /** Get a single Sport by id. */
  sport: Sport;
  /** Get all the teams for the Sport */
  sportTeams: Array<Team>;
  /** Retrieve the sport details and all the users associated with the sport */
  sportUsers: SportUsers;
  /** Get a single Team by id. */
  team: Team;
  user: User;
  userDetails: AuthUser;
  /** Get a single Venue by id. */
  venue: Venue;
  /** Get a single Video by id. */
  video: Video;
  /** Get video url by id. */
  videoUrl: Scalars['String'];
};


export type QueryPipeline_AllGraphsArgs = {
  active?: InputMaybe<Scalars['Boolean']>;
};


export type QueryPipeline_BlobArgs = {
  id: Scalars['ID'];
};


export type QueryPipeline_GraphArgs = {
  id: Scalars['ID'];
};


export type QueryPipeline_JobArgs = {
  graphId?: InputMaybe<Scalars['ID']>;
  id?: InputMaybe<Scalars['ID']>;
};


export type QueryPipeline_NamespaceArgs = {
  id: Scalars['String'];
};


export type QueryPipeline_PointCorrespondenceTemplateArgs = {
  id: Scalars['ID'];
};


export type QueryAllCapturedEventsArgs = {
  dateType?: InputMaybe<Scalars['String']>;
  endDate?: InputMaybe<Scalars['Date']>;
  hasFullAccess?: InputMaybe<Scalars['String']>;
  limit?: InputMaybe<Scalars['Int']>;
  offset?: InputMaybe<Scalars['Int']>;
  participantSearch?: InputMaybe<Scalars['String']>;
  search?: InputMaybe<Scalars['String']>;
  sortType?: InputMaybe<Scalars['String']>;
  sourceSystem?: InputMaybe<Scalars['String']>;
  sportId?: InputMaybe<Scalars['String']>;
  startDate?: InputMaybe<Scalars['Date']>;
  teams?: InputMaybe<Array<Scalars['String']>>;
  venueId?: InputMaybe<Scalars['String']>;
};


export type QueryAllSportDisciplinesArgs = {
  sportID: Scalars['ID'];
};


export type QueryBoatClassArgs = {
  id: Scalars['ID'];
};


export type QueryCapturedEventArgs = {
  id: Scalars['ID'];
};


export type QueryCompetitionArgs = {
  id: Scalars['ID'];
};


export type QueryDisciplineArgs = {
  id: Scalars['ID'];
};


export type QueryDistanceArgs = {
  id: Scalars['ID'];
};


export type QueryParticipantArgs = {
  id: Scalars['ID'];
};


export type QueryRaceArgs = {
  id: Scalars['ID'];
};


export type QuerySourceSystemArgs = {
  id: Scalars['ID'];
};


export type QuerySportArgs = {
  id: Scalars['ID'];
};


export type QuerySportTeamsArgs = {
  sportID: Scalars['ID'];
};


export type QuerySportUsersArgs = {
  id: Scalars['ID'];
};


export type QueryTeamArgs = {
  id: Scalars['ID'];
};


export type QueryVenueArgs = {
  id: Scalars['ID'];
};


export type QueryVideoArgs = {
  id: Scalars['ID'];
};


export type QueryVideoUrlArgs = {
  ID: Scalars['ID'];
};

/** A race. */
export type Race = {
  __typename?: 'Race';
  /** A slug-style ID. */
  id: Scalars['String'];
  /** The name of the race. */
  name: Scalars['String'];
};

/** The system a CapturedEvent was generated by. */
export type SourceSystem = {
  __typename?: 'SourceSystem';
  /** A list of CapturedEvents associated with the system */
  events: Array<CapturedEvent>;
  /** A slug style unique id. */
  id: Scalars['ID'];
  /** The name of the system. */
  name: Scalars['String'];
};

/** An input type for updating a SourceSystem. Creating a source system is not possible via the graphql API. */
export type SourceSystemDto = {
  /** A slug style id for the SourceSystem to update. */
  id: Scalars['ID'];
  /** The name of the SourceSystem. */
  name: Scalars['String'];
};

export type Sport = {
  __typename?: 'Sport';
  id: Scalars['ID'];
  name: Scalars['String'];
  teams: Array<TeamDetails>;
};

export type SportDto = {
  /** A slug style id for the Sport to update. */
  id: Scalars['ID'];
  /** The name of the sport. */
  name: Scalars['String'];
};

export type SportUser = {
  __typename?: 'SportUser';
  /** The ID of the EVDB user */
  id: Scalars['Int'];
  /** The details of the user from Auth */
  userDetails: UserDetails;
};

export type SportUsers = {
  __typename?: 'SportUsers';
  /** The Sport UUID. */
  id: Scalars['ID'];
  /** The name of the team. */
  name: Scalars['String'];
  /** The ids of the users that belong to that sport. */
  users: Array<SportUser>;
};

/** A team that has been associated to a user. */
export type Team = {
  __typename?: 'Team';
  /** A UUID style unique id. */
  id: Scalars['ID'];
  /** The name of the team. */
  name?: Maybe<Scalars['String']>;
  /** The sport of the team */
  sport: Scalars['String'];
  /** The details of the users in the team. */
  users: Array<TeamUsers>;
};

export type TeamDetails = {
  __typename?: 'TeamDetails';
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
};

/** An input type to create or update a Team. */
export type TeamDto = {
  /** A UUID style id. This should only be included when updating a Team, if omitted a new Team will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the Team. */
  name?: InputMaybe<Scalars['String']>;
  /** The sport of the team */
  sport: Scalars['String'];
  /** The users to be added to the team */
  updateUsers?: InputMaybe<Array<Scalars['Int']>>;
};

/** The details of the users within the team. */
export type TeamUserDetails = {
  __typename?: 'TeamUserDetails';
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>;
};

export type TeamUsers = {
  __typename?: 'TeamUsers';
  /** The ID of EVDB user. */
  id: Scalars['Int'];
  /** The details of the EVDB user. */
  userDetails: TeamUserDetails;
};

export enum TranscodeStatus {
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Initialized = 'INITIALIZED'
}

/** An input type for modifying existing events. */
export type UpdateCapturedEventDto = {
  /** The attempt meta-data field for Athletics */
  attempt?: InputMaybe<Scalars['String']>;
  /** The ID of the boat class. */
  boatClassId?: InputMaybe<Scalars['String']>;
  /** The ID of the competition */
  competitionId?: InputMaybe<Scalars['String']>;
  /** This field can contain any data that isn't already associated with the event. */
  data?: InputMaybe<Scalars['JSON']>;
  /** The discipline of captured-event. */
  discipline?: InputMaybe<Scalars['String']>;
  /** The ID of the distance */
  distanceId?: InputMaybe<Scalars['String']>;
  /** The event type of captured-event. */
  eventType?: InputMaybe<Scalars['String']>;
  /** A UUID style unique id. This should only be included when updating an event, for a new event omit ID. */
  id: Scalars['ID'];
  /** A list of UUID style id's that belong to the participants involved in the event. */
  participantIds?: InputMaybe<Array<Scalars['String']>>;
  /** The ID of the race */
  raceId?: InputMaybe<Scalars['String']>;
  /** The UUID style id of the sourceSystem that generated the event. */
  sourceSystem?: InputMaybe<Scalars['String']>;
  /** The id of the sport the event is associated with. */
  sportId: Scalars['String'];
  /** The teams with which the captured event is to be shared */
  teams?: InputMaybe<Array<Scalars['String']>>;
  /** The timestamp of when the event occurred. */
  timestamp?: InputMaybe<Scalars['DateTime']>;
  /** The UUID style id of the venue the event occurred at. */
  venueId?: InputMaybe<Scalars['String']>;
  /** The UUID style ids of the videos that were captured at the event. */
  videoIds?: InputMaybe<Array<Scalars['String']>>;
};

export type User = {
  __typename?: 'User';
  sport?: Maybe<Sport>;
  tcAccepted?: Maybe<Scalars['String']>;
  teams?: Maybe<Array<Team>>;
};

export type UserDetails = {
  __typename?: 'UserDetails';
  /** The email of the user. */
  email?: Maybe<Scalars['String']>;
  /** The first name of the user. */
  firstName?: Maybe<Scalars['String']>;
  /** The last name of the user. */
  lastName?: Maybe<Scalars['String']>;
};

export type UserDto = {
  /** The sport the user is affiliated with. */
  sport?: InputMaybe<Scalars['String']>;
  tcAccepted?: InputMaybe<Scalars['String']>;
  teams?: InputMaybe<Array<Scalars['String']>>;
};

/** A venue that can host a CapturedEvent. */
export type Venue = {
  __typename?: 'Venue';
  /** A list of CapturedEvents associated with the Venue */
  events: Array<CapturedEvent>;
  /** A UUID style unique id. */
  id: Scalars['ID'];
  /** The name of the Venue. */
  name: Scalars['String'];
};

/** An input type to create or update a Venue. */
export type VenueDto = {
  /** A UUID style id. This should only be included when updating a Venue, if omitted a new Venue will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The name of the Venue. */
  name: Scalars['String'];
};

/** A video associated with a CapturedEvent. */
export type Video = {
  __typename?: 'Video';
  /** The associated CapturedEvent. */
  event?: Maybe<CapturedEvent>;
  /** The filename of the Video. */
  filename: Scalars['String'];
  /** A UUID style unique id. */
  id: Scalars['ID'];
  /** The filename of the video that was uploaded. This is not used as the filename in the bucket. */
  originalFilename?: Maybe<Scalars['String']>;
  /** A URL for the video's poster frame. */
  posterUrl?: Maybe<Scalars['String']>;
  /** A URL for the video's thumbnail. */
  thumbnailUrl?: Maybe<Scalars['String']>;
  /** The status of the transcoding job on the the uploaded video. */
  transcodeStatus?: Maybe<TranscodeStatus>;
  /** The upload uri of the video if it is still uploading. */
  uploadUri?: Maybe<Scalars['String']>;
  /** A url that can be used for playing the video. */
  url?: Maybe<Scalars['String']>;
};

/** An input type for creating or updating a Video. */
export type VideoDto = {
  /** The filename of the Video. */
  filename: Scalars['String'];
  /** A UUID style id. This should only be included when updating a Video, if omitted a new Video will be created. */
  id?: InputMaybe<Scalars['ID']>;
  /** The filename of the video that was uploaded. This is not used as the filename in the bucket. */
  originalFilename?: InputMaybe<Scalars['String']>;
};

export type DeleteAnnouncementMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteAnnouncementMutation = { __typename?: 'Mutation', Pipeline_deleteAnnouncement: boolean };

export type DeleteGraphMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeleteGraphMutation = { __typename?: 'Mutation', Pipeline_deleteGraph: boolean };

export type DeletePointCorrespondenceTemplateMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type DeletePointCorrespondenceTemplateMutation = { __typename?: 'Mutation', Pipeline_deletePointCorrespondenceTemplate: boolean };

export type GenerateApiKeyMutationVariables = Exact<{ [key: string]: never; }>;


export type GenerateApiKeyMutation = { __typename?: 'Mutation', Pipeline_generateApiKey: { __typename?: 'Pipeline_ApiKey', apiKey: string, expiresAtReadable: any } };

export type GetAnnouncementsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnnouncementsQuery = { __typename?: 'Query', Pipeline_allAnnouncements: Array<{ __typename?: 'Pipeline_Announcement', id?: string | null, type: Pipeline_AnnouncementType, visible: boolean, title: string, overview: string, detail?: string | null, changeItems?: any | null, date?: any | null, seenByUser: boolean }> };

export type GetGraphFullQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetGraphFullQuery = { __typename?: 'Query', Pipeline_graph: { __typename?: 'Pipeline_Graph', id?: string | null, name: string, description?: string | null, flowGraph?: any | null, templateGraph?: string | null, privacy: PrivacySetting, createdDate?: any | null, modifiedDate?: any | null, owner: { __typename?: 'Pipeline_User', id?: string | null }, blobs: Array<{ __typename?: 'Pipeline_Blob', id?: string | null, blobDataType: Pipeline_BlobDataType, blobType: Pipeline_BlobType, nodeId: string, propertyId: string, blobName: string, originalFilename: string, metadata?: any | null, downloadUrl?: { __typename?: 'Pipeline_signedUrl', url?: string | null, expiryDate?: any | null } | null, uploadUrl?: { __typename?: 'Pipeline_signedUrl', url?: string | null, expiryDate?: any | null } | null, namespace: { __typename?: 'Pipeline_Namespace', id?: string | null, bucketName: string } }> } };

export type GetGraphsSummaryQueryVariables = Exact<{
  active?: InputMaybe<Scalars['Boolean']>;
}>;


export type GetGraphsSummaryQuery = { __typename?: 'Query', Pipeline_allGraphs: Array<{ __typename?: 'Pipeline_Graph', id?: string | null, name: string, description?: string | null, privacy: PrivacySetting, isOwner: boolean, createdDate?: any | null, modifiedDate?: any | null }> };

export type GetJobFullQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  graphId?: InputMaybe<Scalars['ID']>;
}>;


export type GetJobFullQuery = { __typename?: 'Query', Pipeline_job: { __typename?: 'Pipeline_Job', id?: string | null, status: JobStatus, completionPercent?: number | null, percentageReportedDate?: any | null, createdDate?: any | null, modifiedDate?: any | null, startedDate?: any | null, error?: any | null, finishedDate?: any | null } };

export type GetJobStatusQueryVariables = Exact<{
  id?: InputMaybe<Scalars['ID']>;
  graphId?: InputMaybe<Scalars['ID']>;
}>;


export type GetJobStatusQuery = { __typename?: 'Query', Pipeline_job: { __typename?: 'Pipeline_Job', id?: string | null, status: JobStatus, completionPercent?: number | null, createdDate?: any | null, startedDate?: any | null, percentageReportedDate?: any | null, finishedDate?: any | null, error?: any | null } };

export type GetPointCorrespondenceTemplateQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetPointCorrespondenceTemplateQuery = { __typename?: 'Query', Pipeline_pointCorrespondenceTemplate: { __typename?: 'Pipeline_PointCorrespondenceTemplate', id?: string | null, name: string, description?: string | null, pixelCoordinates: Array<number>, worldCoordinates: Array<number>, worldOrigin: Array<number>, edges?: Array<number> | null, svgData?: string | null, owner?: { __typename?: 'Pipeline_User', id?: string | null } | null } };

export type GetPointCorrespondenceTemplatesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPointCorrespondenceTemplatesQuery = { __typename?: 'Query', Pipeline_allPointCorrespondenceTemplates: Array<{ __typename?: 'Pipeline_PointCorrespondenceTemplate', id?: string | null, name: string, description?: string | null, pixelCoordinates: Array<number>, worldCoordinates: Array<number>, worldOrigin: Array<number>, edges?: Array<number> | null, svgData?: string | null, owner?: { __typename?: 'Pipeline_User', id?: string | null } | null }> };

export type GetUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserQuery = { __typename?: 'Query', Pipeline_user: { __typename?: 'Pipeline_User', id?: string | null, dateJoined: any, modifiedDate?: any | null, lastLogin?: any | null, isSuperuser: boolean, username: string, firstName?: string | null, lastName?: string | null, email?: string | null, isStaff: boolean, isActive: boolean, namespace: string } };

export type GetUserPreferencesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetUserPreferencesQuery = { __typename?: 'Query', Pipeline_userPreferences: { __typename?: 'Pipeline_UserPreferences', id?: string | null, soundEnabled: boolean, createdDate?: any | null, modifiedDate?: any | null } };

export type GraphFromTemplateMutationVariables = Exact<{
  templateId: Scalars['ID'];
}>;


export type GraphFromTemplateMutation = { __typename?: 'Mutation', Pipeline_graphFromTemplate: { __typename?: 'Pipeline_Graph', id?: string | null, name: string, description?: string | null, owner: { __typename?: 'Pipeline_User', id?: string | null } } };

export type SaveAnnouncementMutationVariables = Exact<{
  announcement: Pipeline_AnnouncementDto;
}>;


export type SaveAnnouncementMutation = { __typename?: 'Mutation', Pipeline_saveAnnouncement: { __typename?: 'Pipeline_Announcement', id?: string | null } };

export type SaveGraphMutationVariables = Exact<{
  graph: Pipeline_GraphDto;
}>;


export type SaveGraphMutation = { __typename?: 'Mutation', Pipeline_saveGraph: { __typename?: 'Pipeline_Graph', id?: string | null } };

export type SaveJobMutationVariables = Exact<{
  job: Pipeline_JobDto;
}>;


export type SaveJobMutation = { __typename?: 'Mutation', Pipeline_saveJob: { __typename?: 'Pipeline_Job', id?: string | null } };

export type SavePointCorrespondenceTemplateMutationVariables = Exact<{
  pointCorrespondenceTemplate: Pipeline_PointCorrespondenceTemplateDto;
}>;


export type SavePointCorrespondenceTemplateMutation = { __typename?: 'Mutation', Pipeline_savePointCorrespondenceTemplate: { __typename?: 'Pipeline_PointCorrespondenceTemplate', id?: string | null } };

export type SaveUserPreferencesMutationVariables = Exact<{
  userPreferences: Pipeline_UserPreferencesDto;
}>;


export type SaveUserPreferencesMutation = { __typename?: 'Mutation', Pipeline_saveUserPreferences: { __typename?: 'Pipeline_UserPreferences', id?: string | null } };


export const DeleteAnnouncementDocument = gql`
    mutation DeleteAnnouncement($id: ID!) {
  Pipeline_deleteAnnouncement(id: $id)
}
    `;
export type DeleteAnnouncementMutationFn = Apollo.MutationFunction<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>;

/**
 * __useDeleteAnnouncementMutation__
 *
 * To run a mutation, you first call `useDeleteAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteAnnouncementMutation, { data, loading, error }] = useDeleteAnnouncementMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>(DeleteAnnouncementDocument, options);
      }
export type DeleteAnnouncementMutationHookResult = ReturnType<typeof useDeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationResult = Apollo.MutationResult<DeleteAnnouncementMutation>;
export type DeleteAnnouncementMutationOptions = Apollo.BaseMutationOptions<DeleteAnnouncementMutation, DeleteAnnouncementMutationVariables>;
export const DeleteGraphDocument = gql`
    mutation DeleteGraph($id: ID!) {
  Pipeline_deleteGraph(id: $id)
}
    `;
export type DeleteGraphMutationFn = Apollo.MutationFunction<DeleteGraphMutation, DeleteGraphMutationVariables>;

/**
 * __useDeleteGraphMutation__
 *
 * To run a mutation, you first call `useDeleteGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeleteGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deleteGraphMutation, { data, loading, error }] = useDeleteGraphMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeleteGraphMutation(baseOptions?: Apollo.MutationHookOptions<DeleteGraphMutation, DeleteGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeleteGraphMutation, DeleteGraphMutationVariables>(DeleteGraphDocument, options);
      }
export type DeleteGraphMutationHookResult = ReturnType<typeof useDeleteGraphMutation>;
export type DeleteGraphMutationResult = Apollo.MutationResult<DeleteGraphMutation>;
export type DeleteGraphMutationOptions = Apollo.BaseMutationOptions<DeleteGraphMutation, DeleteGraphMutationVariables>;
export const DeletePointCorrespondenceTemplateDocument = gql`
    mutation DeletePointCorrespondenceTemplate($id: ID!) {
  Pipeline_deletePointCorrespondenceTemplate(id: $id)
}
    `;
export type DeletePointCorrespondenceTemplateMutationFn = Apollo.MutationFunction<DeletePointCorrespondenceTemplateMutation, DeletePointCorrespondenceTemplateMutationVariables>;

/**
 * __useDeletePointCorrespondenceTemplateMutation__
 *
 * To run a mutation, you first call `useDeletePointCorrespondenceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useDeletePointCorrespondenceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [deletePointCorrespondenceTemplateMutation, { data, loading, error }] = useDeletePointCorrespondenceTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useDeletePointCorrespondenceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<DeletePointCorrespondenceTemplateMutation, DeletePointCorrespondenceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<DeletePointCorrespondenceTemplateMutation, DeletePointCorrespondenceTemplateMutationVariables>(DeletePointCorrespondenceTemplateDocument, options);
      }
export type DeletePointCorrespondenceTemplateMutationHookResult = ReturnType<typeof useDeletePointCorrespondenceTemplateMutation>;
export type DeletePointCorrespondenceTemplateMutationResult = Apollo.MutationResult<DeletePointCorrespondenceTemplateMutation>;
export type DeletePointCorrespondenceTemplateMutationOptions = Apollo.BaseMutationOptions<DeletePointCorrespondenceTemplateMutation, DeletePointCorrespondenceTemplateMutationVariables>;
export const GenerateApiKeyDocument = gql`
    mutation GenerateApiKey {
  Pipeline_generateApiKey {
    apiKey
    expiresAtReadable
  }
}
    `;
export type GenerateApiKeyMutationFn = Apollo.MutationFunction<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;

/**
 * __useGenerateApiKeyMutation__
 *
 * To run a mutation, you first call `useGenerateApiKeyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGenerateApiKeyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [generateApiKeyMutation, { data, loading, error }] = useGenerateApiKeyMutation({
 *   variables: {
 *   },
 * });
 */
export function useGenerateApiKeyMutation(baseOptions?: Apollo.MutationHookOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>(GenerateApiKeyDocument, options);
      }
export type GenerateApiKeyMutationHookResult = ReturnType<typeof useGenerateApiKeyMutation>;
export type GenerateApiKeyMutationResult = Apollo.MutationResult<GenerateApiKeyMutation>;
export type GenerateApiKeyMutationOptions = Apollo.BaseMutationOptions<GenerateApiKeyMutation, GenerateApiKeyMutationVariables>;
export const GetAnnouncementsDocument = gql`
    query GetAnnouncements {
  Pipeline_allAnnouncements {
    id
    type
    visible
    title
    overview
    detail
    changeItems
    date
    seenByUser
  }
}
    `;

/**
 * __useGetAnnouncementsQuery__
 *
 * To run a query within a React component, call `useGetAnnouncementsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnnouncementsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnnouncementsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnnouncementsQuery(baseOptions?: Apollo.QueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
      }
export function useGetAnnouncementsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>(GetAnnouncementsDocument, options);
        }
export type GetAnnouncementsQueryHookResult = ReturnType<typeof useGetAnnouncementsQuery>;
export type GetAnnouncementsLazyQueryHookResult = ReturnType<typeof useGetAnnouncementsLazyQuery>;
export type GetAnnouncementsQueryResult = Apollo.QueryResult<GetAnnouncementsQuery, GetAnnouncementsQueryVariables>;
export const GetGraphFullDocument = gql`
    query GetGraphFull($id: ID!) {
  Pipeline_graph(id: $id) {
    id
    owner {
      id
    }
    name
    description
    flowGraph
    templateGraph
    blobs {
      id
      blobDataType
      blobType
      nodeId
      propertyId
      blobName
      originalFilename
      metadata
      downloadUrl {
        url
        expiryDate
      }
      uploadUrl {
        url
        expiryDate
      }
      namespace {
        id
        bucketName
      }
    }
    privacy
    createdDate
    modifiedDate
  }
}
    `;

/**
 * __useGetGraphFullQuery__
 *
 * To run a query within a React component, call `useGetGraphFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetGraphFullQuery(baseOptions: Apollo.QueryHookOptions<GetGraphFullQuery, GetGraphFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphFullQuery, GetGraphFullQueryVariables>(GetGraphFullDocument, options);
      }
export function useGetGraphFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphFullQuery, GetGraphFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphFullQuery, GetGraphFullQueryVariables>(GetGraphFullDocument, options);
        }
export type GetGraphFullQueryHookResult = ReturnType<typeof useGetGraphFullQuery>;
export type GetGraphFullLazyQueryHookResult = ReturnType<typeof useGetGraphFullLazyQuery>;
export type GetGraphFullQueryResult = Apollo.QueryResult<GetGraphFullQuery, GetGraphFullQueryVariables>;
export const GetGraphsSummaryDocument = gql`
    query GetGraphsSummary($active: Boolean) {
  Pipeline_allGraphs(active: $active) {
    id
    name
    description
    privacy
    isOwner
    createdDate
    modifiedDate
  }
}
    `;

/**
 * __useGetGraphsSummaryQuery__
 *
 * To run a query within a React component, call `useGetGraphsSummaryQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetGraphsSummaryQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetGraphsSummaryQuery({
 *   variables: {
 *      active: // value for 'active'
 *   },
 * });
 */
export function useGetGraphsSummaryQuery(baseOptions?: Apollo.QueryHookOptions<GetGraphsSummaryQuery, GetGraphsSummaryQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetGraphsSummaryQuery, GetGraphsSummaryQueryVariables>(GetGraphsSummaryDocument, options);
      }
export function useGetGraphsSummaryLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetGraphsSummaryQuery, GetGraphsSummaryQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetGraphsSummaryQuery, GetGraphsSummaryQueryVariables>(GetGraphsSummaryDocument, options);
        }
export type GetGraphsSummaryQueryHookResult = ReturnType<typeof useGetGraphsSummaryQuery>;
export type GetGraphsSummaryLazyQueryHookResult = ReturnType<typeof useGetGraphsSummaryLazyQuery>;
export type GetGraphsSummaryQueryResult = Apollo.QueryResult<GetGraphsSummaryQuery, GetGraphsSummaryQueryVariables>;
export const GetJobFullDocument = gql`
    query GetJobFull($id: ID, $graphId: ID) {
  Pipeline_job(id: $id, graphId: $graphId) {
    id
    status
    completionPercent
    percentageReportedDate
    createdDate
    modifiedDate
    startedDate
    error
    finishedDate
  }
}
    `;

/**
 * __useGetJobFullQuery__
 *
 * To run a query within a React component, call `useGetJobFullQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobFullQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobFullQuery({
 *   variables: {
 *      id: // value for 'id'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useGetJobFullQuery(baseOptions?: Apollo.QueryHookOptions<GetJobFullQuery, GetJobFullQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobFullQuery, GetJobFullQueryVariables>(GetJobFullDocument, options);
      }
export function useGetJobFullLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobFullQuery, GetJobFullQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobFullQuery, GetJobFullQueryVariables>(GetJobFullDocument, options);
        }
export type GetJobFullQueryHookResult = ReturnType<typeof useGetJobFullQuery>;
export type GetJobFullLazyQueryHookResult = ReturnType<typeof useGetJobFullLazyQuery>;
export type GetJobFullQueryResult = Apollo.QueryResult<GetJobFullQuery, GetJobFullQueryVariables>;
export const GetJobStatusDocument = gql`
    query GetJobStatus($id: ID, $graphId: ID) {
  Pipeline_job(id: $id, graphId: $graphId) {
    id
    status
    completionPercent
    createdDate
    startedDate
    percentageReportedDate
    finishedDate
    error
  }
}
    `;

/**
 * __useGetJobStatusQuery__
 *
 * To run a query within a React component, call `useGetJobStatusQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetJobStatusQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetJobStatusQuery({
 *   variables: {
 *      id: // value for 'id'
 *      graphId: // value for 'graphId'
 *   },
 * });
 */
export function useGetJobStatusQuery(baseOptions?: Apollo.QueryHookOptions<GetJobStatusQuery, GetJobStatusQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(GetJobStatusDocument, options);
      }
export function useGetJobStatusLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetJobStatusQuery, GetJobStatusQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetJobStatusQuery, GetJobStatusQueryVariables>(GetJobStatusDocument, options);
        }
export type GetJobStatusQueryHookResult = ReturnType<typeof useGetJobStatusQuery>;
export type GetJobStatusLazyQueryHookResult = ReturnType<typeof useGetJobStatusLazyQuery>;
export type GetJobStatusQueryResult = Apollo.QueryResult<GetJobStatusQuery, GetJobStatusQueryVariables>;
export const GetPointCorrespondenceTemplateDocument = gql`
    query GetPointCorrespondenceTemplate($id: ID!) {
  Pipeline_pointCorrespondenceTemplate(id: $id) {
    id
    name
    description
    pixelCoordinates
    worldCoordinates
    worldOrigin
    edges
    svgData
    owner {
      id
    }
  }
}
    `;

/**
 * __useGetPointCorrespondenceTemplateQuery__
 *
 * To run a query within a React component, call `useGetPointCorrespondenceTemplateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointCorrespondenceTemplateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointCorrespondenceTemplateQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetPointCorrespondenceTemplateQuery(baseOptions: Apollo.QueryHookOptions<GetPointCorrespondenceTemplateQuery, GetPointCorrespondenceTemplateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointCorrespondenceTemplateQuery, GetPointCorrespondenceTemplateQueryVariables>(GetPointCorrespondenceTemplateDocument, options);
      }
export function useGetPointCorrespondenceTemplateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointCorrespondenceTemplateQuery, GetPointCorrespondenceTemplateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointCorrespondenceTemplateQuery, GetPointCorrespondenceTemplateQueryVariables>(GetPointCorrespondenceTemplateDocument, options);
        }
export type GetPointCorrespondenceTemplateQueryHookResult = ReturnType<typeof useGetPointCorrespondenceTemplateQuery>;
export type GetPointCorrespondenceTemplateLazyQueryHookResult = ReturnType<typeof useGetPointCorrespondenceTemplateLazyQuery>;
export type GetPointCorrespondenceTemplateQueryResult = Apollo.QueryResult<GetPointCorrespondenceTemplateQuery, GetPointCorrespondenceTemplateQueryVariables>;
export const GetPointCorrespondenceTemplatesDocument = gql`
    query GetPointCorrespondenceTemplates {
  Pipeline_allPointCorrespondenceTemplates {
    id
    name
    description
    pixelCoordinates
    worldCoordinates
    worldOrigin
    edges
    svgData
    owner {
      id
    }
  }
}
    `;

/**
 * __useGetPointCorrespondenceTemplatesQuery__
 *
 * To run a query within a React component, call `useGetPointCorrespondenceTemplatesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPointCorrespondenceTemplatesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPointCorrespondenceTemplatesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPointCorrespondenceTemplatesQuery(baseOptions?: Apollo.QueryHookOptions<GetPointCorrespondenceTemplatesQuery, GetPointCorrespondenceTemplatesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPointCorrespondenceTemplatesQuery, GetPointCorrespondenceTemplatesQueryVariables>(GetPointCorrespondenceTemplatesDocument, options);
      }
export function useGetPointCorrespondenceTemplatesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPointCorrespondenceTemplatesQuery, GetPointCorrespondenceTemplatesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPointCorrespondenceTemplatesQuery, GetPointCorrespondenceTemplatesQueryVariables>(GetPointCorrespondenceTemplatesDocument, options);
        }
export type GetPointCorrespondenceTemplatesQueryHookResult = ReturnType<typeof useGetPointCorrespondenceTemplatesQuery>;
export type GetPointCorrespondenceTemplatesLazyQueryHookResult = ReturnType<typeof useGetPointCorrespondenceTemplatesLazyQuery>;
export type GetPointCorrespondenceTemplatesQueryResult = Apollo.QueryResult<GetPointCorrespondenceTemplatesQuery, GetPointCorrespondenceTemplatesQueryVariables>;
export const GetUserDocument = gql`
    query GetUser {
  Pipeline_user {
    id
    dateJoined
    modifiedDate
    lastLogin
    isSuperuser
    username
    firstName
    lastName
    email
    isStaff
    isActive
    namespace
  }
}
    `;

/**
 * __useGetUserQuery__
 *
 * To run a query within a React component, call `useGetUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserQuery(baseOptions?: Apollo.QueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
      }
export function useGetUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserQuery, GetUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserQuery, GetUserQueryVariables>(GetUserDocument, options);
        }
export type GetUserQueryHookResult = ReturnType<typeof useGetUserQuery>;
export type GetUserLazyQueryHookResult = ReturnType<typeof useGetUserLazyQuery>;
export type GetUserQueryResult = Apollo.QueryResult<GetUserQuery, GetUserQueryVariables>;
export const GetUserPreferencesDocument = gql`
    query GetUserPreferences {
  Pipeline_userPreferences {
    id
    soundEnabled
    createdDate
    modifiedDate
  }
}
    `;

/**
 * __useGetUserPreferencesQuery__
 *
 * To run a query within a React component, call `useGetUserPreferencesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetUserPreferencesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetUserPreferencesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetUserPreferencesQuery(baseOptions?: Apollo.QueryHookOptions<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>(GetUserPreferencesDocument, options);
      }
export function useGetUserPreferencesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>(GetUserPreferencesDocument, options);
        }
export type GetUserPreferencesQueryHookResult = ReturnType<typeof useGetUserPreferencesQuery>;
export type GetUserPreferencesLazyQueryHookResult = ReturnType<typeof useGetUserPreferencesLazyQuery>;
export type GetUserPreferencesQueryResult = Apollo.QueryResult<GetUserPreferencesQuery, GetUserPreferencesQueryVariables>;
export const GraphFromTemplateDocument = gql`
    mutation GraphFromTemplate($templateId: ID!) {
  Pipeline_graphFromTemplate(templateId: $templateId) {
    id
    name
    description
    owner {
      id
    }
  }
}
    `;
export type GraphFromTemplateMutationFn = Apollo.MutationFunction<GraphFromTemplateMutation, GraphFromTemplateMutationVariables>;

/**
 * __useGraphFromTemplateMutation__
 *
 * To run a mutation, you first call `useGraphFromTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useGraphFromTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [graphFromTemplateMutation, { data, loading, error }] = useGraphFromTemplateMutation({
 *   variables: {
 *      templateId: // value for 'templateId'
 *   },
 * });
 */
export function useGraphFromTemplateMutation(baseOptions?: Apollo.MutationHookOptions<GraphFromTemplateMutation, GraphFromTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<GraphFromTemplateMutation, GraphFromTemplateMutationVariables>(GraphFromTemplateDocument, options);
      }
export type GraphFromTemplateMutationHookResult = ReturnType<typeof useGraphFromTemplateMutation>;
export type GraphFromTemplateMutationResult = Apollo.MutationResult<GraphFromTemplateMutation>;
export type GraphFromTemplateMutationOptions = Apollo.BaseMutationOptions<GraphFromTemplateMutation, GraphFromTemplateMutationVariables>;
export const SaveAnnouncementDocument = gql`
    mutation SaveAnnouncement($announcement: Pipeline_AnnouncementDto!) {
  Pipeline_saveAnnouncement(announcement: $announcement) {
    id
  }
}
    `;
export type SaveAnnouncementMutationFn = Apollo.MutationFunction<SaveAnnouncementMutation, SaveAnnouncementMutationVariables>;

/**
 * __useSaveAnnouncementMutation__
 *
 * To run a mutation, you first call `useSaveAnnouncementMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveAnnouncementMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveAnnouncementMutation, { data, loading, error }] = useSaveAnnouncementMutation({
 *   variables: {
 *      announcement: // value for 'announcement'
 *   },
 * });
 */
export function useSaveAnnouncementMutation(baseOptions?: Apollo.MutationHookOptions<SaveAnnouncementMutation, SaveAnnouncementMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveAnnouncementMutation, SaveAnnouncementMutationVariables>(SaveAnnouncementDocument, options);
      }
export type SaveAnnouncementMutationHookResult = ReturnType<typeof useSaveAnnouncementMutation>;
export type SaveAnnouncementMutationResult = Apollo.MutationResult<SaveAnnouncementMutation>;
export type SaveAnnouncementMutationOptions = Apollo.BaseMutationOptions<SaveAnnouncementMutation, SaveAnnouncementMutationVariables>;
export const SaveGraphDocument = gql`
    mutation SaveGraph($graph: Pipeline_GraphDto!) {
  Pipeline_saveGraph(graph: $graph) {
    id
  }
}
    `;
export type SaveGraphMutationFn = Apollo.MutationFunction<SaveGraphMutation, SaveGraphMutationVariables>;

/**
 * __useSaveGraphMutation__
 *
 * To run a mutation, you first call `useSaveGraphMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveGraphMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveGraphMutation, { data, loading, error }] = useSaveGraphMutation({
 *   variables: {
 *      graph: // value for 'graph'
 *   },
 * });
 */
export function useSaveGraphMutation(baseOptions?: Apollo.MutationHookOptions<SaveGraphMutation, SaveGraphMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveGraphMutation, SaveGraphMutationVariables>(SaveGraphDocument, options);
      }
export type SaveGraphMutationHookResult = ReturnType<typeof useSaveGraphMutation>;
export type SaveGraphMutationResult = Apollo.MutationResult<SaveGraphMutation>;
export type SaveGraphMutationOptions = Apollo.BaseMutationOptions<SaveGraphMutation, SaveGraphMutationVariables>;
export const SaveJobDocument = gql`
    mutation SaveJob($job: Pipeline_JobDto!) {
  Pipeline_saveJob(job: $job) {
    id
  }
}
    `;
export type SaveJobMutationFn = Apollo.MutationFunction<SaveJobMutation, SaveJobMutationVariables>;

/**
 * __useSaveJobMutation__
 *
 * To run a mutation, you first call `useSaveJobMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveJobMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveJobMutation, { data, loading, error }] = useSaveJobMutation({
 *   variables: {
 *      job: // value for 'job'
 *   },
 * });
 */
export function useSaveJobMutation(baseOptions?: Apollo.MutationHookOptions<SaveJobMutation, SaveJobMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveJobMutation, SaveJobMutationVariables>(SaveJobDocument, options);
      }
export type SaveJobMutationHookResult = ReturnType<typeof useSaveJobMutation>;
export type SaveJobMutationResult = Apollo.MutationResult<SaveJobMutation>;
export type SaveJobMutationOptions = Apollo.BaseMutationOptions<SaveJobMutation, SaveJobMutationVariables>;
export const SavePointCorrespondenceTemplateDocument = gql`
    mutation SavePointCorrespondenceTemplate($pointCorrespondenceTemplate: Pipeline_PointCorrespondenceTemplateDto!) {
  Pipeline_savePointCorrespondenceTemplate(
    pointCorrespondenceTemplate: $pointCorrespondenceTemplate
  ) {
    id
  }
}
    `;
export type SavePointCorrespondenceTemplateMutationFn = Apollo.MutationFunction<SavePointCorrespondenceTemplateMutation, SavePointCorrespondenceTemplateMutationVariables>;

/**
 * __useSavePointCorrespondenceTemplateMutation__
 *
 * To run a mutation, you first call `useSavePointCorrespondenceTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSavePointCorrespondenceTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [savePointCorrespondenceTemplateMutation, { data, loading, error }] = useSavePointCorrespondenceTemplateMutation({
 *   variables: {
 *      pointCorrespondenceTemplate: // value for 'pointCorrespondenceTemplate'
 *   },
 * });
 */
export function useSavePointCorrespondenceTemplateMutation(baseOptions?: Apollo.MutationHookOptions<SavePointCorrespondenceTemplateMutation, SavePointCorrespondenceTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SavePointCorrespondenceTemplateMutation, SavePointCorrespondenceTemplateMutationVariables>(SavePointCorrespondenceTemplateDocument, options);
      }
export type SavePointCorrespondenceTemplateMutationHookResult = ReturnType<typeof useSavePointCorrespondenceTemplateMutation>;
export type SavePointCorrespondenceTemplateMutationResult = Apollo.MutationResult<SavePointCorrespondenceTemplateMutation>;
export type SavePointCorrespondenceTemplateMutationOptions = Apollo.BaseMutationOptions<SavePointCorrespondenceTemplateMutation, SavePointCorrespondenceTemplateMutationVariables>;
export const SaveUserPreferencesDocument = gql`
    mutation SaveUserPreferences($userPreferences: Pipeline_UserPreferencesDto!) {
  Pipeline_saveUserPreferences(userPreferences: $userPreferences) {
    id
  }
}
    `;
export type SaveUserPreferencesMutationFn = Apollo.MutationFunction<SaveUserPreferencesMutation, SaveUserPreferencesMutationVariables>;

/**
 * __useSaveUserPreferencesMutation__
 *
 * To run a mutation, you first call `useSaveUserPreferencesMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveUserPreferencesMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveUserPreferencesMutation, { data, loading, error }] = useSaveUserPreferencesMutation({
 *   variables: {
 *      userPreferences: // value for 'userPreferences'
 *   },
 * });
 */
export function useSaveUserPreferencesMutation(baseOptions?: Apollo.MutationHookOptions<SaveUserPreferencesMutation, SaveUserPreferencesMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveUserPreferencesMutation, SaveUserPreferencesMutationVariables>(SaveUserPreferencesDocument, options);
      }
export type SaveUserPreferencesMutationHookResult = ReturnType<typeof useSaveUserPreferencesMutation>;
export type SaveUserPreferencesMutationResult = Apollo.MutationResult<SaveUserPreferencesMutation>;
export type SaveUserPreferencesMutationOptions = Apollo.BaseMutationOptions<SaveUserPreferencesMutation, SaveUserPreferencesMutationVariables>;