import { PipelineConfigType } from 'graph/types';
import TextInput from '../TextInput';
import { CommonProps } from '../types';

interface Props extends CommonProps<number, number> {
  labelOverride?: string;
}

const NumericInput = ({
  nodeId,
  value,
  setValue,
  configKey,
  configType,
  labelOverride,
}: Props) => {
  let step = 1;
  if (configType.key === PipelineConfigType.NORM_DECIMAL().key) {
    step = 0.1;
  }
  if (configType.numericStep) {
    step = configType.numericStep;
  }

  return (
    <TextInput
      nodeId={nodeId}
      value={value}
      setValue={setValue}
      configKey={configKey}
      configType={configType}
      labelOverride={labelOverride}
      type="number"
      inputProps={{
        step,
        min: configType.minValue,
        max: configType.maxValue,
      }}
    />
  );
};

export default NumericInput;
