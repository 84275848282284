import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IconButton } from '@mui/material';
import { Pipeline_Announcement } from 'graphql/graphql';

type Props = Pick<Pipeline_Announcement, 'visible'> & {
  onChangeVisibility:
    | ((visible: Pipeline_Announcement['visible']) => void)
    | undefined;
};

const Visibility = ({ visible, onChangeVisibility }: Props) => {
  return (
    <IconButton
      size="small"
      onClick={() => onChangeVisibility?.(!visible)}
      title={visible ? 'Hide' : 'Show'}
      // Drop any mouse events if not editable
      style={!onChangeVisibility ? { pointerEvents: 'none' } : {}}
    >
      <FontAwesomeIcon icon={visible ? faEye : faEyeSlash} />
    </IconButton>
  );
};

export default Visibility;
