import { DialogContent, Typography } from '@mui/material';
import DraggableDialog from 'components/DraggableDialog';
import StyledButton from 'components/StyledButton';
import { ConfirmDialogProps } from 'contexts/ConfirmDialog';
import styles from './index.module.scss';

interface Props extends ConfirmDialogProps {
  open: boolean;
  onClose: () => void;
}

const ConfirmDialog = ({
  title,
  message,
  onConfirm,
  onCancel,
  onClose,
  open,
  confirmButtonText = 'Okay',
  cancelButtonText = 'Cancel',
  hideCancelButton = false,
  allowClose,
  confirmButtonStyle = 'danger',
}: Props) => {
  return (
    <DraggableDialog
      title={title}
      open={open}
      onClose={onClose}
      allowClose={allowClose}
      className={styles['content-container']}
      actions={[
        ...(hideCancelButton
          ? []
          : [
              <StyledButton
                key="cancel"
                styleName="secondary"
                onClick={() => {
                  onCancel?.();
                  onClose();
                }}
              >
                {cancelButtonText}
              </StyledButton>,
            ]),
        <StyledButton
          key="start"
          styleName={confirmButtonStyle}
          onClick={() => {
            onConfirm();
            onClose();
          }}
          solid
        >
          {confirmButtonText}
        </StyledButton>,
      ]}
    >
      <DialogContent classes={{ root: styles.content }}>
        {message.split('\n').map((part, i) => (
          <Typography key={i}>{part}</Typography>
        ))}
      </DialogContent>
    </DraggableDialog>
  );
};

export default ConfirmDialog;
