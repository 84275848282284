import { List, ListItem, Typography } from '@mui/material';
import { CustomDataType, PipelineConfigType } from 'graph/types';
import { useCallback } from 'react';
import NodeItemList from '..';
import styles from '../shared.module.scss';
import ConfigInput from './ConfigInput';

interface Props {
  nodeId: string;
  collapsed: boolean;
  configTypes: { key: string; type: PipelineConfigType }[];
  configValues: { [key: string]: any } | undefined;
  setConfigValue: (key: string, val: any) => void;
}

const ConfigList = ({
  nodeId,
  collapsed,
  configTypes,
  configValues,
  setConfigValue,
}: Props) => {
  const renderListGroup = useCallback(
    (
      nodeId: string,
      groupKey: string | undefined,
      groupLabel: string | undefined,
      groupedElements: {
        key: string;
        type: CustomDataType;
      }[]
    ) => {
      return (
        <div key={groupKey}>
          <Typography variant="caption">{groupLabel}</Typography>
          <List className={styles['group-list']}>
            {groupedElements.map((configType) => {
              const confType = configType.type as PipelineConfigType;
              if (collapsed && confType.advanced) {
                return null;
              }
              return (
                <ListItem
                  key={configType.key}
                  className={styles['config-listitem']}
                >
                  <ConfigInput
                    nodeId={nodeId}
                    configKey={configType.key}
                    configType={confType}
                    configValue={(configValues ?? {})[configType.key]}
                    setConfigValue={(val) =>
                      setConfigValue(configType.key, val)
                    }
                  />
                </ListItem>
              );
            })}
          </List>
        </div>
      );
    },
    [collapsed, configValues, setConfigValue]
  );

  return (
    <NodeItemList
      nodeId={nodeId}
      title="Config"
      items={configTypes}
      renderListGroup={renderListGroup}
    />
  );
};

export default ConfigList;
