import { Blob, IFlowGraph } from 'contexts/Graph';
import { getNodeTypeFromNode } from 'graph';
import cloneDeep from 'lodash/cloneDeep';
import { Edge } from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import {
  deepIndex,
  isJsonEqual,
  nullOrUndef,
  replaceNullsWithUndefs,
} from 'utils/helpers';
import { Pipeline_FormatConfigFlag } from '../graphql/graphql';
import { NodeCategory } from './nodeCategories';
import {
  NodeTypeKey,
  NodeTypes,
  NODE_REMOVALS,
  NODE_RENAMES,
} from './NodeTypes';
import {
  FlowGraphNode,
  FlowGraphNodeType,
  IFlowGraphNodeData,
  PipelineConfigType,
} from './types';

/**
 * This class allows for the sequential application of corrections to a graph.
 * The input values are copied so that the original values are not modified.
 */
class Corrector {
  private flowGraph: IFlowGraph;
  private blobs: Blob[];

  constructor(flowGraph: IFlowGraph, blobs: Blob[]) {
    this.flowGraph = cloneDeep(flowGraph);
    if (Array.isArray(this.flowGraph) && this.flowGraph.length === 0) {
      this.flowGraph = {
        nodes: [],
        edges: [],
      };
    }
    this.blobs = cloneDeep(blobs);
  }

  static from(flowGraph: IFlowGraph, blobs: Blob[]) {
    return new Corrector(flowGraph, blobs);
  }

  get value(): [IFlowGraph, Blob[]] {
    return [this.flowGraph, this.blobs];
  }

  get replaceNullsWithUndefs() {
    replaceNullsWithUndefs(this.flowGraph);
    return this;
  }

  // Delete any nodes that have been removed from the node types
  get deleteRemovedNodes() {
    this.flowGraph.nodes = this.flowGraph.nodes.filter((node) => {
      const deleted = NODE_REMOVALS.includes(node.data.nodeType as string);
      return !deleted;
    });
    return this;
  }

  // Rename any nodes that have been renamed
  get updateRenamedNodes() {
    this.flowGraph.nodes.forEach((node) => {
      const newNodeType = NODE_RENAMES[node.data.nodeType];
      if (newNodeType) {
        node.data.nodeType = newNodeType;
      }
    });
    return this;
  }

  // Change the moduleType for any nodes that have had their back-end modules renamed/changed
  get updateChangedModuleTypes() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeType = NodeTypes[node.data.nodeType as NodeTypeKey];
      if (nodeType.moduleType) {
        node.data.moduleType = nodeType.moduleType;
      }
    });

    return this;
  }

  // Remove any config values that aren't defined on the node type
  get removeUndefinedConfigValues(): this {
    this.flowGraph.nodes.forEach((node) => {
      const data: IFlowGraphNodeData = node.data;
      if (!data.config) {
        return;
      }
      const nodeType = NodeTypes[node.data.nodeType as NodeTypeKey];
      // Check for any keys that aren't defined in the node type
      Object.keys(data.config).forEach((key) => {
        if (
          !nodeType.configTypes?.find((c) => c.key === key) &&
          data.config &&
          key in data.config
        ) {
          // Allow the config key if 'blob_name' is in the config and it's a file format key
          const formatConfigFlagStrings = Object.values(
            Pipeline_FormatConfigFlag
          ).map((k) => k.toLowerCase());
          const isFormatConfig =
            'blob_name' in data.config && formatConfigFlagStrings.includes(key);

          if (!isFormatConfig) {
            delete data.config[key];
          }
        }
      });
    });

    return this;
  }

  // Remove any properties from nodes that aren't meant to persist
  get removeEphemeralNodeProperties(): this {
    this.flowGraph.nodes.forEach((node) => {
      delete node.height;
      delete node.width;
      delete node.dragging;
    });
    return this;
  }

  /**
   * Inject any constant config values, overriding any existing values.
   */
  get injectConstantConfigValues() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
      nodeTypeObj.configTypes?.forEach((conf) => {
        if (typeof conf.type.constantValue !== 'undefined') {
          node.data.config[conf.key] = conf.type.constantValue;
        }
      });
    });

    return this;
  }

  /*
   * For configs that have explicit "options" choices, check that the value currently set is one of those options.
   * If not, replace it with the default if there is one, or undefined.
   */
  get replaceInvalidOptionConfigValues() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
      nodeTypeObj.configTypes?.forEach((conf) => {
        if (conf.type.options) {
          if (!conf.type.options.includes(node.data.config[conf.key])) {
            const defaultValue =
              conf.type.defaultValue ?? conf.type.constantValue;
            if (
              !isJsonEqual(defaultValue, node.data.config[conf.key]) ||
              nullOrUndef(defaultValue)
            ) {
              node.data.config[conf.key] = defaultValue ?? undefined;
            }
          }
        }
      });
    });

    return this;
  }

  /*
   * For configs that are boolean, check that the value currently set is a boolean.
   * If not, replace it with the default if there is one, or false.
   */
  get replaceInvalidBooleanConfigValues() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
      nodeTypeObj.configTypes?.forEach((conf) => {
        if (conf.type.key === PipelineConfigType.BOOLEAN().key) {
          const currVal = node.data.config[conf.key];
          if (nullOrUndef(currVal)) {
            const defaultValue = Boolean(
              conf.type.defaultValue ?? conf.type.constantValue
            );
            node.data.config[conf.key] = defaultValue;
          }
        }
      });
    });

    return this;
  }

  // Remove any edges which don't have related nodes
  get removeOrphanedEdges() {
    const edgeHasRequired = (edge: Edge) => {
      const sourceNode = this.flowGraph.nodes.find((n) => n.id === edge.source);
      const targetNode = this.flowGraph.nodes.find((n) => n.id === edge.target);
      // Do the nodes exist?
      if (!sourceNode || !targetNode) {
        return false;
      }

      // Do their types exist?
      const sourceNodeType = getNodeTypeFromNode(sourceNode as FlowGraphNode);
      const targetNodeType = getNodeTypeFromNode(targetNode as FlowGraphNode);
      if (!sourceNodeType || !targetNodeType) {
        return false;
      }

      // Do the handles exist on the nodes?
      if (
        !sourceNodeType.outputTypes?.some((c) => c.key === edge.sourceHandle) ||
        !targetNodeType.inputTypes?.some((c) => c.key === edge.targetHandle)
      ) {
        return false;
      }
      return true;
    };

    this.flowGraph.edges = this.flowGraph.edges.filter(edgeHasRequired);

    return this;
  }

  // Remove any edges which don't have the config key or blob meta field they depend on.
  // Assumes there are no orphaned edges - i.e., removeOrphanedEdges has been run.
  get removeEdgesMissingDependencies() {
    const edgeHasDependencies = (edge: Edge) => {
      const sourceNode = this.flowGraph.nodes.find(
        (n) => n.id === edge.source
      )!;
      const targetNode = this.flowGraph.nodes.find(
        (n) => n.id === edge.target
      )!;

      const sourceNodeType = getNodeTypeFromNode(sourceNode as FlowGraphNode)!;
      const targetNodeType = getNodeTypeFromNode(targetNode as FlowGraphNode)!;

      const sourceDataType = sourceNodeType.outputTypes!.find(
        (d) => d.key === edge.sourceHandle
      )!;
      const targetDataType = targetNodeType.inputTypes!.find(
        (d) => d.key === edge.targetHandle
      )!;

      const sourceConfigDependency = sourceDataType.type.requiresConfigKeyValue;
      const targetConfigDependency = targetDataType.type.requiresConfigKeyValue;
      // Is either node missing a required config dependency?
      if (
        sourceConfigDependency &&
        !sourceNode.data.config[sourceConfigDependency]
      ) {
        return false;
      }
      if (
        targetConfigDependency &&
        !targetNode.data.config[targetConfigDependency]
      ) {
        return false;
      }

      const sourceBlobMetaDependency = sourceDataType.type.requiresBlobMetaKey;
      const targetBlobMetaDependency = targetDataType.type.requiresBlobMetaKey;
      // Is either node missing a required blob meta dependency?
      if (sourceBlobMetaDependency) {
        const sourceDependencyBlob = getBlobByKey(
          this.blobs,
          sourceNode.id,
          sourceBlobMetaDependency.inputKey
        );
        if (
          sourceDependencyBlob &&
          !sourceDependencyBlob.metadata?.[sourceBlobMetaDependency.metaKey]
        ) {
          return false;
        }
      }
      if (targetBlobMetaDependency) {
        const targetDependencyBlob = getBlobByKey(
          this.blobs,
          targetNode.id,
          targetBlobMetaDependency.inputKey
        );
        if (
          targetDependencyBlob &&
          !targetDependencyBlob.metadata?.[targetBlobMetaDependency.metaKey]
        ) {
          return false;
        }
      }
      return true;
    };

    this.flowGraph.edges = this.flowGraph.edges.filter(edgeHasDependencies);

    return this;
  }

  // Format config flags used to be defined in the frontend, but now are inferred from the pipeline
  // graph itself during runtime. Thus we remove any format config flags that are still defined on
  // PipelinesDataOutput nodes.
  get removeFormatConfigFlags() {
    const formatConfigFlagStrings = Object.values(
      Pipeline_FormatConfigFlag
    ).map((k) => k.toLowerCase());

    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeKey = node.data.nodeType as NodeTypeKey;
      if (nodeTypeKey === 'PipelinesDataOutput') {
        const data: IFlowGraphNodeData = node.data;
        if (data.config) {
          formatConfigFlagStrings.forEach((flagKey) => {
            if (data.config && flagKey in data.config) {
              delete data.config[flagKey];
            }
          });
        }
      }
    });
    return this;
  }

  // Infer the properties of all blobs with an edge connected
  get inferBlobProperties() {
    // Iterate over edges, looking for any with blobs associated
    this.flowGraph.edges.forEach((edge) => {
      const sourceNode = this.flowGraph.nodes.find((n) => n.id === edge.source);
      const targetNode = this.flowGraph.nodes.find((n) => n.id === edge.target);
      const sourceNodeType = getNodeTypeFromNode(sourceNode as FlowGraphNode);
      const targetNodeType = getNodeTypeFromNode(targetNode as FlowGraphNode);

      // If the target node is a data output and the connection is to its blob_input_affector handle,
      // the edge defines the type of blob generated. Here we set the file_format of the target
      // node using the source node data type.
      if (
        targetNodeType.category.key === NodeCategory.OUTPUT.key &&
        edge.targetHandle === targetNodeType.blobInputAffectorKey
      ) {
        if (targetNodeType.configTypes?.find((c) => c.key === 'file_format')) {
          this.setOutputNodeProperties(
            sourceNodeType,
            targetNode as FlowGraphNode,
            edge.sourceHandle!
          );
        }
      }
    });

    return this;
  }

  private setOutputNodeProperties(
    sourceNodeType: FlowGraphNodeType,
    targetNode: FlowGraphNode,
    handleName: string
  ) {
    const outputType = sourceNodeType?.outputTypes?.find(
      (dataType) => dataType.key === handleName
    )?.type;
    // Set the file format on the target node
    targetNode.data.config.file_format = String(
      outputType?.blobDataType ?? targetNode.data.config.file_format
    );
  }

  // Generate the value for any config fields with a generate function, or set a
  // default if provided and no value exists yet and is required
  get generateConfigValues() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
      nodeTypeObj.configTypes?.forEach(({ key, type }) => {
        if (type.generate) {
          node.data.config[key] = type.generate({
            nodeId: node.id,
            configId: key,
            node: node,
          });
        } else if (
          nullOrUndef(node.data.config[key]) &&
          type.required &&
          type.defaultValue
        ) {
          node.data.config[key] = type.defaultValue;
        }
      });
    });
    return this;
  }

  // Some config values have a dependency on a related blob meta, so we need to
  // enforce those rules where possible. For example, the end_time_secs config
  // in VideoLoaders should never exceed the duration of the video.
  get enforceConfigMetaDependencies() {
    this.flowGraph.nodes.forEach((node) => {
      const nodeTypeObj = NodeTypes[node.data.nodeType as NodeTypeKey];
      nodeTypeObj.configTypes?.forEach(({ key, type }) => {
        if (type.blobMetavalueConstraint) {
          const { blobType, propertyId, metaKey, constrainFunc } =
            type.blobMetavalueConstraint;
          const blob = getBlobByKey(this.blobs, node.id, propertyId, blobType);
          if (blob?.metadata) {
            const metaValue = deepIndex(blob.metadata, metaKey);
            if (metaValue) {
              node.data.config[key] = constrainFunc(
                node.data.config[key],
                metaValue
              );
            }
          }
        }
      });
    });

    return this;
  }
}

// Apply all corrections/migrations sequentially
export const applyCorrectionsAndMigrations = (
  graph: IFlowGraph,
  blobs: Blob[]
) =>
  // Don't allow prettier to change our lovely one-op-per-line formatting
  // prettier-ignore
  Corrector.from(graph, blobs)
    .replaceNullsWithUndefs
    .deleteRemovedNodes
    .updateRenamedNodes
    .updateChangedModuleTypes
    .removeUndefinedConfigValues
    .removeEphemeralNodeProperties
    .replaceInvalidOptionConfigValues
    .replaceInvalidBooleanConfigValues
    .injectConstantConfigValues
    .removeOrphanedEdges
    .removeEdgesMissingDependencies
    .removeFormatConfigFlags
    .inferBlobProperties
    .enforceConfigMetaDependencies
    .generateConfigValues
    .value;
