import { faTimes } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ButtonGroup } from '@mui/material';
import DelayedTooltip from 'components/DelayedTooltip';
import PointCorrespondenceWizard from 'components/PointCorrespondenceWizard';
import { PointCorrespondence } from 'components/PointCorrespondenceWizard/types';
import StyledButton from 'components/StyledButton';
import useGraph from 'hooks/useGraph';
import { useState } from 'react';
import { useStoreApi } from 'reactflow';
import { getBlobByKey } from 'utils/blobs';
import { CommonProps } from '../types';
import styles from './index.module.scss';

interface Props extends CommonProps<any, any> {
  labelOverride?: string;
}

const PointCorrespondenceInput = ({
  nodeId,
  value,
  setValue,
  configType,
  labelOverride,
}: Props) => {
  const { inputBlobs } = useGraph();

  const [open, setOpen] = useState(false);
  const flowStore = useStoreApi();
  const setInteractive = (interactive: boolean) => flowStore.setState({
    nodesDraggable: interactive,
    nodesConnectable: interactive,
    elementsSelectable: interactive,
  })
  const { resetSelectedElements } = flowStore.getState();

  const blob = getBlobByKey(inputBlobs, nodeId, configType.dependentOn!);

  // Along with opening/closing the wizard, we also need to disable/enable the
  // graph interactivity so we don't drag the node along with the dialog.
  const onOpen = () => {
    setOpen(true);
    resetSelectedElements();
    setInteractive(false);
  };
  const onClose = () => {
    setOpen(false);
    setInteractive(true);
  };

  const exists = Boolean(value);
  let tip = 'Click to open the correspondence wizard (optional).';
  const noBlobExists = !blob;
  const blobUploading = !blob?.downloadUrl?.url;
  const disabled = noBlobExists || blobUploading;
  const buttons = [
    <StyledButton
      key="open"
      styleName={exists ? 'primary' : 'secondary'}
      fullWidth
      className="nodrag"
      onClick={onOpen}
      disabled={disabled}
    >
      {labelOverride ?? configType.label}
    </StyledButton>,
  ];
  if (noBlobExists) {
    tip = 'Please upload a video to this node first.';
  } else if (blobUploading) {
    tip = 'Please wait for the video to be ready.';
  } else if (exists) {
    buttons.push(
      <StyledButton
        key="delete"
        className={`nodrag ${styles['delete-btn']}`}
        styleName="danger"
        onClick={() => setValue(undefined)}
      >
        <FontAwesomeIcon icon={faTimes} />
      </StyledButton>
    );
    tip = 'Edit or delete the correspondence.';
  }
  return (
    <div className={styles.container}>
      <PointCorrespondenceWizard
        open={open}
        onClose={onClose}
        videoBlob={blob}
        existingCorrespondence={value}
        removeExistingCorrespondence={() => setValue(undefined)}
        onSaveCorrespondence={(correspondence: PointCorrespondence) => {
          if (blob?.id && correspondence) {
            setValue(correspondence);
            onClose();
          }
        }}
      />
      <DelayedTooltip title={tip}>
        <span className={styles['btn-wrapper']}>
          <ButtonGroup fullWidth>{buttons}</ButtonGroup>
        </span>
      </DelayedTooltip>
    </div>
  );
};

export default PointCorrespondenceInput;
